import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../../_config'
import { CallApi } from './api/callApi';

export function* getJobTitle(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_JOB_TITLE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getPlatforms(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_PLATFORMS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getBusiness(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_BUSINESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageBusiness(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_BUSINESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCurrency(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CURRENCY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteSla(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_SLA + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllRegions(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_REGIONS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllRegionBudget(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_REGION_BUDGET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageRegionBudget(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_REGION_BUDGET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* regionDestroy(action: any): any {
    try {
        console.log(action.payload)
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_REGION + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageRegion(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_REGION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllZone(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_ZONE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteZone(action: any): any {
    try {
        console.log(action.payload)
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_ZONE + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageZone(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_ZONE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getNotification(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_NOTIFICATION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageNotification(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_NOTIFICATION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllTax(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_TAX, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteTax(action: any): any {
    try {
        console.log(action.payload)
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_TAX + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageTax(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_TAX, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllProductFamily(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_PRODUCT_FAMILY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteProductFamily(action: any): any {
    try {
        console.log(action.payload)
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_PRODUCT_FAMILY + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageProductFamily(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_PRODUCT_FAMILY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getZendesk(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ZENDESK, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addZendesk(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.ADD_ZENDESK, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addappoloio(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.ADD_APOLLO_IO, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getappoloio(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_APOLLO_IO, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* isNewAccount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.IS_NEW_ACCOUNT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* doneRegionSetting(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.DONE_REGION_SETTING, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllDesignation(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_DESIGNATION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageDesignation(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_DESIGNATION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteDesignation(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_DESIGNATION + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllPartner(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_PARTNER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* managePartner(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_PARTNER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deletePartner(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_PARTNER + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllApprover(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_APPROVER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageApprover(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_APPROVER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteApprover(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_APPROVER + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllApproverRule(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_APPROVER_RULE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageApproverRule(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_APPROVER_RULE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteApproverRule(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_APPROVER_RULE + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllApproverRuleCondition(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_APPROVER_RULE_CONDITION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageApproverRuleCondition(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_APPROVER_RULE_CONDITION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteApproverRuleCondition(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_APPROVER_RULE_CONDITION + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllApproval(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ALL_APPROVAL, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageApproval(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.MANAGE_APPROVAL, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteApproval(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.COMMON.DELETE_APPROVAL + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addApprover(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.ADD_APPROVER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* donebasicsetting(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.DONE_BASIC_SETTINGS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* doneplatformsetting(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.DONE_PLATFORM_SETTINGS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* updateprofile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.UPDATE_MY_PROFILE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            yield put({
                type: ACTIONS.USER.UPDATE_MY_PROFILE,
                payload: resp.data.data.details,
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);

        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* updatepasword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.UPDATE_MY_PASSWORD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getappoloioLeadPageCount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.APPOLO_LEAD_PAGE_COUNT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getappoloioLead(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.APPOLO_LEAD_GET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getappoloioAccountPageCount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.APPOLO_ACC_PAGE_COUNT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getappoloioAccount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.APPOLO_ACC_GET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getappoloioContactPageCount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.APPOLO_CONTACT_PAGE_COUNT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getappoloioContact(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.APPOLO_CONTACT_GET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}