import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import Footer from '../../components/commonComponents/Footer'
import { URLS } from '../../_config'
import { AiOutlineEdit, AiOutlineKey } from 'react-icons/ai'
import { useUserSelector } from '../../_common/hooks/selectors/userSelector'
const MyProfile = () => {
    const user: any = useUserSelector()
    // console.log('user', user)
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">My Profile</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">My Profile</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}



                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">My Profile</h5>
                                                <div className='d-flex justify-content-end mb-3 gap-10'>
                                                    <Link to={URLS.MY_PROFILE_EDIT} className="btn btn-warning"><AiOutlineEdit /> Edit Profile</Link>
                                                    <Link to={URLS.MY_CHANGE_PASSWORD} className="btn btn-success"><AiOutlineKey /> Change Password</Link>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className='table table-bordered dt-responsive nowrap'>
                                                    <tr>
                                                        <th colSpan={2}></th>
                                                    </tr>
                                                    <tr>
                                                        <td><label htmlFor="">Name</label></td>
                                                        <td>{user?.full_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><label htmlFor="">Work Email</label></td>
                                                        <td>{user?.email}</td>
                                                    </tr>
                                                    {
                                                        (user?.usertype == 1) &&
                                                        <Fragment>
                                                            <tr>
                                                                <td><label htmlFor="">Phone No.</label></td>
                                                                <td>{user?.phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><label htmlFor="">Company</label></td>
                                                                <td>{user?.company}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><label htmlFor="">Employees</label></td>
                                                                <td>{user?.employee_number}</td>
                                                            </tr>
                                                            {/* <tr>
                                                        <td><label htmlFor="">Job Title</label></td>
                                                        <td>{user?.email}</td>
                                                    </tr> */}
                                                            <tr>
                                                                <td><label htmlFor="">Country</label></td>
                                                                <td>{user?.country}</td>
                                                            </tr>
                                                            {/* <tr>
                                                        <td><label htmlFor="">State</label></td>
                                                        <td>{user?.email}</td>
                                                    </tr> */}
                                                        </Fragment>
                                                    }

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default MyProfile