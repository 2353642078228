import React, { useEffect, useMemo, useState } from 'react';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import ViewButtonCell from '../actionsBtns/ViewButtonCell';
import TableContainer from '../commonComponents/TableContainer';
import { AiOutlineEdit } from 'react-icons/ai';
import moment from 'moment';
import { formatNumber, showDate } from '../../_common/functions';
import TableLoader from '../commonComponents/TableLoader';
import { Link } from 'react-router-dom';

interface IncomingProps {
    AccountId?: any;
    selectedContractRow: any;
    setSelectedContractRow: Function;
    selectedContract: string;
    setSelectedContract: Function;
    setShowContractModal: Function;
    setContractId: Function;
    setContractIsEdit: Function;
    showContractModal: boolean;
    showMergeContractModal: boolean;
    contractStatus?: string;
    dataLoadingVal?: any
}

const ContractDataList = ({ AccountId, selectedContractRow, setSelectedContractRow, selectedContract,
    setSelectedContract, setShowContractModal, setContractId, setContractIsEdit, showContractModal,
    showMergeContractModal, contractStatus = '', dataLoadingVal }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [contractIds, setContractIds] = useState<any>([])

    const handleRowClick = (rowId: number, value: string, check: boolean) => {
        // console.log(rowId, value, check)
        // setSelectedContractRow(rowId === selectedContractRow ? null : rowId);
        // if (check) {
        //     setSelectedContract(value);
        // } else {
        //     setSelectedContract('')
        // }
        if (check) {
            setSelectedContractRow((prevSelectedContractRows: any) => [...prevSelectedContractRows, rowId]);
            setSelectedContract((prevSelectedContractId: any) => [...prevSelectedContractId, value]);
        } else {
            setSelectedContractRow((prevSelectedContractRows: any[]) => prevSelectedContractRows.filter(id => id !== rowId));
            setSelectedContract((prevSelectedContractId: any[]) => prevSelectedContractId.filter(cntctId => cntctId !== value));
        }
    };

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = {
            page: page,
            AccountId: AccountId,
            call_form: 'account',
        };
        if (contractStatus && contractStatus != "") {
            params["Status"] = contractStatus
        }
        platformApi.getAllContractForList(params,
            (message: string, resp: any) => {
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setData([])
                setLoading(false);
                hideLoader();
            })
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            setShowContractModal(true)
            setContractId(value)
            setContractIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "ContractId",
                Cell: ({ column, value, row }: any) => (
                    <>
                        <ViewButtonCell column={column} value={value} link={'contract-details'} />
                        {
                            row.original.Status && !["Activated", "Expired"].includes(row.original.Status) ?
                                <EditBtn column={column} value={value} />
                                :
                                null
                        }
                        <input
                            className='mr-2'
                            type="checkbox"
                            checked={selectedContractRow.includes(row.id)}
                            onChange={(e) => { handleRowClick(row.id, row.original, e.target.checked) }}
                        // checked={selectedContractRow === row.id}
                        // onChange={(e: any) => { handleRowClick(row.id, row.original.id, e.target.checked) }}
                        />
                    </>
                )
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
                className: "nowrap",
            },
            {
                Header: "Contract Term",
                accessor: "termtype",
                Cell: ({ column, value, row }: any) => (
                    <div className=' '>
                        {row?.original?.ContractTerm + ' ' + value}
                    </div>
                )
            },
            {
                Header: "Start Date",
                accessor: "StartDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Close Date",
                accessor: "closedate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Contracted Amount ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "MRR ($)",
                accessor: "mrr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Renewal Amount ($)",
                accessor: "renewAmt",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Uplift ($)",
                accessor: "Upliftval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },

            {
                Header: "Cross Sell ($)",
                accessor: "Crosssellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Up Sell ($)",
                accessor: "Upsellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Contraction ($)",
                accessor: "Contractionval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            // {
            //     Header: "Renewal Type",
            //     accessor: "autorenewal",
            //     Cell: ({ column, value, row }: any) =>
            //         <>
            //             {value && value == 1 ? "Auto" : "0.00"}
            //         </>,
            // },
            {
                Header: "Status",
                accessor: "newstatus",
            },
            {
                Header: "Sub Motion",
                accessor: "submotion",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? value : ""}
                    </>,
            },
        ],
        [selectedContractRow]
    )

    useEffect(() => {
        if (AccountId && !showContractModal && !showMergeContractModal) {
            getContractData();
        }
    }, [AccountId, showContractModal, showMergeContractModal])

    useEffect(() => {
        getContractData();
    }, [contractStatus, dataLoadingVal])

    const expandCollapseProduct = (id: any) => {
        let tempArr: any = [...contractIds];
        if (contractIds.includes(id)) {
            tempArr.splice(tempArr.indexOf(id), 1)
        } else {
            tempArr.push(id)
        }
        setContractIds(tempArr)
    }


    return (
        <React.Fragment>
            <div className="table-responsive">
                {/* <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getContractData}
                    loading={loading}
                    rawData={contractData}
                    clickFunc={true}
                /> */}
                <table className='table table-striped dt-responsive dataTable no-footer general-table'
                    id="datatable">
                    <thead>
                        <tr>
                            <th>Actions</th>
                            <th>Contract Number</th>
                            <th>Contract Term</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Close Date</th>
                            <th>Contracted Amount ($)</th>
                            <th>ARR ($)</th>
                            <th>MRR ($)</th>
                            <th>Renewal Amount ($)</th>
                            <th>Uplift ($)</th>
                            <th>Cross Sell ($)</th>
                            <th>Up Sell ($)</th>
                            <th>Contraction ($)</th>
                            <th>Status</th>
                            <th>Sub Motion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data?.map((item: any, index: number) => (
                                <React.Fragment key={`contractItem_${item.ContractId}`}>
                                    <tr >
                                        <td>
                                            <ViewButtonCell column={item} value={item.ContractId} link={'contract-details'} />
                                            {
                                                item.Status && !["Activated", "Expired"].includes(item.Status) &&
                                                <EditBtn column={item} value={item.ContractId} />
                                            }
                                            <input className='mr-2' type="checkbox"
                                                checked={selectedContractRow.includes(item.ContractId)}
                                                onChange={(e) => { handleRowClick(item.ContractId, item, e.target.checked) }}
                                            />
                                            <span className="collapse-link">
                                                <i className={`bx ${contractIds.includes(item.ContractId) ? 'bxs-down-arrow' : 'bxs-right-arrow'}`}
                                                    onClick={() => expandCollapseProduct(item?.ContractId)} />
                                            </span>
                                        </td>
                                        <td>
                                            <Link to={`/contract-details/${item?.ContractNumber}`}>{item?.ContractNumber}</Link>
                                        </td>
                                        <td>{item?.ContractTerm + ' ' + item?.termtype}</td>
                                        <td>{showDate(item?.StartDate, 'MM/DD/YYYY')}</td>
                                        <td>{showDate(item?.EndDate, 'MM/DD/YYYY')}</td>
                                        <td>{showDate(item?.closedate, 'MM/DD/YYYY')}</td>
                                        <td>{formatNumber(item?.ContractValue)}</td>
                                        <td>
                                            {/* {formatNumber(item?.arr)} */}
                                        </td>
                                        <td>
                                            {/* {formatNumber(item?.mrr)} */}
                                        </td>
                                        <td>
                                            {formatNumber(item?.renewAmt)}
                                        </td>
                                        <td>
                                            {/* {formatNumber(item?.Upliftval)} */}
                                        </td>
                                        <td>
                                            {/* {formatNumber(item?.Crosssellval)} */}
                                        </td>
                                        <td>
                                            {/* {formatNumber(item?.Upsellval)} */}
                                        </td>
                                        <td>
                                            {/* {formatNumber(item?.Contractionval)} */}
                                        </td>
                                        <td>{item?.newstatus}</td>
                                        <td>{item?.submotion}</td>
                                    </tr>
                                    {
                                        item && item?.multiyear?.length > 0 &&
                                        item?.multiyear?.map((childItem: any, i: number) => (
                                            <tr key={`contractItemChild_${childItem.ContractId}`} className='child-tr'
                                                style={{ display: (contractIds.includes(item.ContractId) ? "table-row" : "none") }}>
                                                <td> </td>
                                                <td>{`Year ${i + 1}`}</td>
                                                <td>{childItem?.ContractTerm + ' ' + childItem?.termtype}</td>
                                                <td>{showDate(childItem?.StartDate, 'MM/DD/YYYY')}</td>
                                                <td>{showDate(childItem?.EndDate, 'MM/DD/YYYY')}</td>
                                                <td></td>
                                                <td>{formatNumber(childItem?.ContractValue)}</td>
                                                <td>{formatNumber(childItem?.arr)}</td>
                                                <td>{formatNumber(childItem?.mrr)}</td>
                                                <td>
                                                    {/* {formatNumber(childItem?.renewAmt)} */}
                                                </td>
                                                <td>{formatNumber(childItem?.Upliftval)}</td>
                                                <td>{formatNumber(childItem?.Crosssellval)}</td>
                                                <td>{formatNumber(childItem?.Upsellval)}</td>
                                                <td>{formatNumber(childItem?.Contractionval)}</td>
                                                <td></td>
                                                <td>{childItem?.submotion}</td>

                                            </tr>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
                <TableLoader data={data} />
            </div>

        </React.Fragment>
    )
}

export default ContractDataList