import React, { useEffect, useMemo, useState } from 'react'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import AddRegionModal from '../../components/modals/addRegion.modal';
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell';
import { AiOutlineEdit } from 'react-icons/ai';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import TableContainer from '../../components/commonComponents/TableContainer';
import { toast } from 'react-hot-toast';
import { URLS } from '../../_config';
import { useNavigate } from 'react-router-dom';

const RegionSettings = () => {

    const commonApi = useCommonApi();
    const userApi = useUserApi();
    const user: any = useUserSelector();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();

    const [regionsData, setRegionsData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [regionId, setRegionId] = useState<any>(null)

    const logout = (e: any) => {
        //	console.log("logout")
        e.preventDefault();
        e.stopPropagation();
        userApi.logout({}, (message: string, resp: any) => {
        }, (message: string) => {
        })
    }

    const getRegionsData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllRegions({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setRegionsData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddRegionModal = () => {
        setShowAddRegionModal(false)
        setIsEdit(false)
        setRegionId(null)
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowAddRegionModal(true)
            setRegionId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteRegion = (id: any) => {
        console.log(id)
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteRegion({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const callbackFnAfterDelete = () => {
        getRegionsData();
    }
    
    const columns = useMemo(

        () => [
            {
                Header: 'Actions',
                accessor: "Region",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteRegion}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Region"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Region Name",
                accessor: "region_name",
            },
        ],
        []
    )

    const doneRegionSetting = () => {
        showLoader()
        commonApi.doneRegionSetting({},
            (message: string, resp: any) => {
                hideLoader()
                toast.success(message)
                navigate(URLS.BUSINESS_DETAILS)
            }, (message: string) => {
                hideLoader()
                toast.error(message)
            })
    }

    useEffect(() => {
        getRegionsData()
    }, [])

    return (
        <React.Fragment>
            <AddRegionModal
                show={showAddRegionModal}
                close={closeAddRegionModal}
                regionId={regionId}
                isEdit={isEdit}
                regionListCallbackFunc={getRegionsData}
            />
            <div id="layout-wrapper">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-3 justify-content-between">
                                            <h5 className="card-title mr-2">Region Settings</h5>
                                            <div className="d-flex align-items-center">
                                                <h5 className="w-100 mb-0" style={{ whiteSpace: "nowrap" }}>{user?.full_name}</h5>
                                                <button className="dropdown-item text-danger" type='button' onClick={logout}>
                                                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
                                                    <span key="t-logout">Logout</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Regions</h5>
                                                    <div className='d-flex gap-10 justify-content-between'>
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => setShowAddRegionModal(true)}
                                                        >
                                                            Add Region
                                                        </button>
                                                        <button
                                                            className="btn btn-info"
                                                            onClick={() => doneRegionSetting()}
                                                        >
                                                            Skip
                                                        </button>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => doneRegionSetting()}
                                                        >
                                                            Done
                                                        </button>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="table-responsive manage-user-table">
                                                            <TableContainer
                                                                columns={columns}
                                                                data={data}
                                                                fetchData={getRegionsData}
                                                                loading={loading}
                                                                rawData={regionsData}
                                                                clickFunc={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RegionSettings