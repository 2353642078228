import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { store } from '../../store';
import { ACTIONS } from '../../_config';

interface ExternalAppFormValues {
    client_id: string;
    client_secret: string;
}

const validationSchema = Yup.object().shape({
    client_id: Yup.string().required("Client id is required"),
    client_secret: Yup.string().required("Client Secret is required"),
});

interface IncomingProps {
    show: boolean;
    close: () => void;
    credentials?: any
}

const SalesforceConnectModal = ({ show, close, credentials }: IncomingProps) => {

    const [modalCount, setModalCount] = useState<number>(1);

    const { control, formState: { errors }, handleSubmit, setValue } = useForm<ExternalAppFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            client_id: credentials ? credentials?.client_id : "",
            client_secret: credentials ? credentials?.client_secret : "",
        },
    });

    const onSubmit = (data: any) => {
        // console.log({ data })
        store.dispatch({
            type: ACTIONS.SALESFORCE.SAVE_DATA,
            payload: data
        });
        window.open(`https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${data.client_id}&redirect_uri=${window.location.origin}/sfcallback`, '_blank');
    }

    useEffect(() => {
        if (credentials) {
            setValue('client_id', credentials?.client_id);
            setValue('client_secret', credentials?.client_secret);
        }
    }, [credentials])

    return (
        <React.Fragment>
            <Modal
                className='salesforce-modal'
                show={show}
            // onHide={() => { close(); setModalCount(1) }}
            >

                <div className="modal-header custom-modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => { close(); setModalCount(1) }}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    {/* [ Modal #1 ] */}
                    <div className={modalCount && modalCount == 1 ? "d-bloack" : "d-none"}>
                        {/* <div className="modal-cancel">
                            <button
                                type="button"
                                className="close" 
                                onClick={() => { close(); setModalCount(1) }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div> */}
                        <div className="modal-body">
                            <div className="sf-logo">
                                <img src="assets/images/sales-force.png" alt="#" />
                            </div>
                            <div className="mod-login">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label htmlFor="client_id">Client ID</label>
                                        {/* <input type="text" className="form-control" id="clint-id" /> */}
                                        <Controller
                                            name={"client_id"}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="client_id"
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        placeholder="Enter client id"
                                                        autoComplete='off'
                                                    />
                                                </>
                                            )}
                                        />
                                        {errors && errors.client_id &&
                                            <span className='text-red-400'>{errors?.client_id?.message}</span>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="formrow-firstname-input">Secret Key</label>
                                        {/* <input
                                            type="password"
                                            className="form-control"
                                            id="security-key"
                                        /> */}
                                        <Controller
                                            name={"client_secret"}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="client_secret"
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        placeholder="Enter client secret"
                                                        autoComplete='off'
                                                    />
                                                </>
                                            )}
                                        />
                                        {errors && errors.client_secret &&
                                            <span className='text-red-400'>{errors?.client_secret?.message}</span>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn btn-primary w-100"
                                        // data-toggle="modal"
                                        // data-target="#demo-2"
                                        // data-dismiss="modal"
                                        // onClick={() => setModalCount(2)}
                                        >
                                            Set Account
                                        </button>
                                    </div>
                                    {/* <div className="form-group d-none">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="formrow-customCheck"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="formrow-customCheck"
                                            >
                                                Remember me
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="forgot-sec d-none">
                                        <a href="#">Forget Your Password ?</a>
                                        <a href="#">Use Custom Domain</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* [ Modal #2 ] */}
                    <div className={modalCount && modalCount == 2 ? "d-bloack" : "d-none"}>
                        <div className="modal-cancel">
                            <button
                                type="button"
                                className="close"
                                // data-dismiss="modal"
                                // aria-label="Close"
                                onClick={() => { close(); setModalCount(1) }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="sf-logo">
                                <img src="assets/images/sales-force.png" alt="#" />
                            </div>
                            <div className="flp-text mod-login">
                                <ul>
                                    <li>Manage hub connections </li>
                                    <li>Manage Pardot services</li>
                                    <li>Access Lightning applications</li>
                                    <li>Access content resources</li>
                                    <li>Manage Customer Data Platform Ingestion API data</li>
                                    <li>Manage Customer Data Platform profile data</li>
                                    <li>
                                        Perform ANSI SQL queries on Customer Data Platform data
                                    </li>
                                    <li>Access chatbot services</li>
                                    <li>Perform segmentation on Customer Data Platform data</li>
                                    <li>Manage Customer Data Platform Identity Resolution</li>
                                    <li>Access Headless Forgot Password API</li>
                                    <li>Manage Customer Data Platform Calculated Insight data</li>
                                    <li>Perform requests at any time </li>
                                </ul>
                                <span>
                                    Do you want to allow access for scm@force.com?{" "}
                                    <a href="#">(Not you?)</a>
                                </span>
                                <div className="flp-btn">
                                    <button
                                        type="button"
                                        className="btn"
                                        // data-dismiss="modal"
                                        // aria-label="Close"
                                        onClick={() => { close(); setModalCount(1) }}
                                    >
                                        Deny
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        // data-toggle="modal"
                                        // data-target="#demo-2"
                                        // data-dismiss="modal"
                                        onClick={() => setModalCount(3)}
                                    >
                                        Allow
                                    </button>
                                </div>
                                <span>
                                    To revoke access at any time, go to your personal settings.
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* [ Modal #3 ] */}
                    <div className={modalCount && modalCount == 3 ? "d-bloack" : "d-none"}>
                        <div className="modal-cancel">
                            <button
                                type="button"
                                className="close"
                                // data-dismiss="modal"
                                // aria-label="Close"
                                onClick={() => { close(); setModalCount(1) }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="  fl-mod-select">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-6">
                                        <div className="fl-logo-top">
                                            <div className="arr-log mb-2">
                                                <img src="assets/images/logo-light.png" />
                                            </div>
                                            <strong>ARR Modules</strong>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-6">
                                        <div className="fl-logo-top">
                                            <div className="fl-sale-force mb-2">
                                                <img src="assets/images/sales-force.png" />
                                            </div>
                                            <strong>CRM Modules</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mod-login mt-3">
                                <div className="mod-arr-top-part">
                                    <div className="custom-control custom-switch " dir="ltr">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customSwitch1"
                                        // defaultChecked=""
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customSwitch1"
                                        />
                                    </div>
                                    <button type="button" className="btn ml-2">
                                        Account
                                    </button>
                                    <span />
                                    <button type="button" className="btn">
                                        Accounts
                                    </button>
                                    <button type="button" className="btn ml-3 manag-bt">
                                        <i className="bx bx-plus" /> Manage Mapping
                                    </button>
                                </div>
                                <div className="mod-field-sec mod-login">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <strong>ARR Field</strong>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <strong>CRM Field</strong>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <span>Account Name</span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <select className="form-control">
                                                <option>Select</option>
                                                <option>Account Name</option>
                                                <option>Billing City</option>
                                                <option>Industry</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <span>Billing City</span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <select className="form-control">
                                                <option>Select</option>
                                                <option>Account Name</option>
                                                <option>Billing City</option>
                                                <option>Industry</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <span>Industry</span>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <select className="form-control">
                                                <option>Select</option>
                                                <option>Account Name</option>
                                                <option>Billing City</option>
                                                <option>Industry</option>
                                            </select>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <a href="revenue-page.html">
                                                <button type="button" className="btn-primary w-100 btn">
                                                    Submit
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default SalesforceConnectModal