import React, { useEffect, useMemo, useState } from 'react';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import EditButtonCell from '../actionsBtns/EditButtonCell';
import ViewButtonCell from '../actionsBtns/ViewButtonCell';
import TableContainer from '../commonComponents/TableContainer';
import { formatNumber, showDate } from '../../_common/functions';

interface IncomingProps {
    id?: any;
    type?: string;
}

const OpportunityList = ({ id, type }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [opportunityData, setOpportunityData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getOpportunityData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = { page: page }
        params[`${type}`] = id;
        platformApi.getAllOpportunity(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setOpportunityData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "OpportunityId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <ViewButtonCell column={column} value={value} link={'opportunity-details'} />
                        {
                            (row?.original?.StageName != 'Close Won' && row?.original?.StageName != 'Close Lost') &&
                            <EditButtonCell column={column} value={value} link={`add-opportunity/${id}`} />
                        }
                    </>,
            },
            {
                Header: "Code",
                accessor: "id",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {row?.original?.OpportunityId}
                    </div>
                )
            },
            // {
            //     Header: "Opportunity Id",
            //     accessor: "OpportunityId",
            // },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Amount ($)",
                accessor: "Amount",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : ''}
                    </div>
                )
            },
            {
                Header: "Stage name",
                accessor: "StageName",
            },
            {
                Header: "Lead Source",
                accessor: "LeadSource",
                className: "nowrap",
            },
            {
                Header: "Contract Term",
                accessor: "termtype",
                Cell: ({ column, value, row }: any) => (
                    <div className=' '>
                        {row?.original?.contractterm + ' ' + value} 
                    </div>
                )
            },
            {
                Header: "Close Date", accessor: (d: any) => {
                    return showDate(d.CloseDate, "MM-DD-YYYY")

                },
            },
        ],
        []
    )

    useEffect(() => {
        if (id) {
            getOpportunityData();
        }
    }, [id])

    return (
        <React.Fragment>
            {/* <div className="card-body">
                <div className="d-flex flex-wrap">
                    <h5 className="card-title mr-2">Opportunity</h5>
                </div> */}
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getOpportunityData}
                    loading={loading}
                    rawData={opportunityData}
                    clickFunc={true}
                />
            </div>
            {/* </div> */}
        </React.Fragment>
    )
}

export default OpportunityList