import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { toast } from 'react-hot-toast'
import { changeString, formatNumber, formatNumberPvNv, showDate } from '../_common/functions'
import moment from 'moment'
import ForecastEndDate from '../components/modals/forecastEndDate.modal'
import { AiOutlineEdit } from 'react-icons/ai'
import ForecastUpdateModal from '../components/modals/forecastUpdate.modal'
import FilterRow from '../components/commonComponents/FilterRow'
import ForecastBulkUpdateModal from '../components/modals/forecastBulkUpdate.modal'
import UserDetailsModal from '../components/modals/UserDetails.modal'
import TitleBar from '../components/commonComponents/TitleBar'
import TableLoader from '../components/commonComponents/TableLoader'

const Forecast = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showMassUpdateModal, setShowMassUpdateModal] = useState<boolean>(false)
    const [selectedContract, setSelectedContract] = useState<any>(null)
    const [forecastSummary, setForecastSummary] = useState<any>(null)
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [showForecastModal, setShowForecastModal] = useState<boolean>(false)
    const [selectedContractId, setSelectedContractId] = useState<any>(null)
    const [selectedContractValue, setSelectedContractValue] = useState<any>(null)
    const [selectedContractColumn, setSelectedContractColumn] = useState<any>(null)
    const [selectedContractRow, setSelectedContractRow] = useState<any>([])
    const [selectedContractIds, setSelectedContractIds] = useState<any>([])

    const [showUserDetailsModal, setShowUserDetailsModal] = useState<boolean>(false)
    const [arrUserId, setArrUserId] = useState<any>(null)
    const [doFilter, setDoFilter] = useState<boolean>(false)
    const [contractIds, setContractIds] = useState<any>([])

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")

    const handleRowClick = (rowId: number, value: string, check: boolean) => {
        if (check) {
            setSelectedContractRow((prevSelectedContractRows: any) => [...prevSelectedContractRows, rowId]);
            setSelectedContractIds((prevSelectedContractId: any) => [...prevSelectedContractId, value]);
        } else {
            setSelectedContractRow((prevSelectedContractRows: any[]) => prevSelectedContractRows.filter(id => id !== rowId));
            setSelectedContractIds((prevSelectedContractId: any[]) => prevSelectedContractId.filter(cntctId => cntctId !== value));
        }
    };

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ? {
            page: page, call_form: 'forecast', year: new Date().getFullYear(), quarter: quarterValue, typeOfContract: typeOfContract
        } :
            { page: page, call_form: 'forecast', year: new Date().getFullYear() }
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllContractForList(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const getForeCastSummary = () => {
        let params: any = quarterValue && typeOfContract ? { quarter: quarterValue, typeOfContract: typeOfContract } : filterDetails;
        showLoader();
        platformApi.getForeCastSummary(params, (message: string, resp: any) => {
            setForecastSummary(resp?.data?.list)
            hideLoader();
        }, (message: string) => {
            hideLoader();
        })
    }

    const onToggleForecast = (data: any, toggle: boolean) => {
        // console.log(data.id);
        setShowModal(toggle);
        setSelectedContract(data);
        let params: any = {
            id: data.id,
            Forecasted: toggle
        };
        if (!toggle) {
            showLoader()
            platformApi.manageContract(params, (message: string, resp: any) => {
                // console.log(message, resp)
                toast.success(message)
                getContractData()
                hideLoader()
            }, (message: string) => {
                // console.log(message)
                toast.error(message)
                hideLoader()
            })
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedContract(null)
    }

    const openForecastUpdateModalClose = (column: any, value: any, contractId: any) => {
        setShowForecastModal(true);
        setSelectedContractId(contractId);
        setSelectedContractValue(value);
        setSelectedContractColumn(column);
    }

    const closeForecastUpdateModalClose = () => {
        setShowForecastModal(false);
        setSelectedContractId(null);
        setSelectedContractValue(null);
        setSelectedContractColumn(null);
    }

    const EditBtn = ({ column, value, id }: any) => {
        return (
            <span
                data-toggle="tooltip"
                data-placement="top"
                title="update/edit"
            >
                <AiOutlineEdit
                    onClick={() => openForecastUpdateModalClose(column, value, id)}
                    className='cursor-pointer ml-2'
                />
            </span >
        )
    }

    const closeUserDetailsModal = () => {
        setShowUserDetailsModal(false)
        setArrUserId(null)
    }

    const columns = useMemo(
        () => [
            {
                Header: "Action",
                accessor: 'id',
                Cell: ({ column, value, row }: any) => (
                    <>
                        <input
                            className='mr-2 cursor-pointer'
                            type="checkbox"
                            checked={selectedContractRow.includes(row.id)}
                            onChange={(e) => { handleRowClick(row.id, value, e.target.checked) }}
                        />
                    </>
                )
            },
            {
                Header: "Account Name",
                accessor: "account.Name",
            },
            {
                Header: "Account Manager",
                accessor: "account.account_managerobj.name",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {/* {console.log({ column, value, row })} */}
                        {(value) ? <div className='c-pointer text-info'
                            onClick={() => {
                                setShowUserDetailsModal(true)
                                setArrUserId(row.original?.account.AccountManager)
                            }}>{value}</div> : '--'}
                    </>,
            },
            {
                Header: "Contract",
                accessor: "ContractNumber",
                className: "nowrap",
            },
            {
                Header: "Contract End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },

            {
                Header: "Region",
                accessor: "region.region_name",
            },
            {
                Header: "Progress/Stage",
                accessor: "RenewalEventPoint",
                Cell: ({ column, value, row }: any) => (
                    <div className="progress">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${value}%` }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={row.original?.RenewalEvent}
                        >
                        </div>
                        <div className="progressHide"></div>
                    </div>
                ),
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "New ARR ($)",
                accessor: "newarr",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : '0.00'}
                    </>,
            },
            {
                Header: "In Forecast",
                accessor: "Forecasted",
                Cell: ({ column, value, row }: any) => (
                    <div className='cstm-switch'>
                        <input
                            type="checkbox"
                            id={`switch${row.id}`}
                            defaultChecked={value === "1"}
                            onChange={(e: any) => { onToggleForecast(row.original, e.target.checked) }}
                            checked={value === "1"}
                        />
                        <label htmlFor={`switch${row.id}`} data-on-label="on" data-off-label="off"></label>
                    </div>
                )
            },
            {
                Header: "Most Likely Forecast  ($)",
                accessor: "mostlikely_forecast",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                )
            },
            {
                Header: "Best Case Forecast ($)",
                accessor: "bestcase",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                )
            },
            {
                Header: "Actual Renewed ($)",
                accessor: "renewAmt",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : '0.00'}
                    </>,
            },
            {
                Header: "Actual Base Renewed ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : '0.00'}
                    </>,
            },
            {
                Header: "Renewal Risk Level",
                accessor: "RenewalRiskLevel",
            },
            {
                Header: "Forecast Close Date",
                accessor: "forecast_closedate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {console.log('column----', column)}
                        {value ? moment(value).format("MM/DD/YY") : ""}
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </>
                )
            },
            {
                Header: "Business Channel",
                accessor: "account.BusinessChannel",
            },
            {
                Header: "Next Steps",
                accessor: "nextsteps",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={value}

                        >
                            {value ? changeString(value, 16) + " ..." : ""}
                        </span>
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                ),
            },
            {
                Header: "Renewal Notes",
                accessor: "RenewalNote",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={value}

                        >
                            {value ? changeString(value, 16) + " ..." : ""}
                        </span>
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                ),
            },
            {
                Header: "Close Plans",
                accessor: "closeplan",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={value}

                        >
                            {value ? changeString(value, 16) + " ..." : ""}
                        </span>
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                ),
            },
            {
                Header: "Sub Motion",
                accessor: "submotion",
            },
        ],
        [contractData?.length, selectedContractRow]
    )

    const handleMassUpdate = () => {
        if (selectedContractRow && selectedContractRow.length == 0) {
            return toast.error('please select atleast one to proceed')
        }
        setShowMassUpdateModal(true)

    }

    const handleCloseMassUpdate = () => {
        setShowMassUpdateModal(false)
        setSelectedContractRow([])
        setSelectedContractIds([])
    }

    useEffect(() => {
        getContractData();
        getForeCastSummary();
    }, [])

    const filterCallBackFn = () => {
        getContractData();
        getForeCastSummary()
    }


    const expandCollapseProduct = (id: any) => {
        let tempArr: any = [...contractIds];
        if (contractIds.includes(id)) {
            tempArr.splice(tempArr.indexOf(id), 1)
        } else {
            tempArr.push(id)
        }
        setContractIds(tempArr)
    }

    return (
        <React.Fragment>
            {
                showUserDetailsModal && <UserDetailsModal
                    show={showUserDetailsModal}
                    close={closeUserDetailsModal}
                    arrUserId={arrUserId}
                />
            }

            <ForecastEndDate
                show={showModal}
                close={handleModalClose}
                selectedContract={selectedContract}
                callbackFunctions={filterCallBackFn}
            />
            <ForecastUpdateModal
                show={showForecastModal}
                close={closeForecastUpdateModalClose}
                contractId={selectedContractId}
                value={selectedContractValue}
                fieldDetail={selectedContractColumn}
                callbackFunctions={filterCallBackFn}
            />
            <ForecastBulkUpdateModal
                show={showMassUpdateModal}
                close={handleCloseMassUpdate}
                selectedContractIds={selectedContractIds}
                callbackFunctions={filterCallBackFn}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}

                            <TitleBar title={'Forecast'}
                                breadcrumbs={{
                                    'Forecast': '#'
                                }} >
                            </TitleBar>


                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'arr_forcasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Customers: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.customers}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">ARR: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.arr}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">MRR: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.mrr}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'expansion_forcasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Most Likely Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.mostlikely}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Best Case Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.bestcase}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'renewed')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Forecasted Net Revenue Retention Rate: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.retaintionrate}%</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">NRR: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.nrr}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">New ARR Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.newarr}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">AI Level Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.alllevel}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Base Renewal Closed: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.bestrenewalclosed}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">New ARR Closed: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.newarrcosed}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Forecasted Churn and Contraction: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.churnandcontraction}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Customer Retention Rate: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.customerretaintionrate}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Forecasted Gross Revenue Retention Rate: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.grossrevenewretaintionrate}%</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">GRR: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.grr}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="forecast"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        setDoFilter={setDoFilter}
                                        defaultYear={true}
                                        callbackFunctions={filterCallBackFn}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Forecasts</h5>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={handleMassUpdate}
                                                >
                                                    Update
                                                </button>
                                            </div>
                                            <div className="table-responsive">
                                                {/* <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getContractData}
                                                    loading={loading}
                                                    rawData={contractData}
                                                    clickFunc={true}
                                                    extraLarge={true}
                                                /> */}

                                                <table className='table table-striped dt-responsive dataTable no-footer general-table'
                                                    id="datatable">
                                                    <thead>
                                                        <tr>
                                                            <th>Actions</th>
                                                            <th>Account Name</th>
                                                            <th>Account Manager</th>
                                                            <th>Contract</th>
                                                            <th>Contract End Date</th>
                                                            <th>Region</th>
                                                            <th>Progress/Stage</th>
                                                            <th>ARR ($)</th>
                                                            <th>New ARR ($)</th>
                                                            <th>In Forecast</th>
                                                            <th>Most Likely Forecast ($)</th>
                                                            <th>Best Case Forecast ($)</th>
                                                            <th>Actual Renewed ($)</th>
                                                            <th>Actual Base Renewed ($)</th>
                                                            <th>Renewal Risk Level</th>
                                                            <th>Forecast Close Date</th>
                                                            <th>Business Channel</th>
                                                            <th>Next Steps</th>
                                                            <th>Renewal Notes</th>
                                                            <th>Close Plans</th>
                                                            <th>Sub Motion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data && data?.map((item: any, index: number) => (
                                                                <React.Fragment key={`contractItem_${item.ContractId}`}>
                                                                    <tr >
                                                                        <td>
                                                                            <input
                                                                                className='mr-2 cursor-pointer'
                                                                                type="checkbox"
                                                                                checked={selectedContractRow.includes(item.id)}
                                                                                onChange={(e) => { handleRowClick(item.id, item.id, e.target.checked) }}
                                                                            />

                                                                            <span className="collapse-link">
                                                                                <i className={`bx ${contractIds.includes(item.ContractId) ? 'bxs-down-arrow' : 'bxs-right-arrow'}`}
                                                                                    onClick={() => expandCollapseProduct(item?.ContractId)} />
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={`/account-view/${item?.AccountId && item?.AccountId != 'undefined' ? item?.AccountId : item?.quoteobject?.account?.AccountId}`}>{item?.account?.Name}</Link>
                                                                        </td>
                                                                        <td>
                                                                            {(item?.account?.account_managerobj?.name) ? <div className='c-pointer text-info'
                                                                                onClick={() => {
                                                                                    setShowUserDetailsModal(true)
                                                                                    setArrUserId(item?.account.AccountManager)
                                                                                }}>{item?.account?.account_managerobj?.name}</div> : ''}

                                                                        </td>
                                                                        <td>
                                                                            <Link to={`/contract-details/${item?.ContractId}`}>{item?.ContractNumber}</Link>
                                                                        </td>
                                                                        <td>{showDate(item?.EndDate, 'MM/DD/YYYY')}</td>
                                                                        <td>{item?.region.region_name}</td>
                                                                        <td>
                                                                            <div className="progress">
                                                                                <div
                                                                                    className="progress-bar"
                                                                                    role="progressbar"
                                                                                    style={{ width: `${item?.RenewalEventPoint}%` }}
                                                                                    aria-valuenow={25}
                                                                                    aria-valuemin={0}
                                                                                    aria-valuemax={100}
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title={item?.RenewalEvent}
                                                                                >
                                                                                </div>
                                                                                <div className="progressHide"></div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.forecastarr)} */}
                                                                        </td>
                                                                        <td>
                                                                            {formatNumberPvNv(item?.newarr)}
                                                                        </td>
                                                                        <td>
                                                                            <div className='cstm-switch'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`switch${item.id}`}
                                                                                    defaultChecked={item?.Forecasted === "1"}
                                                                                    onChange={(e: any) => { onToggleForecast(item, e.target.checked) }}
                                                                                    checked={item?.Forecasted === "1"}
                                                                                />
                                                                                <label htmlFor={`switch${item.id}`} data-on-label="on" data-off-label="off"></label>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                {item?.mostlikely_forecast ? formatNumber(item?.mostlikely_forecast) : '0.00'}
                                                                                <EditBtn column={{ id: 'mostlikely_forecast', Header: 'Most Likely Forecast  ($)' }}
                                                                                    value={item?.mostlikely_forecast} id={item.id} />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div>
                                                                                {item?.bestcase ? formatNumber(item?.bestcase) : '0.00'}
                                                                                <EditBtn column={{ id: 'bestcase', Header: 'Best Case Forecast ($)' }}
                                                                                    value={item?.bestcase} id={item.id} />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item?.renewAmt ? formatNumber(item?.renewAmt) : '0.00'}
                                                                        </td>
                                                                        <td>
                                                                            {item?.ContractValue ? formatNumber(item?.ContractValue) : '0.00'}
                                                                        </td>
                                                                        <td>
                                                                            {item?.RenewalRiskLevel}
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                {item?.forecast_closedate ? moment(item?.forecast_closedate).format("MM/DD/YY") : ""}
                                                                                <EditBtn column={{ id: 'forecast_closedate', Header: 'Forecast Close Date' }}
                                                                                    value={item?.forecast_closedate} id={item.id} />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item?.account?.BusinessChannel}
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <span
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title={item?.nextsteps}
                                                                                >
                                                                                    {item?.nextsteps ? changeString(item?.nextsteps, 16) + " ..." : ""}
                                                                                </span>
                                                                                <EditBtn column={{ id: 'nextsteps', Header: 'Next Steps' }} value={item?.nextsteps} id={item?.id} />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div>
                                                                                <span
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title={item?.RenewalNote}
                                                                                >
                                                                                    {item?.RenewalNote ? changeString(item?.RenewalNote, 16) + " ..." : ""}
                                                                                </span>
                                                                                <EditBtn column={{ id: 'RenewalNote', Header: 'Renewal Notes' }} value={item?.RenewalNote} id={item?.id} />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div>
                                                                                <span
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title={item?.closeplan}
                                                                                >
                                                                                    {item?.closeplan ? changeString(item?.closeplan, 16) + " ..." : ""}
                                                                                </span>
                                                                                <EditBtn column={{ id: 'closeplan', Header: 'Close Plans' }} value={item?.closeplan} id={item?.id} />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {item?.submotion}
                                                                        </td>
                                                                    </tr>


                                                                    {
                                                                        item && item?.multiyear?.length > 0 &&
                                                                        item?.multiyear?.map((childItem: any, i: number) => (
                                                                            <tr key={`contractItemChild_${childItem.ContractId}`} className='child-tr'
                                                                                style={{ display: (contractIds.includes(item.ContractId) ? "table-row" : "none") }}>

                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>{`Year ${i + 1}`} </td>
                                                                                <td>{showDate(childItem?.EndDate, 'MM/DD/YYYY')}</td>
                                                                                <td></td>
                                                                                <td>  </td>
                                                                                <td>
                                                                                    {formatNumber(childItem?.arr)}
                                                                                </td>
                                                                                <td>
                                                                                    {/* {formatNumber(childItem?.newarr)} */}
                                                                                </td>
                                                                                <td> </td>
                                                                                <td> </td>

                                                                                <td> </td>

                                                                                <td>{formatNumber(childItem?.renewArr)} </td>
                                                                                <td> </td>
                                                                                <td> </td>
                                                                                <td> </td>

                                                                                <td> </td>
                                                                                <td>  </td>

                                                                                <td> </td>

                                                                                <td> </td>

                                                                                <td>
                                                                                    {childItem?.submotion}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <TableLoader data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Forecast