import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeLatest, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as commonSaga from './commonSaga';
import * as userSaga from './userSaga';
import * as platformSaga from './platformSaga';
import * as zendeskSaga from './zendeskSaga';


export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.COMMON.GET_JOB_TITLE, commonSaga.getJobTitle),
    takeLatest(SAGA_ACTIONS.COMMON.GET_PLATFORMS, commonSaga.getPlatforms),
    takeLatest(SAGA_ACTIONS.COMMON.GET_BUSINESS, commonSaga.getBusiness),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_BUSINESS, commonSaga.manageBusiness),
    takeLatest(SAGA_ACTIONS.COMMON.GET_CURRENCY, commonSaga.getCurrency),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_SLA, commonSaga.deleteSla),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_REGIONS, commonSaga.getAllRegions),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_REGION_BUDGET, commonSaga.getAllRegionBudget),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_REGION_BUDGET, commonSaga.manageRegionBudget),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_REGION, commonSaga.regionDestroy),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_REGION, commonSaga.manageRegion),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_ZONE, commonSaga.getAllZone),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_ZONE, commonSaga.deleteZone),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_ZONE, commonSaga.manageZone),
    takeLatest(SAGA_ACTIONS.COMMON.GET_NOTIFICATION, commonSaga.getNotification),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_NOTIFICATION, commonSaga.manageNotification),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_TAX, commonSaga.getAllTax),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_TAX, commonSaga.manageTax),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_TAX, commonSaga.deleteTax),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_PRODUCT_FAMILY, commonSaga.getAllProductFamily),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_PRODUCT_FAMILY, commonSaga.manageProductFamily),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_PRODUCT_FAMILY, commonSaga.deleteProductFamily),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ZENDESK, commonSaga.getZendesk),
    takeLatest(SAGA_ACTIONS.COMMON.ADD_ZENDESK, commonSaga.addZendesk),
    takeLatest(SAGA_ACTIONS.COMMON.ADD_APOLLO_IO, commonSaga.addappoloio),
    takeLatest(SAGA_ACTIONS.COMMON.GET_APOLLO_IO, commonSaga.getappoloio),
    takeLatest(SAGA_ACTIONS.COMMON.IS_NEW_ACCOUNT, commonSaga.isNewAccount),
    takeLatest(SAGA_ACTIONS.COMMON.DONE_REGION_SETTING, commonSaga.doneRegionSetting),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_DESIGNATION, commonSaga.getAllDesignation),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_DESIGNATION, commonSaga.manageDesignation),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_DESIGNATION, commonSaga.deleteDesignation),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_PARTNER, commonSaga.getAllPartner),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_PARTNER, commonSaga.managePartner),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_PARTNER, commonSaga.deletePartner),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER, commonSaga.getAllApprover),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_APPROVER, commonSaga.manageApprover),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_APPROVER, commonSaga.deleteApprover),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER_RULE, commonSaga.getAllApproverRule),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_APPROVER_RULE, commonSaga.manageApproverRule),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_APPROVER_RULE, commonSaga.deleteApproverRule),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER_RULE_CONDITION, commonSaga.getAllApproverRuleCondition),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_APPROVER_RULE_CONDITION, commonSaga.manageApproverRuleCondition),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_APPROVER_RULE_CONDITION, commonSaga.deleteApproverRuleCondition),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ALL_APPROVAL, commonSaga.getAllApproval),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_APPROVAL, commonSaga.manageApproval),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_APPROVAL, commonSaga.deleteApproval),
    takeLatest(SAGA_ACTIONS.COMMON.ADD_APPROVER, commonSaga.addApprover),
    takeLatest(SAGA_ACTIONS.COMMON.DONE_BASIC_SETTINGS, commonSaga.donebasicsetting),
    takeLatest(SAGA_ACTIONS.COMMON.DONE_PLATFORM_SETTINGS, commonSaga.doneplatformsetting),

    takeLatest(SAGA_ACTIONS.COMMON.UPDATE_MY_PROFILE, commonSaga.updateprofile),
    takeLatest(SAGA_ACTIONS.COMMON.UPDATE_MY_PASSWORD, commonSaga.updatepasword),

    takeLatest(SAGA_ACTIONS.COMMON.APPOLO_LEAD_PAGE_COUNT, commonSaga.getappoloioLeadPageCount),
    takeLatest(SAGA_ACTIONS.COMMON.APPOLO_LEAD_GET, commonSaga.getappoloioLead),
    takeLatest(SAGA_ACTIONS.COMMON.APPOLO_ACC_PAGE_COUNT, commonSaga.getappoloioAccountPageCount),
    takeLatest(SAGA_ACTIONS.COMMON.APPOLO_ACC_GET, commonSaga.getappoloioAccount),
    takeLatest(SAGA_ACTIONS.COMMON.APPOLO_CONTACT_PAGE_COUNT, commonSaga.getappoloioContactPageCount),
    takeLatest(SAGA_ACTIONS.COMMON.APPOLO_CONTACT_GET, commonSaga.getappoloioContact),



    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.SIGNUP, userSaga.signup),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_USER_PLATFORM, userSaga.updateUserPlatform),
    takeLatest(SAGA_ACTIONS.USER.VERIFY_EMAIL, userSaga.verifyEmail),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_ARR_USER_PASSWORD, userSaga.updateArrUserPassword),
    takeLatest(SAGA_ACTIONS.USER.GET_LOGIN_USER, userSaga.getloginuser),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),

    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_PLATFORM_OBJECT, platformSaga.getPlatformObject),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_PLATFORM_OBJECT_FIELD, platformSaga.getPlatformObjectField),
    takeLatest(SAGA_ACTIONS.SALESFORCE.SAVE_PLATFORM_OBJECT_FIELD, platformSaga.savePlatformObjectField),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCOUNT, platformSaga.getAllAccount),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRODUCT, platformSaga.getAllProduct),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTACT, platformSaga.getAllContact),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_OPPORTUNITY, platformSaga.getAllOpportunity),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_OPPORTUNITY_STAGE_LIST, platformSaga.getOpportunityStagesList),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_OPPORTUNITY_STAGES, platformSaga.getAllOpportunityStages),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_OPPORTUNITY_STAGES, platformSaga.manageOpportunityStage),
    takeLatest(SAGA_ACTIONS.SALESFORCE.OPPORTUNITY_STAGE_DELETE, platformSaga.OpportunityStagedestroy),

    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT, platformSaga.getAllContract),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_LIST, platformSaga.getAllContractList),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_NEW, platformSaga.getAllContractNew),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_CHURNED, platformSaga.getAllContractChurned), 
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_CCONTRACTION, platformSaga.getAllContractCcontraction),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_RISK_LEVEL, platformSaga.getAllContractRiskLevel), 
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_EARLY_CONTRACTION, platformSaga.getAllContractEarlycontraction),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_CHURN_REASON, platformSaga.getAllContractChurnRreason),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRICEBOOK, platformSaga.getAllPriceBook),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_QUOTE, platformSaga.getAllQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_QUOTE_PDF, platformSaga.getQuotePdf),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT, platformSaga.manageAccount),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_PRODUCT, platformSaga.manageProduct),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_OPPORTUNITY, platformSaga.manageOpportunity),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_QUOTE, platformSaga.manageQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_PRICEBOOK, platformSaga.managePriceBook),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRICEBOOK_ENTITIES, platformSaga.getAllPriceBookEntities),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_PRICEBOOK_ENTITIES, platformSaga.managePriceBookEntities),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_QUOTE_LINEITEMS, platformSaga.getAllQuoteLineItem),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCOUNT_COMMENT, platformSaga.getAllAccountComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_COMMENT, platformSaga.manageAccountComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_QUOTE_LINEITEMS, platformSaga.manageQuoteLineItem),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_SINGLE_QUOTE_LINEITEMS, platformSaga.manageSingleQuoteLineItem),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_ARR_USER, platformSaga.getAllArrUsers),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ARR_USER, platformSaga.manageArrUser),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCNT_SA_COMMENT, platformSaga.getAllAccountSaComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCNT_SA_COMMENT, platformSaga.manageAccountSaComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_RENEWAL_PROCESS, platformSaga.getRenewalProcess),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_RENEWAL_PROCESS, platformSaga.manageRenewalProcess),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTACT, platformSaga.manageContact),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ACCOUNT_TEAM, platformSaga.getAccountTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_TEAM, platformSaga.manageAccountTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CUSTOMER_TEAM, platformSaga.getCustomerTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CUSTOMER_TEAM, platformSaga.manageCustomerTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ARR_USER, platformSaga.arrUserDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT, platformSaga.accountDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_COMMENT, platformSaga.accountCommentDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_SA_COMMENT, platformSaga.accountSaCommentDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_TEAM, platformSaga.accoungTeamDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CUSTOMER_TEAM, platformSaga.customerTeamDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_PRODUCT, platformSaga.productDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CONTACT, platformSaga.contactDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ADD_ACCOUNT_POC, platformSaga.addAccountPOC),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_OPPORTUNITY, platformSaga.opportunityDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_PRICEBOOK, platformSaga.pricebookDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_PRICEBOOK_ENTITY, platformSaga.pricebookEntityDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_QUOTE, platformSaga.quoteDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_QUOTE_LINEITEMS, platformSaga.quoteLineitemDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.CLONE_QUOTE, platformSaga.cloneQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.CREATE_QUOTE_FROM_CONTRACT, platformSaga.createQuoteFromContract),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_REVENUE_DETAILS, platformSaga.getRevenueDetails),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT, platformSaga.manageContract),
    takeLatest(SAGA_ACTIONS.SALESFORCE.TRANSFER_CONTRACT, platformSaga.transferContract),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_LATE_RENEWALS, platformSaga.getLateRenewals),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_LATE_RENEWALS_CP, platformSaga.getlaterenewalsCP),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_UPSELLS, platformSaga.getUpsells),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_PRODUCT, platformSaga.getContractProduct),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_REVENUE_MAP_DETAILS, platformSaga.getRevenueMapDetails),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_LEVEL_LIST, platformSaga.getContractLevelList),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_TIME_LEVEL_LIST, platformSaga.getContractTimeLevelList),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_RENEWVAL_EVENT, platformSaga.manageContractReNewvalEvent),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_REASON_COC, platformSaga.getContractReasonCOC),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_RENEWAL_NOTES, platformSaga.manageRenewalNotes),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_RENEWAL_NOTES, platformSaga.getRenewalNotes),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_ACCOUNT_TEAM, platformSaga.getContractAccountTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CONTRACT_ACCOUNT_TEAM, platformSaga.accoungContractTeamDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_ACCOUNT_TEAM, platformSaga.manageContractAccountTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CUSTOMER_CONTACT, platformSaga.getAllCustomerContact),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CUSTOMER_CONTACT, platformSaga.contactCustomerDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CUSTOMER_CONTACT, platformSaga.manageCustomerContact),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ADD_CONTRACT_MANAGER, platformSaga.addcontractmanager),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_QUOTES_SUMMERY, platformSaga.getQuotesSummery),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_CUSTOMER_TEAM, platformSaga.manageContractCustomerTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CROSSSELLS, platformSaga.getCrossSells),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_PDF, platformSaga.getContractPdf),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_FORECAST_SUMMARY, platformSaga.getForeCastSummary),
    takeLatest(SAGA_ACTIONS.SALESFORCE.UPLOAD_CONTRACT_DOC, platformSaga.uploadContractDoc),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MERGE_CONTRACT, platformSaga.mergeContract),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ARR_PAGE_LIST, platformSaga.getArrPageList),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ACCOUNT_PARTNER, platformSaga.getAccountPartner),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_PARTNER, platformSaga.manageAccountPartner),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_PARTNER, platformSaga.deleteAccountPartner),
    takeLatest(SAGA_ACTIONS.SALESFORCE.FORCAST_BULK_UPDATE, platformSaga.forecastBlukUpdate),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_APPROVAL_RULES_FOR_QUOTE, platformSaga.getAllApprovalRulesForQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ADD_APPROVAL_FOR_QUOTE, platformSaga.addApprovalForQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ADD_QUOTE_SIGNATURE, platformSaga.addQuoteSignature),
    takeLatest(SAGA_ACTIONS.SALESFORCE.UPDATE_CONTRACT_ENTITLEMENT, platformSaga.updateContractEntitlement),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_LEAD, platformSaga.getAllLead),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_LEAD, platformSaga.manageLead),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_LEAD, platformSaga.leaddestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.CREATE_OPPORTUNITY_FROM_LEAD, platformSaga.createOpportunityFromLead),
    takeLatest(SAGA_ACTIONS.SALESFORCE.QUOTE_MULTIYEAR_EDIT, platformSaga.editQuoteMultiyear),
    takeLatest(SAGA_ACTIONS.SALESFORCE.QUOTE_LINEITEM_MULTIYEAR_EDIT, platformSaga.editQuoteLineitemMultiyear),

    takeLatest(SAGA_ACTIONS.ZENDESK.GET_ZENDESK_OPEN_TICKET, zendeskSaga.getZendeskOpenTicket),
    takeLatest(SAGA_ACTIONS.ZENDESK.GET_ZENDESK_TICKET_BY_ACCOUNT, zendeskSaga.getZendeskTicketByAccount),


    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_ACTIVITY_LIST, platformSaga.getAllActivityForAccountView),
   
    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_NOTE_LIST, platformSaga.getAllNoteForAccountView),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_NOTE_ADD_EDIT, platformSaga.addEditNoteForAccountView),

    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_TASK_LIST, platformSaga.getAllTaskForAccountView),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_TASK_ADD_EDIT, platformSaga.addEditTaskForAccountView),

    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_CALL_LIST, platformSaga.getAllCallForAccountView),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_CALL_ADD_EDIT, platformSaga.addEditCallForAccountView),

    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_MEETING_LIST, platformSaga.getAllMeetingForAccountView),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_MEETING_ADD_EDIT, platformSaga.addEditMeetingForAccountView),
    
    takeLatest(SAGA_ACTIONS.SALESFORCE.OPPORTUNITY_PIPELINE_LIST, platformSaga.getOpportunityPipeline),

    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_ALL_CSV, platformSaga.getallcsv),
    takeLatest(SAGA_ACTIONS.SALESFORCE.UPLOAD_CSV, platformSaga.uploadCSV),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_CSV_DATA, platformSaga.getcsvfiledata),
    takeLatest(SAGA_ACTIONS.SALESFORCE.STORE_CSV_DATA, platformSaga.storecsvdata),
    

    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_POWER_BI_TOKEN, platformSaga.getPowerBIToken),

  ]);
}