import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import { deleteNotification, formatNumber, getNextDate, handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../_common/functions'
import Select from 'react-select'
import InputMask from 'react-input-mask'
import { AiOutlineDelete, AiOutlineEye, AiOutlineOrderedList, AiOutlinePlusSquare } from 'react-icons/ai'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import SelectTaxModal from '../components/modals/selectTax.modal'
import { useCommonFunction } from '../_common/hooks/common/appCommonFunctionHook'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import AddRegionModal from '../components/modals/addRegion.modal'
import { billType, countriesData, quoteStatusData, termTypeOptionsStatic } from '../_common/staticData'
import QuoteSignatureModal from '../components/modals/quoteSignature.modal'
import { URLS } from '../_config'
import AddPartnerModal from '../components/modals/addPartnerModal.modal'
import AddContactModal from '../components/modals/addContact.modal'

import { convertToRaw, EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "@tinymce/tinymce-react";
import { numberFieldValidationMsg } from '../_common/numberFieldRestriction'

interface AddQuoteFormValues {
    quoteName: string;
    contractName: any;
    opportunityName: any;
    accntName: any;
    expDate: any;
    billingFrequency: string;
    status: any;
    description: string;
    subTotal: string;
    discount: string;
    totalPrice: string;
    tax: string;
    shipping: string;
    grandTotal: string;
    contactName: string;
    email: string;
    phone: string;
    fax: string;
    partnerid: any;
    billingName: string;
    billingStreet: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
    billingCountry: any;
    shippingName: string;
    shippingStreet: string;
    shippingCity: string;
    shippingState: string;
    shippingZip: string;
    shippingCountry: any;
    file: any;
    region: any;
    // tandc: string;
    billing_frequency_type: any;
    upliftperfornextquote: string;
    addsignature: boolean;
    contractstartdate: any;
    termtype: any,
    contractterm: string | number;
}

const validationSchema = Yup.object().shape({
    quoteName: Yup.string().required("Quote Name is required"),
    // accntName: Yup.object().required("Account is required"),
    region: Yup.object().required("Region is required"),
    status: Yup.object().required("Status is required"),
    billingFrequency: Yup.string().required("Billing frequency is required"),
    billing_frequency_type: Yup.object().required("Billing frequency type is required"),
    contractterm: Yup.string().required("Contract Term is required"),
    upliftperfornextquote: Yup.string().required("Uplift Percentage is required"),
    termtype: Yup.object().required("Type is required"),

});


const AddQuote = () => {

    const urlParams: any = useParams();
    const accountId = urlParams?.id.split('&')[0];
    const platformApi = usePlatformApi()
    const commonApi = useCommonApi()
    const commonFunctions = useCommonFunction()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const quoteId: any = searchParams.get("id");
    const type: any = searchParams.get("type");
    const accountIdForOpp: any = searchParams.get("AccountId");
    const func: any = searchParams.get("func");
    const contractId: any = searchParams.get("contractId");
    const cTerm: any = searchParams.get("cTerm");
    const uplift: any = searchParams.get("uplift");

    var urlOpportunityId = (type == 'OpportunityId') ? urlParams.id : '';

    const { showLoader, hideLoader } = useAppLoader()

    const [quoteData, setQuoteData] = useState<any>(null)
    const [sameAsBilling, setSameAsBilling] = useState<boolean>(false)
    const [countriesOptions, setCountriesOptions] = useState<any>(countriesData)
    const [quoteStatusOptions, setQuoteStatusOptions] = useState<any>(quoteStatusData)
    const [partnerOptions, setPartnerOptions] = useState<any>(null)
    const [accountOptions, setAccountOptions] = useState<any>(null)
    const [opportunityOptions, setOpportunityOptions] = useState<any>(null)
    const [contractOptions, setContractOptions] = useState<any>(null)
    const [contactOptions, setContactOptions] = useState<any>(null)

    const [accountNameShow, setAccountNameShow] = useState<any>(true)
    const [opportunityNameShow, setOpportunityNameShow] = useState<any>(true)

    const [accountNameDisable, setAccountNameDisable] = useState<any>(false)
    const [opportunityNameDisable, setOpportunityNameDisable] = useState<any>(false)
    const [contractNameDisable, setContractNameDisable] = useState<any>(false)
    const [partnerNameDisable, setPartnerNameDisable] = useState<any>(false)
    const [contactDisable, setContactDisable] = useState<any>(false)

    const [selectedAccountId, setSelectedAccountId] = useState<any>('')
    const [selectedOpportunityId, setSelectedOpportunityId] = useState<any>(urlOpportunityId)


    const [fileValue, setFileValue] = useState<any>()
    const [showTaxModal, setShowTaxModal] = useState<boolean>(false)
    const [taxValue, setTaxValue] = useState<any>(null)
    const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false)
    const [showQuoteSignatureModal, setShowQuoteSignatureModal] = useState<boolean>(false)
    const [signatureFullname, setSignatureFullname] = useState<string>('')
    const [signatureUrl, setSignatureUrl] = useState<any>()
    const [selectedAccount, setSelectedAccount] = useState<any>(null)
    const [selectedOpportunity, setSelectedOpportunity] = useState<any>(null)

    const [opportunityData, setOpportunityData] = useState<any>(null)

    const [regionOptions, setRegionOptions] = useState<any>(null)
    const [showAddPartnerModal, setShowPartnerModal] = useState<boolean>(false)
    const [showAddContactModal, setShowContactModal] = useState<boolean>(false)
    const [termTypeOptions, setTermTypeOptions] = useState<any>(termTypeOptionsStatic)

    const [editorState, setEditorState] = useState<any>()

    const datePickerRef = useRef<any>();
    const editorRef: any = useRef<any>(null);

    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm<AddQuoteFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            quoteName: "",
            contractName: undefined,
            opportunityName: undefined,
            accntName: undefined,
            expDate: "",
            billing_frequency_type: '',
            billingFrequency: '1',
            status: '',
            description: "",
            subTotal: "",
            discount: "",
            totalPrice: "",
            tax: "",
            shipping: "",
            grandTotal: "",
            contactName: "",
            email: undefined,
            phone: undefined,
            fax: "",
            partnerid: undefined,
            billingName: "",
            billingStreet: "",
            billingCity: "",
            billingState: "",
            billingZip: "",
            billingCountry: undefined,
            shippingName: "",
            shippingStreet: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: undefined,
            file: undefined,
            region: undefined,
            // tandc: "",
            upliftperfornextquote: "0",
            addsignature: false,
            contractstartdate: "",
            termtype: '',
            contractterm: "12",
        },
    });

    const bName = watch('billingName')
    const bStreet = watch('billingStreet')
    const bCity = watch('billingCity')
    const bState = watch('billingState')
    const bZip = watch('billingZip')
    const bCountry = watch('billingCountry')
    const selectedShipping: any = watch('shipping');
    const selectedSubTotal: any = watch('subTotal');
    const selectedDiscount: any = watch('discount');
    const selectedGrandTotal: any = watch('grandTotal');
    const selectedTax: any = watch('tax');
    const selectedContact: any = watch('contactName');
    const selectedBillingFreqType: any = watch('billing_frequency_type');
    const selectedStatus: any = watch('status');

    // console.log('selectedBillingFreqType', selectedBillingFreqType)
    const getRegionList = (page = 1) => {
        showLoader();
        commonApi.getAllRegions({ page: page },
            (message: string, resp: any) => {
                let tempData = resp.data.list.data
                if (tempData && tempData.length > 0) {
                    setRegionOptions(tempData.map((item: any) => {
                        item.label = item.region_name
                        item.value = item.Region
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getAccountPartnerData = (id: any, filterTypeLable: string) => {
        var params = {};
        if (selectedOpportunityId != '') {
            params = { OpportunityId: selectedOpportunityId }
        } else if (filterTypeLable == 'opportunity') {
            params = { OpportunityId: id }
        } else {
            params = { AccountId: id };
        }

        platformApi.getAccountPartner(params,
            (message: string, resp: any) => {
                const tempList: any = resp.data.list.data;
                setPartnerOptions([]);
                if (tempList && tempList.length > 0) {
                    setPartnerOptions(tempList.map((item: any) => {
                        item.label = item?.partner.name
                        item.value = item?.partner.id
                        return item;
                    }));
                }
            }, (message: string) => {
            })
    }

    const getContactList = (AccountId: string, filterTypeLable: string) => {
        showLoader();
        var params = {};
        if (type == 'OpportunityId' || filterTypeLable == 'opportunity') {
            params = { OpportunityId: AccountId }
        } else if (selectedOpportunityId != '') {
            params = { OpportunityId: selectedOpportunityId }
        } else {
            params = { AccountId: AccountId }
        }
        platformApi.getAllContact(params,
            (message: string, resp: any) => {
                const tempData = resp.data.list.data
                setContactOptions([]);
                if (tempData && tempData.length > 0) {
                    setContactOptions(tempData.map((item: any) => {
                        item.value = item.ContactId
                        item.label = item?.FirstName + " " + item?.LastName
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getContractList = (AccountId: string, filterTypeLable: string) => {
        showLoader();
        var params = (filterTypeLable == 'account') ? { AccountId: AccountId } :
            ((filterTypeLable == 'opportunity') ? { OpportunityId: AccountId } : {});

        platformApi.getAllContract(params,
            (message: string, resp: any) => {
                const tempData = resp.data.list.data
                setContractOptions([])
                if (tempData && tempData.length > 0) {
                    setContractOptions(tempData.map((item: any) => {
                        item.value = item.ContractId
                        item.label = item.ContractNumber
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getQuoteData = (quoteId: string) => {
        showLoader();
        platformApi.getAllQuote({ QuoteId: quoteId },
            (message: string, resp: any) => {
                setQuoteData(resp.data.list.data[0]);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getAccountList = (id: any = '', listFor: any = '') => {
        showLoader();
        var params = (listFor == 'account') ? { AccountId: id } :
            ((listFor == 'opportunity') ? { OpportunityId: id } : {});
        platformApi.getAllAccount(params,
            (message: string, resp: any) => {
                const tempData = resp.data.list.data
                // if(tempData && tempData.length == 1){
                //     setSelectedAccount(tempData[0])
                // }

                if (tempData && tempData.length > 0) {
                    setAccountOptions(tempData.map((item: any) => {
                        item.value = item.AccountId
                        item.label = item.Name
                        item.Region = item.Region
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getOpportunityList = (id: any = '', listFor: any = '') => {
        showLoader();
        var params = (listFor == 'account') ? { AccountId: id } :
            ((listFor == 'opportunity') ? { OpportunityId: id } : {});
        platformApi.getAllOpportunity(params,
            (message: string, resp: any) => {
                const tempData = resp.data.list.data
                setOpportunityData(tempData);

                if (tempData && tempData.length > 0) {
                    setOpportunityOptions(tempData.map((item: any) => {
                        item.value = item.OpportunityId
                        item.label = item.Name
                        item.region_id = item.Region
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const handleShowTaxModal = () => {
        setShowTaxModal(true)
    }

    const handleCloseTaxModal = () => {
        setShowTaxModal(false)
    }

    const handleCloseQuoteSignatureModal = () => {
        setShowQuoteSignatureModal(false)
    }

    const uploadSignature = (quoteid: string, id: string) => {
        if (signatureUrl && (signatureUrl != undefined || signatureUrl != '')) {
            let fd = new FormData();
            fd.append('id', id);
            fd.append('signature_fullname', signatureFullname);
            fd.append('signature', signatureUrl)
            showLoader()
            platformApi.addQuoteSignature(fd, (message: string, resp: any) => {
                // toast.success(message)
                hideLoader()
                if (type == 'OpportunityId') {
                    navigate(`/quote-details/${quoteid}?type=OpportunityId&opId=${accountId}`)
                } else {
                    navigate(`/quote-details/${quoteid}`)
                }
            }, (message: string) => {
                // toast.error(message)
                hideLoader()
            })
        } else {
            if (type == 'OpportunityId') {
                navigate(`/quote-details/${quoteid}?type=OpportunityId&opId=${accountId}`)
            } else {
                navigate(`/quote-details/${quoteid}`)
            }
        }
    }

    const onSubmit = (data: any) => {
        let formData: any = new FormData()

        if ((selectedAccountId == '' || selectedAccountId == 'undefined') && selectedOpportunityId == '') {
            toast.error('Select an Account or Opportunity');
            return false;
        }
        if (data?.accntName?.value) {
            formData.append("AccountId", data?.accntName?.value)
        }
        if (type == 'OpportunityId') { // Create quote from Opportunity
            formData.append("OpportunityId", accountId)
            formData.append("AccountId", accountIdForOpp)
        }
        if (data?.opportunityName?.value) {
            formData.append("OpportunityId", data?.opportunityName?.value)
        }
        if (data?.contractName?.value) {
            formData.append("ContractId", data?.contractName?.value)
        }
        if (data?.contactName?.value) {
            formData.append("ContactId", data?.contactName?.value)
        }
        if (data?.quoteName) {
            formData.append("Name", data?.quoteName)
        }
        if (data?.phone) {
            formData.append("Phone", data?.phone)
        }
        if (data?.billingCity) {
            formData.append("BillingCity", data?.billingCity)
        }
        if (data?.billingCountry?.value) {
            formData.append("BillingCountry", data?.billingCountry?.value)
        }
        if (data?.billingName) {
            formData.append("BillingName", data?.billingName)
        }
        if (data?.billingZip) {
            formData.append("BillingPostalCode", data?.billingZip)
        }
        if (data?.billingState) {
            formData.append("BillingState", data?.billingState)
        }
        if (data?.billingStreet) {
            formData.append("BillingStreet", data?.billingStreet)
        }
        if (data?.description) {
            formData.append("Description", data?.description)
        }
        if (data?.discount) {
            formData.append("Discount", data?.discount)
        }
        if (data?.email) {
            formData.append("Email", data?.email)
        }
        if (data?.expDate) {
            formData.append("ExpirationDate", data?.expDate ? moment(data.expDate).format("MM/DD/YYYY") : "")
        }
        if (data?.contractstartdate) {
            formData.append("contractstartdate", data?.contractstartdate ? moment(data.contractstartdate).format("MM/DD/YYYY") : "")
        }
        if (data?.fax) {
            formData.append("Fax", data?.fax)
        }
        if (data?.grandTotal) {
            formData.append("GrandTotal", data?.grandTotal)
        }
        if (data?.shippingCity) {
            formData.append("ShippingCity", data?.shippingCity)
        }
        if (data?.shippingCountry?.value) {
            formData.append("ShippingCountry", data?.shippingCountry?.value)
        }
        if (data?.shippingName) {
            formData.append("ShippingName", data?.shippingName)
        }
        if (data?.shippingZip) {
            formData.append("ShippingPostalCode", data?.shippingZip)
        }
        if (data?.shippingState) {
            formData.append("ShippingState", data?.shippingState)
        }
        if (data?.shippingStreet) {
            formData.append("ShippingStreet", data?.shippingStreet)
        }
        if (data?.shipping) {
            formData.append("ShippingCharge", data?.shipping)
        }
        if (data?.status?.value) {
            formData.append("Status", data?.status?.value || "")
        }
        if (data?.subTotal) {
            formData.append("Subtotal", data?.subTotal)
        }
        if (data?.tax) {
            formData.append("Tax", data?.tax)
        }
        if (data?.totalPric) {
            formData.append("TotalPrice", data?.totalPric)
        }
        if (data?.region?.value) {
            formData.append("Region", data?.region?.value || "")
        }
        if (data?.billingFrequency) {
            formData.append("billing_frequency", data?.billingFrequency)
        }
        if (data?.billing_frequency_type) {
            formData.append("billing_frequency_type", data?.billing_frequency_type?.value)
        }
        // if (data?.tandc) {
        //     formData.append("tandc", data?.tandc)
        // }
        // if(editorState) {
        //     formData.append("tandc", draftToHtml(convertToRaw(editorState.getCurrentContent())))
        // }
        if (editorRef.current) {
            let editorContent = editorRef?.current.getContent()
            // if (editorContent == '') {
            //   setDescErrorMessage("Story text is required")
            //   editorRef?.current.focus()
            // }
            // else if (errorMessage != '') {
            //   editorRef?.current.focus()
            // }
            // else {
            formData.append('tandc', editorContent)
            // }
        }
        if (data?.partnerid) {
            formData.append("partnerid", data?.partnerid?.value)
        }
        if (data?.upliftperfornextquote) {
            formData.append("upliftperfornextquote", data?.upliftperfornextquote)
        }
        if (quoteData && quoteData?.id) {
            // params["id"] = quoteData?.id
            formData.append('id', quoteData?.id)
        }
        if (data?.contractterm && data?.contractterm != "") {
            formData.append("contractterm", data?.contractterm)
        }
        if (data?.termtype && data?.termtype.value != "") {
            formData.append("termtype", data?.termtype.value)
        }
        if (taxValue) {
            formData.append("taxid", taxValue?.id)
            formData.append("taxper", taxValue?.taxpercent)
        }
        if (fileValue) {
            formData.append('sla', fileValue)
        }
        formData.append('addsignature', data?.addsignature === true ? "1" : "0")
        showLoader()
        platformApi.manageQuote(formData, (message: string, resp: any) => {
            toast.success(message)
            reset()
            uploadSignature(resp?.data?.details?.QuoteId, resp?.data?.details?.id)
            // navigate(`/quote-details/${resp?.data?.details?.QuoteId}`)
            hideLoader()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const fileOnChange = (data: any) => {
        if (data?.target?.files) {
            setFileValue(data.target.files[0])
        }
    }

    const closeAddRegionModal = () => {
        setShowAddRegionModal(false)
    }

    const closeAddPartnerModal = () => {
        setShowPartnerModal(false)
    }

    const closeAddContactModal = () => {
        setShowContactModal(false)
    }

    const onEditorStateChange = (data: any) => {
        // console.log(draftToHtml(convertToRaw(data.getCurrentContent())))
        setEditorState(data)
    }

    const onEditorChange = (data: any) => {
        // console.log({ data })
        // setEditorState(data)
    }

    useEffect(() => {
        if (selectedBillingFreqType?.value != 'Monthly') {
            setValue('billingFrequency', '1')
        }
    }, [selectedBillingFreqType])

    useEffect(() => {
        if (taxValue) {
            setValue("tax", taxValue?.taxpercent)
        }
    }, [taxValue])

    useEffect(() => {
        if (accountId && accountOptions && accountOptions.length > 0) {
            setValue('accntName', accountOptions?.find((item: any) => item.value == accountId) || null)
        }
        if (accountOptions && accountOptions.length == 1) {
            var regionId = accountOptions[0].Region
            setValue('region', regionOptions?.find((item: any) => +item.value == +regionId))
        }
    }, [accountId, accountOptions])

    useEffect(() => {
        if (accountId && opportunityOptions && opportunityOptions.length > 0) {
            setValue('opportunityName', opportunityOptions?.find((item: any) => item.value == accountId) || null)
        }
    }, [accountId, opportunityOptions])

    useEffect(() => {
        if (quoteId) {
            getQuoteData(quoteId)
        }
    }, [quoteId])



    useEffect(() => {
        setAccountNameDisable(false);
        setOpportunityNameDisable(false);

        setContractNameDisable(true);
        setPartnerNameDisable(true);
        setContactDisable(true);

        var selectedAccId = (selectedAccount) ? selectedAccount.value : ''
        setSelectedAccountId(selectedAccId)

        if (selectedAccount) { // Account selected
            setOpportunityNameDisable(true);
            setValue('region', regionOptions?.find((item: any) => +item.value == +selectedAccount?.Region))
        }

        if (selectedAccId.includes('A-')) {
            getContractList(selectedAccId, 'account')
            getContactList(selectedAccId, 'account')
            getAccountPartnerData(selectedAccId, 'account')
        }

        var selectedOppId = (selectedOpportunity) ? selectedOpportunity.value : '';
        setSelectedOpportunityId(selectedOppId)

        if (selectedOpportunity) { // Opportunity selected
            setAccountNameDisable(true);

            setValue('region', regionOptions?.find((item: any) => +item.value == +selectedOpportunity?.Region))
            setValue('termtype', termTypeOptions?.find((item: any) => item.value === selectedOpportunity?.termtype) || '')
            setValue('contractterm', selectedOpportunity?.contractterm || '0.00')
        }

        if (selectedOppId.includes('OP-')) {
            // getContractList(selectedOppId, 'opportunity')
            getContactList(selectedOppId, 'opportunity')
            getAccountPartnerData(selectedOppId, 'opportunity')
        }


        if (selectedAccount || selectedOpportunity) {
            setContractNameDisable(false);
            setPartnerNameDisable(false);
            setContactDisable(false);
        }
    }, [selectedAccount, selectedOpportunity, accountId])

    useEffect(() => {
        if (contractId && contractOptions && contractOptions.length > 0) {
            setValue('contractName', contractOptions?.find((item: any) => item.id == contractId) || '')
        }
    }, [contractId, contractOptions])

    useEffect(() => {

        if (quoteData && func == null) {
            setValue('quoteName', quoteData?.Name)
            setValue('contractName', contractOptions?.find((item: any) => item.value === quoteData?.ContractId) || '')
            setValue('accntName', accountOptions?.find((item: any) => item.value === quoteData?.AccountId) || '')
            setValue('billing_frequency_type', billType?.find((item: any) => item.value === quoteData?.billing_frequency_type) || '')
            setValue('expDate', quoteData?.ExpirationDate && new Date(quoteData?.ExpirationDate))
            setValue('contractstartdate', quoteData?.contractstartdate && new Date(quoteData?.contractstartdate))
            // setValue('contractstartdate', quoteData?.contract && quoteData?.contract?.EndDate && getNextDate(quoteData?.contract?.EndDate))
            setValue('billingFrequency', quoteData?.billing_frequency)
            setValue('status', quoteStatusOptions?.find((item: any) => item.value === quoteData?.Status))
            setValue('region', regionOptions?.find((item: any) => +item.value == +quoteData?.Region))
            setValue('partnerid', partnerOptions?.find((item: any) => item.value == quoteData?.partnerid))
            setValue('description', quoteData?.Description)
            setValue('subTotal', quoteData?.Subtotal)
            setValue('discount', quoteData?.Discount)
            setValue('totalPrice', quoteData?.TotalPrice)
            setValue('tax', quoteData?.Tax)
            setValue('shipping', quoteData?.ShippingCharge)
            setValue('grandTotal', quoteData?.GrandTotal)
            setValue('contactName', contactOptions?.find((item: any) => item.value === quoteData?.ContactId))
            setValue('email', quoteData?.Email)
            setValue('phone', quoteData?.Phone)
            setValue('fax', quoteData?.Fax)
            // setValue('tandc', quoteData?.tandc)
            if (quoteData?.tandc) {
                const contentBlock = htmlToDraft(quoteData?.tandc)
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorContent = EditorState.createWithContent(contentState);
                    setEditorState(editorContent)
                }
            }
            setValue('upliftperfornextquote', quoteData?.upliftperfornextquote)
            setValue('termtype', termTypeOptions?.find((item: any) => item.value === quoteData?.termtype) || '')
            setValue('contractterm', quoteData?.contractterm)
            setValue('addsignature', quoteData?.addsignature && quoteData?.addsignature == "1" ? true : false)
            setValue('billingName', quoteData?.BillingName)
            setValue('billingStreet', quoteData?.BillingStreet)
            setValue('billingCity', quoteData?.BillingCity)
            setValue('billingState', quoteData?.BillingState)
            setValue('billingZip', quoteData?.BillingPostalCode)
            setValue('billingCountry', countriesData?.find((item: any) => item.value === quoteData?.BillingCountry) || '')
            setValue('shippingName', quoteData?.ShippingName)
            setValue('shippingStreet', quoteData?.ShippingStreet)
            setValue('shippingCity', quoteData?.ShippingCity)
            setValue('shippingState', quoteData?.ShippingState)
            setValue('shippingZip', quoteData?.ShippingPostalCode)
            setValue('shippingCountry', countriesData?.find((item: any) => item.value === quoteData?.ShippingCountry))
        } else if (func && func == 'ADD' && accountId != 'undefined' && quoteData == null && (accountOptions && accountOptions.length > 0)) {
            const accntData: any = accountOptions?.find((item: any) => item.value == accountId)
            let currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + 3);
            setValue('expDate', currentDate)
            setValue('billingName', accntData?.Name)
            setValue('billingStreet', accntData?.BillingStreet)
            setValue('billingCity', accntData?.BillingCity)
            setValue('billingState', accntData?.BillingState)
            setValue('billingZip', accntData?.BillingPostalCode)
            setValue('billingCountry', countriesData?.find((item: any) => item.value === accntData?.BillingCountry) || '')
            setValue('shippingName', accntData?.Name)
            setValue('shippingStreet', accntData?.ShippingStreet)
            setValue('shippingCity', accntData?.ShippingCity)
            setValue('shippingState', accntData?.ShippingState)
            setValue('shippingZip', accntData?.ShippingPostalCode)
            setValue('shippingCountry', countriesData?.find((item: any) => item.value === accntData?.ShippingCountry) || '')
            setValue("contactName", contactOptions?.[0])
            setValue('status', quoteStatusOptions?.[0])
        }

        if (func == 'ADD' && type == 'OpportunityId') {
            setValue('upliftperfornextquote', (uplift > 0) ? uplift : '0')
            setValue('contractterm', (cTerm > 0) ? cTerm : '12')
            setValue('status', quoteStatusOptions?.find((item: any) => item.value == 'Draft'))
        }



        if (accountId.includes('OP-')) { // Open from Opportunity
            if ((func && func == 'ADD' && opportunityData && opportunityData.length > 0)) {
                setValue('region', regionOptions?.find((item: any) => +item.value == +opportunityData[0]?.Region))
                setValue('termtype', termTypeOptions?.find((item: any) => item.value == opportunityData[0]?.termtype || ''))

            }
        }

    }, [quoteData, contractOptions, partnerOptions, accountOptions, contactOptions, func, opportunityData, regionOptions, termTypeOptions])


    useEffect(() => {
        if ((func && func == 'ADD') && accountId == 'undefined' && quoteData == null && selectedAccount) {
            setValue('billingName', selectedAccount?.Name)
            setValue('billingStreet', selectedAccount?.BillingStreet)
            setValue('billingCity', selectedAccount?.BillingCity)
            setValue('billingState', selectedAccount?.BillingState)
            setValue('billingZip', selectedAccount?.BillingPostalCode)
            setValue('billingCountry', countriesData?.find((item: any) => item.value === selectedAccount?.BillingCountry) || '')
            setValue('shippingName', selectedAccount?.Name)
            setValue('shippingStreet', selectedAccount?.ShippingStreet)
            setValue('shippingCity', selectedAccount?.ShippingCity)
            setValue('shippingState', selectedAccount?.ShippingState)
            setValue('shippingZip', selectedAccount?.ShippingPostalCode)
            setValue('shippingCountry', countriesData?.find((item: any) => item.value === selectedAccount?.ShippingCountry) || '')
            setValue("contactName", contactOptions?.[0])
            setValue('status', quoteStatusOptions?.[0])
        } else if ((func && func == 'ADD') && accountId == 'undefined' && quoteData == null && !selectedAccount) {
            let currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + 3);
            setValue('expDate', currentDate)
            setValue('status', quoteStatusOptions?.[0])
        }


    }, [func, quoteData, selectedAccount, contractOptions])

    useEffect(() => {
        if (func && func == 'ADD' && type == 'OpportunityId' && accountIdForOpp.includes('A-') && opportunityData && opportunityData.length > 0) {
            let oppDataSingle = opportunityData[0];
            console.log('opportunityDataopportunityData', oppDataSingle)

            setValue('billingName', oppDataSingle?.accountobj?.Name)
            setValue('billingStreet', oppDataSingle?.accountobj?.BillingStreet)
            setValue('billingCity', oppDataSingle?.accountobj?.BillingCity)
            setValue('billingState', oppDataSingle?.accountobj?.BillingState)
            setValue('billingZip', oppDataSingle?.accountobj?.BillingPostalCode)
            setValue('billingCountry', countriesData?.find((item: any) => item.value === oppDataSingle?.accountobj?.BillingCountry) || '')
            setValue('shippingName', oppDataSingle?.accountobj?.Name)
            setValue('shippingStreet', oppDataSingle?.accountobj?.ShippingStreet)
            setValue('shippingCity', oppDataSingle?.accountobj?.ShippingCity)
            setValue('shippingState', oppDataSingle?.accountobj?.ShippingState)
            setValue('shippingZip', oppDataSingle?.accountobj?.ShippingPostalCode)
            setValue('shippingCountry', countriesData?.find((item: any) => item.value === oppDataSingle?.accountobj?.ShippingCountry) || '')
        }
    }, [func, accountIdForOpp, opportunityData])


    useEffect(() => {
        if (sameAsBilling) {
            if (bName) { setValue('shippingName', bName) }
            if (bStreet) { setValue('shippingStreet', bStreet) }
            if (bCity) { setValue('shippingCity', bCity) }
            if (bState) { setValue('shippingState', bState) }
            if (bZip) { setValue('shippingZip', bZip) }
            if (bCountry) { setValue('shippingCountry', bCountry) }
        } else {
            setValue('shippingName', "")
            setValue('shippingStreet', "")
            setValue('shippingCity', "")
            setValue('shippingState', "")
            setValue('shippingZip', "")
            setValue('shippingCountry', "")
        }
    }, [sameAsBilling])

    useEffect(() => {
        if (selectedDiscount && +selectedDiscount > 100) {
            toast.error('discount must be less than 100')
        } else if (selectedTax && +selectedTax > 100) {
            toast.error('tax must be less than 100')
        } else {
            const price = +selectedSubTotal;
            const discountAmount = price * (+selectedDiscount / 100);
            const totalAfterDiscount = price - discountAmount;
            setValue('totalPrice', String(formatNumber(totalAfterDiscount)))
            const taxAmount = totalAfterDiscount * (+selectedTax / 100);
            const grandTotal = totalAfterDiscount + taxAmount + +selectedShipping;
            setValue('grandTotal', String(formatNumber(grandTotal)))
        }
    }, [selectedDiscount, selectedSubTotal, selectedTax, selectedShipping, selectedGrandTotal])

    useEffect(() => {
        if (selectedContact) {
            setValue("email", selectedContact?.Email ? selectedContact?.Email : "")
            setValue("fax", selectedContact?.Fax ? selectedContact?.Fax : "")
            setValue("phone", selectedContact?.Phone ? selectedContact?.Phone : "")
        }
    }, [selectedContact])

    useEffect(() => {
        if (errors?.expDate) {
            datePickerRef.current.setOpen(true);
        }
    }, [errors])



    useEffect(() => {
        if (accountId.includes('OP-')) { // Open from Opportunity
            if ((func && func == 'ADD' && opportunityData && opportunityData.length > 0)) {
                setValue('region', regionOptions?.find((item: any) => +item.value == +opportunityData[0]?.Region))
                // setValue('termtype', termTypeOptions?.find((item: any) => +item.value == +opportunityData[0]?.termtype))

                // console.log('opportunityData', opportunityData[0]?.termtype)
                var oppDataAddress = opportunityData[0]?.lead;

                if (oppDataAddress != null && oppDataAddress != 'null') {
                    setValue('billingName', oppDataAddress?.FirstName + " " + oppDataAddress?.LastName)
                    setValue('billingStreet', oppDataAddress?.Street)
                    setValue('billingCity', oppDataAddress?.City)
                    setValue('billingState', oppDataAddress?.State)
                    setValue('billingZip', oppDataAddress?.PostalCode)
                    setValue('billingCountry', countriesData?.find((item: any) => item.value === oppDataAddress?.Country) || '')

                    setValue('shippingName', oppDataAddress?.FirstName + " " + oppDataAddress?.LastName)
                    setValue('shippingStreet', oppDataAddress?.Street)
                    setValue('shippingCity', oppDataAddress?.City)
                    setValue('shippingState', oppDataAddress?.State)
                    setValue('shippingZip', oppDataAddress?.PostalCode)
                    setValue('shippingCountry', countriesData?.find((item: any) => item.value === oppDataAddress?.Country) || '')
                }

                let currentDate = new Date();
                currentDate.setMonth(currentDate.getMonth() + 3);
                setValue('expDate', currentDate)
            }
        }

    }, [opportunityData])


    useEffect(() => {
        setValue('billing_frequency_type', billType?.find((item: any) => item.value === 'Monthly') || '')
    }, [billType])

    useEffect(() => {

        getRegionList()
        if (accountId == 'undefined' || accountId == 'null') {
            getAccountList()
            getOpportunityList()
        }

        if (accountId.includes('A-')) { // Open From Account
            setSelectedAccountId(accountId)

            setAccountNameShow(true);
            setOpportunityNameShow(false);

            setAccountNameDisable(true);
            setOpportunityNameDisable(true);

            setContractNameDisable(false);
            setPartnerNameDisable(false);
            setContactDisable(false);


            getAccountList(accountId, 'account')
            getContractList(accountId, 'account')
            getContactList(accountId, 'account')
            getAccountPartnerData(accountId, 'account')
        }


        if (accountId.includes('OP-')) { // Open from Opportunity
            setSelectedOpportunityId(accountId)
            setAccountNameShow(false);
            setOpportunityNameShow(true);

            setAccountNameDisable(true);
            setOpportunityNameDisable(true);

            setContractNameDisable(false);
            setPartnerNameDisable(false);
            setContactDisable(false);


            getOpportunityList(accountId, 'opportunity')
            // getContractList(accountId, 'opportunity')
            getContactList(accountId, 'opportunity')
            getAccountPartnerData(accountId, 'opportunity')
        }


        // console.log('selectedAccountId', selectedAccountId);
        // console.log('selectedOpportunityId', selectedOpportunityId);
    }, [])


    return (
        <React.Fragment>
            <AddRegionModal
                show={showAddRegionModal}
                close={closeAddRegionModal}
                isEdit={false}
                regionListCallbackFunc={getRegionList}
            />
            <SelectTaxModal
                show={showTaxModal}
                close={handleCloseTaxModal}
                status={"1"}
                taxfor={"Quote"}
                setTaxValue={setTaxValue}
                taxVal={null}
            />
            <QuoteSignatureModal
                show={showQuoteSignatureModal}
                close={handleCloseQuoteSignatureModal}
                setSignatureUrl={setSignatureUrl}
                setSignatureFullname={setSignatureFullname}
            />
            <AddPartnerModal
                show={showAddPartnerModal}
                close={closeAddPartnerModal}
                isEdit={false}
                AccountId={selectedAccount ? selectedAccount.value : accountId != 'undefined' ? accountId : ''}
                partnerListCallbackFunc={getAccountPartnerData}
                OpportunityId={selectedOpportunityId}
            />
            <AddContactModal
                show={showAddContactModal}
                close={closeAddContactModal}
                // isEdit={false}
                AccountId={selectedAccount ? selectedAccount.value : accountId != 'undefined' ? accountId : ''}
                contactListCallbackFunc={getContactList}
                OpportunityId={selectedOpportunityId}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Quote</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    {type && type == "AccountId" ?
                                                        <Link to={`/account-view/${accountId}`}>Account Details</Link>
                                                        :
                                                        <Link to={URLS.QUOTING_MANAGEMENT}>Quoting Management</Link>
                                                    }
                                                </li>
                                                <li className="breadcrumb-item active">Add Quote</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end mb-3 gap-10'>


                                        {(accountId.includes('A-')) &&
                                            <Link to={`/account-view/${accountId}`} className="btn btn-warning"><AiOutlineEye /> Go To Account</Link>}
                                        {(accountId.includes('OP-')) &&
                                            <Link to={`/opportunity-details/${accountId}`} className="btn btn-warning"><AiOutlineEye /> Go To Opportunity</Link>}


                                        <Link to={`/quoting-management`} className="btn btn-primary"><AiOutlineOrderedList /> Quote List</Link>

                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Quote Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="quoteName">Quote name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"quoteName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.quoteName ? ' border-danger' : '')}
                                                                        id="quoteName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter quote name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.quoteName &&
                                                            <span className='text-danger'>{errors?.quoteName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="expDate">Expiration Date</label><br></br>
                                                        <Controller
                                                            name={"expDate"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <DatePicker
                                                                        selected={value ? new Date(value) : null}
                                                                        onChange={onChange}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        className={"form-control " + (errors?.expDate ? ' border-danger' : '')}
                                                                        id="expDate"
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        ref={datePickerRef}
                                                                        value={value}
                                                                        placeholderText="Enter expiration date"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.expDate &&
                                                            <span className='text-danger'>Expiration date is required</span>
                                                        }
                                                    </div>
                                                    {accountNameShow &&
                                                        <div className="form-group col-6">
                                                            <label htmlFor="accntName">Account Name
                                                                <span className='text-danger' style={{ display: (accountNameDisable) ? 'none' : '' }}>*</span>
                                                            </label>
                                                            <Controller name={"accntName"} control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <Select
                                                                        className={"select-box " + (errors?.accntName ? ' border-danger' : '')}
                                                                        id="accntName"
                                                                        options={accountOptions ? accountOptions : []}
                                                                        placeholder={"Select account"}
                                                                        onChange={(selectedOption) => { onChange(selectedOption); setSelectedAccount(selectedOption) }}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        // isDisabled={accountId != 'undefined'}
                                                                        isDisabled={accountNameDisable}
                                                                        ref={ref}
                                                                        isClearable={true}
                                                                    />
                                                                )}
                                                            />
                                                            {errors && errors.accntName &&
                                                                <span className='text-danger'>Account is required</span>
                                                            }
                                                        </div>
                                                    }

                                                    {opportunityNameShow &&
                                                        <div className="form-group col-6">
                                                            <label htmlFor="opportunityName">Opportunity Name
                                                                <span className='text-danger' style={{ display: (opportunityNameDisable) ? 'none' : '' }}>*</span>
                                                            </label>
                                                            <Controller name={"opportunityName"} control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <Select
                                                                        className={"select-box " + (errors?.opportunityName ? ' border-danger' : '')}
                                                                        id="opportunityName"
                                                                        options={opportunityOptions ? opportunityOptions : []}
                                                                        placeholder={"Select Opportunity"}
                                                                        onChange={(selectedOption) => { onChange(selectedOption); setSelectedOpportunity(selectedOption) }}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isDisabled={opportunityNameDisable}
                                                                        ref={ref}
                                                                        isClearable={true}
                                                                    />
                                                                )}
                                                            />
                                                            {errors && errors.opportunityName &&
                                                                <span className='text-danger'>Opportunity is required</span>
                                                            }
                                                        </div>
                                                    }

                                                    <div className="form-group col-6">
                                                        <label htmlFor="contractName">Contract Number
                                                            {/* <span className='text-danger'>*</span> */}
                                                        </label>
                                                        <Controller
                                                            name={"contractName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <Select
                                                                    className={"select-box " + (errors?.contractName ? ' border-danger' : '')}
                                                                    id="contractName"
                                                                    options={contractOptions ? contractOptions : []}
                                                                    placeholder={"Select contract"}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                    ref={ref}
                                                                    isClearable={true}
                                                                    // isDisabled={func !== 'ADD' || (!selectedAccount && accountId == 'undefined')}
                                                                    isDisabled={contractNameDisable}
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.contractName &&
                                                            <span className='text-danger'>Contract is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="partnerid">Partner name</label>
                                                        <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                            <Controller
                                                                name={"partnerid"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <Select
                                                                        className={"select-box " + (errors?.partnerid ? ' border-danger' : '')}
                                                                        id="partnerid"
                                                                        options={partnerOptions ? partnerOptions : []}
                                                                        placeholder={"Select Partner"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isClearable={true}
                                                                        // isDisabled={!selectedAccount && accountId == 'undefined'}
                                                                        isDisabled={partnerNameDisable}
                                                                    />
                                                                )}
                                                            />
                                                            <span
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={"Add Partner"}
                                                                onClick={() => { selectedAccountId || selectedOpportunityId ? setShowPartnerModal(true) : toast.error('Select an Account or Opportunity') }}
                                                            >
                                                                <AiOutlinePlusSquare className='cursor-pointer' />
                                                            </span>
                                                        </div>
                                                        {errors && errors.partnerid &&
                                                            <span className='text-danger'>Partner is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="status">Status <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"status"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.status ? ' border-danger' : '')}
                                                                        // id="status"
                                                                        options={quoteStatusOptions ? quoteStatusOptions : []}
                                                                        placeholder={"Select status"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        ref={ref}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.status &&
                                                            <span className='text-danger'>Status is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <div className='d-flex justify-content-between'>
                                                            <label htmlFor="region">Region
                                                                <span className='text-danger'>*</span>
                                                            </label>
                                                        </div>
                                                        <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                            <Controller
                                                                name={"region"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <>
                                                                        <Select
                                                                            className={"select-box " + (errors?.region ? ' border-danger' : '')}
                                                                            id="region"
                                                                            options={regionOptions ? regionOptions : []}
                                                                            placeholder={"Select region"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                            ref={ref}
                                                                            isClearable={true}
                                                                        // isDisabled={isCreateLoading}
                                                                        // isLoading={isCreateLoading}
                                                                        // onCreateOption={handleCreate}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            <span
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={"Add Region"}
                                                                onClick={() => setShowAddRegionModal(true)}
                                                            >
                                                                <AiOutlinePlusSquare className='cursor-pointer' />
                                                            </span>
                                                        </div>
                                                        {errors && errors.region &&
                                                            <span className='text-danger'>Region is required</span>
                                                        }
                                                    </div>
                                                    {/* {selectedStatus && selectedStatus.value == 'Accepted' ? */}
                                                    <div className="form-group col-6">
                                                        <label htmlFor="contractstartdate">Contract Start Date</label><br></br>
                                                        <Controller
                                                            name={"contractstartdate"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <DatePicker
                                                                    selected={value ? new Date(value) : null}
                                                                    onChange={onChange}
                                                                    dateFormat="MM/dd/yyyy"
                                                                    className={"form-control " + (errors?.contractstartdate ? ' border-danger' : '')}
                                                                    id="contractstartdate"
                                                                    name={name}
                                                                    onBlur={onBlur}
                                                                    ref={datePickerRef}
                                                                    value={value}
                                                                    placeholderText="Enter contract start date"
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.contractstartdate &&
                                                            <span className='text-danger'>Contract start date is required</span>
                                                        }
                                                    </div>



                                                    <div className="form-group col-6">
                                                        <div className="row">
                                                            {/* <div className="form-group col-6">
                                                                    <label htmlFor="contractstartdate">Contract Start Date</label><br></br>
                                                                    <Controller
                                                                        name={"contractstartdate"}
                                                                        control={control}
                                                                        render={({ field: { value, onChange, onBlur, name } }) => (
                                                                            <>
                                                                                <DatePicker
                                                                                    selected={value ? new Date(value) : null}
                                                                                    onChange={onChange}
                                                                                    dateFormat="MM/dd/yy"
                                                                                    className={"form-control " + (errors?.contractstartdate ? ' border-danger' : '')}
                                                                                    id="contractstartdate"
                                                                                    name={name}
                                                                                    onBlur={onBlur}
                                                                                    ref={datePickerRef}
                                                                                    value={value}
                                                                                    placeholderText="Enter contract start date"
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div> */}


                                                            <div className="form-group col-4">
                                                                <label htmlFor="contractterm">Contract Term <span className='text-danger'>*</span></label>
                                                                <Controller
                                                                    name={"contractterm"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                        <>
                                                                            <input
                                                                                type="text"
                                                                                className={"form-control " + (errors?.contractterm ? ' border-danger' : '')}
                                                                                id="contractterm"
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                value={value}
                                                                                onBlur={onBlur}
                                                                                ref={ref}
                                                                                placeholder="Enter contract term"
                                                                                onKeyDown={handleKeyPressNumber}
                                                                                disabled={func !== 'ADD'}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                                {errors && errors.contractterm &&
                                                                    <span className='text-danger'>{numberFieldValidationMsg(errors?.contractterm)}</span>
                                                                }
                                                            </div>
                                                            <div className="form-group col-5">
                                                                <div className='d-flex justify-content-between'>
                                                                    <label htmlFor="termtype">Term Type <span className='text-danger'>*</span></label>
                                                                </div>
                                                                <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                                    <Controller
                                                                        name={"termtype"}
                                                                        control={control}
                                                                        render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                            <Select
                                                                                className={"select-box" + (errors?.termtype ? ' border-danger' : '')}
                                                                                id="termtype"
                                                                                options={termTypeOptions ? termTypeOptions : []}
                                                                                placeholder={"Select..."}
                                                                                onChange={onChange}
                                                                                value={value}
                                                                                defaultValue={value}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                {errors && errors.termtype &&
                                                                    <span className='text-danger'>Term type is required</span>
                                                                }
                                                            </div>
                                                            <div className="form-group col-3">
                                                                <label htmlFor="upliftperfornextquote">Uplift (%) </label>
                                                                <Controller
                                                                    name={"upliftperfornextquote"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                                        <>
                                                                            <input
                                                                                type="number"
                                                                                className={"form-control " + (errors?.upliftperfornextquote ? ' border-danger' : '')}
                                                                                id="upliftperfornextquote"
                                                                                name={name}
                                                                                value={value}
                                                                                onChange={onChange}
                                                                                onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                                onFocus={(e) => handleFocus(e)}
                                                                                onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                                onWheel={handleWheel}
                                                                                step={"0.01"}
                                                                                min={0}
                                                                                placeholder="Enter uplift percentage"
                                                                                disabled={func !== 'ADD'}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                                {errors && errors.upliftperfornextquote &&
                                                                    <span className='text-danger'>{numberFieldValidationMsg(errors?.upliftperfornextquote)}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* :
                                                        null
                                                    } */}
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billing_frequency_type">Billing frequency type
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name={"billing_frequency_type"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <Select
                                                                    className={"select-box " + (errors?.billing_frequency_type ? ' border-danger' : '')}
                                                                    id="billing_frequency_type"
                                                                    options={billType ? billType : []}
                                                                    placeholder={"Select Billing frequency type"}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                    ref={ref}
                                                                    isClearable={true}
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.billing_frequency_type &&
                                                            <span className='text-danger'>Billing frequency type is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingFrequency">Billing frequency
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name={"billingFrequency"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <input
                                                                    type="text"
                                                                    className={"form-control " + (errors?.billingFrequency ? ' border-danger' : '')}
                                                                    id="billingFrequency"
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    ref={ref}
                                                                    placeholder="Enter billing frequency"
                                                                    onKeyDown={handleKeyPressNumber}
                                                                    disabled={selectedBillingFreqType?.value != 'Monthly'}
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.billingFrequency &&
                                                            <span className='text-danger'>{errors?.billingFrequency?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="description">Description</label>
                                                        <Controller
                                                            name={"description"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <textarea
                                                                        // type="text"
                                                                        className={"form-control " + (errors?.description ? ' border-danger' : '')}
                                                                        id="description"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter description"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.description &&
                                                            <span className='text-danger'>{errors?.description?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label htmlFor="tandc">Terms & Conditions </label>

                                                        <Editor
                                                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                            onInit={(evt: any, tandc: any) => {
                                                                editorRef.current = tandc
                                                                if (quoteData?.tandc) {
                                                                    tandc.setContent(quoteData?.tandc)
                                                                }
                                                            }}
                                                            initialValue={quoteData?.tandc}
                                                            onEditorChange={onEditorChange}
                                                            init={{
                                                                height: 300,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic underline | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat | help',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        /> 
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="description">Upload{quoteData?.sladoc ? "ed" : ""} PDF: </label>
                                                        {quoteData?.sladoc ?
                                                            <div className="form-group">
                                                                <a
                                                                    href={quoteData?.sla?.basepath}
                                                                    target="_blank"
                                                                >
                                                                    {quoteData?.sladoc?.file_name_original}
                                                                </a>
                                                                <AiOutlineDelete
                                                                    size={"18px"}
                                                                    color='red'
                                                                    className='cursor-pointer'
                                                                    style={{ paddingLeft: "5px" }}
                                                                    onClick={() =>
                                                                        deleteNotification(quoteData?.sladoc?.id, "document", "", commonFunctions.deleteFile, getQuoteData)
                                                                    }
                                                                />
                                                            </div>
                                                            :
                                                            <div className="form-group mb-1">
                                                                <Controller
                                                                    name={"file"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                                        <>
                                                                            {/* <input name={name} type="file" accept=".pdf" onChange={fileOnChange} style={{ paddingLeft: "5px" }} /> */}
                                                                            <div className="custom-file">
                                                                                <input name={name} type="file" className="custom-file-input" id="customFile" accept=".pdf" onChange={fileOnChange} style={{ paddingLeft: "5px" }} />
                                                                                <label className="custom-file-label" htmlFor="customFile">{fileValue && fileValue != undefined ? fileValue?.name : "Choose file"}</label>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className='row'><div className="form-group col-6">
                                                    <div className='row'>
                                                        <div className='col-md-5'>
                                                            <button
                                                                type='button'
                                                                className="btn btn-success mb-3"
                                                                onClick={() => setShowQuoteSignatureModal(true)}
                                                            >
                                                                Add Signature
                                                            </button>
                                                        </div>
                                                        {signatureUrl && signatureFullname && signatureFullname != '' ?
                                                            <div className='col-md-7 text-right'>
                                                                <div className='sign_ctrl'>
                                                                    <img
                                                                        src={typeof signatureUrl === 'string' ? signatureUrl : URL.createObjectURL(signatureUrl)}
                                                                        alt=""
                                                                        className='bg-soft-secondary'
                                                                        style={{ maxHeight: '80px' }}
                                                                    />
                                                                    <div className='mt-2 text-center'>({signatureFullname})</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            quoteData?.signature && quoteData?.signature?.basepath != '' && quoteData?.signatureimg ?
                                                                <div className='col-md-7 text-right'>
                                                                    <div className='sign_ctrl'>
                                                                        <img
                                                                            className='bg-soft-secondary'
                                                                            style={{ maxHeight: '80px' }}
                                                                            src={quoteData?.signature?.basepath} alt=""
                                                                        />
                                                                        <div className='mt-2 text-center'>({quoteData?.signature_fullname})</div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    {(signatureUrl && signatureFullname && signatureFullname != '') || (quoteData?.signature && quoteData?.signature?.basepath != '' && quoteData?.signatureimg) ?
                                                        <div className="row mt-2">
                                                            <div className="col d-flex form-group gap-10 align-items-center">
                                                                <Controller
                                                                    name={"addsignature"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                        <div className='cstm-switch'>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={"addsignature"}
                                                                                className={"form-control"}
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                value={value === true ? "1" : "0"}
                                                                                onBlur={onBlur}
                                                                                defaultChecked={quoteData?.addsignature === "1"}
                                                                                checked={value === true}
                                                                                disabled={signatureFullname == '' && !quoteData?.signatureimg}
                                                                            />
                                                                            <label htmlFor="addsignature" data-on-label="on" data-off-label="off"></label>
                                                                        </div>
                                                                    )}
                                                                />
                                                                <div>
                                                                    <label htmlFor="addsignature">Add to preview and export</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Prepared For</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="contactName">Contact Name</label>
                                                        <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                            <Controller
                                                                name={"contactName"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <Select
                                                                            className={"select-box " + (errors?.contactName ? ' border-danger' : '')}
                                                                            id="contactName"
                                                                            options={contactOptions ? contactOptions : []}
                                                                            placeholder={"Select contact"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                            isDisabled={contactDisable}
                                                                        // isDisabled={type !== "AccountId" && (!selectedAccount && accountId == 'undefined')}
                                                                        // isDisabled={func !== 'ADD'}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            <span
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={"Add Contact"}
                                                                onClick={() => { (selectedAccountId || selectedOpportunityId) ? setShowContactModal(true) : toast.error('Select an Account or Opportunity') }}
                                                            >
                                                                <AiOutlinePlusSquare className='cursor-pointer' />
                                                            </span>
                                                        </div>
                                                        {errors && errors.contactName &&
                                                            <span className='text-danger'>{errors?.contactName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="phone">Phone</label>
                                                        <Controller
                                                            name={"phone"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <InputMask
                                                                        mask="(999) 999-9999"
                                                                        maskChar="_"
                                                                        className={"form-control " + (errors?.phone ? ' border-danger' : '')}
                                                                        id="phone"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter phone"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.phone &&
                                                            <span className='text-danger'>{errors?.phone?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="email">Email</label>
                                                        <Controller
                                                            name={"email"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.email ? ' border-danger' : '')}
                                                                        id="email"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter email"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.email &&
                                                            <span className='text-danger'>{errors?.email?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="fax">Fax</label>
                                                        <Controller
                                                            name={"fax"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.fax ? ' border-danger' : '')}
                                                                        id="fax"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter fax"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.fax &&
                                                            <span className='text-danger'>{errors?.fax?.message}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Address Information</h5>
                                                    <div className="align-items-center col-6 d-flex form-group gap-10">
                                                        <input
                                                            type="checkbox"
                                                            id="sameBilling"
                                                            defaultChecked={sameAsBilling}
                                                            onClick={(e: any) => setSameAsBilling(e.target.checked)}
                                                        />
                                                        <label htmlFor="sameBilling" className='mb-0'>same as billing</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingName">Bill To Name</label>
                                                        <Controller
                                                            name={"billingName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingName ? ' border-danger' : '')}
                                                                        id="billingName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing name"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingName &&
                                                            <span className='text-danger'>{errors?.billingName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingName">Ship To Name</label>
                                                        <Controller
                                                            name={"shippingName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingName ? ' border-danger' : '')}
                                                                        id="shippingName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled={sameAsBilling || (!selectedAccount && accountId == 'undefined')}
                                                                        placeholder="Enter shipping name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingName &&
                                                            <span className='text-danger'>{errors?.shippingName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingStreet">Bill To Street</label>
                                                        <Controller
                                                            name={"billingStreet"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingStreet ? ' border-danger' : '')}
                                                                        id="billingStreet"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing street"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingStreet &&
                                                            <span className='text-danger'>{errors?.billingStreet?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingStreet">Ship To Street</label>
                                                        <Controller
                                                            name={"shippingStreet"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingStreet ? ' border-danger' : '')}
                                                                        id="shippingStreet"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter shipping street"
                                                                        disabled={sameAsBilling || (!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingStreet &&
                                                            <span className='text-danger'>{errors?.shippingStreet?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingCity">Bill To City</label>
                                                        <Controller
                                                            name={"billingCity"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingCity ? ' border-danger' : '')}
                                                                        id="billingCity"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing city"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingCity &&
                                                            <span className='text-danger'>{errors?.billingCity?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingCity">Ship To City</label>
                                                        <Controller
                                                            name={"shippingCity"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingCity ? ' border-danger' : '')}
                                                                        id="shippingCity"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter shipping city"
                                                                        disabled={sameAsBilling || (!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingCity &&
                                                            <span className='text-danger'>{errors?.shippingCity?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingState">Bill To State/Province</label>
                                                        <Controller
                                                            name={"billingState"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingState ? ' border-danger' : '')}
                                                                        id="billingState"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing state"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingState &&
                                                            <span className='text-danger'>{errors?.billingState?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingState">Ship To State/Province</label>
                                                        <Controller
                                                            name={"shippingState"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingState ? ' border-danger' : '')}
                                                                        id="shippingState"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter shipping state"
                                                                        disabled={sameAsBilling || (!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingState &&
                                                            <span className='text-danger'>{errors?.shippingState?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingZip">Bil To Zip/Postal Code</label>
                                                        <Controller
                                                            name={"billingZip"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingZip ? ' border-danger' : '')}
                                                                        id="billingZip"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing zip"
                                                                        disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingZip &&
                                                            <span className='text-danger'>{errors?.billingZip?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingZip">Ship To Zip/Postal Code</label>
                                                        <Controller
                                                            name={"shippingZip"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingZip ? ' border-danger' : '')}
                                                                        id="shippingZip"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter shipping zip"
                                                                        disabled={sameAsBilling || (!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingZip &&
                                                            <span className='text-danger'>{errors?.shippingZip?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingCountry">Bill To Country</label>
                                                        <Controller
                                                            name={"billingCountry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.billingCountry ? ' border-danger' : '')}
                                                                        id="billingCountry"
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isDisabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingCountry">Ship To Country</label>
                                                        <Controller
                                                            name={"shippingCountry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.shippingCountry ? ' border-danger' : '')}
                                                                        id="shippingCountry"
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isDisabled={sameAsBilling || (!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                    <button type='submit' className='btn btn-primary'>Save</button>

                                                    <Link className='btn btn-outline-danger'
                                                        to={
                                                            (accountId && accountId.includes('A-') ? '/account-view/' + accountId :
                                                                (accountId && accountId.includes('OP-') ? '/opportunity-details/' + accountId :
                                                                    URLS.QUOTING_MANAGEMENT))
                                                        }
                                                    >
                                                        Cancel</Link>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddQuote