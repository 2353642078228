import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { formatNumber } from '../_common/functions'
import { yearOptionsStatic } from '../_common/staticData'


const OpportunityPipeline = () => {
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();
    // const history = useHistory();

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterGet = searchParams.get("q");
    const yearGet = searchParams.get("year");

    const [quarter, setQuarter] = useState((quarterGet != undefined) ? quarterGet : 'Q1')
    const [pipelineDataList, setPipelineDataList] = useState<any>(null)

    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState<any>((yearGet != undefined) ? yearGet : currentYear)
    const [yearOption, setYearOption] = useState<any>(yearOptionsStatic)

    const getOpportunityPipelineData = (getQt: any) => {
        showLoader();
        var params = {
            type: "all",
            typeid: "",
            parenttype: "",
            quarter: getQt,
            year: year
        }
        platformApi.getOpportunityPipeline(params,
            (message: string, resp: any) => {
                // console.log('resp', resp);
                setPipelineDataList(resp.data.list);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    // console.log('pipelineDataList', pipelineDataList)
    useEffect(() => {
        getOpportunityPipelineData(quarter)
    }, [quarter, year])

    const yearHandler = (e: any) => {
        setYear(e.target.value);
    }


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Pipeline Management </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Pipeline Management</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mb-3">Manage Pipeline</h5>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <label style={{ width: '180px' }} >Budget Year  </label>
                                                    <select className='form-control' value={year} onChange={yearHandler}>
                                                        {
                                                            yearOption && yearOption.length > 0 &&
                                                            yearOption.map((item: any) =>
                                                                <option value={item.value}
                                                                    // selected={item.value == year ? true : false}
                                                                    key={Math.random()}>{item.label}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                <li className="nav-item">
                                                    <a className={`nav-link ${(quarter == 'Q1') ? 'active' : ''}`} data-toggle="tab" href="#Q1" role="tab"
                                                        onClick={() => setQuarter('Q1')}>
                                                        Quarter 1
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${(quarter == 'Q2') ? 'active' : ''}`} data-toggle="tab" href="#Q2" role="tab"
                                                        onClick={() => setQuarter('Q2')}>
                                                        Quarter 2
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${(quarter == 'Q3') ? 'active' : ''}`} data-toggle="tab" href="#Q3" role="tab"
                                                        onClick={() => setQuarter('Q3')}>
                                                        Quarter 3
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${(quarter == 'Q4') ? 'active' : ''}`} data-toggle="tab" href="#Q4" role="tab"
                                                        onClick={() => setQuarter('Q4')}>
                                                        Quarter 4
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content pt-4 pb-4">
                                                <div className="tab-pane active" id={quarter} role="tabpanel">
                                                    <div className="table-responsive">
                                                        <table id="datatable" className="table table-bordered dt-responsive nowrap" style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Plan</th>
                                                                    <th>Most Likely Forecast</th>
                                                                    <th>Upside Forecast</th>
                                                                    <th>Deal Level Forecast</th>
                                                                    <th>AI Forecast</th>
                                                                    <th>Closed</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pipelineDataList && pipelineDataList.length > 0 && pipelineDataList.map((item: any, index: number) => (
                                                                    <tr key={Math.random()}>
                                                                        <td >
                                                                            {(index > 0) ? <> |<AiOutlineArrowRight /> &nbsp;</> : ''}
                                                                            {(item.canexpand) ?
                                                                                <Link to={`/opportunity-pipeline/region?q=${quarter}&sec=${item.parenttype}&year=${year}`}>{item.typename}</Link>
                                                                                :
                                                                                <>{item.typename}</>
                                                                            }
                                                                        </td>
                                                                        <td className="text-right">${formatNumber(item.plan)}</td>
                                                                        <td className="text-right">
                                                                            <div className="d-flex justify-content-between mb-2">
                                                                                <i className="bx bx-user font-size-18"></i>
                                                                                <p>${formatNumber(item.mostlikely)}</p>
                                                                            </div>
                                                                            {/* <div className="d-flex justify-content-between">
                                                                                <i className='bx bx-group font-size-18'></i>
                                                                                <p className="mb-0">${formatNumber(0)}</p>
                                                                            </div> */}
                                                                            {/* <div className="text-right">
                                                                                <a href="#">Accept Rollup</a>
                                                                            </div> */}
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <div className="d-flex justify-content-between mb-2">
                                                                                <i className="bx bx-user font-size-18"></i>
                                                                                <p>${formatNumber(item.upside)}</p>
                                                                            </div>
                                                                            {/* <div className="d-flex justify-content-between">
                                                                                <i className='bx bx-group font-size-18'></i>
                                                                                <p className="mb-0">${formatNumber(0)}</p>
                                                                            </div> */}
                                                                        </td>
                                                                        <td className="text-right">${formatNumber(item.deallevel)}</td>
                                                                        <td className="text-right">${formatNumber(item.ai)}</td>
                                                                        <td className="text-right">${formatNumber(item.closed)}</td>
                                                                    </tr>)
                                                                )}

                                                                {
                                                                    pipelineDataList && pipelineDataList.length == 0 &&
                                                                    <tr><td colSpan={7} className='text-center'>No Data Found.</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {/* <div className="tab-pane" id="sell" role="tabpanel"></div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default OpportunityPipeline