import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

interface FormValues {
	task_for: any,
	task_date: string,
	task_time: string,
	task_title: string,
	task_description: string
}

const validationSchema = Yup.object().shape({
	task_for: Yup.object().required("User is required"),
	task_date: Yup.string().nullable().required("Task date is required"),
	task_time: Yup.string().nullable().required("Task time is required"),
	task_title: Yup.string().nullable().required("Task title is required"),
	task_description: Yup.string().nullable().required("Task description is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
	type?: any;
}


const AddTaskFormOnAccActivity = ({ show, close, AccountId, type }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();
	const [userListData, setUserListData] = useState<any>([]);
	const datePickerRef = useRef<any>()

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			task_for: "",
			task_date: "",
			task_time: "",
			task_title: "",
			task_description: "",
		},
	});

	const getUserListData = (page = 1) => {
		platformApi.getAllArrUsers({},
			(message: string, resp: any) => {
				let tempArr = resp.data.list.data;
				// console.log('tempArr', tempArr);

				if (tempArr && tempArr.length > 0) {
					setUserListData(tempArr.map((item: any) => {
						item.label = item.name;
						item.value = item.id;
						return item
					}));
				}
			}, (message: string) => {
				hideLoader();
			})
	}

	const customOptionUI = (props: any) => {
		const { innerProps, innerRef, data } = props;
		return (
			<div ref={innerRef} {...innerProps} className="cstm-option m-2 cursor-pointer">
				{data?.name}
				<span className='ml-2'>({data?.licencetype})</span>
			</div>
		);
	}

	useEffect(() => {
		getUserListData();
	}, []);

	const onSubmit = (data: any) => {
		showLoader()
		let params: any = {
			"AccountId": AccountId,
			"task_for": data.task_for?.value,
			// "task_date": data?.task_date,
			"task_date": data?.task_date ? moment(data.task_date).format("MM/DD/YY") : "",
			"task_time": data?.task_time,
			"task_title": data?.task_title,
			"task_description": data?.task_description,
		}
		platformApi.addEditAccountViewTask(params, (message: string, resp: any) => {
			toast.success(message)
			reset()
			close()
			hideLoader()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}

	const today = new Date();
	var todayString = moment(today).local().format("YYYY-MM-DD");

	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}>
				<div className="modal-header custom-modal-header">
					Add Task
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { close(); reset() }} >
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">

											<div className="form-group col-12">
												<label htmlFor="task_for">Task For <span className='text-danger'>*</span></label>
												<Controller
													name={"task_for"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<Select
															className={"select-box" + (errors?.task_for ? ' border-danger' : '')}
															id="task_for"
															components={{ Option: customOptionUI }}
															options={userListData ? userListData : []}
															placeholder={""}
															onChange={onChange}
															value={value}
															defaultValue={value}
														/>
													)}
												/>

												{errors && errors.task_for &&
													<span className='text-danger'>Please select user</span>
												}
											</div>

											<div className="form-group col-6">
												<label htmlFor="arruserid">Due Date <span className='text-danger'>*</span></label>
												<Controller
													name={"task_date"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<> 
															<DatePicker
																selected={value ? new Date(value) : null}
																onChange={onChange}
																dateFormat="MM/dd/yyyy"
																className={"form-control " + (errors?.task_date ? ' border-danger' : '')}
																id="task_date"
																name={name}
																onBlur={onBlur}
																ref={datePickerRef}
																value={value}
																minDate={new Date()}
																placeholderText="Enter date"
																autoComplete='off'
															/>
														</>
													)}
												/>
												{errors && errors.task_date &&
													<span className='text-danger'>{errors?.task_date?.message}</span>
												}
											</div>

											<div className="form-group col-6">
												<label htmlFor="arruserid">Due Time <span className='text-danger'>*</span></label>
												<Controller
													name={"task_time"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="time"
																className={"form-control " + (errors?.task_time ? ' border-danger' : '')}
																id="task_time"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter time"
															/>
														</>
													)}
												/>
												{errors && errors.task_time &&
													<span className='text-danger'>{errors?.task_time?.message}</span>
												}
											</div>

											<div className="form-group col-12">
												<label htmlFor="arruserid">Task Title <span className='text-danger'>*</span></label>
												<Controller
													name={"task_title"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.task_title ? ' border-danger' : '')}
																id="task_title"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter task title"
															/>
														</>
													)}
												/>
												{errors && errors.task_title &&
													<span className='text-danger'>{errors?.task_title?.message}</span>
												}
											</div>

											<div className="form-group col-12">
												<label htmlFor="arruserid">Task Description <span className='text-danger'>*</span></label>
												<Controller
													name={"task_description"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.task_description ? ' border-danger' : '')}
																id="task_description"
																name={name}
																onChange={onChange}
																onBlur={onBlur}
																placeholder="Enter task description"
															/>
														</>
													)}
												/>

												{errors && errors.task_description &&
													<span className='text-danger'>{errors?.task_description?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button type='button' className='btn btn-outline-danger' onClick={() => { close(); reset() }} >
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddTaskFormOnAccActivity