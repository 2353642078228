import { AiOutlineDelete } from 'react-icons/ai';
import { deleteNotification } from '../../_common/functions';

interface IncomingProps {
    column: any;
    value: any;
    callbackFunc: any;
    deleteFunc: Function;
    type: string;
    infoMsg?: string
}



const DeleteButtonCell = ({ column, value, type, infoMsg, deleteFunc, callbackFunc }: IncomingProps) => {

    return (
        <AiOutlineDelete
            className="cursor-pointer mr-2"
            color='red'
            onClick={() => deleteNotification(value, type, infoMsg, deleteFunc, callbackFunc)}
            data-toggle="tooltip" data-placement="top" title="Delete"
        />
    )
}

export default DeleteButtonCell