import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function usePlatformApi() {

  const callApi = useApiCall()

  const getPlatformObject = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_PLATFORM_OBJECT, data, onSuccess, onError);
  }

  const getPlatformObjectField = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_PLATFORM_OBJECT_FIELD, data, onSuccess, onError);
  }

  const savePlatformObjectField = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.SAVE_PLATFORM_OBJECT_FIELD, data, onSuccess, onError);
  }

  const getAllAccount = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCOUNT, data, onSuccess, onError);
  }

  const getAllProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRODUCT, data, onSuccess, onError);
  }

  const getAllContact = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTACT, data, onSuccess, onError);
  }

  const getAllOpportunity = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_OPPORTUNITY, data, onSuccess, onError);
  }

  const getOpportunityStagesList = (data: any, onSuccess: Function, onError: Function) => { // For dropdown
    callApi(SAGA_ACTIONS.SALESFORCE.GET_OPPORTUNITY_STAGE_LIST, data, onSuccess, onError);
  }

  const getAllOpportunityStages = (data: any, onSuccess: Function, onError: Function) => { // For Stage Listing page
    callApi(SAGA_ACTIONS.SALESFORCE.GET_OPPORTUNITY_STAGES, data, onSuccess, onError);
  } 

  const manageOpportunityStage = (data: any, onSuccess: Function, onError: Function) => { // For Stage Add edit modal
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_OPPORTUNITY_STAGES, data, onSuccess, onError);
  } 

  const OpportunityStagedestroy = (data: any, onSuccess: Function, onError: Function) => { // For Stage Add edit modal
    callApi(SAGA_ACTIONS.SALESFORCE.OPPORTUNITY_STAGE_DELETE, data, onSuccess, onError);
  } 

  const getAllContract = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT, data, onSuccess, onError);
  }
  const getAllContractForList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_LIST, data, onSuccess, onError);
  }
  const getAllContractNew = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_NEW, data, onSuccess, onError);
  }

  const getAllContractChurned = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_CHURNED, data, onSuccess, onError);
  }

  const getAllContractCcontraction = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_CCONTRACTION, data, onSuccess, onError);
  }

  const getAllContractRiskLevel = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_RISK_LEVEL, data, onSuccess, onError);
  }
  const getAllContractEarlycontraction = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_EARLY_CONTRACTION, data, onSuccess, onError);
  }

  const getAllContractChurnRreason = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT_CHURN_REASON, data, onSuccess, onError);
  }


  const getAllPriceBook = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRICEBOOK, data, onSuccess, onError);
  }

  const getAllQuote = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_QUOTE, data, onSuccess, onError);
  }

  const getQuotePdf = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_QUOTE_PDF, data, onSuccess, onError);
  }

  const manageAccount = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT, data, onSuccess, onError);
  }

  const manageProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_PRODUCT, data, onSuccess, onError);
  }

  const manageOpportunity = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_OPPORTUNITY, data, onSuccess, onError);
  }

  const manageQuote = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_QUOTE, data, onSuccess, onError);
  }

  const managePriceBook = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_PRICEBOOK, data, onSuccess, onError);
  }

  const getAllPriceBookEntities = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRICEBOOK_ENTITIES, data, onSuccess, onError);
  }

  const managePriceBookEntities = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_PRICEBOOK_ENTITIES, data, onSuccess, onError);
  }

  const getAllQuoteLineItem = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_QUOTE_LINEITEMS, data, onSuccess, onError);
  }

  const getAllAccountComment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCOUNT_COMMENT, data, onSuccess, onError);
  }

  const manageAccountComment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_COMMENT, data, onSuccess, onError);
  }

  const manageQuoteLineItem = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_QUOTE_LINEITEMS, data, onSuccess, onError);
  }
  const manageSingleQuoteLineItem = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_SINGLE_QUOTE_LINEITEMS, data, onSuccess, onError);
  }

  const getAllArrUsers = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_ARR_USER, data, onSuccess, onError);
  }

  const manageArrUser = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_ARR_USER, data, onSuccess, onError);
  }

  const getAllAccountSaComment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCNT_SA_COMMENT, data, onSuccess, onError);
  }

  const manageAccountSaComment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCNT_SA_COMMENT, data, onSuccess, onError);
  }

  const getRenewalProcess = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_RENEWAL_PROCESS, data, onSuccess, onError);
  }

  const manageRenewalProcess = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_RENEWAL_PROCESS, data, onSuccess, onError);
  }

  const manageContact = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTACT, data, onSuccess, onError);
  }

  const getAccountTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ACCOUNT_TEAM, data, onSuccess, onError);
  }

  const manageAccountTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_TEAM, data, onSuccess, onError);
  }

  const getCustomerTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CUSTOMER_TEAM, data, onSuccess, onError);
  }

  const manageCustomerTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CUSTOMER_TEAM, data, onSuccess, onError);
  }

  const arrUserDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_ARR_USER, data, onSuccess, onError);
  }

  const accountDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT, data, onSuccess, onError);
  }

  const accountCommentDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_COMMENT, data, onSuccess, onError);
  }

  const accountSaCommentDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_SA_COMMENT, data, onSuccess, onError);
  }

  const accoungTeamDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_TEAM, data, onSuccess, onError);
  }

  const customerTeamDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_CUSTOMER_TEAM, data, onSuccess, onError);
  }

  const productDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_PRODUCT, data, onSuccess, onError);
  }

  const contactDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_CONTACT, data, onSuccess, onError);
  }
  const addAccountPOC = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ADD_ACCOUNT_POC, data, onSuccess, onError);
  }

  const opportunityDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_OPPORTUNITY, data, onSuccess, onError);
  }

  const pricebookDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_PRICEBOOK, data, onSuccess, onError);
  }

  const pricebookEntityDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_PRICEBOOK_ENTITY, data, onSuccess, onError);
  }

  const quoteDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_QUOTE, data, onSuccess, onError);
  }

  const quoteLineitemDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_QUOTE_LINEITEMS, data, onSuccess, onError);
  }

  const cloneQuote = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.CLONE_QUOTE, data, onSuccess, onError);
  }

  const createQuoteFromContract = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.CREATE_QUOTE_FROM_CONTRACT, data, onSuccess, onError);
  }

  const getRevenueDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_REVENUE_DETAILS, data, onSuccess, onError);
  }

  const manageContract = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT, data, onSuccess, onError);
  }

  const transferContract = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.TRANSFER_CONTRACT, data, onSuccess, onError);
  }

  const getLateRenewals = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_LATE_RENEWALS, data, onSuccess, onError);
  }

  const getlaterenewalsCP = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_LATE_RENEWALS_CP, data, onSuccess, onError);
  }
  const getUpsells = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_UPSELLS, data, onSuccess, onError);
  }

  const getContractProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_PRODUCT, data, onSuccess, onError);
  }

  const getRevenueMapDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_REVENUE_MAP_DETAILS, data, onSuccess, onError);
  }

  const getContractLevelLIst = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_LEVEL_LIST, data, onSuccess, onError);
  }
  const getContractTimeLevelLIst = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_TIME_LEVEL_LIST, data, onSuccess, onError);
  }
  const manageContractREnewvalEvent = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_RENEWVAL_EVENT, data, onSuccess, onError);
  }

  const getContractReasonCOC = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_REASON_COC, data, onSuccess, onError);
  }

  const manageContractRenewalNotes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_RENEWAL_NOTES, data, onSuccess, onError);
  }

  const getRenewalNotes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_RENEWAL_NOTES, data, onSuccess, onError);
  }

  const getContractAccountTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_ACCOUNT_TEAM, data, onSuccess, onError);
  }

  const accoungContractTeamDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_CONTRACT_ACCOUNT_TEAM, data, onSuccess, onError);
  }

  const manageContractAccountTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_ACCOUNT_TEAM, data, onSuccess, onError);
  }

  const getAllCustomerContact = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CUSTOMER_CONTACT, data, onSuccess, onError);
  }

  const contactCustomerDestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_CUSTOMER_CONTACT, data, onSuccess, onError);
  }

  const manageCustomerContact = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CUSTOMER_CONTACT, data, onSuccess, onError);
  }
  const addcontractmanager = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ADD_CONTRACT_MANAGER, data, onSuccess, onError);
  }

  const getquotesSummeryy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_QUOTES_SUMMERY, data, onSuccess, onError);
  }

  const manageContractCustomerTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_CUSTOMER_TEAM, data, onSuccess, onError);
  }

  const getCrossSells = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CROSSSELLS, data, onSuccess, onError);
  }

  const getContractPdf = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_PDF, data, onSuccess, onError);
  }

  const getForeCastSummary = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_FORECAST_SUMMARY, data, onSuccess, onError);
  }

  const uploadContractDoc = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.UPLOAD_CONTRACT_DOC, data, onSuccess, onError);
  }

  const mergeContract = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MERGE_CONTRACT, data, onSuccess, onError);
  }

  const getArrPageList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ARR_PAGE_LIST, data, onSuccess, onError);
  }

  const manageAccountPartner = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_PARTNER, data, onSuccess, onError);
  }

  const getAccountPartner = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ACCOUNT_PARTNER, data, onSuccess, onError);
  }

  const deleteAccountPartner = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_PARTNER, data, onSuccess, onError);
  }

  const forecastBlukUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.FORCAST_BULK_UPDATE, data, onSuccess, onError);
  }

  const getAllApprovalRulesForQuote = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_APPROVAL_RULES_FOR_QUOTE, data, onSuccess, onError);
  }

  const addApprovalForQuote = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ADD_APPROVAL_FOR_QUOTE, data, onSuccess, onError);
  }

  const addQuoteSignature = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ADD_QUOTE_SIGNATURE, data, onSuccess, onError);
  }

  const updateContractEntitlement = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.UPDATE_CONTRACT_ENTITLEMENT, data, onSuccess, onError);
  }

  const getAllLead = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_LEAD, data, onSuccess, onError);
  }

  const manageLead = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.MANAGE_LEAD, data, onSuccess, onError);
  }

  const leaddestroy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.DELETE_LEAD, data, onSuccess, onError);
  }

  const createOpportunityFromLead = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.CREATE_OPPORTUNITY_FROM_LEAD, data, onSuccess, onError);
  }

  const editQuoteMultiyear = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.QUOTE_MULTIYEAR_EDIT, data, onSuccess, onError);
  }

  const editQuoteLineitemMultiyear = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.QUOTE_LINEITEM_MULTIYEAR_EDIT, data, onSuccess, onError);
  }


  //=========** START:: ACCOUNT VIEW- Activities  **==============//
  
 

  const getAccountViewActivityList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_ACTIVITY_LIST, data, onSuccess, onError);
  }

  const getAccountViewNoteList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_NOTE_LIST, data, onSuccess, onError);
  }
  const addEditAccountViewNote = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_NOTE_ADD_EDIT, data, onSuccess, onError);
  }

  const getAccountViewTaskList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_TASK_LIST, data, onSuccess, onError);
  }
  const addEditAccountViewTask = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_TASK_ADD_EDIT, data, onSuccess, onError);
  }

   const getAccountViewCallList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_CALL_LIST, data, onSuccess, onError);
  }
  const addEditAccountViewCall = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_CALL_ADD_EDIT, data, onSuccess, onError);
  }

  const getAccountViewMeetingList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_MEETING_LIST, data, onSuccess, onError);
  }
  const addEditAccountViewMeeting = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.ACCOUNT_VIEW_MEETING_ADD_EDIT, data, onSuccess, onError);
  }

  //=========** END:: ACCOUNT VIEW- Activities  **==============//

  //============= START:: Manage Pipeline =========//
  
  const getOpportunityPipeline = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.OPPORTUNITY_PIPELINE_LIST, data, onSuccess, onError);
  }
  // const getAllOpportunityStages = (data: any, onSuccess: Function, onError: Function) => {
    // callApi(SAGA_ACTIONS.SALESFORCE.ALL_OPPORTU, data, onSuccess, onError);
  // }
  //============= END:: Manage Pipeline =========//

  const getallcsv = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_ALL_CSV, data, onSuccess, onError);
  }
  const uploadCSV = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.UPLOAD_CSV, data, onSuccess, onError);
  }
  const getcsvfiledata = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_CSV_DATA, data, onSuccess, onError);
  }
  const storecsvdata = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.STORE_CSV_DATA, data, onSuccess, onError);
  }




  
  const getPowerBIToken = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SALESFORCE.GET_POWER_BI_TOKEN, data, onSuccess, onError);
  }


  return {
    getPlatformObject: getPlatformObject,
    getPlatformObjectField: getPlatformObjectField,
    savePlatformObjectField: savePlatformObjectField,
    getAllAccount: getAllAccount,
    getAllProduct: getAllProduct,
    getAllContact: getAllContact,
    getAllOpportunity: getAllOpportunity,
    getOpportunityStagesList: getOpportunityStagesList,
    getAllOpportunityStages: getAllOpportunityStages,
    manageOpportunityStage: manageOpportunityStage,
    OpportunityStagedestroy: OpportunityStagedestroy,
    getAllContract: getAllContract,
    getAllContractForList: getAllContractForList,
    getAllContractNew: getAllContractNew,
    getAllContractChurned: getAllContractChurned,
    getAllContractCcontraction: getAllContractCcontraction,
    getAllContractRiskLevel: getAllContractRiskLevel,
    getAllContractEarlycontraction: getAllContractEarlycontraction,
    getAllContractChurnRreason: getAllContractChurnRreason,
    getlaterenewalsCP: getlaterenewalsCP,
    getAllPriceBook: getAllPriceBook,
    getAllQuote: getAllQuote,
    manageAccount: manageAccount,
    manageProduct: manageProduct,
    manageOpportunity: manageOpportunity,
    manageQuote: manageQuote,
    managePriceBook: managePriceBook,
    getAllPriceBookEntities: getAllPriceBookEntities,
    managePriceBookEntities: managePriceBookEntities,
    getAllQuoteLineItem: getAllQuoteLineItem,
    getAllAccountComment: getAllAccountComment,
    manageAccountComment: manageAccountComment,
    manageQuoteLineItem: manageQuoteLineItem,
    getAllArrUsers: getAllArrUsers,
    manageArrUser: manageArrUser,
    getAllAccountSaComment: getAllAccountSaComment,
    manageAccountSaComment: manageAccountSaComment,
    getRenewalProcess: getRenewalProcess,
    manageRenewalProcess: manageRenewalProcess,
    manageContact: manageContact,
    getAccountTeam: getAccountTeam,
    manageAccountTeam: manageAccountTeam,
    getCustomerTeam: getCustomerTeam,
    manageCustomerTeam: manageCustomerTeam,
    arrUserDestroy: arrUserDestroy,
    accountDestroy: accountDestroy,
    accountCommentDestroy: accountCommentDestroy,
    accountSaCommentDestroy: accountSaCommentDestroy,
    accoungTeamDestroy: accoungTeamDestroy,
    customerTeamDestroy: customerTeamDestroy,
    productDestroy: productDestroy,
    contactDestroy: contactDestroy,
    addAccountPOC: addAccountPOC,
    opportunityDestroy: opportunityDestroy,
    pricebookDestroy: pricebookDestroy,
    pricebookEntityDestroy: pricebookEntityDestroy,
    quoteDestroy: quoteDestroy,
    quoteLineitemDestroy: quoteLineitemDestroy,
    cloneQuote: cloneQuote,
    createQuoteFromContract: createQuoteFromContract,
    getRevenueDetails: getRevenueDetails,
    manageContract: manageContract,
    transferContract: transferContract,
    getUpsells: getUpsells,
    getLateRenewals: getLateRenewals,
    getContractProduct: getContractProduct,
    getRevenueMapDetails: getRevenueMapDetails,
    getContractLevelLIst: getContractLevelLIst,
    getContractTimeLevelLIst: getContractTimeLevelLIst,
    manageContractREnewvalEvent: manageContractREnewvalEvent,
    getContractReasonCOC: getContractReasonCOC,
    manageContractRenewalNotes: manageContractRenewalNotes,
    getRenewalNotes: getRenewalNotes,
    getContractAccountTeam: getContractAccountTeam,
    accoungContractTeamDestroy: accoungContractTeamDestroy,
    manageContractAccountTeam: manageContractAccountTeam,
    getAllCustomerContact: getAllCustomerContact,
    contactCustomerDestroy: contactCustomerDestroy,
    manageCustomerContact: manageCustomerContact,
    addcontractmanager: addcontractmanager,
    getQuotePdf: getQuotePdf,
    getquotesSummeryy: getquotesSummeryy,
    manageContractCustomerTeam: manageContractCustomerTeam,
    getCrossSells: getCrossSells,
    getContractPdf: getContractPdf,
    getForeCastSummary: getForeCastSummary,
    uploadContractDoc: uploadContractDoc,
    mergeContract: mergeContract,
    getArrPageList: getArrPageList,
    manageAccountPartner: manageAccountPartner,
    getAccountPartner: getAccountPartner,
    deleteAccountPartner: deleteAccountPartner,
    forecastBlukUpdate: forecastBlukUpdate,
    getAllApprovalRulesForQuote: getAllApprovalRulesForQuote,
    addApprovalForQuote: addApprovalForQuote,
    addQuoteSignature: addQuoteSignature,
    manageSingleQuoteLineItem: manageSingleQuoteLineItem,
    updateContractEntitlement: updateContractEntitlement,
    getAllLead: getAllLead,
    manageLead: manageLead,
    leaddestroy: leaddestroy,
    createOpportunityFromLead: createOpportunityFromLead,
    editQuoteMultiyear: editQuoteMultiyear,
    editQuoteLineitemMultiyear: editQuoteLineitemMultiyear,

    getAccountViewActivityList: getAccountViewActivityList,
    getAccountViewNoteList: getAccountViewNoteList,
    addEditAccountViewNote: addEditAccountViewNote,
    getAccountViewTaskList: getAccountViewTaskList,
    addEditAccountViewTask: addEditAccountViewTask,
    getAccountViewCallList: getAccountViewCallList,
    addEditAccountViewCall: addEditAccountViewCall,
    getAccountViewMeetingList: getAccountViewMeetingList,
    addEditAccountViewMeeting: addEditAccountViewMeeting,
 
    getOpportunityPipeline: getOpportunityPipeline,

    getallcsv: getallcsv,
    uploadCSV: uploadCSV,
    getcsvfiledata: getcsvfiledata,
    storecsvdata: storecsvdata,


    getPowerBIToken: getPowerBIToken,
  }
}