import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import Footer from '../../components/commonComponents/Footer';
import TableContainer from '../../components/commonComponents/TableContainer';
import { URLS } from '../../_config';
import AddOpportunityStageModal from '../../components/modals/addOpportunityStage.modal';
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell';

const OpportunityStages = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const [opportunityData, setOpportunityData] = useState<any>([])
    const [showAddStageModal, setShowAddStageModal] = useState<any>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [stageId, setStageId] = useState<any>(null)


    const getAllOpportunityStagesData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = { page: page }
        platformApi.getAllOpportunityStages(params,
            (message: string, resp: any) => {
                console.log(resp);
                setLoading(false);
                setOpportunityData(resp?.data?.list);
                setData(resp?.data?.list);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowAddStageModal(true)
            setStageId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }



    const deleteStage = (id: any) => {
        // console.log(id)
        return new Promise((resolve: any, reject: any) => {
            platformApi.OpportunityStagedestroy({ id: id }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const deleteStageCallBack = () => {
        getAllOpportunityStagesData();
    }


    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteStage}
                            callbackFunc={deleteStageCallBack}
                            type={"Stage"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Stage Name",
                accessor: "statusname",
            },
            {
                Header: "Position",
                accessor: "position",
            },
        ],
        []
    )

    const closeAddStageModal = () => {
        setShowAddStageModal(false)
        setIsEdit(false)
        setStageId(null)
    }

    const listCallbackFunc = () => {
        getAllOpportunityStagesData()
        setShowAddStageModal(false)
    }


    useEffect(() => {
        getAllOpportunityStagesData()
    }, [])

    return (
        <React.Fragment>
            {showAddStageModal && <AddOpportunityStageModal
                show={showAddStageModal}
                close={closeAddStageModal}
                stageId={stageId}
                isEdit={isEdit}
                listCallbackFunc={listCallbackFunc}
            />}

            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Manage Opportunity Stage </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Manage Opportunity Stage</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Opportunity Stages</h5>
                                                <button className="btn btn-success" onClick={() => setShowAddStageModal(true)} >
                                                    <AiOutlinePlusCircle /> Add Stage
                                                </button>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getAllOpportunityStagesData}
                                                    loading={loading}
                                                    rawData={opportunityData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default OpportunityStages