import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { formatNumber } from '../../_common/functions';

interface FormValues {
	tax: any
}

const validationSchema = Yup.object().shape({
	tax: Yup.object().required("Tax is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	taxfor: string;
	status: string;
	setTaxValue: any;
	taxVal: any
}


const SelectTaxModal = ({ show, close, status, taxfor, setTaxValue, taxVal = null }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [taxOptions, setTaxOptions] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			tax: ""
		},
	});

	const getTaxData = () => {
		showLoader();
		commonApi.getAllTax({
			taxfor,
			status
		},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setTaxOptions(tempArr.map((item: any) => {
						item.label = item.taxname + ` (${item?.taxpercent} %)`;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const customOptionUI = (props: any) => {
		const { innerProps, innerRef, data } = props;
		return (
			<div ref={innerRef} {...innerProps} className="cstm-option m-2 cursor-pointer">
				{data?.taxname}
				<span className='ml-2'>({data?.taxpercent} %)</span>
			</div>
		);
	}

	const onSubmit = (data: any) => {
		setTaxValue(data?.tax)
		close()
		reset()
	}

	useEffect(() => {
		if (show) {
			getTaxData()
		}
	}, [show])


	useEffect(() => {
		setValue('tax', taxOptions?.find((item: any) => formatNumber(item.taxpercent) == formatNumber(taxVal)) || '')
	}, [taxOptions])
	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}  >
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												{/* <label htmlFor="tax">Select Account User <span className='text-danger'>*</span></label> */}
												<label htmlFor="tax">Select Tax <span className='text-danger'>*</span></label>
												<Controller
													name={"tax"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<Select
																className={"select-box" + (errors?.tax ? ' border-danger' : '')}
																id="tax"
																components={{ Option: customOptionUI }}
																options={taxOptions ? taxOptions : []}
																placeholder={"Select tax"}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
												{errors && errors.tax &&
													<span className='text-danger'>Please select tax</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default SelectTaxModal