import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import UpsellsList from '../components/dataListing/UpsellsDataList'
import CrossSellsList from '../components/dataListing/CrossSellsDataList'
import CommonBoxDetails from '../components/commonComponents/CommonBoxDetails'
import FilterRow from '../components/commonComponents/FilterRow'
import TitleBar from '../components/commonComponents/TitleBar'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'

const Upside = () => {

    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)
    const [revenueDetails, setRevenueDetails] = useState<any>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")


    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [upsellsData, setUpsellsData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)


    const [crossSellsData, setCrossSellsData] = useState<any>(null)
    const [dataCrossSells, setDataCrossSells] = useState<any>([])
    const [loadingCrossSells, setLoadingCrossSells] = useState<boolean>(false)


    const getUpsellsData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page }
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getUpsells(params,
            (message: string, resp: any) => {
                setUpsellsData(resp.data.list);
                setData(resp.data.list.data);
                setLoading(false);
                hideLoader();
                setDoFilter(false)
            }, (message: string) => {
                setLoading(false);
                hideLoader();
                setDoFilter(false)
            })
    }


    const getCrossSellsData = (page = 1) => {
        showLoader();
        setLoadingCrossSells(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getCrossSells(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoadingCrossSells(false);
                setCrossSellsData(resp.data.list);
                setDataCrossSells(resp.data.list.data);
                hideLoader();
                setDoFilter(false)
            }, (message: string) => {
                setLoadingCrossSells(false);
                hideLoader();
                setDoFilter(false)
                setDataCrossSells([])
            })
    }


    useEffect(() => {
        getUpsellsData();
        getCrossSellsData();
    }, [])

    useEffect(() => {
        if (doFilter) {
            getUpsellsData();
            getCrossSellsData();
        }
    }, [doFilter])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            <TitleBar title={'Upside'}
                                breadcrumbs={{
                                    'Upside': '#'
                                }} >
                            </TitleBar>

                            <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                        revenueDetails={revenueDetails}
                                        setRevenueDetails={setRevenueDetails}
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="upside"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        setDoFilter={setDoFilter}
                                        defaultYear={true}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">
                                                    Expansion &amp; Cross Sell Accounts
                                                </h4>
                                                <div className="ml-auto d-flex">
                                                </div>
                                            </div>
                                            <div className="risk-table-nav">
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-toggle="tab"
                                                            href="#buy"
                                                            role="tab"
                                                        >
                                                            Upsell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell"
                                                            role="tab"
                                                        >
                                                            Output
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell1"
                                                            role="tab"
                                                        >
                                                            Cross Sell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell2"
                                                            role="tab"
                                                        >
                                                            Stage
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4 pb-4">
                                                    <div className="tab-pane active" id="buy" role="tabpanel">
                                                        <UpsellsList
                                                            loading={loading}
                                                            upsellsData={upsellsData}
                                                            data={data}
                                                            getUpsellsData={getUpsellsData}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                    <div className="tab-pane" id="sell1" role="tabpanel">
                                                        <CrossSellsList
                                                            loading={loadingCrossSells}
                                                            crossSellsData={crossSellsData}
                                                            data={dataCrossSells}
                                                            getCrossSellsData={getCrossSellsData}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell2" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">Entitlements &amp; Usage</h4>
                                            </div>
                                            <div className="risk-table-nav">
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-toggle="tab"
                                                            href="#buy1"
                                                            role="tab"
                                                        >
                                                            Upsell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell3"
                                                            role="tab"
                                                        >
                                                            Output
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell4"
                                                            role="tab"
                                                        >
                                                            Cross Sell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell5"
                                                            role="tab"
                                                        >
                                                            Stage
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4 pb-4">
                                                    <div className="tab-pane active" id="buy1" role="tabpanel">
                                                        <UpsellsList
                                                            loading={loading}
                                                            upsellsData={upsellsData}
                                                            data={data}
                                                            getUpsellsData={getUpsellsData}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell3" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                    <div className="tab-pane" id="sell4" role="tabpanel">
                                                        <CrossSellsList
                                                            loading={loadingCrossSells}
                                                            crossSellsData={crossSellsData}
                                                            data={dataCrossSells}
                                                            getCrossSellsData={getCrossSellsData}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell5" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Upside