import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import Footer from '../../components/commonComponents/Footer'
import { URLS } from '../../_config'
import { AiOutlineEye, AiOutlineKey } from 'react-icons/ai';
import { Controller, useForm } from 'react-hook-form'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import { useUserSelector } from '../../_common/hooks/selectors/userSelector'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'


interface EditProfile {
    firstName: string;
    lastName: string;
    jobTitle: string;
    employees: string;
    country: string;
    state: string;
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(20, "First Name can be of maximum 20 characters").required("First Name is required"),
    lastName: Yup.string().max(20, "Last Name can be of maximum 20 characters").required("Last Name is required"),
    // jobTitle: Yup.object().required("Job Title is required"),
    // employees: Yup.object().required("Employees is required"),
    // country: Yup.object().required("Country is required"),
    // state: Yup.string().required("State is required"),
});




const MyProfileEdit = () => {
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();

    const user: any = useUserSelector()

    const [jobTitleOptions, setJobTitleOptions] = useState<any>([])
    const [employeesOptions, setEmployeesOptions] = useState<any>([])
    const [countriesOptions, setCountriesOptions] = useState<any>([])


    const { control, formState: { errors }, handleSubmit, setValue, watch, reset } = useForm<EditProfile>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: user?.first_name,
            lastName: user?.last_name,
            jobTitle: "",
            employees: "",
            country: "",
            state: "",
        },
    });


    const onSubmit = (data: any) => {
        console.log({ data })
        let params: any = {
            first_name: data.firstName,
            last_name: data.lastName,
            // employee_number: data.employees.value,
            // jobtitle: data.jobTitle.value,
            // country: data.country.value,
            // state: data.state,
        }
        showLoader();
        commonApi.updateprofile(params, (message: string, resp: any) => {
            // console.log({ resp })
            toast.success(resp?.message, { duration: 5000 })
            // navigate(URLS.LOGIN)
            hideLoader();
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }
    // console.log('user', user)

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Edit Profile</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Edit Profile</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}



                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Edit Profile</h5>
                                                <div className='d-flex justify-content-end mb-3 gap-10'>
                                                    <Link to={URLS.MY_PROFILE_VIEW} className="btn btn-warning"><AiOutlineEye /> View Profile</Link>
                                                    <Link to={URLS.MY_CHANGE_PASSWORD} className="btn btn-success"><AiOutlineKey /> Change Password</Link>
                                                </div>
                                            </div>

                                            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="firstName">First name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"firstName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.firstName ? ' border-danger' : '')}
                                                                        id="firstName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter first name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.firstName &&
                                                            <span className='text-danger'>{errors?.firstName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"lastName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.lastName ? ' border-danger' : '')}
                                                                        id="lastName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter last name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.lastName &&
                                                            <span className='text-danger'>{errors?.lastName?.message}</span>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{ display: 'none' }}>
                                                    <div className="row"  >

                                                        <div className="form-group col-6">
                                                            <label htmlFor="employees">Employees <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name="employees"
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        {/* <Select
                                                                        className={"select-box " + (errors?.employees ? ' border-danger' : '')}
                                                                        options={employeesOptions ? employeesOptions : []}
                                                                        placeholder={"Select employees"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        // defaultValue={value}
                                                                    /> */}
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.employees &&
                                                                <span className='text-danger'>Employees is required</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="form-group col-6">
                                                            <label htmlFor="jobTitle">Job Title <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"jobTitle"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        {/* <Select
                                                                        className={"select-box " + (errors?.jobTitle ? ' border-danger' : '')}
                                                                        options={jobTitleOptions ? jobTitleOptions : []}
                                                                        placeholder={"Select job title"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    /> */}
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.jobTitle &&
                                                                <span className='text-danger'>Job Title is required</span>
                                                            }
                                                        </div>


                                                        <div className="form-group col-6">
                                                            <label htmlFor="country">Country <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name="country"
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        {/* <Select
                                                                        className={"select-box " + (errors?.country ? ' border-danger' : '')}
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        // defaultValue={value}
                                                                    /> */}
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.country &&
                                                                <span className='text-danger'>Country is required</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name="state"
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.state ? ' border-danger' : '')}
                                                                            id="state"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter state"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.state &&
                                                                <span className='text-danger'>{errors?.state?.message}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <button type="submit"
                                                        className="btn btn-primary btn-block waves-effect waves-light" >
                                                        Update
                                                    </button>
                                                </div>
                                            </form>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default MyProfileEdit