import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import EditButtonCell from '../components/actionsBtns/EditButtonCell'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import { toast } from 'react-hot-toast'
import { changeString } from '../_common/functions'

const ProductList = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [productData, setProductData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getProductData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllProduct({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setProductData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteProduct = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.productDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const onToggleProduct = (data: any, toggle: boolean) => {
        // console.log(data);
        let params: any = data;
        params["Status"] = toggle;
        showLoader()
        platformApi.manageProduct(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            getProductData()
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const callbackFnAfterDelete = () => {
        getProductData();
    }
    
    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "ProductId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deleteProduct}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Product"}
                        />
                        <EditButtonCell column={column} value={value} link={'add-product'} />
                    </>,
            },
            {
                Header: "Product Code",
                accessor: "ProductCode",
            },
            {
                Header: "Name",
                accessor: "Name",
                className: "nowrap",
            },
            {
                Header: "Product Family",
                accessor: "family.name",
            },
            {
                Header: "Description",
                accessor: "Description",
                Cell: ({ column, value, row }: any) => (
                    <div
                        data-toggle="tooltip"
                        data-placement="top"
                        title={value}
                    >
                        {value ? changeString(value, 16) + " ..." : ""}
                    </div>
                ),
            },
            {
                Header: "Active",
                accessor: "Status",
                className: "nowrap",
                Cell: ({ column, value, row }: any) => (
                    <div className='cstm-switch'>
                        <input
                            type="checkbox"
                            id={`switch${row.id}`}
                            defaultChecked={value === "1"}
                            onChange={(e: any) => { onToggleProduct(row.original, e.target.checked) }}
                        />
                        <label htmlFor={`switch${row.id}`} data-on-label="on" data-off-label="off"></label>
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        getProductData();
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Products List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Products List</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Products</h5>
                                                <Link to={URLS.ADD_PRODUCT} className="btn btn-success">Add Product</Link>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getProductData}
                                                    loading={loading}
                                                    rawData={productData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductList