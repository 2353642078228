import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TabCalls from './TabCalls'
import TabTasks from './TabTasks'
import TabMeetings from './TabMeetings'
import TabNotes from './TabNotes'
import TabActivity from './TabActivity'

interface IncomingProps {
    accountId?: number;
}

const AccountViewActivities = ({ accountId }: IncomingProps) => {
    const [activeTab, setActiveTab] = useState('activity');


    return (
        <React.Fragment>
            <div className="activities-tab-pan">
                <div className="row" style={{ display: 'none' }}>
                    <div className="col-sm-5">
                        <h5 className="font-weight-bold font-size-18">ARR Management Account Name</h5>
                        <p>Amount: $500.000</p>
                        <p className="d-flex align-items-center gap-10"><span className="w-f-80">Close Date:</span>
                            <input type="date" className="form-control w-f-200" /></p>
                        <p className="d-flex align-items-center gap-10"><span className="w-f-80">Stage:</span>
                            <select className="form-control w-f-200">
                                <option>Appointment Schedule</option>
                            </select>
                        </p>
                        <ul className="activity-link-btns">
                            <li><Link to="#"  ><span className="activity-link-icon"><i className="bx bx-notepad font-size-20"></i></span> Note</Link></li>
                            <li><a href="#"><span className="activity-link-icon"><i className="bx bx-mail-send font-size-20"></i></span> Email</a></li>
                            <li><a href="#"><span className="activity-link-icon"><i className="bx bx-phone-call font-size-20"></i></span> Call</a></li>
                            <li><Link to="#"><span className="activity-link-icon"><i className="bx bx-task font-size-20"></i></span> Task</Link></li>
                            <li><a href="#"><span className="activity-link-icon"><i className="bx bx-user-voice font-size-20"></i></span> Meeting</a></li>
                            <li>
                                <div className="dropdown mb-0">
                                    <a role="button" data-toggle="dropdown" aria-haspopup="true"><span className="activity-link-icon">
                                        <i className="bx bx-dots-horizontal font-size-20"></i></span> More</a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item align-items-end" href="#">Share Files</a>
                                        <a className="dropdown-item align-items-end" href="#">Share with me</a>
                                        <a className="dropdown-item align-items-end" href="#">Other Actions</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-7">
                        <h5 className="font-weight-bold font-size-16 mb-3">About this deal</h5>
                        <h6 className="font-size-15">Deal Owner</h6>
                        <p>John Doe</p>
                        <h6 className="font-size-15">Last Connected</h6>
                        <p>--</p>
                        <h6 className="font-size-15">Deal Type</h6>
                        <p>New Business</p>
                        <h6 className="font-size-15">Priority</h6>
                        <p className="d-flex align-items-center"><span className="dot-medium"></span> Medium</p>
                        <div className="d-flex gap-10">
                            <a href="#" className="btn btn-primary">View All Properties</a>
                            <a href="#" className="btn btn-primary">View Property History</a>
                        </div>
                    </div>
                </div>
                {/* <hr /> */}


                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#activity" role="tab"
                            onClick={() => setActiveTab('activity')}>
                            Activity
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#notes" role="tab"
                            onClick={() => setActiveTab('notes')}>
                            Notes
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#email" role="tab"
                            onClick={() => setActiveTab('email')}>
                            Email
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#calls" role="tab"
                            onClick={() => setActiveTab('calls')}>
                            Calls
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tasks" role="tab"
                            onClick={() => setActiveTab('tasks')}>
                            Tasks
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#meetings" role="tab"
                            onClick={() => setActiveTab('meetings')}>
                            Meetings
                        </a>
                    </li>
                </ul>
                <div className="tab-content pt-4 pb-4">
                    <div className="tab-pane active" id="activity" role="tabpanel">
                    {(activeTab == 'activity') && <TabActivity accountId={accountId} />}
                    </div>

                    <div className="tab-pane" id="notes" role="tabpanel">
                        {(activeTab == 'notes') && <TabNotes />}
                    </div>

                    <div className="tab-pane" id="email" role="tabpanel">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="font-size-18">Activity Email Goes Here</h2>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane" id="calls" role="tabpanel">
                        {(activeTab == 'calls') && <TabCalls />}

                        {/* <TabCalls /> */}
                    </div>

                    <div className="tab-pane" id="tasks" role="tabpanel">
                        {(activeTab == 'tasks') && <TabTasks />}

                        {/* <TabTasks /> */}
                    </div>

                    <div className="tab-pane" id="meetings" role="tabpanel">
                        {(activeTab == 'meetings') && <TabMeetings />}

                        {/* <TabMeetings /> */}
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
}

export default AccountViewActivities