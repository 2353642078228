import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'; 
import { AiOutlineCheck } from 'react-icons/ai';
import TermsAndConditionsText from '../../pages/TermsAndConditionsText';

interface FormValues {
	arruserid: any
}

const validationSchema = Yup.object().shape({
	// arruserid: Yup.object().required("User is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	setCheckTermsCondition: any
}


const SignupTermsAndConditionsModal = ({ show, close, setCheckTermsCondition }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			arruserid: ""
		},
	});


	const onSubmit = (data: any) => {
		console.log('onSubmit');
		showLoader()
		setCheckTermsCondition(true)
		close()
		reset()
		hideLoader()

	}



	return (
		<React.Fragment>
			<Modal className='salesforce-modal modal-lndscp' show={show} >
				<div className="modal-header custom-modal-header">
					<h5>Terms & Conditions</h5>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row" style={{ maxHeight: '400px', overflowY: 'auto' }}>
											<div className="form-group col-12" >
												<div>
													<TermsAndConditionsText />
												</div>
											</div>

											<div className="col-12">
												<div className='align-items-center d-flex gap-10 justify-content-center'>
													<button type='submit' className='btn btn-success'><AiOutlineCheck /> Accept</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default SignupTermsAndConditionsModal