import { toast } from "react-hot-toast";
import { useCommonApi } from "./hooks/api/common/commonApiHook";
import { format } from "date-fns";
import moment from "moment";

export function usNumber(e: any) {
    let n = e.target.value.replace(/[^\d]/g, '');
    var x = n.match(/(\d{3})(\d*)/);
    var y = n.match(/(\d{3})(\d{3})(\d*)/);
    var z = n.match(/(\d{3})(\d{3})(\d{4})/);
    if (z) {
        e.target.value = '(' + z[1] + ') ' + z[2] + '-' + z[3];
    } else if (y) {
        e.target.value = '(' + y[1] + ') ' + y[2] + ((y[3] != '') ? ('-' + y[3]) : '');
    } else if (x && x[2] != '') {
        e.target.value = '(' + x[1] + ') ' + x[2];
    } else {
        e.target.value = n;
    }

}

export function creditCard(e: any) {
    let n = e.target.value.replace(/\D/g, '');
    var a = n.match(/(\d{4})(\d*)/);
    var b = n.match(/(\d{4})(\d{4})(\d*)/);
    var c = n.match(/(\d{4})(\d{4})(\d{4})(\d*)/);
    var d = n.match(/(\d{4})(\d{4})(\d{4})(\d{4})(\d*)/);
    if (d) {
        e.target.value = d[1] + ' ' + d[2] + ' ' + d[3] + ' ' + d[4];
    } else if (c) {
        e.target.value = c[1] + ' ' + c[2] + ' ' + c[3] + ((c[4] != '') ? (' ' + c[4]) : '');
    } else if (b) {
        e.target.value = b[1] + ' ' + b[2] + ((b[3] != '') ? (' ' + b[3]) : '');
    } else if (a && a[2] != '') {
        e.target.value = a[1] + ' ' + a[2];
    }

}

export function creditCardExp(e: any) {
    let n = e.target.value.replace(/\D/g, '');
    var x = n.match(/(\d{2})(\d*)/);
    var y = n.match(/(\d{2})(\d{2})(\d*)/);
    if (y) {
        e.target.value = y[1] > 12 ? 12 : y[1] + ' / ' + y[2]
    } else if (x && x[2] != '') {
        e.target.value = x[1] > 12 ? 12 : x[1] + ' / ' + x[2];
    }
}

export function CheckNumber(e: any) {
    let n: any = e.target.value.replace(/^0+/, '').replace(/[^\d.]/g, '')
    let min: number = parseFloat(e.target.getAttribute('min'));
    let max: number = parseFloat(e.target.getAttribute('max'));
    if (n == '') { n = 0 }
    if (min && parseFloat(n) < min) {
        n = min
    }

    if (max && parseFloat(n) > max) {
        n = max
    }
    e.target.value = n;
}

export function checkImageBase64Code(url: any) {
    if (url.search('data:image/') == 0) {
        return url;
    } else {

        return 'data:image/jpeg;base64,' + url
    }

}

export function formatDate(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function handleKeyPressNumber(event: any) {
    if (!/[0-9]|ArrowLeft|ArrowRight|Delete|Backspace/.test(event.key)) {
        event.preventDefault();
    }
}

export function handleKeyPressDecimalNumber(event: any) {
    if (!/[.0-9]|ArrowLeft|ArrowRight|Delete|Backspace/.test(event.key)) {
        event.preventDefault();
    } else if (!/^[0-9]*\.?[0-9]{0,1}$/.test(event.target.value) && !['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(event.key)) {
        event.preventDefault()
    }
}

export const changeString = (str: any, len: number) => {
    if (str.length > 0 && str.length > len) {
        return str.substr(0, len)
    } else {
        return str
    }
}

export const extractContent = (s: any) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    span.innerHTML = span.innerHTML.replace(/\&nbsp;/g, '');
    return span.textContent || span.innerText;
}

export const formatNumber = (num?: any) => {
    if (num && num >= 0) {
        return (Math.floor(Number(num) * 100) / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
    return "0.00";
}

export const formatNumberPvNv = (num?: any) => {
    if (num) {
        return (Math.floor(Number(num) * 100) / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
    return "0.00";
}

export const handleKeyUpDown = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault(); // prevent up/down arrow keys
    }
};

export const handleWheel = (event: any) => {
    event.preventDefault(); // prevent scrolling
};

export const handleFocus = (e: any) => {
    const input = e.target;
    input.addEventListener('wheel', disableScroll);
}

export const handleBlur = (e: any) => {
    const input = e.target;
    input.removeEventListener('wheel', disableScroll);
}

export const disableScroll = (e: any) => {
    e.preventDefault();
}

export const deleteNotification = (id: any, type: string, infoMsg: string = '', deleteFunc: Function, callbackFuncs: any) => {
    toast(
        (t) => (
            <span>
                <strong>Are you sure you want to delete this {type}?</strong>
                <br />
                {
                (infoMsg != '') ? <><hr /> {infoMsg}</> : ''
                }
                <br />
                <hr />
                <div className="d-flex align-items-center justify-content-end">
                    <button
                        className="btn btn-danger btn-sm m-1"
                        onClick={() => {
                            toast.dismiss(t.id);
                            toast.promise(deleteFunc(id), {
                                loading: "working on request...",
                                success: (msg: any) => {
                                    // callbackFuncs?.forEach((func: any) => func()) 
                                    callbackFuncs();
                                    return <b>{type} removed successfully</b>
                                },
                                error: (msg) => { return <b>{msg}</b> },
                            }, { duration: 2000 });
                        }}
                    >
                        Yes
                    </button>

                    <button
                        className="btn btn-secondary btn-sm m-1"
                        onClick={() => toast.dismiss(t.id)}
                    >
                        No
                    </button>
                </div>
            </span>
        ),
        { duration: 15000, className: 'withdraw-toast' }
    );
}

export const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const futureYears = currentYear + 3;
    const yearList = [];

    for (let year = currentYear; year <= futureYears; year++) {
        yearList.push(year);
    };

    let tempList: any = [];
    yearList.forEach((item: any) => {
        tempList.push({
            label: item,
            value: item
        })
    })
    tempList.unshift({
        label: currentYear - 1,
        value: currentYear - 1
    })
    return tempList
};

export const getNextDate = (givenDate: any) => {
    const currentDate = new Date(givenDate);
    const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    return nextDate
}

export const showDate = (dbDate: any, dateFormat?: any) => {
    if (dateFormat == '') {
        dateFormat = 'MM/DD/YY';
    }
    return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";

}



export const getSeletedValue = (value: any, node: string, ownerOptions: any[]) => {
	let result = null;
	ownerOptions && ownerOptions.map((option: any) => {
		if (option[node] == value) {
			result = option;
		}
	})
	return result;
}