import React, { useEffect, useMemo, useState } from "react"
import TableContainer from "../../components/commonComponents/TableContainer"
import { usePlatformApi } from "../../_common/hooks/api/PlatformApiHook"
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook"
import { formatNumber } from "../../_common/functions"
import { Link } from "react-router-dom"

interface IncomingProps {
    title: string;
    filterDetails: any;
    quarterValue: any;
    typeOfContract: any;
    yearValue: any;
    regionValue: any;
    repValue: any;
    statusValue: any;
    doFilter: any;
    setDoFilter: any;
}

const RiskLevel = ({ title, filterDetails, quarterValue, typeOfContract, yearValue, regionValue, repValue, statusValue, doFilter, setDoFilter }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getRiskLevelLink = (value: string) => {
        let quarterVal = filterDetails?.quarter != null ? filterDetails?.quarter : 'FY';
        let yearVal = filterDetails?.year;

        let returnLink = `/contract-page?year=${yearVal}&quarter=${quarterVal}&type=churntable&risklable=${value}`
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            if (filterDetails?.rep) {
                returnLink += `&rep=${filterDetails?.rep}`
            }
            if (filterDetails?.region) {
                returnLink += `&region=${filterDetails?.region}`
            }
        }
        return returnLink;
    }

    const columns = useMemo(
        () => [
            {
                Header: "Risk Level",
                accessor: "risklevel",
                Cell: ({ column, value, row }: any) => (
                    <>
                        <Link to={getRiskLevelLink(value)}>{(value)}</Link>
                    </>
                )
            },
            // {
            //     Header: "%",
            //     accessor: "percent",
            //     Cell: ({ column, value, row }: any) => (
            //         <>
            //             {formatNumber(value)}
            //         </>
            //     )
            // },

            {
                Header: "Forecasted",
                accessor: "forected",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {formatNumber(value)}
                    </>
                )
            },
            {
                Header: "Actual",
                accessor: "actual",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {formatNumber(value)}
                    </>
                )
            },
        ],
        []
    )

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page }
        if (regionValue && regionValue != 'undefined') { params['region'] = regionValue };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllContractRiskLevel(params,
            (message: string, resp: any) => {
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list);
                setDoFilter(false)
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                setDoFilter(false)
                hideLoader();
            })
    }


    useEffect(() => {
        getContractData();
    }, [doFilter])

    useEffect(() => {
        if (doFilter) {
            getContractData();
        }
    }, [doFilter])


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap justify-content-between">
                                <h5 className="card-title mr-2">{title}</h5>
                            </div>
                            <div className="table-responsive">
                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    fetchData={getContractData}
                                    loading={loading}
                                    rawData={contractData}
                                    clickFunc={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RiskLevel