import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from 'react-select';

interface IncomingProps {
	show: boolean;
	close: () => void;
	selectedContractIds: any | [];
	callbackFunctions: any;
}

interface FormValues {
	mostlikely_forecast: string,
	basecase_forecast: string,
	RenewalNote: string,
	RenewalRiskLevel: any,
	nextsteps: string,
	closeplan: string,
	forecast_closedate: string
}

const validationSchema = Yup.object().shape({
	// mostlikely_forecast: Yup.string().required("Most likely forecast is required"),
	// basecase_forecast: Yup.string().required("Basecase forecast is required"),
	// RenewalNote: Yup.string().required("Renewal note is required"),
	// RenewalRiskLevel: Yup.object().required("Renewal risk level is required"),
	// nextsteps: Yup.string().required("Nest steps required"),
	// closeplan: Yup.string().required("closePlan required"),
	// forecast_closedate: Yup.string().required("Close date is required"),
});

const ForecastBulkUpdateModal = ({ show, close, selectedContractIds, callbackFunctions }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();
	const datePickerRef = useRef<any>();

	const [renewalRiskLevelOption, setRenewalRiskLevelOption] = useState<any>([]);

	const { formState: { errors }, handleSubmit, control, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			mostlikely_forecast: "",
			basecase_forecast: "",
			RenewalNote: "",
			RenewalRiskLevel: "",
			nextsteps: "",
			closeplan: "",
			forecast_closedate: ""
		},
	});

	const onSubmit = (data: any) => {
		let params: any = {
			contract: selectedContractIds,
			mostlikely_forecast: data?.mostlikely_forecast,
			bestcase: data?.basecase_forecast,
			RenewalNote: data?.RenewalNote,
			RenewalRiskLevel: data?.RenewalRiskLevel?.value || "",
			nextsteps: data?.nextsteps,
			closeplan: data?.closeplan,
			forecast_closedate: moment(data?.forecast_closedate).format('MM/DD/YY') != "Invalid date" ? moment(data?.forecast_closedate).format('MM/DD/YY') : ""
		}
		// console.log(selectedContractIds, params)
		showLoader()
		platformApi.forecastBlukUpdate(params,
			(message: string, resp: any) => {
				toast.success(message);
				hideLoader();
				handleClose();
				callbackFunctions()
			}, (message: string) => {
				toast.error(message);
				hideLoader();
			})
	}

	const getRiskLevelData = () => {
		showLoader();
		platformApi.getContractLevelLIst(
			{},
			(message: string, resp: any) => {
				let tempArr = resp.data;
				if (tempArr && tempArr.length != 0) {
					let arr: any | [] = [];
					tempArr.forEach((item: any) => {
						arr.push(
							{
								label: item,
								value: item
							})
					})
					setRenewalRiskLevelOption(arr);
				}
				hideLoader();
			},
			(message: string) => {
				hideLoader();
			},
		);
	};

	const handleClose = () => {
		close();
		reset();
	}

	useEffect(() => {
		if (errors?.forecast_closedate) {
			datePickerRef.current.setOpen(true);
		}
	}, [errors])

	useEffect(() => {
		getRiskLevelData()
	}, [])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
				onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-6">
												<label htmlFor="RenewalRiskLevel">Renewal risk level
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"RenewalRiskLevel"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<Select
																className={"select-box" + (errors?.RenewalRiskLevel ? ' border-danger' : '')}
																id="RenewalRiskLevel"
																options={renewalRiskLevelOption ? renewalRiskLevelOption : []}
																placeholder={"Select risk level"}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
												{errors && errors.RenewalRiskLevel &&
													<span className='text-danger'>Renwal risk level is required</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="forecast_closedate">Forecast Close Date
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"forecast_closedate"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<DatePicker
																selected={value ? new Date(value) : null}
																onChange={onChange}
																dateFormat="MM/dd/yy"
																className={"form-control " + (errors?.forecast_closedate ? ' border-danger' : '')}
																id="forecast_closedate"
																name={name}
																onBlur={onBlur}
																ref={datePickerRef}
																value={value}
																placeholderText="Enter forecast close date"
															/>
														</>
													)}
												/>
												{errors && errors.forecast_closedate &&
													<span className='text-danger'>Contract Start Date is required</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="mostlikely_forecast">Most likely forecast ($)
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"mostlikely_forecast"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="number"
																className={"form-control " + (errors?.mostlikely_forecast ? ' border-danger' : '')}
																id="mostlikely_forecast"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={(e) => { onBlur(); handleBlur(e) }}
																onFocus={(e) => handleFocus(e)}
																onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																onWheel={handleWheel}
																step={"0.01"}
																min={0}
																placeholder="Enter most likely forecast"
															/>
														</>
													)}
												/>
												{errors && errors.mostlikely_forecast &&
													<span className='text-danger'>{errors?.mostlikely_forecast?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="basecase_forecast">Best case forecast ($)
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"basecase_forecast"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="number"
																className={"form-control " + (errors?.basecase_forecast ? ' border-danger' : '')}
																id="basecase_forecast"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={(e) => { onBlur(); handleBlur(e) }}
																onFocus={(e) => handleFocus(e)}
																onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																onWheel={handleWheel}
																step={"0.01"}
																min={0}
																placeholder="Enter best case forecast"
															/>
														</>
													)}
												/>
												{errors && errors.basecase_forecast &&
													<span className='text-danger'>{errors?.basecase_forecast?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="RenewalNote">Renewal note
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"RenewalNote"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.RenewalNote ? ' border-danger' : '')}
																id="RenewalNote"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter renewal note"
															/>
														</>
													)}
												/>
												{errors && errors.RenewalNote &&
													<span className='text-danger'>{errors?.RenewalNote?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="closeplan">Close plan
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"closeplan"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.closeplan ? ' border-danger' : '')}
																id="closeplan"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter close plan"
															/>
														</>
													)}
												/>
												{errors && errors.closeplan &&
													<span className='text-danger'>{errors?.closeplan?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="nextsteps">Next Step
													{/* <span className='text-danger'>*</span> */}
												</label>
												<Controller
													name={"nextsteps"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.nextsteps ? ' border-danger' : '')}
																id="nextsteps"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter next step"
															/>
														</>
													)}
												/>
												{errors && errors.nextsteps &&
													<span className='text-danger'>{errors?.nextsteps?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal >
		</React.Fragment >
	)
}

export default ForecastBulkUpdateModal