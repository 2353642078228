import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import Modal from 'react-bootstrap/Modal'
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/numberFieldRestriction';

interface IncomingProps {
    show: boolean;
    close: () => void;
}

interface AddFormValues {
    frompage: string;
    topage: string;
}

const ApolloMaping1Modal = ({ show, close }: IncomingProps) => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [maxRange, setMaxRange] = useState(0);

    const validationSchema = Yup.object().shape({
        frompage: Yup.number().max(maxRange, 'Field can not be greater than ' + maxRange).required("Field is required"),
        topage: Yup.number().max(maxRange, 'Field can not be greater than ' + maxRange).required("Field is required"),

    });

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            frompage: "1",
            topage: "1",
        },
    });

    const getappoloioLeadPageCount = () => {
        showLoader()
        commonApi.getappoloioLeadPageCount({},
            (message: string, resp: any) => {
                console.log(resp.data.noofpages)
                setMaxRange(resp?.data?.noofpages)
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }


    const onSubmit = (data: any) => {
        console.log(data);
        let params: any = {
            "frompage": data.frompage,
            "topage": data.topage,
        }

        showLoader()
        commonApi.getappoloioLead(params, (message: string, resp: any) => { 
            toast.success(message)
            handleClose()
            hideLoader()
        }, (message: string) => { 
            toast.error(message)
            hideLoader()
        })

    }



    useEffect(() => {
        getappoloioLeadPageCount()
    }, [])

    const handleClose = () => {
        close();
        reset();
    };

    return (
        <React.Fragment>
            <Modal className='salesforce-modal modal-lndscp' show={show}  >
                <div className="modal-header custom-modal-header">
                    <div className="d-flex flex-wrap">
                        <h5 className="card-title mr-2">Get Lead Data</h5>
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    <div className="row">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100" >
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">


                                            <div className="col-lg-6 col-md-6 col-6">
                                                <div className="fl-logo-top">
                                                    <div className=" mb-2">
                                                        <img src="assets/images/Apollo.png" style={{ width: '100%' }} />
                                                    </div>
                                                    <strong>CRM Modules</strong>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-6">
                                                {
                                                    (maxRange && maxRange > 0) ?
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label htmlFor="accntName">Select Range <span className='text-danger'>*</span></label>
                                                            </div>
                                                            <div className="col-12 row">
                                                                <Controller
                                                                    name={"frompage"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control col-5 " + (errors?.frompage ? ' border-danger' : '')}
                                                                            id="frompage"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            // onBlur={onBlur}
                                                                            placeholder="From page"
                                                                            onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                            onFocus={(e) => handleFocus(e)}
                                                                            onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                            onWheel={handleWheel}
                                                                            step={"1"}
                                                                            min={0}
                                                                        />
                                                                    )}
                                                                />
                                                                <div className="form-group col-2  ">to</div>
                                                                <Controller
                                                                    name={"topage"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control col-5 " + (errors?.topage ? ' border-danger' : '')}
                                                                            id="topage"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            placeholder="To page"
                                                                            onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                            onFocus={(e) => handleFocus(e)}
                                                                            onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                            onWheel={handleWheel}
                                                                            step={"1"}
                                                                            min={0}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors && errors.topage &&
                                                                    <span className='text-danger'>{errors?.topage?.message}</span>
                                                                }
                                                                <em><br />
                                                                    Max range you can select from 1 to {maxRange}
                                                                </em>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p>No data found from Apollo.io</p>
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className="col-12">
                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                    {
                                        (maxRange && maxRange > 0) ?
                                            <button type='submit' className='btn btn-primary'>Save</button>
                                            : ''
                                    }
                                    <button
                                        type='button'
                                        className='btn btn-outline-danger'
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ApolloMaping1Modal