import React, { useEffect, useState } from 'react'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { Link, useSearchParams } from 'react-router-dom';
import { URLS } from '../../_config';
import Footer from '../../components/commonComponents/Footer';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import Header from '../../components/commonComponents/Header';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { formatNumber } from '../../_common/functions';
import { AiOutlineOrderedList } from 'react-icons/ai';

const PipelineAccManagerList = () => {
    const platformApi = usePlatformApi();
    const [searchParams, setSearchParams] = useSearchParams();
    const quarter = searchParams.get("q");
    const region = searchParams.get("region");
    const regSec = searchParams.get("sec");
    const year = searchParams.get("year");

    const { showLoader, hideLoader } = useAppLoader();
    const [accManagerDataList, setAccManagerDataList] = useState<any>([])


    const getOpportunityPipelineData = () => {
        showLoader();
        var params = {
            type: "accountmanager",
            typeid: region,
            parenttype: regSec,
            quarter: quarter
        }
        platformApi.getOpportunityPipeline(params,
            (message: string, resp: any) => {
                setAccManagerDataList(resp.data.list);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    useEffect(() => {
        getOpportunityPipelineData()
    }, [])


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Pipeline Account Manager </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`/opportunity-pipeline?q=${quarter}&year=${year}`}>Pipeline </Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`${URLS.PIPELINE_REGION_LIST}?q=${quarter}&sec=${regSec}&year=${year}`}>Region </Link>
                                                </li>
                                                <li className="breadcrumb-item active">Account Manager</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mb-3">Manage Account Manager</h5>
                                                <div className='d-flex justify-content-end mb-3 gap-10'>
                                                    <Link to={`${URLS.PIPELINE}?q=${quarter}&year=${year}`} className="btn btn-primary"><AiOutlineOrderedList /> Back to Pipeline List</Link>
                                                    <Link to={`${URLS.PIPELINE_REGION_LIST}?q=${quarter}&sec=${regSec}&year=${year}`} className="btn btn-info"><AiOutlineOrderedList /> Back to Region List</Link>
                                                </div>
                                            </div>


                                            <div className="table-responsive">
                                                <table id="datatable" className="table table-bordered dt-responsive nowrap" style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Account Manager</th>
                                                            <th>Plan</th>
                                                            <th>Most Likely Forecast</th>
                                                            <th>Upside Forecast</th>
                                                            <th>Deal Level Forecast</th>
                                                            <th>AI Forecast</th>
                                                            <th>Closed</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accManagerDataList && accManagerDataList.length > 0 && accManagerDataList.map((item: any, index: number) => (
                                                            <tr key={Math.random()}>
                                                                <td >
                                                                    {(item.canexpand) ?
                                                                        <Link to={`/opportunity-pipeline/region/manager/opportunity?q=${quarter}&sec=${item.parenttype}&region=${region}&acc=${item.typeid}&year=${year}`}>{item.typename}</Link>
                                                                        :
                                                                        <>{item.typename}</>
                                                                    }
                                                                </td>
                                                                <td className="text-right">${formatNumber(item.plan)}</td>
                                                                <td className="text-right">
                                                                    <div className="d-flex justify-content-between mb-2">
                                                                        <i className="bx bx-user font-size-18"></i>
                                                                        <p> ${formatNumber(item.mostlikely)}</p>
                                                                    </div>
                                                                    {/* <div className="d-flex justify-content-between">
                                                                        <i className='bx bx-group font-size-18'></i>
                                                                        <p className="mb-0">${formatNumber(0)}</p>
                                                                    </div> */}
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="d-flex justify-content-between mb-2">
                                                                        <i className="bx bx-user font-size-18"></i>
                                                                        <p> ${formatNumber(item.upside)}</p>
                                                                    </div>
                                                                    {/* <div className="d-flex justify-content-between">
                                                                        <i className='bx bx-group font-size-18'></i>
                                                                        <p className="mb-0">${formatNumber(0)}</p>
                                                                    </div> */}
                                                                </td>
                                                                <td className="text-right">${formatNumber(item.deallevel)}</td>
                                                                <td className="text-right">${formatNumber(item.ai)}</td>
                                                                <td className="text-right">${formatNumber(item.closed)}</td>
                                                            </tr>)
                                                        )}

                                                        {
                                                            accManagerDataList && accManagerDataList.length == 0 &&
                                                            <tr><td colSpan={7} className='text-center'>No Data Found.</td></tr>
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PipelineAccManagerList