import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import CommonBoxDetails from '../components/commonComponents/CommonBoxDetails'
import FilterRow from '../components/commonComponents/FilterRow'
import ChurnedCustomers from './ChurnForecast/ChurnedCustomers'
import ContractionCustomers from './ChurnForecast/ContractionCustomers'
import PossibleLateRenewals from './ChurnForecast/PossibleLateRenewals'
import PossibleEarlyContraction from './ChurnForecast/PossibleEarlyContraction'
import RiskLevel from './ChurnForecast/RiskLevel'
import ReasonChartContainer from './ChurnForecast/ReasonChartContainer'
import TitleBar from '../components/commonComponents/TitleBar'

const ChurnForecast = () => {
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)
    const [revenueDetails, setRevenueDetails] = useState<any>(null)

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")
    const yearValue = searchParams.get("year")
    const regionValue = searchParams.get("region")
    const repValue = searchParams.get("rep")
    const statusValue = searchParams.get("Status")

    const filterCallBackFn = () => {
        
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}

                            <TitleBar title={'Churn Forecast'}
                                breadcrumbs={{
                                    'Churn Forecast': '#'
                                }} >
                            </TitleBar>


                            <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                        revenueDetails={revenueDetails}
                                        setRevenueDetails={setRevenueDetails}
                                        initialLoadFromPage={'churn-forecast'}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <FilterRow
                                                type="churn-forecast"
                                                filterDetails={filterDetails}
                                                setFilterDetails={setFilterDetails}
                                                setDoFilter={setDoFilter}
                                                defaultYear={true}
                                                callbackFunctions={filterCallBackFn}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ChurnedCustomers
                                title='Churned Customers'
                                filterDetails={filterDetails}
                                quarterValue={quarterValue}
                                typeOfContract={typeOfContract}
                                yearValue={yearValue}
                                regionValue={regionValue}
                                repValue={repValue}
                                statusValue={statusValue}
                                doFilter={doFilter}
                                setDoFilter={setDoFilter}
                            />

                            <ContractionCustomers
                                title='Contraction Customers'
                                filterDetails={filterDetails}
                                quarterValue={quarterValue}
                                typeOfContract={typeOfContract}
                                yearValue={yearValue}
                                regionValue={regionValue}
                                repValue={repValue}
                                statusValue={statusValue}
                                doFilter={doFilter}
                                setDoFilter={setDoFilter}
                            />

                            <PossibleLateRenewals
                                title='Possible Late Renewals'
                                filterDetails={filterDetails}
                                quarterValue={quarterValue}
                                typeOfContract={typeOfContract}
                                yearValue={yearValue}
                                regionValue={regionValue}
                                repValue={repValue}
                                statusValue={statusValue}
                                doFilter={doFilter}
                                setDoFilter={setDoFilter}
                            />

                            <PossibleEarlyContraction
                                title='Possible Early Contraction'
                                filterDetails={filterDetails}
                                quarterValue={quarterValue}
                                typeOfContract={typeOfContract}
                                yearValue={yearValue}
                                regionValue={regionValue}
                                repValue={repValue}
                                statusValue={statusValue}
                                doFilter={doFilter}
                                setDoFilter={setDoFilter}
                            />

                            <div className="row">
                                <div className="col-xl-6">
                                    <RiskLevel
                                        title='Churn forecast table based on Risk Level'
                                        filterDetails={filterDetails}
                                        quarterValue={quarterValue}
                                        typeOfContract={typeOfContract}
                                        yearValue={yearValue}
                                        regionValue={regionValue}
                                        repValue={repValue}
                                        statusValue={statusValue}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter} />
                                </div>

                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5>Churn & Contraction Reason Graph</h5>

                                            {/* <div id="column_chart" className="apex-charts" dir="ltr"></div> */}
                                            <ReasonChartContainer
                                                filterDetails={filterDetails}
                                                doFilter={doFilter}
                                                setDoFilter={setDoFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChurnForecast