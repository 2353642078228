import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/numberFieldRestriction';

interface FormValues {
	statusname: string;
	position: string;
}

const validationSchema = Yup.object().shape({
	statusname: Yup.string().required("Stage name is required"),
	position: Yup.string().required("Position is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	stageId?: any
	isEdit: boolean;
	listCallbackFunc: any
}

const AddOpportunityStageModal = ({ show, close, stageId, isEdit = false, listCallbackFunc }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [stageData, setStageData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			statusname: "",
			position: "",
		},
	});

	const getStageData = () => {
		showLoader();
		setLoading(true)
		platformApi.getAllOpportunityStages({ id: stageId },
			(message: string, resp: any) => {
				console.log(resp);
				setStageData(resp.data.list);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const onSubmit = (data: any) => {
		let params: any = {
			"statusname": data?.statusname,
			"position": data?.position,
		}
		if (isEdit) {
			params["id"] = stageData.id
		}

		showLoader()
		platformApi.manageOpportunityStage(params, (message: string, resp: any) => {
			toast.success(message)
			reset()
			close()
			hideLoader()
			listCallbackFunc()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		if (stageId) {
			getStageData()
		}
	}, [stageId, isEdit])

	useEffect(() => {
		console.log(stageData)
		if (stageData) {
			setValue('statusname', stageData?.statusname)
			setValue('position', stageData?.position)
		}
	}, [stageData])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
				onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											 
											<div className="row">
												<div className="form-group col-12">
													<label htmlFor="statusname">Stage Name <span className='text-danger'>*</span></label>
													<Controller
														name={"statusname"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<input
																type="text"
																className={"form-control " + (errors?.statusname ? ' border-danger' : '')}
																id="statusname"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter stage name"
															/>
														)}
													/>
													{errors && errors.statusname &&
														<span className='text-danger'>{errors?.statusname?.message}</span>
													}
												</div>


												<div className="form-group col-12">
													<label htmlFor="position">Position <span className='text-danger'>*</span></label>
													<Controller
														name={"position"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<input
																type="text"
																className={"form-control " + (errors?.position ? ' border-danger' : '')}
																id="position"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={(e) => { onBlur(); handleBlur(e) }}
																onFocus={(e) => handleFocus(e)}
																onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																onWheel={handleWheel}
																ref={ref}
																placeholder="Enter position"
															/>
														)}
													/>
													{errors && errors.position &&
														<span className='text-danger'>{errors?.position?.message}</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={() => { close(); reset() }}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddOpportunityStageModal