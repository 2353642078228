import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import TableContainer from '../components/commonComponents/TableContainer'
import { formatNumber, showDate } from '../_common/functions'
import moment from 'moment'
import FilterRow from '../components/commonComponents/FilterRow'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import TitleBar from '../components/commonComponents/TitleBar'

const QuotingManagement = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [quoteData, setQuoteData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [quoteSummery, setQuoteSummery] = useState<any>(null)
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)
    const navigate = useNavigate();

    const getQuoteData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = { page: page }
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllQuote(params,
            (message: string, resp: any) => {
                console.log(resp);
                setLoading(false);
                setQuoteData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const getquotesSummery = () => {
        showLoader();
        platformApi.getquotesSummeryy(filterDetails, (message: string, resp: any) => {
            // console.log(resp)
            setQuoteSummery(resp?.data?.list)
            hideLoader();
        }, (message: string) => {
            hideLoader();
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Account Name",
                accessor: "account.Name",
                className: "link-clr",
            },
            {
                Header: "Opportunity Name",
                accessor: "opportunity.Name",
                // className: "link-clr",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {
                            (value) ? <Link to={`/opportunity-details/${row?.original?.OpportunityId}`}
                                className='text-primary'>{value}</Link> : '--'
                        }
                    </div>
                )
            },
            {
                Header: "Contract Number",
                accessor: "contract.ContractNumber",
                className: "link-clr",
            },
            {
                Header: "Quote Name",
                accessor: "Name",
            },
            // {
            //     Header: "Rep",
            //     accessor: "contract.pocobj.name",
            // },
            {
                Header: "Approval Status",
                accessor: "Status",
            },
            {
                Header: "Renewal Due Date",
                accessor: "contract.EndDate",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? moment(value).format("MM/DD/YY") : ''}
                    </div>
                )
            },
            {
                Header: "Subtotal ($)",
                accessor: "Subtotal",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Discount ($)",
                accessor: "Discount",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(+row?.original?.Subtotal * +value / 100) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Tax ($)",
                accessor: "Tax",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(+row?.original?.TotalPrice * +value / 100) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Shipping Charge ($)",
                accessor: "ShippingCharge",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Grand Total ($)",
                accessor: "GrandTotal",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },

            {
                Header: "Contract Term",
                accessor: "contractterm",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value + ' ' + row.original.termtype}
                    </div>
                )
            },

            {
                Header: "Contract Start",
                accessor: "contractstartdate",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? showDate(value, 'MM/DD/YYYY') : '--'}
                    </div>
                )
            },
            {
                Header: "Contract End",
                accessor: "contractenddate",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? showDate(value, 'MM/DD/YYYY') : '--'}
                    </div>
                )
            },
        ],
        []
    )

    const gotToContractPage = (quarter: any, type: any) => {
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            navigate(`/contract-page?quarter=${filterDetails?.quarter != null ? filterDetails?.quarter : quarter}&type=${type}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}&Status=${filterDetails?.Status}`)
        } else {
            navigate(`/contract-page?quarter=${filterDetails?.quarter != null ? filterDetails?.quarter : quarter}&type=${type}`)
        }
    }

    useEffect(() => {
        getQuoteData();
        getquotesSummery();
    }, [])

    const filterCallBackFn = () => {
        getQuoteData();
        getquotesSummery();
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}

                            <TitleBar title={'Quoting Management'}
                                breadcrumbs={{
                                    'Quoting Management': '#'
                                }} >
                            </TitleBar>


                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body" onClick={() => gotToContractPage('FY', 'quotes_submitted')} style={{ cursor: "pointer" }}>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Quotes Submitted</p>
                                                            <h5 className="mb-2">${formatNumber(quoteSummery?.quotesubmited.toFixed(2))}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-file-document-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mr-3">
                                                        <p className="text-muted mb-2">Quotes Submitted Count</p>
                                                        <h5 className="mb-2">{quoteSummery?.quotesubmitedCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" onClick={() => gotToContractPage('FY', 'quotes_in_review')} style={{ cursor: "pointer" }}>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Quotes in Review</p>
                                                            <h5 className="mb-0">${formatNumber(quoteSummery?.quotereviewed.toFixed(2))}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-chart-bell-curve-cumulative" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mr-3">
                                                        <p className="text-muted mb-2">Quotes in Review Count</p>
                                                        <h5 className="mb-0">{quoteSummery?.quotereviewedCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" onClick={() => gotToContractPage('FY', 'quotes_rejected')} style={{ cursor: "pointer" }}>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Quotes Rejected</p>
                                                            <h5 className="mb-0">${formatNumber(quoteSummery?.quoterejected.toFixed(2))}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-autorenew" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mr-3">
                                                        <p className="text-muted mb-2">Quotes Rejected Count</p>
                                                        <h5 className="mb-0">{quoteSummery?.quoterejectedCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" onClick={() => gotToContractPage('FY', 'quotes_approved')} style={{ cursor: "pointer" }}>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Quotes Approved</p>
                                                            <h5 className="mb-0">${formatNumber(quoteSummery?.quoteapproved.toFixed(2))}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-file-multiple-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mr-3">
                                                        <p className="text-muted mb-2">Quotes Approved Count</p>
                                                        <h5 className="mb-0">{quoteSummery?.quoteapprovedCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end row */}
                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="quoting-management"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        setDoFilter={setDoFilter}
                                        defaultYear={true}
                                        callbackFunctions={filterCallBackFn}
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex flex-wrap justify-content-end">
                                                <Link to={`/add-quote/${undefined}?func=ADD`} className="btn btn-success">
                                                    <AiOutlinePlusCircle /> Add Quote</Link>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getQuoteData}
                                                    loading={loading}
                                                    rawData={quoteData}
                                                    clickFunc={true}
                                                // cstmClassName={'link-clr'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default QuotingManagement