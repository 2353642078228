import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { showDate } from '../../_common/functions'
import { AiOutlineSmile } from 'react-icons/ai';
import Pagination from '../../components/commonComponents/Pagination';

interface IncomingProps {
    accountId?: any;
}

const TabActivity = ({ accountId }: IncomingProps) => {
    const urlParams: any = useParams();
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [selectedPageNo, set_selectedPageNo] = useState(1);

    const [returnData, setReturnData] = useState<any>([]);
    const [listData, setListData] = useState<any>([]);
    const [userListData, setUserListData] = useState<any>([]);

    const [activityFilter, setActivityFilter] = useState<any>([]);
    const [userFilter, setUserFilter] = useState<any>([]);
    const [searchText, setSearchText] = useState<any>([]);

    const getListData = () => {
        showLoader();
        let params: any = {
            page: selectedPageNo, entity_id: accountId, activity: activityFilter,
            user_id: userFilter, search: searchText, entity_type: 'account'
        };
        platformApi.getAccountViewActivityList(params,
            (message: string, resp: any) => {
                setReturnData(resp.data.list);
                setListData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }


    const getUserList = (page = 1) => {
        platformApi.getAllArrUsers({ page: page },
            (message: string, resp: any) => {
                setUserListData(resp.data.list.data);
            }, (message: string) => {
                hideLoader();
            })
    }

    useEffect(() => {
        getListData();
    }, [selectedPageNo, accountId, activityFilter, userFilter, searchText])

    useEffect(() => {
        getListData();
        getUserList();
    }, [])

    const onChageDropdownActivity = (event: any) => {
        setActivityFilter(event.target.value);
        set_selectedPageNo(1);
    }
    const onChageDropdownUser = (event: any) => {
        setUserFilter(event.target.value);
        set_selectedPageNo(1);
    }

    const activitySearchHandler = (e: any) => {
        var searchVal = e.target.value;
        if (e.key === 'Enter') {
            setSearchText(searchVal);
            set_selectedPageNo(1);
        }
    }

    function selectPageHandler(pageNo: any) {
        set_selectedPageNo(pageNo)
    }


    var activityDate = '';
    const showDateTimeSection = (date: any) => {
        if (activityDate != date) {
            activityDate = date;
            return <h3 className="font-size-12 font-weight-bold mb-2">{date}</h3>
        }
    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-8">
                    <div className="d-flex align-items-center w-100">
                        <div className="d-flex align-items-center gap-10 w-75 activity-filter">
                            <span className="white-space-nowrap">Filter by: </span>
                            <select className="form-control activity_filter_dropdown"
                                onChange={onChageDropdownActivity} id="activityDropdown"  >
                                <option value=''>Filter activity </option>
                                <option value='' disabled>COMMUNICATION </option>
                                <option value={'calls'}>&nbsp;&nbsp; Calls </option>
                                <option value={'emails'}>&nbsp;&nbsp; Emails </option>
                                <option value='' disabled>TEAM ACTIVITY </option>
                                <option value={'meetings'}>&nbsp;&nbsp; Meetings </option>
                                <option value={'notes'}>&nbsp;&nbsp; Notes </option>
                                <option value={'quotes'}>&nbsp;&nbsp; Quotes </option>
                                <option value={'tasks'}>&nbsp;&nbsp; Tasks </option>
                                <option value='' disabled>UPDATES </option>
                                <option value={'custom_behavioral_events'}>&nbsp;&nbsp; Custom behavioral events</option>
                                <option value={'deal_activity'}>&nbsp;&nbsp; Deal activity </option>
                                <option value={'merges'}>&nbsp;&nbsp; Merges </option>
                            </select>
                            <select className="form-control" onChange={onChageDropdownUser} id="userDropdown" >
                                <option value='0'>Select User</option>
                                {
                                    userListData && userListData.length > 0 &&
                                    userListData.map((userRow: any) =>
                                        <option value={userRow.id} key={`userDropdown${userRow.id}`}>{userRow.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mb-3">
                    <div className="search-activity-fld">
                        <input type="text" className="form-control" placeholder="Search Activities"
                            onKeyDown={activitySearchHandler} onBlur={(e: any) => setSearchText(e.target.value)} />
                        <button className="activity-search-btn"><i className="bx bx-search-alt-2"></i></button>
                    </div>
                </div>
            </div>
            {/* <div className="row mt-3">
                <div className="col-sm-12">
                    <h3 className="font-size-18 font-weight-bold mb-2">Upcoming</h3>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="d-flex align-items-center gap-10 font-size-18">
                                <i className="dripicons-chevron-right font-size-18 text-primary"></i>
                                <strong>Manual Email</strong> assigned to John Doe</h4>
                            <p className="d-flex align-items-center gap-10 mb-0">
                                <i className="bx bx-check-circle text-danger font-size-18"></i> Email Customer</p>
                        </div>
                    </div>
                </div>
            </div> */}

            {(listData && listData.length > 0) ?
                <>
                    {
                        listData.map((itemData: any, index: any) =>
                            <div className="row mt-3" key={`activityLi${index}`}>
                                <div className="col-sm-12">

                                    {showDateTimeSection(showDate(itemData.created_at, "Do MMM YYYY"))}

                                    <div className="card">
                                        <div className="card-body">
                                            <span style={{ float: 'right' }} title={showDate(itemData.created_at, "Do MMM YYYY hh:mm A")}>{showDate(itemData.created_at, " hh:mm A")}</span>
                                            <h4 className="d-flex align-items-center gap-10 font-size-14">
                                                <i className="dripicons-chevron-right font-size-18 text-primary"></i>
                                                {itemData.activity_text}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <Pagination selectedPage={selectedPageNo} this_page_count={listData && listData.length}
                        total_count={returnData?.total} selectPage={selectPageHandler} perPage={returnData?.per_page} />
                </>

                :
                (<div className='row mt-3'>
                    <div className="no_data_found" >No activity found <AiOutlineSmile /></div>
                </div>)
            }

        </React.Fragment >
    )
}

export default TabActivity