import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { formatNumber } from '../_common/functions'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import moment from 'moment'
import AddLineitemModal from '../components/modals/addLineitem.modal'
import { AiOutlineCheckCircle, AiOutlineEdit, AiOutlineFilePdf, AiOutlineInfoCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { toast } from 'react-hot-toast'
import { changeString } from '../_common/functions'
import QuoteStatusEditModal from '../components/modals/quoteStatusEdit.modal'
import parse from 'html-react-parser'
import SelectTaxModal from '../components/modals/selectTax.modal'
import EditFieldModal from '../components/modals/editField.modal'
import { URLS } from '../_config'

const QuoteDetails = () => {

    const urlParams: any = useParams()
    const platformApi = usePlatformApi()
    const navigate = useNavigate()

    const { showLoader, hideLoader } = useAppLoader()

    const [searchParams, setSearchParams] = useSearchParams();
    const quoteType = searchParams.get("type")
    const opId = searchParams.get("opId")

    const [data, setData] = useState<any>(null)
    const [lineItemData, setLineItemData] = useState<any>([])
    const [rawData, setRawData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showLineitemModel, setShowLineitemModel] = useState<boolean>(false)
    const [lineitemId, setLineitemId] = useState<any>(null)
    const [pricebookId, setPricebookId] = useState<any>(null)
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [showEditStatusModal, setShowEditStatusModal] = useState<boolean>(false)
    const [currentProductId, setCurrentProductId] = useState<any>()
    const [showTaxModal, setShowTaxModal] = useState<boolean>(false)
    const [showEditFieldModal, setShowEditFieldModal] = useState<boolean>(false)
    const [taxValue, setTaxValue] = useState<any>(null)
    const [taxFor, setTaxFor] = useState<any>(null)
    const [taxForVal, setTaxForVal] = useState<any>(null)
    const [fieldDetails, setFieldDetails] = useState<any>(null)

    const expandedLineItemData = [
        { label: 'Quantity', fieldToShow: 'Quantity', fieldCalculateVal: 'Quantity', edit: true },
        { label: 'Sales Price', fieldToShow: 'UnitPrice', fieldCalculateVal: 'UnitPrice', edit: true, sign: '$' },
        { label: 'Uplift', fieldToShow: 'uplift_percentage', fieldCalculateVal: 'uplift_percentage', edit: true, sign: '%' },
        { label: 'Additional Disc', fieldToShow: 'Discount', fieldCalculateVal: 'Discountval', edit: true, sign: '$' },
        { label: 'Partner Discount', fieldToShow: 'partner_discount', fieldCalculateVal: 'partner_discount_value', edit: true, sign: '$' },
        { label: 'Tax', fieldToShow: 'taxper', fieldCalculateVal: 'Taxval', edit: true, sign: '$' },
        { label: 'Sub Total', fieldToShow: 'Subtotal', fieldCalculateVal: 'TotalPrice', sign: '$' },
    ]


    const getQuoteDetails = () => {
        showLoader()
        platformApi.getAllQuote({
            QuoteId: urlParams?.id
        }, (message: string, resp: any) => { 
            setData(resp.data.list.data[0])
            hideLoader()
        }, (message: string) => { 
            hideLoader()
        })
    }

    const getAllQuoteLineItem = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllQuoteLineItem({
            page: page,
            QuoteId: urlParams?.id
        },
            (message: string, resp: any) => { 
                setLoading(false);
                setRawData(resp.data.list);
                setLineItemData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteLineItems = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.quoteLineitemDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const deleteCalBackFn = () => {
        getAllQuoteLineItem();
        getQuoteDetails();
    }

    const getQuotePdf = (id: any) => {
        showLoader();
        setLoading(true);
        platformApi.getQuotePdf({ id },
            (message: string, resp: any) => { 
                window.open(resp.data.link, '_blank');
                setLoading(false);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const handleShowLineitemModel = (isEdit: boolean, lineItemId?: any) => {
        setShowLineitemModel(true)
        setLineitemId(lineItemId)
        setIsEditable(isEdit)
    }

    const handleCloseLineitemModal = () => {
        setShowLineitemModel(false)
        setLineitemId(null)
        setPricebookId(null)
        setIsEditable(false)
    }


    const submitforApproval = () => {
        showLoader()
        let params: any = {
            "quoteid": data.id,
        } 
        platformApi.addApprovalForQuote(params, (message: string, resp: any) => { 
            // toast.success(message)
            // if (resp.data.details && resp.data.details.list && resp.data.details.list.length > 0) {

            toast(t => (
                <span style={{ minWidth: "280px" }}>
                    <div className='align-items-center d-flex font-size-20 gap-10 justify-content-center text-center text-success'>
                        <i className='bx bxs-check-circle font-size-22' /> {message}
                    </div>
                    <hr />
                    {resp.data.details.list.length > 0 && resp?.data?.details?.list?.map((item: any, key: any) =>
                        <div className='bg-light mb-2 px-3 py-2' key={key}>
                            <h5>{item?.rulename}</h5>
                            {/* <h5>{item?.approverobject?.user?.full_name}</h5>
                                <p className='mb-0'>{item?.approverobject?.user?.designation?.name}</p> */}
                        </div>
                    )}
                    <div className='align-items-center'>
                        <button className="btn btn-success btn-sm m-1" onClick={() => toast.dismiss(t.id)}>
                            OK
                        </button>
                    </div>

                </span>
            ), { duration: 3000, className: 'withdraw-toast' });


            //     toast((t) => (
            //         <span style={{ minWidth: "280px" }} >
            //             <div className='align-items-center d-flex font-size-20 gap-10 justify-content-center text-center text-success'>
            //                 <i className='bx bxs-check-circle font-size-22' />
            //                 {message}
            //             </div>
            //             {resp.data.details.list?.map((item: any) =>
            //                 <div className='bg-light mb-2 px-3 py-2' key={Math.random()}>
            //                     <h5>{item.approverobject && item.approverobject.user ? item.approverobject.user?.full_name : ""}</h5>
            //                     <p className='mb-0'>{item.approverobject && item.approverobject.user && item.approverobject.user.designation ? item?.approverobject?.user?.designation?.name : ""}</p>
            //                 </div>
            //             )}

            //         </span>
            //     ), { duration: 3000 });
            // }
            hideLoader()
            getQuoteDetails()
        }, (message: string) => { 
            toast.error(message)
            hideLoader()
        })
    }

    const EditBtn = ({ column, value, quoteId }: any) => {
        return (
            <AiOutlineEdit
                onClick={() => handleShowLineitemModel(true, value)}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const confirmQuoteStatusEvent = (status: string, params?: any) => { 
        toast(
            t => (
                <span>
                    Are you sure you want to change quote status to {status} ?
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                let payload = params ? params : { id: data?.id, Status: status };
                                showLoader()
                                platformApi.manageQuote(
                                    payload,
                                    (message: string, resp: any) => { 
                                        toast.success(message);
                                        getQuoteDetails();
                                        getAllQuoteLineItem();
                                        hideLoader();
                                    },
                                    (message: string) => { 
                                        toast.error(message);
                                        hideLoader();
                                    },
                                );
                            }}>
                            Yes
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}>
                            No
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' },
        );
    };

    const closeEditStatusModal = () => {

        setShowEditStatusModal(false)
    }

    const expandCollapseProduct = (id: any) => {
        setCurrentProductId(id)
    }

    const editQuoteAndLineItem = (id: any, field: any, type: any, label: any, value: any) => {
        // console.log({ id, field, type, label, value })
        setFieldDetails(({ id, field, type, label, value }))
        setTaxForVal(value)
        if (field == "Tax" && type == "quote") {
            setTaxFor("Quote")
            handleShowTaxModal(type)
        } else if (field == "taxper" && type == "lineitem") {
            setTaxFor("Product")
            handleShowTaxModal()
        } else {
            openEditFieldModal()
        }
    }

    const handleShowTaxModal = (type=null) => {
        setShowTaxModal(true)
    }

    const handleCloseTaxModal = () => {
        setShowTaxModal(false)
    }

    const openEditFieldModal = () => {
        setShowEditFieldModal(true)
    }

    const closeEditFieldModal = () => {
        getQuoteDetails()
        getAllQuoteLineItem()
        setShowEditFieldModal(false)
    }

    const callEditQuoteMultiyearApi = (data?: any) => {
        if (fieldDetails) {
            let params: any = {
                id: fieldDetails?.id
            }
            params[fieldDetails?.field] = data?.field
            setTaxValue(null)
            showLoader()
            platformApi.editQuoteMultiyear(params,
                (message: string, resp: any) => {
                    // console.log({ resp })
                    closeEditFieldModal()
                    hideLoader()
                }, (message: string) => {
                    hideLoader()
                })
        }
    }

    const callEditQuoteLineitemMultiyearApi = (data?: any) => {
        if (fieldDetails) {
            if (fieldDetails?.field == "Quantity" && data?.field < 1) {
                toast.error("Minimum Quantity must be 1")
                return
            } else {
                let params: any = {
                    id: fieldDetails?.id
                }
                params[fieldDetails?.field] = data?.field
                setTaxValue(null)
                showLoader()
                platformApi.editQuoteLineitemMultiyear(params,
                    (message: string, resp: any) => {
                        console.log({ resp })
                        closeEditFieldModal()
                        hideLoader()
                    }, (message: string) => {
                        hideLoader()
                    })
            }
        }
    }

    useEffect(() => {
        if (taxValue) {
            if (fieldDetails?.type == 'quote') {
                callEditQuoteMultiyearApi({ field: taxValue?.taxpercent })
            } else if (fieldDetails?.type == 'lineitem') {
                callEditQuoteLineitemMultiyearApi({ field: taxValue?.taxpercent })
            }
        }
    }, [taxValue])

    useEffect(() => {
        if (urlParams && urlParams.id) {
            getQuoteDetails()
            getAllQuoteLineItem()
        }
    }, [])

    useEffect(() => {
        if (!showLineitemModel && lineItemData && lineItemData.length > 0) {
            setPricebookId(lineItemData[0]?.pricebookentity?.Pricebook2Id)
        } else if (!showLineitemModel && lineItemData && lineItemData.length == 0) {
            setPricebookId(null)
        }
    }, [lineItemData, showLineitemModel])

    return (
        <React.Fragment>
            <AddLineitemModal
                show={showLineitemModel}
                close={handleCloseLineitemModal}
                quoteId={urlParams?.id}
                lineItemId={lineitemId}
                isEdit={isEditable}
                pricebookId={pricebookId}
                getQuoteDetails={getQuoteDetails}
                getAllQuoteLineItem={getAllQuoteLineItem}
                quoteDetails={data}
            />
            {data && data != null && <QuoteStatusEditModal
                show={showEditStatusModal}
                close={closeEditStatusModal}
                quoteData={data}
                confirmQuoteStatusEvent={confirmQuoteStatusEvent}
            />}
            <SelectTaxModal
                show={showTaxModal}
                close={handleCloseTaxModal}
                status={"1"}
                taxfor={taxFor}
                setTaxValue={setTaxValue}
                taxVal={taxForVal}
            />
            {showEditFieldModal && fieldDetails &&
                <EditFieldModal
                    show={showEditFieldModal}
                    close={closeEditFieldModal}
                    fieldDetails={fieldDetails}
                    callEditQuoteMultiyearApi={callEditQuoteMultiyearApi}
                    callEditQuoteLineitemMultiyearApi={callEditQuoteLineitemMultiyearApi}
                />
            }
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div>
                                            <h4 className="mb-0 font-size-18">{data?.Name ? data?.Name + "'s details" : "Loading..."} </h4>
                                        </div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    {
                                                        data?.OpportunityId && data?.OpportunityId.includes('OP-') ?
                                                            <Link to={`/opportunity-details/${data?.OpportunityId}`}  > Opportunity Details</Link>
                                                            :
                                                            (
                                                                data?.AccountId && data?.AccountId.includes('A-') ?
                                                                    <Link to={`/account-view/${data?.AccountId}`} > Account Details</Link> :
                                                                    <Link to={URLS.QUOTING_MANAGEMENT}>Quoting Management</Link>
                                                            )
                                                    }

                                                </li>
                                                <li className="breadcrumb-item active">Quote details</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mb-3 gap-10'>
                                        {/* <button
                                            type='button'
                                            className="btn btn-success"
                                            onClick={() => setShowQuoteSignatureModal(true)}
                                        >
                                            Add Signature
                                        </button> */}

                                        {
                                            (data?.AccountId != "null" && data?.AccountId != null) ?
                                                <>
                                                    <Link to={`/account-view/${data?.AccountId}`} className="btn btn-primary">Go To Account</Link>
                                                    {
                                                        ["Draft", "Needs Review", "In Review"].includes(data?.Status) &&
                                                        <Link to={`/add-quote/${data?.AccountId}?type=AccountId&id=${urlParams?.id}`} className="btn btn-warning">
                                                            <AiOutlineEdit /> Edit Quote
                                                        </Link>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        data?.OpportunityId && data?.OpportunityId.includes('OP-') &&
                                                        <Link to={`/opportunity-details/${data?.OpportunityId}`} className="btn btn-primary">Go To Opportunity</Link>
                                                    }

                                                    {
                                                        ["Draft", "Needs Review", "In Review"].includes(data?.Status) &&
                                                        <Link to={`/add-quote/${data?.OpportunityId}?type=OpportunityId&id=${urlParams?.id}`} className="btn btn-warning">
                                                            <AiOutlineEdit /> Edit Quote
                                                        </Link>
                                                    }

                                                </>
                                        }


                                        {data && data.approval && data.approval.length === 0 && data.GrandTotal > 0 &&
                                            (!["Accepted", "Denied"].includes(data?.Status)) &&
                                            <button type='button' className="btn btn-success" onClick={submitforApproval}  >
                                                <AiOutlineCheckCircle />  Submit for Approval
                                            </button>
                                        }

                                        <button type='button' className="btn btn-dark" onClick={() => getQuotePdf(data?.id)} >
                                            <AiOutlineFilePdf /> Export to PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title text-center mb-3">Quote Status</h5>
                                            <div className="">
                                                <div className="progress-container">
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center '>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Draft", "Needs Review",
                                                                        "In Review", "Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Draft", "Needs Review",
                                                                        "In Review", "Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('Draft')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Draft">
                                                                1
                                                            </div>
                                                            <div>Draft</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Needs Review",
                                                                        "In Review", "Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Needs Review",
                                                                        "In Review", "Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('Needs Review')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Needs Review">
                                                                2
                                                            </div>
                                                            <div>Needs Review</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["In Review", "Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["In Review", "Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('In Review')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="In Review">
                                                                3
                                                            </div>
                                                            <div>In Review</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Approved",
                                                                        "Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('Approved')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Approved">
                                                                4
                                                            </div>
                                                            <div>Approved</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Rejected", "Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('Rejected')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Rejected">
                                                                5
                                                            </div>
                                                            <div>Rejected</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Presented",
                                                                        "Accepted", "Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('Presented')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Presented">
                                                                6
                                                            </div>
                                                            <div>Presented</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Accepted", "Denied"].includes(data?.Status) &&
                                                                    setShowEditStatusModal(true)
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Accepted">
                                                                7
                                                            </div>
                                                            <div>Accepted</div>
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt renewal-evnt-text'>
                                                        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                            <div
                                                                className={'progress-circle ' + (
                                                                    ["Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                                )}
                                                                onClick={() =>
                                                                    !["Denied"].includes(data?.Status) ?
                                                                        confirmQuoteStatusEvent('Denied')
                                                                        : null
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Denied">
                                                                8
                                                            </div>
                                                            <div>Denied</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                        </div >
                                    </div >
                                </div >

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Quote Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="quoteName">Quote name :</label>
                                                    <strong>{data?.Name ? data?.Name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="expDate">Expiration Date :</label>
                                                    <strong>{data?.ExpirationDate ? moment(data?.ExpirationDate).format("MM/DD/YY") : ""}</strong>
                                                </div>
                                                <div className="col-6">

                                                    {
                                                        (data?.AccountId != "null" && data?.AccountId != null) ?
                                                            <>
                                                                <label className='mr-2' htmlFor="accntName">Account Name :</label>
                                                                {data?.account &&
                                                                    <strong className='cursor-pointer'
                                                                        onClick={() => navigate(`/account-view/${data?.account?.AccountId}`)}
                                                                    >
                                                                        {data?.account ? data?.account?.Name : ""}
                                                                    </strong>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <label className='mr-2' htmlFor="accntName">Opportunity Name :</label>
                                                                {data?.opportunity &&
                                                                    <Link className='cursor-pointer' to={`/opportunity-details/${data?.OpportunityId}`} >
                                                                        {data?.opportunity?.Name}
                                                                    </Link>
                                                                }
                                                            </>
                                                    }

                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract Number :</label>
                                                    {data?.contract &&
                                                        <strong className='cursor-pointer'
                                                            onClick={() => navigate(`/contract-details/${data?.contract?.ContractId}`)}
                                                        >
                                                            {data?.contract ? data?.contract?.ContractNumber : ""}
                                                        </strong>
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="expDate">Contract Start Date :</label>
                                                    <strong>{data?.contractstartdate ? moment(data?.contractstartdate).format("MM/DD/YY") : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract End Date :</label>
                                                    <strong>{data?.contractenddate ? moment(data?.contractenddate).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract Term :</label>
                                                    <strong>{data?.contractterm + ' ' + data?.termtype}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Partner Name :</label>
                                                    <strong>{data?.partner ? data?.partner?.name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Status :</label>
                                                    <strong>{data?.Status ? data?.Status : ""}</strong>
                                                </div>
                                                {/* <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Approval Status :</label>
                                                    <strong>{data?.approval ? data?.approval[0]?.status : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Approval Date :</label>
                                                    <strong>{data?.approval && data.approval.length > 0 ? moment(data?.approval[0]?.updated_at).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Approver Name :</label>
                                                    <strong>{data?.approval && data.approval.length > 0 && data.approval[0]?.approverobject ? data?.approval[0]?.approverobject?.approvername : ""}</strong>
                                                </div> */}
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Description :</label>
                                                    <strong>{data?.Description ? data?.Description : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Pdf :</label>
                                                    <a
                                                        href={data?.sla?.basepath}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {data?.sladoc?.file_name_original}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {data && data.approval && data.approval.length > 0 ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Approver List</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Approver Name </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Status </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Comment </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Updated At </label>
                                                    </div>
                                                </div>
                                                {data && data.approval && data.approval.length > 0 ?
                                                    data.approval?.map((item: any) =>
                                                        <div className="row mt-2" key={Math.random()}>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.user ? item.user?.full_name : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.status ? item?.status : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title={item?.comment}
                                                                    >
                                                                        {item.comment ? changeString(item?.comment, 25) + " ..." : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.updated_at ? moment(item.updated_at).format('MM/DD/YY') : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    null}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <div className="col-12 d-none">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Totals</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="subTotal">Sub Total ($) :</label>
                                                    <strong>{data?.Subtotal ? formatNumber(data?.Subtotal) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="tax">Tax (%) :</label>
                                                    <strong>{data?.Tax ? data?.Tax : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="discount">Discount (%) :</label>
                                                    <strong>{data?.Discount ? data?.Discount : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shipping">Shipping and Handling ($) :</label>
                                                    <strong>{data?.ShippingCharge ? formatNumber(data?.ShippingCharge) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="totalPrice">Total Price ($) :</label>
                                                    <strong>{data?.TotalPrice ? formatNumber(data?.TotalPrice) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="grandTotal">Grand Total ($) :</label>
                                                    <strong>{data?.GrandTotal ? formatNumber(data?.GrandTotal) : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Prepared For</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="contactName">Contact Name :</label>
                                                    <strong>{data?.contact ? data?.contact?.FirstName + " " + data?.contact?.LastName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="phone">Phone :</label>
                                                    <strong>{data?.Phone ? data?.Phone : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="email">Email :</label>
                                                    <strong>{data?.Email ? data?.Email : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="fax">Fax :</label>
                                                    <strong>{data?.Fax ? data?.Fax : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Address Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingName">Bill To Name :</label>
                                                    <strong>{data?.BillingName ? data?.BillingName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingName">Ship To Name :</label>
                                                    <strong>{data?.ShippingName ? data?.ShippingName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingStreet">Bill To Street :</label>
                                                    <strong>{data?.BillingStreet ? data?.BillingStreet : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingStreet">Ship To Street :</label>
                                                    <strong>{data?.ShippingStreet ? data?.ShippingStreet : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingCity">Bill To City :</label>
                                                    <strong>{data?.BillingCity ? data?.BillingCity : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingCity">Ship To City :</label>
                                                    <strong>{data?.ShippingCity ? data?.ShippingCity : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingState">Bill To State/Province :</label>
                                                    <strong>{data?.BillingState ? data?.BillingState : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingState">Ship To State/Province :</label>
                                                    <strong>{data?.ShippingState ? data?.ShippingState : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingZip">Bil To Zip/Postal Code :</label>
                                                    <strong>{data?.BillingPostalCode ? data?.BillingPostalCode : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingZip">Ship To Zip/Postal Code :</label>
                                                    <strong>{data?.ShippingPostalCode ? data?.ShippingPostalCode : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingCountry">Bill To Country :</label>
                                                    <strong>{data?.BillingCountry ? data?.BillingCountry : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingCountry">Ship To Country :</label>
                                                    <strong>{data?.ShippingCountry ? data?.ShippingCountry : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Line Items</h5>
                                                {
                                                    ["Draft", "Needs Review", "In Review"].includes(data?.Status) &&
                                                    <button onClick={() => handleShowLineitemModel(false)} className="btn btn-success" >
                                                        <AiOutlinePlusCircle /> Add Line Items
                                                    </button>
                                                }

                                            </div>

                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title" />
                                            </div>
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered dt-responsive nowrap"
                                                    style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }} >
                                                    <thead>
                                                        <tr>
                                                            <th className="w-f-80">Actions</th>
                                                            <th className="w-15">Product Code</th>
                                                            <th>Product Name</th>
                                                            <th className="text-right w-15">List Price</th>
                                                            {data?.multiyearpricing?.length > 0 &&
                                                                data?.multiyearpricing?.map((year: any, i: any) =>
                                                                    <th className="text-right w-15" key={Math.random()}>Year {i + 1}</th>
                                                                )
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lineItemData?.length ?
                                                            lineItemData.map((item: any) =>
                                                                <Fragment key={Math.random()}>
                                                                    <tr>
                                                                        <td>
                                                                            {["Draft", "Needs Review", "In Review"].includes(data?.Status) &&

                                                                                <DeleteButtonCell
                                                                                    column={null}
                                                                                    value={item?.id}
                                                                                    deleteFunc={deleteLineItems}
                                                                                    callbackFunc={deleteCalBackFn}
                                                                                    type={"Lineitem"}
                                                                                />
                                                                            }
                                                                            {/* <EditBtn column={null} value={item?.QuoteLineItemId} quoteId={urlParams?.id} /> */}

                                                                        </td>
                                                                        {/* <td className="w-f-80"> */}
                                                                        <td  >
                                                                            {item?.product?.ProductCode}
                                                                            <span className="collapse-link">
                                                                                {
                                                                                    currentProductId != item?.id ?
                                                                                        <i className="bx bxs-right-arrow" onClick={() => expandCollapseProduct(item?.id)} />
                                                                                        :
                                                                                        <i className="bx bxs-down-arrow" onClick={() => expandCollapseProduct(null)} />
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>{item?.product?.Name}</td>
                                                                        <td className="text-right w-15">${item?.ListPrice ? formatNumber(item?.ListPrice) : '0.00'}</td>

                                                                        {item?.multiyearpricing?.length > 0 &&
                                                                            item?.multiyearpricing?.map((yearData: any, i: any) =>
                                                                                <th className="text-right w-15" key={Math.random()}>${formatNumber(yearData?.TotalPrice)}</th>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                    {
                                                                        expandedLineItemData.map((lineData: any) =>
                                                                            <tr key={Math.random()} className={"collapse-tr " + (currentProductId == item?.id ? "show" : "")}>
                                                                                <th className="text-right white-space-nowrap" colSpan={4}>{lineData.label}</th>
                                                                                {
                                                                                    item?.multiyearpricing?.length > 0 &&
                                                                                    item?.multiyearpricing?.map((yearData: any) =>
                                                                                        <td className="text-right w-15" key={Math.random()}>

                                                                                            {yearData && yearData[lineData.fieldToShow] &&
                                                                                                (lineData.fieldToShow == 'Discount' || lineData.fieldToShow == 'partner_discount' || lineData.fieldToShow == 'taxper') &&
                                                                                                <AiOutlineInfoCircle title={formatNumber(yearData[lineData.fieldToShow]) + " %"} className='font-size-14' data-toggle="tooltip" data-placement="top" />
                                                                                            }
                                                                                            {
                                                                                                ["Draft", "Needs Review", "In Review"].includes(data?.Status) && lineData.edit &&
                                                                                                <AiOutlineEdit onClick={() => editQuoteAndLineItem(yearData?.id, lineData.fieldToShow, 'lineitem', lineData.label, yearData[lineData.fieldToShow])}
                                                                                                />
                                                                                            }
                                                                                            &nbsp;
                                                                                            {lineData.sign == '$' ? '$' : ''}

                                                                                            {yearData && yearData[lineData.fieldCalculateVal] ?
                                                                                                lineData.fieldCalculateVal != "Quantity" ?
                                                                                                    formatNumber(yearData[lineData.fieldCalculateVal])
                                                                                                    : yearData[lineData.fieldCalculateVal]
                                                                                                : "0.00"
                                                                                            }

                                                                                            {lineData.sign == '%' ? '%' : ''}

                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </Fragment>
                                                            )
                                                            :
                                                            <tr>
                                                                <td colSpan={7} className="text-center">No Data Found</td>
                                                            </tr>
                                                        }
                                                        {lineItemData && lineItemData?.length > 0 &&
                                                            <Fragment>
                                                                <tr style={{ borderTop: '3px solid #c1c1c1' }}>
                                                                    <td colSpan={4} className="text-right">
                                                                        <strong>Sub Total</strong>
                                                                    </td>
                                                                    {data?.multiyearpricing?.length > 0 ?
                                                                        data?.multiyearpricing?.map((yearData: any) =>
                                                                            <td className="text-right" key={Math.random()}>
                                                                                <strong>${yearData?.Subtotal}</strong>
                                                                            </td>
                                                                        )
                                                                        : null
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} className="text-right">
                                                                        <strong>Discount</strong>
                                                                    </td>
                                                                    {data?.multiyearpricing?.length > 0 ?
                                                                        data?.multiyearpricing?.map((yearData: any) =>
                                                                            <td className="text-right" key={Math.random()}>
                                                                                <AiOutlineInfoCircle title={formatNumber(yearData?.Discount) + " %"} className='font-size-14' data-toggle="tooltip" data-placement="top" />
                                                                                &nbsp;
                                                                                {
                                                                                    ["Draft", "Needs Review", "In Review"].includes(data?.Status) &&
                                                                                    <AiOutlineEdit
                                                                                        onClick={() => editQuoteAndLineItem(yearData?.id, 'Discount', 'quote', 'Discount', yearData?.Discount)}
                                                                                    />
                                                                                }
                                                                                &nbsp;&nbsp;
                                                                                <strong>${yearData?.Discount ? formatNumber(+yearData?.Subtotal * +yearData?.Discount / 100) : "0.00"}</strong>
                                                                            </td>
                                                                        )
                                                                        : null
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} className="text-right">
                                                                        <strong>Total Price</strong>
                                                                    </td>
                                                                    {data?.multiyearpricing?.length > 0 ?
                                                                        data?.multiyearpricing?.map((yearData: any) =>
                                                                            <td className="text-right" key={Math.random()}>
                                                                                <strong>${yearData?.TotalPrice ? formatNumber(yearData?.TotalPrice) : "0.00"}</strong>
                                                                            </td>
                                                                        )
                                                                        : null
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} className="text-right">
                                                                        <strong>Tax</strong>
                                                                    </td>
                                                                    {data?.multiyearpricing?.length > 0 ?
                                                                        data?.multiyearpricing?.map((yearData: any) =>
                                                                            <td className="text-right" key={Math.random()}>
                                                                                <AiOutlineInfoCircle title={formatNumber(yearData?.Tax) + " %"} className='font-size-14' data-toggle="tooltip" data-placement="top" />
                                                                                &nbsp; 
                                                                                {/* {yearData?.Tax} */}
                                                                                {
                                                                                    ["Draft", "Needs Review", "In Review"].includes(data?.Status) &&
                                                                                    <AiOutlineEdit
                                                                                        onClick={() => editQuoteAndLineItem(yearData?.id, 'Tax', 'quote', 'Tax', yearData?.Tax)}
                                                                                    />
                                                                                }
                                                                                &nbsp;&nbsp;
                                                                                <strong>${yearData?.Tax ? formatNumber(yearData?.TotalPrice * +yearData?.Tax / 100) : "0.00"}</strong>
                                                                            </td>
                                                                        )
                                                                        : null
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} className="text-right">
                                                                        <strong>Shipping and Handling</strong>
                                                                    </td>
                                                                    {data?.multiyearpricing?.length > 0 ?
                                                                        data?.multiyearpricing?.map((yearData: any) =>
                                                                            <td className="text-right" key={Math.random()}>
                                                                                {
                                                                                    ["Draft", "Needs Review", "In Review"].includes(data?.Status) &&
                                                                                    <AiOutlineEdit
                                                                                        onClick={() => editQuoteAndLineItem(yearData?.id, 'ShippingCharge', 'quote', 'Shipping and Handling', yearData?.ShippingCharge)}
                                                                                    />
                                                                                }
                                                                                &nbsp;&nbsp;
                                                                                <strong>${yearData?.ShippingCharge ? formatNumber(yearData?.ShippingCharge) : "0.00"}</strong>
                                                                            </td>
                                                                        )
                                                                        : null
                                                                    }
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} className="text-right">
                                                                        <strong>Grand Total</strong>
                                                                    </td>
                                                                    {data?.multiyearpricing?.length > 0 &&
                                                                        data?.multiyearpricing?.map((yearData: any) =>
                                                                            <td className="text-right" key={Math.random()}>
                                                                                <strong>${yearData?.GrandTotal ? formatNumber(yearData?.GrandTotal) : "0.00"}</strong>
                                                                            </td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            </Fragment>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <label className='mr-2' htmlFor="shippingCountry">Terms & Conditions:</label>
                                            <div
                                                style={{
                                                    maxHeight: '150px',
                                                    overflowY: 'auto'
                                                }}
                                            >
                                                {/* <strong>{data?.tandc ? data?.tandc : ""}</strong> */}
                                                {data?.tandc ? parse(data?.tandc) : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-around">
                                                <h5 className="mb-auto mt-auto">Authorized Signature</h5>

                                                <div>
                                                    {data?.signature && data?.signature?.basepath != '' && data.signatureimg ?
                                                        <div>
                                                            <img
                                                                src={data?.signature?.basepath}
                                                                alt=""
                                                                className='bg-soft-secondary'
                                                                style={{ maxHeight: '80px' }}
                                                            />
                                                            <div className='text-center mt-2'>({data?.signature_fullname})</div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default QuoteDetails