import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import Footer from '../../components/commonComponents/Footer';
import { URLS } from '../../_config';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { importDataOptionsStatic } from '../../_common/staticData';
import { AiOutlineCloudUpload, AiOutlineDownload, AiOutlineEye } from 'react-icons/ai';
import { showDate } from '../../_common/functions';


interface ImportFormValues {
    importType: any,
    importfor: any,
    csvfile: any
}

const validationSchema = Yup.object().shape({
    importType: Yup.object().required("Type is required"),
    importfor: Yup.object().required("Field is required"),
    // csvfile: Yup.object().required("File is required"),
});


const ImportData = () => {
    const platformApi = usePlatformApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [typeOptions, setTypeOptions] = useState<any>(importDataOptionsStatic)
    const [accUserOptions, setAccUserOptions] = useState<any>({})
    const [fileValue, setFileValue] = useState<any>()
    const [fileValueSelected, setFileValueSelected] = useState(true)
    const [csvData, setCsvData] = useState<any>({})
    const [csvDataPreviewId, setCsvDataPreviewId] = useState<any>(null)
    const [csvDataPreview, setCsvDataPreview] = useState<any>(null)

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<ImportFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            importType: '',
            importfor: '',
            csvfile: '',
        },
    });
    // hideLoader();
    const getAllArrUsers = () => {
        showLoader();
        platformApi.getAllArrUsers({},
            (message: string, resp: any) => {
                const tempData = resp.data.list.data
                if (tempData && tempData.length > 0) {
                    setAccUserOptions(tempData.map((item: any) => {
                        item.value = item.id
                        item.label = item.name
                        return item
                    }))
                }
                hideLoader();

            }, (message: string) => {
                hideLoader();
            })
    }

    // const getallcsv = () => {
    //     showLoader();
    //     platformApi.getallcsv({}, (message: string, resp: any) => {
    //         const tempData = resp.data.list.data
    //         console.log('tempData', tempData)
    //         setCsvData(tempData)
    //         hideLoader();
    //     }, (message: string) => {
    //         hideLoader();
    //     })
    // }

    useEffect(() => {
        getAllArrUsers()
        // getallcsv()
    }, [])

    const onSubmit = (data: any) => {
        let formData: any = new FormData()
        if (fileValue) {
            formData.append('csvfile', fileValue)
            setFileValueSelected(true)
        } else {
            setFileValueSelected(false)
            return false;
        }
        formData.append("type", data?.importType?.value)
        formData.append("importfor", data?.importfor?.value)

        showLoader()
        platformApi.uploadCSV(formData, (message: string, resp: any) => {
            toast.success(message)
            reset()
            setFileValue(null) 
            setCsvDataPreviewId(resp?.data?.details?.id);
            setCsvDataPreview(resp?.data?.retdata)
            // getallcsv()
            hideLoader()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const fileOnChange = (data: any) => {
        if (data?.target?.files) {
            setFileValue(data.target.files[0])
        }
    }

    const csvFilePreview = (id: any) => {
        showLoader();
        platformApi.getcsvfiledata({ 'id': id }, (message: string, resp: any) => {
            toast.success(message)
            console.log('resp', resp)
            setCsvDataPreview(resp.data)
            hideLoader()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })

    }

    const csvFileImportData = (id: any) => {

        toast(
            t => (
                <span>
                    Are you sure you want to import this CSV data?
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);

                                showLoader();
                                platformApi.storecsvdata({ 'id': id }, (message: string, resp: any) => {
                                    toast.success(message)
                                    setCsvDataPreview(null)
                                    setCsvDataPreviewId(null); 
                                    // getallcsv();
                                    hideLoader();
                                    window. location. reload(); 
                                }, (message: string) => {
                                    toast.error(message)
                                    hideLoader()
                                })
                            }}>
                            Yes
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => { toast.dismiss(t.id) }}>
                            No
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' },
        );



    }


    return (
        <React.Fragment>

            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Import Data</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Import Data</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-end mb-3 gap-10'>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}



                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-3">
                                                        <label htmlFor="description">Upload CSV <span className='text-danger'>*</span> </label>

                                                        <div className="form-group mb-1">
                                                            <Controller
                                                                name={"csvfile"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <div className="custom-file">
                                                                        <input name={name} type="file" className={"custom-file-input " + (errors?.csvfile ? ' border-danger' : '')}
                                                                            id="customFile" accept=".csv" onChange={fileOnChange} 
                                                                            style={{ paddingLeft: "5px" }}
                                                                            required />
                                                                        <label className="custom-file-label" htmlFor="customFile">{fileValue && fileValue != undefined ? fileValue?.name : "Choose file"}</label>
                                                                    </div>
                                                                )}
                                                            />
                                                            {!fileValueSelected &&
                                                                <span className='text-danger'>File is required.</span>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="form-group col-3">
                                                        <label htmlFor="importType">Type Name <span className='text-danger'>*</span> </label>
                                                        <Controller name={"importType"} control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <Select
                                                                    className={"select-box " + (errors?.importType ? ' border-danger' : '')}
                                                                    id="importType"
                                                                    options={typeOptions ? typeOptions : []}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                    placeholder={"Select type"}
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.importType &&
                                                            <span className='text-danger'>Field is required.</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-3">
                                                        <label htmlFor="importfor">Account Manager <span className='text-danger'>*</span></label>

                                                        <Controller
                                                            name={"importfor"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <Select
                                                                    className={"select-box" + (errors?.importfor ? ' border-danger' : '')}
                                                                    id="importfor"
                                                                    options={accUserOptions ? accUserOptions : []}
                                                                    placeholder={"Select User"}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.importfor &&
                                                            <span className='text-danger'>Field is required</span>
                                                        }
                                                    </div>



                                                    <div className="form-group col-1">
                                                        <label htmlFor="importfor">&nbsp;  </label>

                                                        <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                            <button type='submit' className='btn btn-primary'>Upload</button>
                                                        </div>
                                                    </div>


                                                    <div className="form-group col-2">
                                                        <b><u>Sample Files</u> </b> <br /><br />
                                                        <div className=' d-flex gap-10 '>
                                                            <Link to='/assets/files/Lead_sample.csv' target='_blank'>Lead <AiOutlineDownload /></Link> <br />
                                                            <Link to='/assets/files/Account_sample.csv' target='_blank'>Account <AiOutlineDownload /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>



                            <div className="row d-none" >

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Uploaded CSV Files</h5>
                                            </div>
                                            {
                                                csvData && csvData.length > 0 ? <div>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Original File Name</th>
                                                                <th>File Type</th>
                                                                <th>Account User</th>
                                                                <th>Uploaded on</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                csvData.map((item: any, index: any) =>
                                                                    <tr key={Math.random()}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item?.csvfiledoc?.file_name_original}</td>
                                                                        <td>{item?.type}</td>
                                                                        <td>{item?.uploadfor?.name}</td>
                                                                        <td>{showDate(item?.csvfiledoc?.created_at, 'MM/DD/yyyy')}</td>
                                                                        <td>
                                                                            <Link to={item?.csvfile?.basepath} title='Download uploaded csv'><AiOutlineDownload /></Link>
                                                                            &nbsp; &nbsp;

                                                                            <AiOutlineEye className='c-pointer'
                                                                                onClick={() => csvFilePreview(item?.id)} title='Preview uploaded csv' />
                                                                            &nbsp; &nbsp;
                                                                            <AiOutlineCloudUpload className='c-pointer'
                                                                                onClick={() => csvFileImportData(item?.id)} title='Import uploaded csv data' />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                                    :
                                                    <p>No file import yet!</p>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>



                            {
                                csvDataPreview &&

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">
                                                        Uploaded CSV File Preview &nbsp;&nbsp;&nbsp;
                                                        <button className='btn btn-sm btn-success' title='Import uploaded csv data'
                                                            onClick={() => csvFileImportData(csvDataPreviewId)} >
                                                            <AiOutlineCloudUpload /> Import Data
                                                        </button>
                                                    </h5>
                                                </div>

                                                <div className="table-responsive">
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                {
                                                                    csvDataPreview && csvDataPreview.header &&
                                                                    csvDataPreview.header.map((item: any, index: any) =>
                                                                        <th key={Math.random()}>{csvDataPreview.header[index]}</th>
                                                                    )
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                csvDataPreview && csvDataPreview.data &&
                                                                csvDataPreview.data.map((itemRow: any, index: any) =>
                                                                    <tr key={Math.random()}>
                                                                        <td>{index + 1}</td>
                                                                        {
                                                                            itemRow && itemRow.map((item: any, index: any) =>
                                                                                <td key={Math.random()}>{item}</td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                )
                                                            }


                                                            {
                                                                csvDataPreview && csvDataPreview.data &&
                                                                csvDataPreview.data.length == 0 &&
                                                                <tr>
                                                                    <td key={Math.random()} colSpan={100}>No data found on this csv!</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                            }












                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default ImportData