import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import TableContainer from '../../components/commonComponents/TableContainer'
import { AiOutlineCheckCircle, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai'
import AddCallFormOnAccActivity from '../../components/modals/AccountView/AddCallFormOnAccActivity.modal'
import { showDate } from '../../_common/functions'
import CompletedNoteBox from '../../components/modals/AccountView/CompletedNoteBox.modal'
import CompletedNoteBoxView from '../../components/modals/AccountView/CompletedNoteBoxView.modal'

const TabCalls = () => {
    const urlParams: any = useParams();
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    var accountIdString = urlParams?.id

    const [activeTab, setActiveTab] = useState('Upcoming');
    const [actionRow, setActionRow] = useState({});
    const [actionId, setActionId] = useState(0);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showNoteBoxModal, setShowNoteBoxModal] = useState<boolean>(false);
    const [showNoteBoxViewModal, setShowNoteBoxViewModal] = useState<boolean>(false);

    const [returnData, setReturnData] = useState<any>([])
    const [listData, setListData] = useState<any>([])

    const closeModalHandler = () => {
        setShowModal(false);
        setShowNoteBoxModal(false);
        setShowNoteBoxViewModal(false);

        getListData();
    }

    const addNoteBoxModalHandler = (id: any) => {
        setActionId(id);
        setShowNoteBoxModal(true);
    }
    const viewNoteBoxModalHandler = (rowData: any) => {
        setActionRow(rowData);
        setShowNoteBoxViewModal(true);
    }

    const columnData = useMemo(
        () => [
            {
                Header: "Call with", accessor: (d: any) => {
                    return (d.callwithdetails == null) ? '--' : d.callwithdetails?.FirstName + " " + d.callwithdetails?.LastName
                }
            },
            { Header: "Call Agenda", accessor: "agenda" },
            { Header: "Description", accessor: "description" },
            {
                Header: "Call Date & Time", accessor: (d: any) => {
                    return showDate(d.call_date + " " + d.call_time, "MM-DD-YYYY hh:mm A")
                },
            }, 
            {
                Header: 'Actions', accessor: "id",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {(activeTab != 'Completed') ?
                            <AiOutlineCheckCircle onClick={() => addNoteBoxModalHandler(row.original.id)} />
                            :
                            <AiOutlineEye onClick={() => viewNoteBoxModalHandler(row.original)} />
                        }
                    </>,
            }
        ],
        [listData]
    )

    const getListData = (page = 1) => {
        showLoader();
        let params: any = { page: page, AccountId: accountIdString, tab: activeTab };
        platformApi.getAccountViewCallList(params,
            (message: string, resp: any) => {
                setReturnData(resp.data.list);
                setListData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    useEffect(() => {
        getListData();
    }, [activeTab])

    return (
        <React.Fragment>
            <AddCallFormOnAccActivity close={closeModalHandler} show={showModal} AccountId={urlParams?.id} />
            <CompletedNoteBox close={closeModalHandler} show={showNoteBoxModal} AccountId={urlParams?.id} actionId={actionId} type={'call'} />
            <CompletedNoteBoxView close={closeModalHandler} show={showNoteBoxViewModal} AccountId={urlParams?.id} actionRow={actionRow} type={'call'} />

            <div className="card">
                <div className="card-body">
                    <h5 className="font-size-18">Manage Calls
                        <button className='btn btn-sm btn-success' onClick={() => setShowModal(true)} style={{ float: 'right' }}>
                            <AiOutlinePlusCircle /> Add Call Schedule
                        </button>
                    </h5>

                    <div className="activities-tab-pan">
                        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#Upcoming" role="tab"
                                    onClick={() => setActiveTab('Upcoming')}>
                                    Upcoming
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#Completed" role="tab"
                                    onClick={() => setActiveTab('Completed')}>
                                    Completed
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#Overdue" role="tab"
                                    onClick={() => setActiveTab('Overdue')}>
                                    Overdue
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content pt-4 pb-4">
                            <div className="tab-pane active" id="Upcoming" role="tabpanel">
                                {/* <h5 className="font-size-12">Upcoming Calls </h5> */}
                                <div className="table-responsive">
                                    <TableContainer
                                        columns={columnData}
                                        fetchData={getListData}
                                        rawData={returnData}
                                        data={listData}
                                        loading={false}
                                        clickFunc={true}
                                    />
                                </div>
                            </div>

                            <div className="tab-pane" id="Completed" role="tabpanel">
                                <div className="table-responsive">
                                    <TableContainer
                                        columns={columnData}
                                        fetchData={getListData}
                                        rawData={returnData}
                                        data={listData}
                                        loading={false}
                                        clickFunc={true}
                                    />
                                </div>
                            </div>

                            <div className="tab-pane" id="Overdue" role="tabpanel">
                                <div className="table-responsive">
                                    <TableContainer
                                        columns={columnData}
                                        fetchData={getListData}
                                        rawData={returnData}
                                        data={listData}
                                        loading={false}
                                        clickFunc={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TabCalls