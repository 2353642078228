import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { toast } from 'react-hot-toast'
import { changeString, formatNumber } from '../_common/functions'
import moment from 'moment'
import ForecastEndDate from '../components/modals/forecastEndDate.modal'
import { AiOutlineEdit } from 'react-icons/ai'
import ForecastUpdateModal from '../components/modals/forecastUpdate.modal'
import FilterRow from '../components/commonComponents/FilterRow'
import ForecastBulkUpdateModal from '../components/modals/forecastBulkUpdate.modal'
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';

const CalendarPage = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Calendar</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Calendar</li>
                                        </ol>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                    <div className="card-body pb-5">
                                        <div id="lnb">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div
                                            id="calendarList"
                                            className="lnb-calendars-d1 mt-4 mr-sm-0 mb-4"
                                            />
                                            <div className="lnb-new-schedule ml-sm-3">
                                            <button
                                                id="btn-new-schedule"
                                                type="button"
                                                className="btn btn-primary lnb-new-schedule-btn"
                                                data-toggle="modal"
                                            >
                                                New schedule
                                            </button>
                                            </div>
                                        </div>
                                        <Calendar
                                            height='800px'
                                        />
                                        {/* <div id="calendar" style={{ height: 800 }}>
                                        </div> */}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* end row */}
                        </div>{" "}
                        {/* container-fluid */}
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default CalendarPage