import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import { handleKeyPressNumber, showDate } from '../_common/functions'
import Select from 'react-select'
import { URLS } from '../_config'
import { AiOutlineEye, AiOutlineOrderedList, AiOutlinePlusSquare } from 'react-icons/ai'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import AddRegionModal from '../components/modals/addRegion.modal'
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel, numberFieldValidationMsg } from '../_common/numberFieldRestriction'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { termTypeOptionsStatic } from '../../src/_common/staticData';

interface AddOpportunityFormValues {
    opportunityName: string;
    accntName: any;
    type: string;
    description: string;
    primaryCampSource: string;
    budgetConfirmed: any;
    discoveryCompleted: any;
    roiAnalysis: any;
    lossReason: any;
    closeDate: any | string;
    stage: any;
    probability: string | number;
    amount: string;
    nextStep: string;
    leadSource: string;
    contracted: any;
    termtype: any,
    contractterm: string | number;
    upliftperfornextquote: number | number;
    region: string;
    AccountManager: string;
}

const validationSchema = Yup.object().shape({
    opportunityName: Yup.string().required("Opportunity Name is required"),
    stage: Yup.object().required("Stage Name is required"),
    region: Yup.object().required("Region Name is required"),
    closeDate: Yup.string().required("Close Date is required"),
    termtype: Yup.object().required("Term type is required"),
    contractterm: Yup.string().required("Contract Term is required"),
    amount: Yup.string().required("Amount is required"),
    probability: Yup.number().max(100, 'Probability can not be greater than 100').required("Probability is required"),
    AccountManager: Yup.object().required("Account Manager is required"),
});


const AddOpportunity = () => {
    const commonApi = useCommonApi()

    const urlParams: any = useParams()
    const platformApi = usePlatformApi()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id: any = searchParams.get("id")

    const { showLoader, hideLoader } = useAppLoader()
    const [opportunityData, setOpportunityData] = useState<any>(null)
    const [accountData, setAccountData] = useState<any>(null)
    const [stageOptions, setStageOptions] = useState<any>([])
    const [accManagerOptions, setAccManagerOptions] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [accountOptions, setAccountOptions] = useState<any>(null)
    const [closeOnStageContent, setCloseOnStageContent] = useState<any>(false)
    const [regionOptions, setRegionOption] = useState<any>(null)
    const [termTypeOptions, setTermTypeOptions] = useState<any>(termTypeOptionsStatic)
    const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false)

    const urlAccountId = urlParams.id;

    const datePickerRef = useRef<any>();

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddOpportunityFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            opportunityName: "",
            accntName: undefined,
            type: "",
            termtype: '',
            contractterm: 12,
            upliftperfornextquote: 0,
            description: "",
            primaryCampSource: "",
            budgetConfirmed: "",
            discoveryCompleted: "",
            roiAnalysis: "",
            lossReason: 0,
            closeDate: '',
            stage: "",
            region: "",
            probability: "",
            amount: "",
            nextStep: "",
            leadSource: "",
            contracted: "",
            AccountManager: ''
        },
    });


    const getOpportunityStages = () => {
        showLoader()
        platformApi.getOpportunityStagesList({}, (message: string, resp: any) => {
            let stageStatus = resp.data;
            if (stageStatus && stageStatus.length > 0) {
                stageStatus.map((status: string, key: any) => {
                    var stage = { label: status, value: status };
                    setStageOptions((prevStages: any) => [...prevStages, stage])
                })
            }
            hideLoader()
        }, (message: string) => {
            hideLoader()
        })
    }


    const getAccountManagers = () => {
        showLoader()
        platformApi.getAllArrUsers({}, (message: string, resp: any) => {
            let tempArr = resp.data.list.data
            if (tempArr && tempArr.length > 0) {
                setAccManagerOptions(tempArr.map((item: any) => {
                    console.log('item', item)
                    item.label = item.name;
                    item.value = item.id;
                    return item
                }));
            }
            hideLoader()
        }, (message: string) => {
            hideLoader()
        })
    }

    const getOpportunityData = () => {
        showLoader();
        setLoading(true)
        platformApi.getAllOpportunity({ OpportunityId: id },
            (message: string, resp: any) => {
                // console.log(resp);
                setOpportunityData(resp.data.list.data[0]);
                setLoading(false)
                hideLoader();
            }, (message: string) => {
                setLoading(false)
                hideLoader();
            })
    }

    const getAccountList = () => {
        showLoader();
        let params: any = {}
        if (urlParams && urlParams.id) {
            params["AccountId"] = urlParams?.id
        }
        platformApi.getAllAccount(params,
            (message: string, resp: any) => {
                // console.log(resp);
                const tempData = resp.data.list.data
                // const tempData = resp.data.list
                setAccountData(tempData);
                if (tempData && tempData.length > 0) {
                    setAccountOptions(tempData.map((item: any) => {
                        item.value = item.AccountId
                        item.label = item.Name
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getRegionList = () => {
        showLoader();
        commonApi.getAllRegions({},
            (message: string, resp: any) => {
                // console.log(resp);
                let tempArr = resp.data.list.data
                if (tempArr && tempArr.length > 0) {
                    setRegionOption(tempArr.map((item: any) => {
                        item.label = item.region_name;
                        item.value = item.Region;
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }




    const onSubmit = (data: any) => {
        console.log(data);
        let params: any = {
            "AccountId": urlParams?.id || data?.accntName?.AccountId,
            "Amount": data?.amount,
            "CloseDate": data?.closeDate ? showDate(data.closeDate, 'YYYY-MM-DD') : "",

            "Description": data?.description,
            "LeadSource": data?.leadSource,
            "Name": data?.opportunityName,
            "NextStep": data?.nextStep,
            "Probability": data?.probability,
            "StageName": data?.stage?.value,
            "OpportunityType": data?.type,
            "termtype": data?.termtype.value,
            "contractterm": data?.contractterm,
            "upliftperfornextquote": data?.upliftperfornextquote,

            "primaryCampSource": data?.primaryCampSource,
            "budgetConfirmed": data?.budgetConfirmed,
            "discoveryCompleted": data?.discoveryCompleted,
            "roiAnalysis": data?.roiAnalysis,
            "lossReason": data?.lossReason == 1 ? 1 : 0,
            "contracted": data?.contracted,
            "Region": data?.region?.value,
            "AccountManager": data?.AccountManager?.value,
        }
        if (opportunityData && opportunityData?.id) {
            params["id"] = opportunityData?.id
        }
        // console.log(params)
        showLoader()
        platformApi.manageOpportunity(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            reset()
            navigate(`/opportunity-details/${resp?.data?.details?.OpportunityId}`)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    useEffect(() => {
        getRegionList()
        getOpportunityStages()
        getAccountManagers()

        if (id) {
            getOpportunityData()
        }
    }, [id])

    useEffect(() => {
        setValue('termtype', termTypeOptions?.find((item: any) => item.value === 'month') || '')

        // if (urlParams && urlParams.id) {
        getAccountList()
        // }
    }, [])

    useEffect(() => {

        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + 3);
        setValue('closeDate', currentDate)

        if (opportunityData) {
            setValue('opportunityName', opportunityData?.Name)
            setValue('accntName', '')
            setValue('type', opportunityData?.OpportunityType)

            setValue('termtype', termTypeOptions?.find((item: any) => item.value === opportunityData?.termtype) || '')
            setValue('contractterm', opportunityData?.contractterm)
            setValue('upliftperfornextquote', opportunityData?.upliftperfornextquote)
            setValue('description', opportunityData?.Description)

            setValue('primaryCampSource', opportunityData?.primaryCampSource)
            setValue('budgetConfirmed', opportunityData?.budgetConfirmed)
            setValue('discoveryCompleted', opportunityData?.discoveryCompleted)
            setValue('roiAnalysis', opportunityData?.roiAnalysis)

            setValue('lossReason', opportunityData?.lossReason)
            setValue('closeDate', new Date(opportunityData?.CloseDate))
            setValue('stage', stageOptions?.find((item: any) => item.value === opportunityData?.StageName) || '')
            setCloseOnStageContent(opportunityData?.StageName == 'Close Won' ? true : false)
            setValue('probability', opportunityData?.Probability)
            setValue('amount', opportunityData?.Amount)
            setValue('nextStep', opportunityData?.NextStep)
            setValue('leadSource', opportunityData?.LeadSource)
            setValue('contracted', opportunityData?.contracted)

            setValue('region', regionOptions?.find((item: any) => item.value == opportunityData?.Region) || '')
            setValue('AccountManager', accManagerOptions?.find((item: any) => item.value == opportunityData?.AccountManager) || '')

        } else {
            setValue('stage', stageOptions?.find((item: any) => item.value === 'Prospecting') || '')

        }
    }, [opportunityData, regionOptions, accManagerOptions, stageOptions])

    useEffect(() => {
        if (urlParams && urlParams.id && accountOptions && accountOptions.length > 0) {
            setValue('accntName', accountOptions?.find((item: any) => item.value === urlParams.id) || '')
        }

        if (accountData && accountData.length == 1) {
            var selectedAccountData = accountData[0];
            setValue('region', regionOptions?.find((item: any) => item.value == selectedAccountData?.Region) || '')
        }


    }, [urlParams, accountOptions, accountData, regionOptions])

    const closeAddRegionModal = () => {
        setShowAddRegionModal(false)
    }

    return (
        <React.Fragment>

            <AddRegionModal
                show={showAddRegionModal}
                close={closeAddRegionModal}
                isEdit={false}
                regionListCallbackFunc={getRegionList}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Opportunity</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.OPPORTUNITY_PAGE}>Opportunity Page</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Add Opportunity</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            {loading ?
                                <div>
                                    Loading......
                                </div>
                                :
                                <div className="row">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    <div className="d-flex flex-wrap justify-content-between">
                                                        <h5 className="card-title mr-2">Opportunity Information</h5>
                                                        <div className='d-flex justify-content-end mb-3 gap-10'>
                                                            <Link to={`/opportunity-management`} className="btn btn-primary"><AiOutlineOrderedList /> Opportunity List</Link>
                                                            {
                                                                (id != null) &&
                                                                <Link to={`/opportunity-details/${id}`} className="btn btn-warning"><AiOutlineEye /> Opportunity Details</Link>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="opportunityName">Opportunity name <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"opportunityName"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.opportunityName ? ' border-danger' : '')}
                                                                            id="opportunityName"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter opportunity name"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.opportunityName &&
                                                                <span className='text-danger'>{errors?.opportunityName?.message}</span>
                                                            }
                                                        </div>
                                                        {(urlAccountId !== undefined) &&
                                                            <div className="form-group col-6">
                                                                <label htmlFor="accntName">Account Name <span className='text-danger'>*</span></label>
                                                                <Controller
                                                                    name={"accntName"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                                        <>
                                                                            <Select
                                                                                className={"select-box " + (errors?.accntName ? ' border-danger' : '')}
                                                                                id="accntName"
                                                                                options={accountOptions ? accountOptions : []}
                                                                                placeholder={"Select account"}
                                                                                onChange={onChange}
                                                                                value={value}
                                                                                defaultValue={value}
                                                                                isDisabled={urlParams?.id}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                                {errors && errors.accntName &&
                                                                    <span className='text-danger'>Account is required</span>
                                                                }
                                                            </div>
                                                        }

                                                        <div className="form-group col-6">
                                                            <label htmlFor="AccountManager">Account Manager <span className='text-danger'>*</span></label>

                                                            <Controller
                                                                name={"AccountManager"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <Select
                                                                        className={"select-box" + (errors?.AccountManager ? ' border-danger' : '')}
                                                                        id="AccountManager"
                                                                        options={accManagerOptions ? accManagerOptions : []}
                                                                        placeholder={"Select Account Manager"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                )}
                                                            />
                                                            {errors && errors.AccountManager &&
                                                                <span className='text-danger'>Account Manager is required</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="stage">Stage name <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"stage"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <Select
                                                                        className={"select-box " + (errors?.stage ? ' border-danger' : '')}
                                                                        options={stageOptions ? stageOptions : []}
                                                                        placeholder={"Select Stage Name"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    // onBlur={ () => onChangeStage(value)}
                                                                    />
                                                                )}
                                                            />
                                                            {errors && errors.stage &&
                                                                <span className='text-danger'>Stage Name is required</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <div className='d-flex justify-content-between'>
                                                                <label htmlFor="region">Region <span className='text-danger'>*</span></label>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                                <Controller
                                                                    name={"region"}
                                                                    control={control}
                                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                        <Select
                                                                            className={"select-box" + (errors?.region ? ' border-danger' : '')}
                                                                            id="region"
                                                                            options={regionOptions ? regionOptions : []}
                                                                            placeholder={"Select region"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                        />
                                                                    )}
                                                                />
                                                                <span data-toggle="tooltip" data-placement="top"
                                                                    title={"Add Region"} onClick={() => setShowAddRegionModal(true)} >
                                                                    <AiOutlinePlusSquare className='cursor-pointer' />
                                                                </span>
                                                            </div>
                                                            {errors && errors.region &&
                                                                <span className='text-danger'>Region is required</span>
                                                            }
                                                        </div>



                                                        <div className="col-6 close_on_stage_content" >
                                                            <div className="row  ">
                                                                <div className="form-group col-4">
                                                                    <label htmlFor="contractterm">Contract Term <span className='text-danger'>*</span></label>
                                                                    <Controller
                                                                        name={"contractterm"}
                                                                        control={control}
                                                                        render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                            <input
                                                                                type="text"
                                                                                className={"form-control " + (errors?.contractterm ? ' border-danger' : '')}
                                                                                id="contractterm"
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                value={value}
                                                                                onBlur={onBlur}
                                                                                ref={ref}
                                                                                placeholder="Enter contract term"
                                                                                onKeyDown={handleKeyPressNumber}
                                                                            />

                                                                        )}
                                                                    />
                                                                    {errors && errors.contractterm &&
                                                                        <span className='text-danger'>{numberFieldValidationMsg(errors?.contractterm)}  </span>
                                                                    }
                                                                </div>

                                                                <div className="form-group col-5">
                                                                    <div className='d-flex justify-content-between'>
                                                                        <label htmlFor="termtype">Term Type <span className='text-danger'>*</span></label>
                                                                    </div>
                                                                    <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                                        <Controller
                                                                            name={"termtype"}
                                                                            control={control}
                                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                                <Select
                                                                                    className={"select-box" + (errors?.termtype ? ' border-danger' : '')}
                                                                                    id="termtype"
                                                                                    options={termTypeOptions ? termTypeOptions : []}
                                                                                    placeholder={"Select term type"}
                                                                                    onChange={onChange}
                                                                                    value={value}
                                                                                    defaultValue={value}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    {errors && errors.termtype &&
                                                                        <span className='text-danger'>Term type is required</span>
                                                                    }
                                                                </div>
                                                                <div className="form-group col-3">
                                                                    <label htmlFor="upliftperfornextquote">Uplift (%)   </label>
                                                                    <Controller
                                                                        name={"upliftperfornextquote"}
                                                                        control={control}
                                                                        render={({ field: { value, onChange, onBlur, name } }) => (
                                                                            <input
                                                                                type="number"
                                                                                className={"form-control " + (errors?.upliftperfornextquote ? ' border-danger' : '')}
                                                                                id="upliftperfornextquote"
                                                                                name={name}
                                                                                value={value}
                                                                                onChange={onChange}
                                                                                onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                                onFocus={(e) => handleFocus(e)}
                                                                                onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                                onWheel={handleWheel}
                                                                                step={"0.01"}
                                                                                min={0}
                                                                                placeholder="Enter uplift percentage"
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors && errors.upliftperfornextquote &&
                                                                        <span className='text-danger'>{errors?.upliftperfornextquote?.message}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-6">
                                                            <label htmlFor="type">Type</label>
                                                            <Controller
                                                                name={"type"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.type ? ' border-danger' : '')}
                                                                            id="type"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter type"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.type &&
                                                                <span className='text-danger'>{errors?.type?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="closeDate">Close Date <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"closeDate"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <DatePicker
                                                                        selected={value ? new Date(value) : null}
                                                                        onChange={onChange}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        className={"form-control " + (errors?.closeDate ? ' border-danger' : '')}
                                                                        id="closeDate"
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        ref={datePickerRef}
                                                                        value={value}
                                                                        placeholderText="Enter close date"
                                                                        minDate={new Date()}
                                                                    />
                                                                )}
                                                            />

                                                            {errors && errors.closeDate &&
                                                                <span className='text-danger'>Close date is required</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="primaryCampSource">Primary Campaign Source</label>
                                                            <Controller
                                                                name={"primaryCampSource"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.primaryCampSource ? ' border-danger' : '')}
                                                                            id="primaryCampSource"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter primary campaign source"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.primaryCampSource &&
                                                                <span className='text-danger'>{errors?.primaryCampSource?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="probability">Probability (%) <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"probability"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.probability ? ' border-danger' : '')}
                                                                        id="probability"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                        onFocus={(e) => handleFocus(e)}
                                                                        onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                        onWheel={handleWheel}
                                                                        step={"0.01"}
                                                                        min={0}
                                                                        placeholder="Enter probability"
                                                                    />
                                                                )}
                                                            />
                                                            {errors && errors.probability &&
                                                                <span className='text-danger'>
                                                                    {numberFieldValidationMsg(errors?.probability)}

                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="amount">Amount ($) <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"amount"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.amount ? ' border-danger' : '')}
                                                                            id="amount"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter amount"
                                                                            onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.amount &&
                                                                <span className='text-danger'>{numberFieldValidationMsg(errors?.amount)}</span>
                                                            }
                                                        </div>
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="lossReason" className='mb-0' >Loss Reason</label>

                                                            <Controller
                                                                name={"lossReason"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.lossReason ? ' border-danger' : '')}
                                                                            id="lossReason"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            checked={value == 1 ? true : false}
                                                                        />
                                                                    </>
                                                                )}
                                                            />

                                                        </div>
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="budgetConfirmed" className='mb-0' >Budget Confirmed</label>
                                                            <Controller
                                                                name={"budgetConfirmed"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.budgetConfirmed ? ' border-danger' : '')}
                                                                            id="budgetConfirmed"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            checked={value == '1' ? true : false}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="discoveryCompleted" className='mb-0' >Discovery Completed</label>
                                                            <Controller
                                                                name={"discoveryCompleted"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.discoveryCompleted ? ' border-danger' : '')}
                                                                            id="discoveryCompleted"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            checked={value == '1' ? true : false}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="roiAnalysis" className='mb-0' >ROI Analysis Completed</label>
                                                            <Controller
                                                                name={"roiAnalysis"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.roiAnalysis ? ' border-danger' : '')}
                                                                            id="roiAnalysis"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            checked={value == '1' ? true : false}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <h5 className="card-title mr-2">Additional Information</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="nextStep">Next Step</label>
                                                            <Controller
                                                                name={"nextStep"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.nextStep ? ' border-danger' : '')}
                                                                            id="nextStep"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter next step"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.nextStep &&
                                                                <span className='text-danger'>{errors?.nextStep?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="leadSource">Lead Source</label>
                                                            <Controller
                                                                name={"leadSource"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.leadSource ? ' border-danger' : '')}
                                                                            id="leadSource"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter lead source"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.leadSource &&
                                                                <span className='text-danger'>{errors?.leadSource?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="description">Description</label>
                                                            <Controller
                                                                name={"description"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.description ? ' border-danger' : '')}
                                                                            id="description"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter description"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.description &&
                                                                <span className='text-danger'>{errors?.description?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="contracted" className='mb-0' >Contracted</label>
                                                            <Controller
                                                                name={"contracted"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.contracted ? ' border-danger' : '')}
                                                                            id="contracted"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            checked={value == '1' ? true : false}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                        <button type='submit' className='btn btn-primary'>Save</button>

                                                        {
                                                            (urlAccountId && urlAccountId.includes('A-')) ?
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-outline-danger'
                                                                    onClick={() => navigate('/account-view/' + urlAccountId)}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                :
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-outline-danger'
                                                                    onClick={() => navigate(URLS.OPPORTUNITY_PAGE)}
                                                                >
                                                                    Cancel
                                                                </button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddOpportunity