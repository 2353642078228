import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai'
import CreateOpportunityModal from '../components/modals/createOpportunity.modal'
import LeadDetailsModal from '../components/modals/LeadDetails.modal'
import UserDetailsModal from '../components/modals/UserDetails.modal'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'

const LeadManagement = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [leadData, setLeadData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    // const [additionalData, setAdditionalData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate();
    const [showCreateOpportunityModal, setShowCreateOpportunityModal] = useState<boolean>(false)
    const [showLeadDetailsModal, setShowLeadDetailsModal] = useState<boolean>(false)
    const [selectedLead, setSelectedLead] = useState<any>()
    const [showUserDetailsModal, setShowUserDetailsModal] = useState<boolean>(false)

    const [arrUserId, setArrUserId] = useState<any>(null)

    const getLeadData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = {
            page: page
        }
        platformApi.getAllLead(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setLeadData(resp.data.list);
                setData(resp.data.list.data);
                // setAdditionalData(resp.data.addldata);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }


    const closeLeadDetailsModal = () => {
        setShowLeadDetailsModal(false);
    }

    const opportunityDestroy = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.leaddestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }
    const callbackFnAfterDelete = () => {
        getLeadData();
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "LeadId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={opportunityDestroy}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Lead"}
                            infoMsg="All related Opportunity will be deleted. Do you want to continue?"
                        />
                        <AiOutlinePlus
                            className="cursor-pointer mr-2"
                            onClick={() => {
                                setShowCreateOpportunityModal(true)
                                setSelectedLead(row.original)
                            }}
                        />
                        {
                            (row.original?.accountCreated != 1) && <AiOutlineEdit
                                onClick={() => navigate(`/add-lead/${row.original.LeadId}`)}
                                className="cursor-pointer mr-2"
                                data-toggle="tooltip" data-placement="top" title="Edit"
                            />
                        }

                    </>,
            },
            {
                Header: 'Title',
                accessor: 'Title',
                Cell: ({ column, value, row }: any) =>
                    <>
                        <span className='c-pointer text-primary'
                            onClick={() => {
                                setShowLeadDetailsModal(true)
                                setSelectedLead(row.original)
                            }}>{value}</span>
                    </>,
            },
            {
                Header: 'Company',
                accessor: 'Company'
            },
            {
                Header: 'Account Manager',
                accessor: 'AccountManager',
                Cell: ({ column, value, row }: any) =>
                    <>
                        {console.log({ column, value, row })}
                        {(value > 0) ? <div className='c-pointer text-primary'
                            onClick={() => {
                                setShowUserDetailsModal(true)
                                setArrUserId(row.original?.accountManagerdetails.id)
                            }}>{row.original?.accountManagerdetails?.name}</div> : '--'}
                    </>,
            },
            {
                Header: 'Street',
                accessor: 'Street'
            },
            {
                Header: 'City',
                accessor: 'City'
            },
            {
                Header: 'State',
                accessor: 'State'
            },
            {
                Header: 'Country',
                accessor: 'Country'
            },
            {
                Header: 'First Name',
                accessor: 'FirstName'
            },
            {
                Header: 'Last Name',
                accessor: 'LastName'
            },
            {
                Header: 'Lead Source',
                accessor: 'LeadSource'
            },
            {
                Header: 'Status',
                accessor: 'Status'
            },
        ],
        []
    )

    useEffect(() => {
        getLeadData()
    }, [])

    const closeUserDetailsModal = () => {
        setShowUserDetailsModal(false)
        setArrUserId(null)
    }

    return (
        <React.Fragment>
            {
                showCreateOpportunityModal && selectedLead &&
                <CreateOpportunityModal
                    show={showCreateOpportunityModal}
                    close={() => setShowCreateOpportunityModal(false)}
                    id={selectedLead?.id}
                />
            }
            {
                showLeadDetailsModal && <LeadDetailsModal
                    show={showLeadDetailsModal}
                    close={closeLeadDetailsModal}
                    leadData={selectedLead}
                />
            }
            {
                showUserDetailsModal && <UserDetailsModal
                    show={showUserDetailsModal}
                    close={closeUserDetailsModal}
                    arrUserId={arrUserId}
                />
            }
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Lead Management</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Lead Management</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2"></h5>
                                                <Link to={`/add-lead`} className="btn btn-success">Add Lead</Link>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getLeadData}
                                                    loading={loading}
                                                    rawData={leadData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default LeadManagement