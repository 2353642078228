import React, { useEffect, useMemo, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import EditButtonCell from '../actionsBtns/EditButtonCell'
import TableContainer from '../commonComponents/TableContainer'
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import toast from 'react-hot-toast'

interface IncomingProps {
    AccountId?: any;
    accountData?: any;
    addPocCallBack?: any;
}

const ContactList = ({ AccountId, accountData, addPocCallBack }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contactData, setContactData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getContactData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllContact({
            page: page,
            AccountId: AccountId,
            limit: 4
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContactData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteContact = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.contactDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const callbackFnAfterDelete = () => {
        getContactData();
    }

    const addAccPOC = (POC: any) => {
        toast(
            (t) => (
                <span>
                    <b>Are you sure you want to add POC?</b>
                    <br />
                    <br />
                    <p>If POC is already added, it will be replaced by this.</p>
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="btn btn-success btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                console.log('accountData', accountData)
                                platformApi.addAccountPOC({ id: accountData?.id, POC: POC },
                                    (message: string, resp: any) => {
                                        // console.log(resp);
                                        addPocCallBack()
                                        toast.success(message)
                                        hideLoader();
                                    }, (message: string) => {
                                        hideLoader();
                                        toast.error(message)
                                    })
                            }}
                        >
                            Yes
                        </button>

                        <button className="btn btn-secondary btn-sm m-1" onClick={() => toast.dismiss(t.id)} >
                            No
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' }
        );
    }

    const columns = useMemo(
        () => [{
            Header: 'Actions',
            accessor: "ContactId",
            Cell: ({ column, value, row }: any) =>
                <>
                    <DeleteButtonCell
                        column={column}
                        value={row.original.id}
                        deleteFunc={deleteContact}
                        callbackFunc={callbackFnAfterDelete}
                        type={"Contact"}
                    />
                    <EditButtonCell column={column} value={value} link={`add-contact/${AccountId}`} />
                    {/* {JSON.stringify(row.original.id)} */}
                    {/* {JSON.stringify(accountData?.pocobj?.id)} */}
                    {
                        row.original.id != accountData?.pocobj?.id &&
                        <AiOutlinePlusCircle onClick={() => addAccPOC(row.original.id)} className='c-pointer' title='Mark as POC' />
                    }

                </>,
        },
        {
            Header: "Name",
            accessor: (row: { FirstName: any; LastName: any; name: any }) => {
                return { FirstName: row.FirstName, LastName: row.LastName };
            },
            Cell: ({ value }: any) => {
                return (
                    <div className='nowrap'>
                        <span>{value.FirstName}{" "}{value.LastName}</span>
                    </div>
                );
            },
        },
        {
            Header: "Email",
            accessor: "Email",
        },
        // {
        //     Header: "Department",
        //     accessor: "Department",
        // },
        // {
        //     Header: "Title",
        //     accessor: "Title",
        // },
        {
            Header: "Phone",
            accessor: "Phone",
            className: "nowrap",
        },
        ], [accountData]
    )

    useEffect(() => {
        if (AccountId) {
            getContactData();
        }
    }, [AccountId])

    return (
        <React.Fragment>
            {/* <div className="card-body">
                <div className="d-flex flex-wrap">
                    <h5 className="card-title mr-2">Contact</h5>
                </div> */}
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getContactData}
                    loading={loading}
                    rawData={contactData}
                    cstmClassName={"table-scroll-overflow"}
                />
            </div>
            {/* </div> */}
        </React.Fragment>
    )
}

export default ContactList