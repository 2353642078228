import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { formatNumber } from '../../_common/functions';
import moment from 'moment';
import parse from 'html-react-parser'


interface IncomingProps {
    show: boolean;
    close: () => void;
    quoteId: any;
}

const QuotePreviewModal = ({ show, close, quoteId }: IncomingProps) => {

    // const urlParams: any = useParams()
    const platformApi = usePlatformApi()

    const { showLoader, hideLoader } = useAppLoader()

    const [quoteData, setQuoteData] = useState<any>(null)
    const [lineItemData, setLineItemData] = useState<any>([])

    const getQuoteDetails = () => {
        showLoader()
        platformApi.getAllQuote({
            QuoteId: quoteId
        }, (message: string, resp: any) => {
            // console.log(message, resp)
            setQuoteData(resp.data.list.data[0])
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            hideLoader()
        })
    }

    const getAllQuoteLineItem = (page = 1) => {
        showLoader();
        platformApi.getAllQuoteLineItem({
            page: page,
            QuoteId: quoteId
        },
            (message: string, resp: any) => {
                console.log(resp);
                setLineItemData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    useEffect(() => {
        if (quoteId) {
            getQuoteDetails()
            getAllQuoteLineItem()
        }
    }, [quoteId])


    return (
        <React.Fragment>
            <Modal
                className='salesforce-modal prv-quote-modal quote-preview-modal'
                show={show}
            // onHide={() => { close() }}
            >
                <div className="modal-header custom-modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => { close() }}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    <div className="table-responsive">
                        <table
                            cellSpacing={0}
                            cellPadding={0}
                            style={{
                                width: "100%",
                                maxWidth: 800,
                                margin: "0 auto",
                                border: 0,
                                padding: 0
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            width: "50%",
                                            paddingBottom: 10,
                                            fontSize: 18,
                                            fontWeight: 500,
                                            textTransform: "uppercase",
                                            color: "#222",
                                            textAlign: "left"
                                        }}
                                    >
                                        Quote details
                                    </td>
                                    <td style={{ width: "50%", textAlign: "right" }}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: "100%", padding: "0 0 15px 0" }}>
                                        <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            style={{
                                                width: "100%",
                                                border: 0,
                                                padding: 15,
                                                background: "#fff",
                                                borderRadius: 5,
                                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: "50%",
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            color: "#222"
                                                        }}
                                                    >
                                                        Quote:
                                                        <span
                                                            style={{ fontSize: 14, fontWeight: 400, paddingLeft: 10 }}
                                                        >
                                                            {quoteData?.Name ? quoteData?.Name : ""}
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "50%",
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "right",
                                                            color: "#222"
                                                        }}
                                                    >
                                                        STATUS:
                                                        <span
                                                            style={{ fontSize: 14, fontWeight: 400, paddingLeft: 10 }}
                                                        >
                                                            {quoteData?.Status ? quoteData?.Status : ""}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: "50%",
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            color: "#222"
                                                        }}
                                                    >
                                                        Created:
                                                        <span
                                                            style={{ fontSize: 14, fontWeight: 400, paddingLeft: 10 }}
                                                        >
                                                            {quoteData?.created_at ? moment(quoteData?.created_at).format('MM/DD/YY') : ""}
                                                        </span>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: "50%",
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            color: "#222"
                                                        }}
                                                    >
                                                        Due:
                                                        <span
                                                            style={{ fontSize: 14, fontWeight: 400, paddingLeft: 10 }}
                                                        >
                                                            {quoteData?.ExpirationDate ? moment(quoteData?.ExpirationDate).format("MM/DD/YY") : ""}
                                                        </span>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: "100%", padding: "0 0 15px 0" }}>
                                        <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            style={{
                                                width: "100%",
                                                border: 0,
                                                padding: 15,
                                                background: "#fff",
                                                borderRadius: 5,
                                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        valign="top"
                                                        style={{
                                                            width: "50%",
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            color: "#222"
                                                        }}
                                                    >
                                                        <table
                                                            cellSpacing={0}
                                                            cellPadding={0}
                                                            style={{ width: "100%", border: 0 }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 15,
                                                                            fontWeight: 500,
                                                                            textAlign: "left",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Company Information
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "left",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Test Company srl
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "left",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Address:{" "}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                paddingLeft: 10
                                                                            }}
                                                                        >
                                                                            Public Wales, Somewhere New York NY 4468
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "left",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Phone:{" "}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                paddingLeft: 10
                                                                            }}
                                                                        >
                                                                            0123456789
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "left",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Email:{" "}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                paddingLeft: 10
                                                                            }}
                                                                        >
                                                                            office@breakingpoint.ro
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td
                                                        valign="top"
                                                        style={{
                                                            width: "50%",
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "right",
                                                            color: "#222"
                                                        }}
                                                    >
                                                        <table
                                                            cellSpacing={0}
                                                            cellPadding={0}
                                                            style={{ width: "100%", border: 0 }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 15,
                                                                            fontWeight: 500,
                                                                            textAlign: "right",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Customer Information
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "right",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        {quoteData?.account?.Name ? quoteData?.account?.Name : ""}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "right",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Address:{" "}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                paddingLeft: 10
                                                                            }}
                                                                        >
                                                                            {`${quoteData?.account?.BillingStreet ? quoteData?.account?.BillingStreet : ""}  
                                                                        ${quoteData?.account?.BillingCity ? quoteData?.account?.BillingCity : ""} 
                                                                        ${quoteData?.account?.BillingState ? quoteData?.account?.BillingState : ""}  
                                                                        ${quoteData?.account?.BillingCountry ? quoteData?.account?.BillingCountry : ""} 
                                                                        ${quoteData?.account?.BillingPostalCode ? quoteData?.account?.BillingPostalCode : ""}`}

                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "right",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Phone:{" "}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                paddingLeft: 10
                                                                            }}
                                                                        >
                                                                            {quoteData?.account?.Phone ? quoteData?.account?.Phone : ""}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            width: "100%",
                                                                            padding: "0 0 8px 0",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            textAlign: "right",
                                                                            color: "#222"
                                                                        }}
                                                                    >
                                                                        Email:{" "}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                paddingLeft: 10
                                                                            }}
                                                                        >
                                                                            {quoteData?.account?.Email ? quoteData?.account?.Email : ""}
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: "100%", padding: "0 0 15px 0" }}>
                                        <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            style={{
                                                width: "100%",
                                                border: 0,
                                                padding: 15,
                                                background: "#fff",
                                                borderRadius: 5,
                                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
                                            }}
                                        >
                                            <tbody>
                                                {quoteData?.multiyear && quoteData?.multiyear.length > 0 ?
                                                    quoteData?.multiyear?.map((itemmulti: any, indexmulti: number) => (
                                                        <>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: "50%",
                                                                        padding: "8px 0",
                                                                        fontSize: 15,
                                                                        fontWeight: 600,
                                                                        textAlign: "left",
                                                                        color: "#222"
                                                                    }}
                                                                >
                                                                    Line Items for year  {indexmulti + 1}
                                                                </td>
                                                                <td
                                                                    style={{ width: "50%", padding: "8px 0", textAlign: "right" }}
                                                                >
                                                                    {/* <a href="#" style="padding:5px 8px; background-color: #5fc490; font-size:12px; color:#fff; text-transform:capitalize; text-decoration:none; border-radius:3px">Add Line Items</a> */}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colSpan={2} style={{ width: "100%", padding: "8px 0" }}>
                                                                    <table
                                                                        cellSpacing={0}
                                                                        cellPadding={0}
                                                                        style={{ width: "100%", border: "1px solid #ddd" }}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    No.
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Product Name
                                                                                </th>

                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Quantity
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Unit Price($)
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Partner Discount(%)
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Discount(%)
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "left",
                                                                                        color: "#fff",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Tax(%)
                                                                                </th>
                                                                                <th
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        background: "#438aab",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#fff"
                                                                                    }}
                                                                                >
                                                                                    Total ($)
                                                                                </th>
                                                                            </tr>
                                                                            {lineItemData && lineItemData.length > 0 ?
                                                                                lineItemData?.map((item: any, index: number) => (
                                                                                    <tr key={Math.random()}>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "left",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            {index + 1}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "left",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            {item?.product?.Name ? item?.product?.Name : ""}
                                                                                        </td>

                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "left",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            {item?.multiyearpricing[indexmulti]?.Quantity ? item?.multiyearpricing[indexmulti]?.Quantity : ""}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "right",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            ${item?.multiyearpricing[indexmulti]?.ListPrice ? formatNumber(item?.multiyearpricing[indexmulti]?.UnitPrice) : '0.00'}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "right",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            {item?.multiyearpricing[indexmulti]?.partner_discount ? formatNumber(item?.multiyearpricing[indexmulti]?.partner_discount) : '0.00'}%
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "right",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            {item?.multiyearpricing[indexmulti]?.Discount ? formatNumber(item?.multiyearpricing[indexmulti]?.Discount) : '0.00'}%
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "right",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            {item?.multiyearpricing[indexmulti]?.taxper ? formatNumber(item?.multiyearpricing[indexmulti]?.taxper) : '0.00'}%
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "5px 8px",
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                textAlign: "right",
                                                                                                color: "#222",
                                                                                                borderRight: "1px solid #ddd",
                                                                                                borderBottom: "1px solid #ddd"
                                                                                            }}
                                                                                        >
                                                                                            ${item?.multiyearpricing[indexmulti]?.TotalPrice ? formatNumber(item?.multiyearpricing[indexmulti]?.TotalPrice) : '0.00'}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                                :
                                                                                null
                                                                            }
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={7}
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Subtotal
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    ${itemmulti?.Subtotal ? formatNumber(itemmulti?.Subtotal) : "0.00"}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={7}
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Discount
                                                                                    {/* ({quoteData?.Discount ? formatNumber(quoteData?.Discount) : "0.00"}%) */}
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    ${itemmulti?.Discount ? formatNumber(+itemmulti?.Subtotal * +itemmulti?.Discount / 100) : "0.00"}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={7}
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    TOTAL
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    ${itemmulti?.TotalPrice ? formatNumber(itemmulti?.TotalPrice) : "0.00"}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={7}
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Tax

                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    ${itemmulti?.Tax ? formatNumber(itemmulti?.TotalPrice * +itemmulti?.Tax / 100) : "0.00"}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={7}
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Shipping and Handling
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd",
                                                                                        borderBottom: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    ${itemmulti?.ShippingCharge ? formatNumber(itemmulti?.ShippingCharge) : "0.00"}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={7}
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    Grand Total
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        padding: "5px 8px",
                                                                                        fontSize: 14,
                                                                                        fontWeight: 400,
                                                                                        textAlign: "right",
                                                                                        color: "#222",
                                                                                        borderRight: "1px solid #ddd"
                                                                                    }}
                                                                                >
                                                                                    ${itemmulti?.GrandTotal ? formatNumber(itemmulti?.GrandTotal) : ""}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>

                                                        </>
                                                    )) : null
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: "100%", padding: "0 0 15px 0" }}>
                                        <table
                                            cellSpacing={0}
                                            cellPadding={0}
                                            style={{
                                                width: "100%",
                                                border: 0,
                                                padding: 15,
                                                background: "#fff",
                                                borderRadius: 5,
                                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: "8px 0",
                                                            fontSize: 15,
                                                            fontWeight: 600,
                                                            textAlign: "left",
                                                            color: "#222",
                                                            width: "75%",
                                                        }}
                                                    >
                                                        Terms and conditions of the quotation
                                                    </td>
                                                    {quoteData?.addsignature == '1' ?
                                                        <td
                                                            style={{
                                                                padding: "8px 0",
                                                                fontSize: 15,
                                                                fontWeight: 600,
                                                                textAlign: "left",
                                                                color: "#222",
                                                                width: "25%",

                                                            }}
                                                        >
                                                            Authorized Signature
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: "0 0 8px 0",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            color: "#222",
                                                            width: "75%",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: "200px",
                                                                overflowY: "auto",
                                                            }}
                                                        >
                                                            {/* {quoteData?.tandc ? quoteData?.tandc : ""} */}
                                                            {quoteData?.tandc ? parse(quoteData?.tandc) : ""}
                                                        </div>
                                                    </td>
                                                    {quoteData?.addsignature == '1' && quoteData?.signature && quoteData?.signature?.basepath != '' && quoteData?.signatureimg ?
                                                        <td
                                                            style={{
                                                                padding: "0 0 8px 0",
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                                textAlign: "left",
                                                                color: "#222",
                                                                width: "25%",
                                                            }}
                                                        >
                                                            <div className='ml-3'>
                                                                <img
                                                                    style={{
                                                                        maxHeight: "80px",
                                                                    }}
                                                                    src={quoteData?.signature?.basepath}
                                                                    alt=""
                                                                    className='bg-soft-secondary'
                                                                />
                                                                <div className='mt-2 text-center'>({quoteData?.signature_fullname})</div>
                                                            </div>
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default QuotePreviewModal