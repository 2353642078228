import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';


interface FormValues {
	appoloio_token: string,
	enableAppoloio: boolean,
}

const validationSchema = Yup.object().shape({
	appoloio_token: Yup.string().required("Token is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
}

const ApolloConnectionModal = ({ show, close }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [zendeskData, setZendeskData] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			enableAppoloio: false,
			appoloio_token: '',
		},
	});

	const getappoloio = () => {
		showLoader();
		commonApi.getappoloio({},
			(message: string, resp: any) => {
				// console.log(resp);
				setZendeskData(resp.data)
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		let params: any = {
			appoloio_token: data?.appoloio_token,
			enableAppoloio: data?.enableAppoloio && data?.enableAppoloio === true ? "1" : "0"
		}

		showLoader()
		commonApi.addappoloio(params, (message: string, resp: any) => {
			toast.success(message)
			close()
			hideLoader()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		if (show) {
			getappoloio()
		}
	}, [show])

	useEffect(() => {
		if (zendeskData) {
			setValue('appoloio_token', zendeskData?.appoloio_token ? zendeskData?.appoloio_token : '')
			setValue('enableAppoloio', zendeskData?.enableAppoloio && zendeskData?.enableAppoloio === "1" ? true : false)
		}
	}, [zendeskData])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			>
				<div className="modal-header custom-modal-header">
					<div className="d-flex flex-wrap">
						<h5 className="card-title mr-2">Apollo.IO Information</h5>
					</div>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">

										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="appoloio_token">Appolo.IO Token
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"appoloio_token"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<input
															type="text"
															className={"form-control " + (errors?.appoloio_token ? ' border-danger' : '')}
															id="appoloio_token"
															name={name}
															onChange={onChange}
															value={value}
															onBlur={onBlur}
															ref={ref}
															placeholder="Enter appoloio token"
														/>
													)}
												/>
												{errors && errors.appoloio_token &&
													<span className='text-danger'>{errors?.appoloio_token?.message}</span>
												}
											</div>

											<div className="form-group col-6">
												<label htmlFor="enableAppoloio">Status
													{/* <span className='text-danger'>*</span> */}
												</label>
												<br /><Controller
													name={"enableAppoloio"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<div className='cstm-switch'>
															<input
																type="checkbox"
																id={"enableAppoloio"}
																className={"form-control"}
																name={name}
																onChange={onChange}
																value={value === true ? "1" : "0"}
																onBlur={onBlur}
																defaultChecked={zendeskData?.enableAppoloio === "1"}
																checked={value === true}
															/>
															<label htmlFor="enableAppoloio" data-on-label="on" data-off-label="off"></label>
														</div>
													)}
												/>
												{errors && errors.enableAppoloio &&
													<span className='text-danger'>{errors?.enableAppoloio?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ApolloConnectionModal