import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import TableContainer from '../../components/commonComponents/TableContainer';
import { URLS } from '../../_config';
import { AiOutlineEdit } from 'react-icons/ai'
import Footer from '../../components/commonComponents/Footer';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import AddApproverRuleModal from '../../components/modals/addApproverRule.modal';
import ViewButtonCell from '../../components/actionsBtns/ViewButtonCell';


const ManageApproverRule = () => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [approverRuleData, setApproverRuleData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddApproverRuleModal, setShowAddApproverRuleModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [approverRuleId, setApproverRuleId] = useState<any>(null)

    const getApproverRuleData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllApproverRule({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setApproverRuleData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddApproverRuleModal = () => {
        setShowAddApproverRuleModal(false)
        setIsEdit(false)
        setApproverRuleId(null)
        getApproverRuleData()
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowAddApproverRuleModal(true)
            setApproverRuleId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteApproverRule = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteApproverRule({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const callbackFnAfterDelete = () => {
        getApproverRuleData();
    }
    
    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteApproverRule}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Rule"}
                        />
                        <ViewButtonCell column={column} value={value} link={`approver-rule-details`} />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Rule Name",
                accessor: "rulename",
            },
            {
                Header: "Target Object",
                accessor: "targetobject",
            },
            {
                Header: "Approver",
                accessor: "approverobject.approvername",
            },
            {
                Header: "Step",
                accessor: "step",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value && value == '1' ? "Active" : 'Inactive'}
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        getApproverRuleData()
    }, [])

    return (
        <React.Fragment>
            <AddApproverRuleModal
                show={showAddApproverRuleModal}
                close={closeAddApproverRuleModal}
                id={approverRuleId}
                isEdit={isEdit}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Rules</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Rules</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Rules</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowAddApproverRuleModal(true)}
                                        >
                                            Add Rule
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getApproverRuleData}
                                                    loading={loading}
                                                    rawData={approverRuleData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default ManageApproverRule