import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';

interface FormValues {
	name: string;
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	regionId?: any
	isEdit: boolean;
	regionListCallbackFunc: any
}

const AddRegionModal = ({ show, close, regionId, isEdit = false, regionListCallbackFunc }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [regionData, setRegionData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: "",
		},
	});

	const getRegionData = () => {
		showLoader();
		setLoading(true)
		commonApi.getAllRegions({ Region: regionId },
			(message: string, resp: any) => {
				// console.log(resp);
				setRegionData(resp.data.list);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			"region_name": data?.name,
		}
		if (isEdit) {
			if (regionData && regionData.Region) {
				params["Region"] = regionData.Region
			}
		}
		// console.log(params)
		showLoader()
		commonApi.manageRegion(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			regionListCallbackFunc()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		if (regionId) {
			getRegionData()
		}
	}, [regionId])

	useEffect(() => {
		// console.log(regionData)
		if (regionData) {
			setValue('name', regionData?.region_name)
		}
	}, [regionData])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											 
											<div className="row">
												<div className="form-group col-12">
													<label htmlFor="name">Region <span className='text-danger'>*</span></label>
													<Controller
														name={"name"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.name ? ' border-danger' : '')}
																	id="name"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter region name"
																/>
															</>
														)}
													/>
													{errors && errors.name &&
														<span className='text-danger'>{errors?.name?.message}</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={() => { close(); reset() }}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddRegionModal