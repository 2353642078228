import React, { Fragment, useEffect, useState } from 'react';
import { formatNumber, showDate } from '../../_common/functions';
import { object } from 'yup';

interface IncomingProps {
    dataList?: any;
}

const LeftYearAccordion = ({ dataList }: IncomingProps) => {
    const [currentProductId, setCurrentProductId] = useState<any>()
    const [collapsProductId, setCollapsProductId] = useState<object>()

    const expandCollapseProduct = (id: any) => {
        setCurrentProductId(id)
    }

    useEffect(() => {
        if (dataList?.multiyear.length > 0) {
            expandCollapseProduct(dataList.multiyear[0].id)
        }
    }, [])


    return (
        <React.Fragment>
            <div className="table-responsive">
                {
                    dataList?.multiyear.length && dataList.multiyear.map((rowItem: any, index: any) =>
                        <Fragment key={Math.random()}>
                            <div>
                                <label htmlFor="">
                                    {/* <strong> Year {rowItem.year}</strong> &nbsp;&nbsp;
                                    <em><small>({showDate(rowItem.StartDate, "DD MMM 'YY")} - {showDate(rowItem.EndDate, "DD MMM 'YY")})</small> </em> */}
                                    <strong> {showDate(rowItem.StartDate, "DD MMM 'YY")} - {showDate(rowItem.EndDate, "DD MMM 'YY")}</strong> &nbsp;&nbsp;


                                    <span className="collapse-link">
                                        {
                                            currentProductId != rowItem?.id ?
                                                <i className="bx bxs-right-arrow" onClick={() => expandCollapseProduct(rowItem?.id)} />
                                                :
                                                <i className="bx bxs-down-arrow" onClick={() => expandCollapseProduct(null)} />
                                        }
                                    </span>
                                </label>
                                <div id={`content__${index}`} style={{ borderLeft: "2px solid gray", paddingLeft: "5px", display: (currentProductId == rowItem?.id ? "show" : "none") }}>
                                    <div><label htmlFor="">Contract Value : </label>  ${formatNumber(rowItem.ContractValue)} </div>
                                    <div><label htmlFor="">ARR : </label>  ${formatNumber(rowItem.arr)} </div>
                                    <div><label htmlFor="">MRR : </label> ${(rowItem.mrr)} </div>
                                    <div><label htmlFor="">Uplift : </label> ${formatNumber(rowItem.Upliftval)} </div>
                                    <div><label htmlFor="">Cross Sell : </label> ${formatNumber(rowItem.Crosssellval)} </div>
                                    <div><label htmlFor="">Up Sell : </label> ${formatNumber(rowItem.Upsellval)} </div>
                                    <div><label htmlFor="">Contraction : </label> ${formatNumber(rowItem.Contractionval)} </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            </div>
        </React.Fragment>
    )
}

export default LeftYearAccordion