import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { formatNumber, showDate } from '../_common/functions'
import ChartContainer from '../components/commonComponents/ChartContainer'
import moment from 'moment'
import CommonBoxDetails from '../components/commonComponents/CommonBoxDetails'
import FilterRow from '../components/commonComponents/FilterRow'
import UserDetailsModal from '../components/modals/UserDetails.modal'
import TitleBar from '../components/commonComponents/TitleBar'
import TableLoader from '../components/commonComponents/TableLoader'

const ContractPage = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [revenueDetails, setRevenueDetails] = useState<any>(null)
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)

    const [showUserDetailsModal, setShowUserDetailsModal] = useState<boolean>(false)
    const [arrUserId, setArrUserId] = useState<any>(null)
    const [contractIds, setContractIds] = useState<any>([])

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")
    const yearValue = searchParams.get("year")
    const regionValue = searchParams.get("region")
    const repValue = searchParams.get("rep")
    const statusValue = searchParams.get("Status")
    const churnreasonUrl = searchParams.get("churnreason")
    const risklableUrl = searchParams.get("risklable")

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue }
            :
            { page: page }
        params['year'] = new Date().getFullYear();
        if (repValue && repValue != 'undefined') { params['rep'] = repValue };
        if (regionValue && regionValue != 'undefined') { params['region'] = regionValue };
        if (yearValue && yearValue != 'undefined') { params['year'] = yearValue };
        if (statusValue && statusValue != 'undefined') { params['Status'] = statusValue };
        if (typeOfContract && typeOfContract != 'undefined') { params['typeOfContract'] = typeOfContract };
        if (churnreasonUrl && churnreasonUrl != 'undefined') { params['churnreason'] = churnreasonUrl };
        if (risklableUrl && risklableUrl != 'undefined') { params['risklable'] = risklableUrl };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllContractForList(params,
            (message: string, resp: any) => {
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeUserDetailsModal = () => {
        setShowUserDetailsModal(false)
        setArrUserId(null)
    }

    const columns = useMemo(
        () => [
            {
                Header: "Action",
                accessor: "id",
            },

            {
                Header: "Account",
                accessor: "account.Name",
            },
            {
                Header: "Contract Manager",
                accessor: "pocobj.name",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {/* {console.log({ column, value, row })} */}
                        {(value) ? <div className='c-pointer text-info'
                            onClick={() => {
                                setShowUserDetailsModal(true)
                                setArrUserId(row.original?.account.AccountManager)
                            }}>{value}</div> : ''}
                    </>,
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
                className: "nowrap",
            },
            {
                Header: "Contract Term",
                accessor: "ContractTerm",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value + ' ' + row.original.termtype}
                    </>
                )
            },
            {
                Header: "Start Date",
                accessor: "StartDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Close Date",
                accessor: "closedate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Contract Amount ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "MRR ($)",
                accessor: "mrr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Renewal Amount ($)",
                accessor: "renewAmt",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Uplift ($)",
                accessor: "Upliftval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>
            },
            {
                Header: "Cross Sell ($)",
                accessor: "Crosssellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Up Sell ($)",
                accessor: "Upsellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Contraction ($)",
                accessor: "Contractionval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Status",
                accessor: "newstatus",
            },
            {
                Header: "Sub Motion",
                accessor: "submotion",
            },
        ],
        []
    )

    const filterCallBackFn = () => {
        getContractData()
    }

    useEffect(() => {
        getContractData();
    }, [])

    const expandCollapseProduct = (id: any) => {
        let tempArr: any = [...contractIds];
        if (contractIds.includes(id)) {
            tempArr.splice(tempArr.indexOf(id), 1)
        } else {
            tempArr.push(id)
        }
        setContractIds(tempArr)
    }

    return (
        <React.Fragment>
            {
                showUserDetailsModal && <UserDetailsModal
                    show={showUserDetailsModal}
                    close={closeUserDetailsModal}
                    arrUserId={arrUserId}
                />
            }
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}

                            <TitleBar title={'Contract Page'}
                                breadcrumbs={{
                                    'Contract Page': '#'
                                }} />

                            <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                        revenueDetails={revenueDetails}
                                        setRevenueDetails={setRevenueDetails}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <FilterRow
                                                type="contract-page"
                                                filterDetails={filterDetails}
                                                setFilterDetails={setFilterDetails}
                                                setDoFilter={setDoFilter}
                                                defaultYear={true}
                                                callbackFunctions={filterCallBackFn}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap justify-content-between">
                                                        <h5 className="card-title mr-2">Fiscal year</h5>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table
                                                            id="datatable"
                                                            className="table table-striped dt-responsive nowrap"
                                                            style={{
                                                                borderCollapse: "collapse",
                                                                borderSpacing: 0,
                                                                width: "100%"
                                                            }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Quarter of availble to renew</th>
                                                                    <th>Availble to renew ($)</th>
                                                                    <th>Open availble to renew ($)</th>
                                                                    <th>Forecast Renewal Rate (%)</th>
                                                                    <th>Actual C/C ($)</th>
                                                                    <th>Actual Base Renewed ($)</th>
                                                                    <th>Net Retention Rate (%)</th>
                                                                    <th>Actual Renew ($)</th>
                                                                    <th>Gross Renewal Rate (%)</th>
                                                                    <th>Renewal Forecast ($)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>FY</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q1</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q2</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q3</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q4</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.renewalforcast)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <ChartContainer
                                                        filterDetails={filterDetails}
                                                        doFilter={doFilter}
                                                        setDoFilter={setDoFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Contracts</h5>
                                            </div>
                                            <div className="table-responsive">
                                                {/* <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getContractData}
                                                    loading={loading}
                                                    rawData={contractData}
                                                    clickFunc={true}
                                                /> */}

                                                <table className='table table-striped dt-responsive dataTable no-footer general-table'
                                                    id="datatable">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Account</th>
                                                            <th>Contract Manager</th>
                                                            <th>Contract Number</th>
                                                            <th>Contract Term</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Close Date</th>
                                                            <th>Contracted Amount ($)</th>
                                                            <th>ARR ($)</th>
                                                            <th>MRR ($)</th>
                                                            <th>Renewal Amount ($)</th>
                                                            <th>Uplift ($)</th>
                                                            <th>Cross Sell ($)</th>
                                                            <th>Up Sell ($)</th>
                                                            <th>Contraction ($)</th>
                                                            <th>Status</th>
                                                            <th>Sub Motion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data && data?.map((item: any, index: number) => (
                                                                <React.Fragment key={`contractItem_${item.ContractId}`}>
                                                                    <tr >
                                                                        <td>
                                                                            <span className="collapse-link">
                                                                                <i className={`bx ${contractIds.includes(item.ContractId) ? 'bxs-down-arrow' : 'bxs-right-arrow'}`}
                                                                                    onClick={() => expandCollapseProduct(item?.ContractId)} />
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                (item?.account?.Name) ?
                                                                                    <Link to={`/account-view/${item?.AccountId && item?.AccountId != 'undefined' ? item?.AccountId : item?.quoteobject?.account?.AccountId}`}>
                                                                                        {item?.account?.Name}
                                                                                    </Link>
                                                                                    : ''
                                                                            }

                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {(item?.pocobj?.name) ? <div className='c-pointer text-info'
                                                                                    onClick={() => {
                                                                                        setShowUserDetailsModal(true)
                                                                                        setArrUserId(item?.account.AccountManager)
                                                                                    }}>{item?.pocobj.name}</div> : ''}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={`/contract-details/${item?.ContractNumber}`}>{item?.ContractNumber}</Link>
                                                                        </td>
                                                                        <td>{item?.ContractTerm + ' ' + item?.termtype}</td>
                                                                        <td>{showDate(item?.StartDate, 'MM/DD/YYYY')}</td>
                                                                        <td>{showDate(item?.EndDate, 'MM/DD/YYYY')}</td>
                                                                        <td>{(item?.closedate) ? showDate(item?.closedate, 'MM/DD/YYYY') : ''}</td>
                                                                        <td>{formatNumber(item?.ContractValue)}</td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.arr)} */}
                                                                        </td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.mrr)} */}
                                                                        </td>
                                                                        <td>
                                                                            {formatNumber(item?.renewAmt)}
                                                                        </td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.Upliftval)} */}
                                                                        </td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.Crosssellval)} */}
                                                                        </td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.Upsellval)} */}
                                                                        </td>
                                                                        <td>
                                                                            {/* {formatNumber(item?.Contractionval)} */}
                                                                        </td>
                                                                        <td>{item?.newstatus}</td>
                                                                        <td>{item?.submotion}</td>
                                                                    </tr>
                                                                    {
                                                                        item && item?.multiyear?.length > 0 &&
                                                                        item?.multiyear?.map((childItem: any, i: number) => (
                                                                            <tr key={`contractItemChild_${childItem.ContractId}`} className='child-tr'
                                                                                style={{ display: (contractIds.includes(item.ContractId) ? "table-row" : "none") }}>
                                                                                <td> </td>
                                                                                <td> </td>
                                                                                <td> </td>
                                                                                <td>{`Year ${i + 1}`}</td>
                                                                                <td>{childItem?.ContractTerm + ' ' + childItem?.termtype}</td>
                                                                                <td>{showDate(childItem?.StartDate, 'MM/DD/YYYY')}</td>
                                                                                <td>{showDate(childItem?.EndDate, 'MM/DD/YYYY')}</td>
                                                                                <td></td>
                                                                                <td>{formatNumber(childItem?.ContractValue)}</td>
                                                                                <td>{formatNumber(childItem?.arr)}</td>
                                                                                <td>{formatNumber(childItem?.mrr)}</td>
                                                                                <td>
                                                                                    {/* {formatNumber(childItem?.renewAmt)} */}
                                                                                </td>
                                                                                <td>{formatNumber(childItem?.Upliftval)}</td>
                                                                                <td>{formatNumber(childItem?.Crosssellval)}</td>
                                                                                <td>{formatNumber(childItem?.Upsellval)}</td>
                                                                                <td>{formatNumber(childItem?.Contractionval)}</td>
                                                                                <td></td>
                                                                                <td>{childItem?.submotion}</td>

                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>


                                            <TableLoader data={data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContractPage