import React, { useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { URLS } from "../_config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineInfoCircle } from "react-icons/ai";

interface LoginFormValues {
	password: string;
	c_password: string;
}

const validationSchema = Yup.object().shape({
	password: Yup.string().required("Password is required")
		.min(6, "Password must be at least 6 characters")
		.matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&%|]).{6,32}$/, 'Password does not match allowed format.'),

	c_password: Yup.string().required("Confirm Password is required").min(6, "Confirm Password must be at least 6 characters").oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});

export default function ResetPassword() {

	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const navigate = useNavigate()
	const [searchParams] = useSearchParams();
	const token: any = searchParams.get("token");

	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [showCPassword, setShowCPassword] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, } = useForm<LoginFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: "",
			c_password: "",
		},
	});

	const onSubmit = (data: any) => {
		let params: any = data;
		params["token"] = token;
		showLoader();
		userApi.resetPassword(data, (message: string, resp: any) => {
			toast.success(message)
			hideLoader();
			navigate(URLS.LOGIN)
		}, (message: string) => {
			toast.error(message)
			hideLoader();
		});
	}

	return (
		<React.Fragment>
			<div className="account-pages my-5 pt-sm-5 arr-login">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8 col-lg-6 col-xl-5">
							<div className="card overflow-hidden">
								<div className="bg-soft-primary">
									<div className="row">
										<div className="col-7">
											<div className=" p-4">
												<h5>Reset Password!</h5>
												<p>Continue to ARR Management</p>
											</div>
										</div>
										<div className="col-5 align-self-end">
											<img
												src="assets/images/profile-img.png"
												alt=""
												className="img-fluid"
											/>
										</div>
									</div>
								</div>
								<div className="card-body pt-0">
									<div>
										<div className="avatar-md profile-user-wid mb-4">
											<span className="avatar-title rounded-circle ">
												<img
													src="assets/images/logo-light.png"
													alt=""
													height={34}
													className="rounded-circle"
												/>
											</span>
										</div>
									</div>
									<div className="p-2">
										<form className="form-horizontal" >
											<div className="form-group">
												<label htmlFor="password">Password
													<span className='text-danger'>*</span>
												</label>
												<div className="cstm-password">
													<Controller
														name="password"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type={!showPassword ? "password" : "text"}
																	className={"form-control " + (errors?.password ? ' border-danger' : '')}
																	id="password"
																	autoComplete="new-password"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter password"
																/>
															</>
														)}
													/>
													{!showPassword ?
														<AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} />
														:
														<AiOutlineEye onClick={() => setShowPassword(!showPassword)} />
													}
												</div>
												<AiOutlineInfoCircle title='At least 1 digit, 1 lowercase, 1 uppercase, 1 special character & minimum 6 characters.' />
												&nbsp;&nbsp;
												{errors && errors.password &&
													<span className='text-danger'>{errors?.password?.message}</span>
												}
											</div>
											<div className="form-group">
												<label htmlFor="c_password">Confirm Password
													<span className='text-danger'>*</span>
												</label>
												<div className="cstm-password">
													<Controller
														name="c_password"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type={!showCPassword ? "password" : "text"}
																	className={"form-control " + (errors?.c_password ? ' border-danger' : '')}
																	id="c_password"
																	autoComplete="new-password"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Re-Enter password"
																/>
															</>
														)}
													/>
													{!showCPassword ?
														<AiOutlineEyeInvisible onClick={() => setShowCPassword(!showCPassword)} />
														:
														<AiOutlineEye onClick={() => setShowCPassword(!showCPassword)} />
													}
												</div>
												{errors && errors.c_password &&
													<span className='text-danger'>{errors?.c_password?.message}</span>
												}
											</div>
											<div className="mt-3">
												<button
													type="submit"
													className="btn btn-primary btn-block waves-effect waves-light"
													onClick={handleSubmit(onSubmit)}
												>
													Log In
												</button>
											</div>
										</form>
										<div className="mt-4 text-center">
											<Link to={URLS.FORGOT_PASSWORD} className="text-muted">
												<i className="mr-1" /> Back to Forgot Password
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="mt-5 text-center">
								<div>
									<p>
										Don't have an account ?
										<Link
											to={URLS.SIGNUP}
											className="font-weight-medium text-primary"
										>{" "}
											Signup now
										</Link>
									</p>
									<p>© ARR Management</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}