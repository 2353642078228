import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { handleFocus, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import DatePicker from 'react-datepicker';
import moment from 'moment';

interface IncomingProps {
	show: boolean;
	close: () => void;
	contractId: any;
	value: any;
	fieldDetail: any;
	callbackFunctions: any;
}

const ForecastUpdateModal = ({ show, close, contractId, value, fieldDetail, callbackFunctions }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [endDate, setEndDate] = useState<any>(null);

	const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm();

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			id: contractId
		}
		if (fieldDetail && fieldDetail?.id == 'forecast_closedate') {
			params[fieldDetail?.id] = data[fieldDetail?.id] ? moment(data[fieldDetail?.id]).format("MM/DD/YY") : ""
		} else {
			params[fieldDetail?.id] = data[fieldDetail?.id]
		}
		// console.log(data[fieldDetail?.id])
		showLoader()
		platformApi.manageContract(params, (message: string, resp: any) => {
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFunctions()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = () => {
		close();
		reset();
		setEndDate(null);
	}

	useEffect(() => {
		if (value && fieldDetail && fieldDetail?.id == 'forecast_closedate') {
			setValue(`${fieldDetail?.id}`, value ? new Date(value) : null)
			setEndDate(value ? new Date(value) : null)
		}
	}, [value, fieldDetail])

	useEffect(() => {
		setValue(`${fieldDetail?.id}`, endDate ? endDate : null)
	}, [endDate])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
				onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor={fieldDetail?.Header}>{fieldDetail && fieldDetail?.Header}
													<span className='text-danger'>*</span>
												</label>
												{fieldDetail && (fieldDetail?.id == 'mostlikely_forecast' || fieldDetail?.id == 'bestcase') ?
													<input
														type="text"
														className={"form-control " + (errors?.[fieldDetail?.id] ? ' border-danger' : '')}
														id={fieldDetail?.Header}
														defaultValue={value}
														onFocus={(e) => handleFocus(e)}
														onKeyDown={(event: any) => { handleKeyUpDown(event); handleKeyPressNumber(event) }}
														onWheel={handleWheel}
														step={"1"}
														min={0}
														{...register(`${fieldDetail?.id}`, { required: true })}
													/>
													: null
												}
												{fieldDetail && (fieldDetail?.id == 'nextsteps' || fieldDetail?.id == 'RenewalNote' || fieldDetail?.id == 'closeplan') ?
													<textarea
														className={"form-control " + (errors?.[fieldDetail?.id] ? ' border-danger' : '')}
														id={fieldDetail?.Header}
														defaultValue={value}
														{...register(`${fieldDetail?.id}`, { required: true })}
													/>
													: null
												}
												{fieldDetail && fieldDetail?.id == 'forecast_closedate' ?
													<DatePicker
														selected={endDate}
														dateFormat="MM/dd/yy"
														className={"form-control " + (errors?.[fieldDetail?.id] ? ' border-danger' : '')}
														id={fieldDetail?.Header}
														value={endDate}
														placeholderText="mm/dd/yy"
														{...register(`${fieldDetail?.id}`, { required: true })}
														onChange={(date) => { setEndDate(date) }}
													/>
													: null
												}
												{errors?.[fieldDetail?.id] ? <div className="invalid-feedback">
													{errors?.[fieldDetail?.id]?.type === "required" ? 'Required' : null}
												</div> : null}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal >
		</React.Fragment >
	)
}

export default ForecastUpdateModal