import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../_common/functions'
import Select from 'react-select'
import InputMask from 'react-input-mask'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import { useCommonFunction } from '../_common/hooks/common/appCommonFunctionHook'
import { billType, countriesData, employeesData, industriesData, leadStatusData, salutationData } from '../_common/staticData'
import { URLS } from '../_config'

interface AddLeadFormValues {
    // address: string,
    annualRevenue: string,
    city: string,
    company: string,
    country: any,
    // createOpportunity: boolean,
    description: string,
    email: string,
    firstName: string,
    industry: string,
    lastName: string,
    leadSource: string,
    // name: string,
    numberOfEmployees: any,
    phone: string,
    postalCode: string,
    // rating: string,
    salutation: any,
    state: string,
    status: any,
    street: string,
    title: string,
    website: string,
    AccountManager: string;
}

const validationSchema = Yup.object().shape({
    annualRevenue: Yup.string().required("Annual Revenue is required"),
    city: Yup.string().required("City is required"),
    company: Yup.string().required("Company is required"),
    country: Yup.object().required("Country is required"),
    // description: Yup.string().required("Description is required"),
    email: Yup.string().nullable().email('Email is not valid'),
    firstName: Yup.string().required("First Name is required"),
    industry: Yup.object().required("Industry is required"),
    lastName: Yup.string().required("Last Name is required"),
    leadSource: Yup.string().required("Lead Source is required"),
    // name: Yup.string().required("Name is required"),
    numberOfEmployees: Yup.object().required("Number of employees is required"),
    // phone: Yup.string().required("Phone is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    // rating: Yup.string().required("Annual Revenue is required"),
    salutation: Yup.object().nullable().required("Salutation is required"),
    state: Yup.string().required("State is required"),
    status: Yup.object().required("Status is required"),
    // createOpportunity: Yup.string().required("Create Opportunity is required").when('status',
    // 	{
    // 		is: (item: any) => item && item.value == 'Qualified',
    //         otherwise: Yup.string(),
    // 	}),
    street: Yup.string().required("Street is required"),
    title: Yup.string().required("Title is required"),
    // website: Yup.string().required("Website is required"),
    // website: Yup.string()
    //     .matches(
    //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //         'Website is not valid'
    //     ),
    // .required('Website is not valid'),
    AccountManager: Yup.object().required("Account Manager is required"),

});


const AddLead = () => {

    const urlParams: any = useParams();
    const platformApi = usePlatformApi() 
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const quoteId: any = searchParams.get("id");
    const type: any = searchParams.get("type");
    const func: any = searchParams.get("func");
    const contractId: any = searchParams.get("contractId");

    const { showLoader, hideLoader } = useAppLoader()
    const [loading, setLoading] = useState<boolean>(false)

    const [leadData, setLeadData] = useState<any>(null)
    const [countriesOptions, setCountriesOptions] = useState<any>(countriesData)
    const [leadStatusOptions, setLeadStatusOptions] = useState<any>(leadStatusData)
    const [salutationsOptions, setSalutationOptions] = useState<any>(salutationData)
    const [employeesOptions, setEmployeesOptions] = useState<any>(employeesData)
    const [industriesOptions, setIndustriesOptions] = useState<any>(industriesData) 

    const [showOppotunityCheckBox, setShowOppotunityCheckBox] = useState<boolean>(false)
    const [accManagerOptions, setAccManagerOptions] = useState<any>(null)
 

    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm<AddLeadFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            annualRevenue: '',
            city: '',
            company: '',
            country: '',
            description: '',
            email: '',
            firstName: '',
            industry: undefined,
            lastName: '',
            leadSource: '',
            numberOfEmployees: '',
            phone: '',
            postalCode: '',
            salutation: '',
            state: '',
            status: '',
            street: '',
            title: '',
            website: '',
            AccountManager: ''
        },
    });

    const onSubmit = (values: any) => {
        console.log({ values })
        let params: any = {}
        Object.entries(values).forEach((val: any) => {
            let key = val[0][0].toUpperCase() + val[0].substring(1)
            if (key != "LeadId" && key != "CreateOpportunity") {
                if (typeof val[1] == 'string') {
                    params[key] = val[1]
                } else {
                    params[key] = val[1].value
                }
            }
        })
        if (showOppotunityCheckBox) {
            params["createOpportunity"] = values?.createOpportunity ? "1" : "0"
        }
        if (leadData?.id) {
            params["id"] = leadData?.id
        }
        params["AccountManager"] = values?.AccountManager?.value

        console.log({ params })
        showLoader();
        setLoading(true);
        platformApi.manageLead(params,
            (message: any, resp: any) => {
                console.log({ resp })
                toast.success(message)
                navigate(URLS.LEAD_MANAGEMENT)
                setLoading(false);
                hideLoader();
            },
            (message: any) => {
                toast.error(message)
                setLoading(false);
                hideLoader();
            })
    }


    const getLeadData = (LeadId: any) => {
        showLoader();
        setLoading(true);
        let params: any = {
            LeadId
        }
        platformApi.getAllLead(params,
            (message: string, resp: any) => {
                console.log({ resp });
                setLeadData(resp.data.list.data[0])
                // toast.success(message)
                setLoading(false);
                hideLoader();
            }, (message: string) => {
                toast.error(message)
                setLoading(false);
                hideLoader();
            })
    }


    const getAccountManagers = () => {
        showLoader()
        platformApi.getAllArrUsers({}, (message: string, resp: any) => {
            let tempArr = resp.data.list.data
            if (tempArr && tempArr.length > 0) {
                setAccManagerOptions(tempArr.map((item: any) => {
                    console.log('item', item)
                    item.label = item.name;
                    item.value = item.id;
                    return item
                }));
            }
            hideLoader()
        }, (message: string) => {
            hideLoader()
        })
    }

    useEffect(() => {
        console.log({ urlParams })
        getAccountManagers();
        if (urlParams?.id) {
            getLeadData(urlParams?.id)
        }
    }, [])

    useEffect(() => {
        if (leadData) {
            Object.entries(leadData).forEach((val: any) => {
                let key = val[0][0].toLowerCase() + val[0].substring(1)
                // console.log(val[0])
                if (["NumberOfEmployees", "Status", "Salutation", "Country", "Industry"].includes(val[0])) {
                    setValue(key, { label: val[1], value: val[1] })
                } else if (val[0] == 'AccountManager') {
                    setValue('AccountManager', accManagerOptions?.find((item: any) => item.value == val[1]) || '')
                } else if (val[1]) {
                    setValue(key, val[1])
                }
            })
        }
    }, [leadData, accManagerOptions])

    return (
        <React.Fragment> 
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Lead</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    {/* {type && type == "AccountId" ?
                                                        <Link to={`/account-view/${accountId}`}>Account Details</Link>
                                                        : */}
                                                    <Link to={URLS.LEAD_MANAGEMENT}>Lead Management</Link>
                                                    {/* } */}
                                                </li>
                                                <li className="breadcrumb-item active">Add Lead</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Lead Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="title">Title <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"title"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.title ? ' border-danger' : '')}
                                                                        id="title"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter lead title"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.title &&
                                                            <span className='text-danger'>{errors?.title?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="leadSource">Lead Source <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"leadSource"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.leadSource ? ' border-danger' : '')}
                                                                        id="leadSource"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter lead source"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.leadSource &&
                                                            <span className='text-danger'>{errors?.leadSource?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="industry">Industry <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"industry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.industry ? ' border-danger' : '')}
                                                                        options={industriesOptions ? industriesOptions : []}
                                                                        placeholder={"Select industry"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.industry &&
                                                            <span className='text-danger'>{errors?.industry?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="AccountManager">Account Manager <span className='text-danger'>*</span></label>

                                                        <Controller
                                                            name={"AccountManager"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <Select
                                                                    className={"select-box" + (errors?.AccountManager ? ' border-danger' : '')}
                                                                    id="AccountManager"
                                                                    options={accManagerOptions ? accManagerOptions : []}
                                                                    placeholder={"Select Account Manager"}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    defaultValue={value}
                                                                />
                                                            )}
                                                        />
                                                        {errors && errors.AccountManager &&
                                                            <span className='text-danger'>Account Manager is required</span>
                                                        }
                                                    </div>

                                                    <div className="form-group col-6">
                                                        <label htmlFor="numberOfEmployees">Number Of Employees <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name="numberOfEmployees"
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.numberOfEmployees ? ' border-danger' : '')}
                                                                        options={employeesOptions ? employeesOptions : []}
                                                                        placeholder={"Select number of employees"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.numberOfEmployees &&
                                                            <span className='text-danger'>Number Of Employees is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shipping">Annual Revenue ($) <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"annualRevenue"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="number"
                                                                        className={"form-control " + (errors?.annualRevenue ? ' border-danger' : '')}
                                                                        id="annualRevenue"
                                                                        name={name}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                        onFocus={(e) => handleFocus(e)}
                                                                        onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                        onWheel={handleWheel}
                                                                        step={"0.01"}
                                                                        min={0}
                                                                        placeholder="Enter Annual Revenue"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.annualRevenue &&
                                                            <span className='text-danger'>{errors?.annualRevenue?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="description">Description</label>
                                                        <Controller
                                                            name={"description"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <textarea
                                                                        // type="text"
                                                                        className={"form-control " + (errors?.description ? ' border-danger' : '')}
                                                                        id="description"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter description"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.description &&
                                                            <span className='text-danger'>{errors?.description?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="status">Status <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"status"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.status ? ' border-danger' : '')}
                                                                        // id="status"
                                                                        options={leadStatusOptions ? leadStatusOptions : []}
                                                                        placeholder={"Select status"}
                                                                        onChange={e => {
                                                                            onChange(e)
                                                                            if (e?.value == "Qualified") {
                                                                                setShowOppotunityCheckBox(true)
                                                                            } else {
                                                                                setShowOppotunityCheckBox(false)
                                                                            }
                                                                        }}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        ref={ref}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.status &&
                                                            <span className='text-danger'>Status is required</span>
                                                        }
                                                    </div>
                                                    {/* {
                                                        showOppotunityCheckBox  &&
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor='createOpportunity' className='mb-0' >Create Opportunity</label>
                                                            <Controller
                                                                name='createOpportunity'
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            // className={(errors?.[item.formName] ? ' border-danger' : '')}
                                                                            id='createOpportunity'
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            // value={value}
                                                                            onBlur={onBlur}
                                                                            defaultChecked={leadData?.createOpportunity=="1" ? true : false}
                                                                        />
                                                                    </>
                                                                )}
                                                            />*/}
                                                    {/* {errors && errors.createOpportunity &&
                                                                <span className='text-danger'>Create Opportunity is required</span>
                                                            } */}
                                                    {/* </div>
                                                    } */}
                                                    <div className="form-group col-6">
                                                        <label htmlFor="company">Company <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"company"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.company ? ' border-danger' : '')}
                                                                        id="company"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter company"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.company &&
                                                            <span className='text-danger'>{errors?.company?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="phone">Phone</label>
                                                        <Controller
                                                            name={"phone"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <InputMask
                                                                        mask="(999) 999-9999"
                                                                        maskChar="_"
                                                                        className={"form-control " + (errors?.phone ? ' border-danger' : '')}
                                                                        id="phone"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter phone"
                                                                        onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.phone &&
                                                            <span className='text-danger'>{errors?.phone?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="email">Email</label>
                                                        <Controller
                                                            name={"email"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.email ? ' border-danger' : '')}
                                                                        id="email"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter email"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.email &&
                                                            <span className='text-danger'>{errors?.email?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="website">Website</label>
                                                        <Controller
                                                            name={"website"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.website ? ' border-danger' : '')}
                                                                        id="website"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter website"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.website &&
                                                            <span className='text-danger'>{errors?.website?.message}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Address Information</h5>
                                                    {/* <div className="align-items-center col-6 d-flex form-group gap-10">
                                                        <input
                                                            type="checkbox"
                                                            id="sameBilling"
                                                            defaultChecked={sameAsBilling}
                                                            onClick={(e: any) => setSameAsBilling(e.target.checked)}
                                                        />
                                                        <label htmlFor="sameBilling" className='mb-0'>same as billing</label>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="salutation">Salutation <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"salutation"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.salutation ? ' border-danger' : '')}
                                                                        id="salutation"
                                                                        options={salutationsOptions ? salutationsOptions : []}
                                                                        placeholder={"Select salutation"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.salutation &&
                                                            <span className='text-danger'>Salutation is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="firstName">First Name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"firstName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.firstName ? ' border-danger' : '')}
                                                                        id="name"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter first name"
                                                                    // disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.firstName &&
                                                            <span className='text-danger'>{errors?.firstName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"lastName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.lastName ? ' border-danger' : '')}
                                                                        id="name"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter last name"
                                                                    // disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.lastName &&
                                                            <span className='text-danger'>{errors?.lastName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="street">Street <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"street"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.street ? ' border-danger' : '')}
                                                                        id="street"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter street"
                                                                    // disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.street &&
                                                            <span className='text-danger'>{errors?.street?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="city">City <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"city"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.city ? ' border-danger' : '')}
                                                                        id="city"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter city"
                                                                    // disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.city &&
                                                            <span className='text-danger'>{errors?.city?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"state"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.state ? ' border-danger' : '')}
                                                                        id="state"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter state"
                                                                    // disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.state &&
                                                            <span className='text-danger'>{errors?.state?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="postalCode">Zip/Postal Code <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"postalCode"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.postalCode ? ' border-danger' : '')}
                                                                        id="postalCode"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter postal code"
                                                                    // disabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.postalCode &&
                                                            <span className='text-danger'>{errors?.postalCode?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="country">Country <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"country"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.country ? ' border-danger' : '')}
                                                                        id="country"
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    // isDisabled={(!selectedAccount && accountId == 'undefined')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.country &&
                                                            <span className='text-danger'>Country is required</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                    <button type='submit' className='btn btn-primary'>Save</button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-danger'
                                                        onClick={() => navigate(`/lead-management`)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddLead