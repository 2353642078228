import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import Select from 'react-select';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';

interface FormValues {
    closewon_quote_id: any;
}

const validationSchema = Yup.object().shape({
    closewon_quote_id: Yup.object().required("User is required"),
    // closewon_quote_id: Yup.array().min(1, "please select atleast one user").required("User is required"),
});

interface IncomingProps {
    show: boolean;
    close: () => void;
    closeWonCallBackFn: any;
    oppId: any,
}

const CloseWonQuoteListModal = ({ show, close, closeWonCallBackFn, oppId }: IncomingProps) => {

    const commonApi = useCommonApi();
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [quoteOptions, setQuoteOptions] = useState<any | []>([])

    const { control, formState: { errors }, handleSubmit, reset } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            closewon_quote_id: "",
        },
    });


    const getQuoteData = (page = 1) => {
        showLoader();
        let params: any = { page: page }
        params['OpportunityId'] = oppId;
        platformApi.getAllQuote(params,
            (message: string, resp: any) => {
                const tempData = resp.data.list.data
                if (tempData && tempData.length > 0) {
                    setQuoteOptions(tempData.map((item: any) => {
                        console.log(item?.Status);
                        // if (item?.Status != 'Denied') {
                            item.value = item.id
                            item.label = item.Name
                            return item
                        // } else{
                        //     return false
                        // }
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }


    const onSubmit = (data: any) => {
        let params: any = {
            StageName: 'Close Won',
            id: oppId,
            closewon_quote_id: data.closewon_quote_id.value,
        }
        // console.log(data, userIds, params)
        // showLoader()
        // platformApi.manageOpportunity(params, (message: string, resp: any) => {
        //     toast.success(message)
        //     hideLoader()
        closeWonCallBackFn(data.closewon_quote_id.value);
        // }, (message: string) => {
        //     toast.error(message)
        //     hideLoader()
        // })
    }

    useEffect(() => {
        getQuoteData()
    }, [])

    return (
        <React.Fragment>
            <Modal
                className='salesforce-modal modal-lndscp'
                show={show}
            // onHide={() => { close(); reset() }}
            >
                <div className="modal-header custom-modal-header">
                    <h5 className="card-title mr-2">Select Quote for Close Won stage</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => { close(); reset() }}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    <div className="row">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100" >
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label htmlFor="closewon_quote_id">Select Quote <span className='text-danger'>*</span></label>
                                                <Controller
                                                    name={"closewon_quote_id"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                        <Select
                                                            className={"select-box" + (errors?.closewon_quote_id ? ' border-danger' : '')}
                                                            id="closewon_quote_id"
                                                            options={quoteOptions ? quoteOptions : []}
                                                            placeholder={"Select Quote"}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                            isClearable={true}
                                                        />
                                                    )}
                                                />
                                                {errors && errors.closewon_quote_id &&
                                                    <span className='text-danger'>Select one quote.</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                    <button type='submit' className='btn btn-primary'>Save</button>
                                    <button
                                        type='button'
                                        className='btn btn-outline-danger'
                                        onClick={() => { close(); reset() }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default CloseWonQuoteListModal