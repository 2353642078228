import React, { Fragment, useEffect, useState } from 'react';
import { formatNumber } from '../../_common/functions';
import { AiOutlineEdit, AiOutlineInfoCircle } from 'react-icons/ai';
import EntitlementUpdateModal from '../../components/modals/entitlementUpdate.modal';
import { useParams } from 'react-router-dom';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';


const Entitlements = () => {
    const urlParams: any = useParams();
    const { showLoader, hideLoader } = useAppLoader();
    const [loading, setLoading] = useState<boolean>(false);
    const platformApi = usePlatformApi();

    const [currentProductId, setCurrentProductId] = useState<any>()
    const [contractProductData, setContractProductData] = useState<any>(null);

    const [showEntitlementUpdateModal, setShowEntitlementUpdateModal] = useState<boolean>(false)
    const [selectedEntitlementProductId, setSelecedEntitlementProductId] = useState<any>(null)
    const [selectedEntitlementProductValue, setSelectedEntitlementProductvalue] = useState<any>(null)
    const [selectedEntitlementProductColumn, setSelectedEntitlementProductColumn] = useState<any>(null)


    const expandCollapseProduct = (id: any) => {
        setCurrentProductId(id)
    }
    const openEntitlementUpdateModalClose = (column: any, value: any, productId: any) => {
        // console.log(column, value, contractId)
        setShowEntitlementUpdateModal(true);
        setSelecedEntitlementProductId(productId);
        setSelectedEntitlementProductvalue(value);
        setSelectedEntitlementProductColumn(column);
    }
    const closeEntitlementUpdateModalClose = () => {
        setShowEntitlementUpdateModal(false);
        setSelecedEntitlementProductId(null);
        setSelectedEntitlementProductvalue(null);
        setSelectedEntitlementProductColumn(null);
    }

    const EditBtn = ({ column, value, id }: any) => {
        return (
            <span data-toggle="tooltip" data-placement="top" title="Edit" >
                <AiOutlineEdit onClick={() => openEntitlementUpdateModalClose(column, value, id)}
                    className='cursor-pointer ml-2' />
            </span >
        )
    }

    const getContractProductData = () => {
        // showLoader();
        setLoading(true);
        platformApi.getContractProduct(
            { ContractId: urlParams?.id },
            (message: string, resp: any) => {
                setLoading(false);
                setContractProductData(resp.data.list);
                // hideLoader();
            },
            (message: string) => {
                setLoading(false);
                // hideLoader();
            },
        );
    };
    useEffect(() => {
        getContractProductData();
    }, [urlParams]);


    return (
        <React.Fragment>

            <EntitlementUpdateModal
                show={showEntitlementUpdateModal}
                close={closeEntitlementUpdateModalClose}
                contractId={urlParams?.id}
                id={selectedEntitlementProductId}
                value={selectedEntitlementProductValue}
                fieldDetail={selectedEntitlementProductColumn}
                callbackFunctions={[getContractProductData]}
            />

            <div className="table-responsive">
                <table className="table table-bordered dt-responsive nowrap"
                    style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }} >
                    <thead>
                        <tr>
                            <th className="w-15">Product Code</th>
                            <th className="w-15">Product Name</th>
                            <th>Renewal Forecast ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractProductData?.length ?
                            contractProductData.map((item: any) =>
                                <Fragment key={Math.random()}>
                                    <tr>
                                        <td  >
                                            {item?.product?.ProductCode}
                                            <span className="collapse-link">
                                                {
                                                    currentProductId != item?.id ?
                                                        <i className="bx bxs-right-arrow" onClick={() => expandCollapseProduct(item?.id)} />
                                                        :
                                                        <i className="bx bxs-down-arrow" onClick={() => expandCollapseProduct(null)} />
                                                }
                                            </span>
                                        </td>
                                        <td>{item?.product?.Name}</td>
                                        <td className="text-right w-15">
                                            ${formatNumber(item?.RenewalForecast)}
                                            <EditBtn column={{ Header: 'Renewal Forecast($)', id: 'RenewalForecast' }} value={item?.RenewalForecast} id={item.id} />                                        </td>

                                    </tr>
                                    <tr style={{ display: (currentProductId == item?.id ? "show" : "none") }}>
                                        <td colSpan={3}>
                                            <table className={"table table-bordered dt-responsive nowrap"} >
                                                <tr>
                                                    <td>Label <hr />
                                                        <p className="text-right">Quantity</p>
                                                        <p className="text-right">Sales Price</p>
                                                        <p className="text-right">Additional Disc</p>
                                                        <p className="text-right">Partner Discount</p>
                                                        <p className="text-right">Tax</p>
                                                        <p className="text-right">Sub Total</p>
                                                    </td>
                                                    {item?.multiyearpricing?.length > 0 &&
                                                        item?.multiyearpricing.map((yearData: any) =>
                                                            <td key={Math.random()}> Year {yearData?.yearno} <hr />
                                                                <p className="text-right">{yearData?.Quantity}</p>
                                                                <p className="text-right">${yearData?.UnitPrice}</p>
                                                                <p className="text-right">
                                                                    <AiOutlineInfoCircle title={formatNumber(yearData?.Discount) + " %"} className='font-size-14' data-toggle="tooltip" data-placement="top" />
                                                                    &nbsp;
                                                                    ${yearData?.Discountval}
                                                                </p>
                                                                <p className="text-right">
                                                                    <AiOutlineInfoCircle title={formatNumber(yearData?.partner_discount) + " %"} className='font-size-14' data-toggle="tooltip" data-placement="top" />
                                                                    &nbsp;
                                                                    ${yearData?.partner_discount_value}
                                                                </p>
                                                                <p className="text-right">
                                                                    <AiOutlineInfoCircle title={formatNumber(yearData?.taxper) + " %"} className='font-size-14' data-toggle="tooltip" data-placement="top" />
                                                                    &nbsp;
                                                                    ${yearData?.Taxval}
                                                                </p>
                                                                <p className="text-right">${yearData?.TotalPrice}</p>
                                                            </td>
                                                        )}
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                </Fragment>
                            )
                            :
                            <tr>
                                <td colSpan={3} className="text-center">No Data Found</td>
                            </tr>
                        }


                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default Entitlements