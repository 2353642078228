import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import moment from 'moment';


interface FormValues {
	completed_note: string
}
const validationSchema = Yup.object().shape({
	completed_note: Yup.string().required("Completed note is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
	actionId: any;
	type?: any;
}



const CompletedNoteBox = ({ show, close, AccountId, actionId, type }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			completed_note: "",
		},
	});


	const onSubmit = (data: any) => {
		showLoader()
		let params: any = {
			"AccountId": AccountId,
			"id": actionId,
			"type": type,
			"status": 2,
			"completed_note": data?.completed_note,
		}
		if (type == 'meeting') {
			platformApi.addEditAccountViewMeeting(params, (message: string, resp: any) => {
				toast.success(message);
				reset();
				close();
				hideLoader();
			}, (message: string) => {
				toast.error(message);
				hideLoader();
			})
		} else if (type == 'call') {
			platformApi.addEditAccountViewCall(params, (message: string, resp: any) => {
				toast.success(message);
				reset();
				close();
				hideLoader();
			}, (message: string) => {
				toast.error(message);
				hideLoader();
			})
		} else if (type == 'task') {
			platformApi.addEditAccountViewTask(params, (message: string, resp: any) => {
				toast.success(message);
				reset();
				close();
				hideLoader();
			}, (message: string) => {
				toast.error(message);
				hideLoader();
			})
		}

	}


	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}>
				<div className="modal-header custom-modal-header"> Note Box
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { close(); reset() }} >
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<input type="hidden" name='AccountId' value={AccountId} />
						<input type="hidden" name='actionId' value={actionId} />
						<input type="hidden" name='type' value={type} />

						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="arruserid">  Completed Note <span className='text-danger'>*</span></label>
												<Controller
													name={"completed_note"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.completed_note ? ' border-danger' : '')}
																id="completed_note"
																name={name}
																onChange={onChange}
																onBlur={onBlur}
																placeholder="Enter completed note"
															/>
														</>
													)}
												/>

												{errors && errors.completed_note &&
													<span className='text-danger'>{errors?.completed_note?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button type='button' className='btn btn-outline-danger' onClick={() => { close(); reset() }} >
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default CompletedNoteBox