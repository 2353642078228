import React, { useEffect, useMemo, useState } from "react"
import TableContainer from "../../components/commonComponents/TableContainer"
import { formatNumber } from "../../_common/functions"
import moment from "moment"
import { usePlatformApi } from "../../_common/hooks/api/PlatformApiHook"
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook"

interface IncomingProps {
    title: string;
    filterDetails: any;
    quarterValue: any;
    typeOfContract: any;
    yearValue: any;
    regionValue: any;
    repValue: any;
    statusValue: any;
    doFilter: any;
    setDoFilter: any;
}

const ChurnedCustomers = ({ title, filterDetails, quarterValue, typeOfContract, yearValue, regionValue, repValue, statusValue, doFilter, setDoFilter }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)


    const columns = useMemo(
        () => [
            {
                Header: "Account",
                accessor: "account.Name",
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
                className: "nowrap",
            },

            {
                Header: "Contract Term",
                accessor: "ContractTerm",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value + ' ' + row.original.termtype}
                    </>
                )
            },
            {
                Header: "Start Date",
                accessor: "StartDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : "--"}
                    </>
                )
            },
            {
                Header: "End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : "--"}
                    </>
                )
            },
            {
                Header: "Contract Amount ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "MRR ($)",
                accessor: "mrr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
        ],
        []
    )

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page }

        if (repValue && repValue != 'undefined') { params['rep'] = repValue };
        if (regionValue && regionValue != 'undefined') { params['region'] = regionValue };
        // if (yearValue && yearValue != 'undefined') { params['year'] = yearValue };
        if (statusValue && statusValue != 'undefined') { params['Status'] = statusValue };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllContractChurned(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }


    useEffect(() => {
        getContractData();
    }, [])

    useEffect(() => {
        if (doFilter) {
            getContractData();
        }
    }, [doFilter])


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap justify-content-between">
                                <h5 className="card-title mr-2">{title}</h5>
                            </div>
                            <div className="table-responsive">
                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    fetchData={getContractData}
                                    loading={loading}
                                    rawData={contractData}
                                    clickFunc={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChurnedCustomers