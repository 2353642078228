import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { formatNumber, showDate } from '../_common/functions'
import { AiOutlineCheckCircle, AiOutlineEdit, AiOutlineOrderedList, AiOutlinePlusCircle } from 'react-icons/ai'
import QuoteList from '../components/dataListing/QuoteList'
import AccountPartnerListing from '../components/dataListing/AccountPartnerListing'
import AddPartnerTeamModal from '../components/modals/addPartnerTeam.modal'
import OpportunityStageStep from './OpportunityDetails/OpportunityStageStep'


const OpportunityDetails = () => {
    const platformApi = usePlatformApi()
    const navigate = useNavigate()
    const params: any = useParams();

    const { showLoader, hideLoader } = useAppLoader()
    const [data, setData] = useState<any>(null)
    const [accountData, setAccountData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState<number | null>(null)
    const [selectedQuoteId, setSelectedQuoteId] = useState<string>('')
    const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false)
    const [showPartnerActionBtn, setShowPartnerActionBtn] = useState<boolean>(false)

    const getOpportunityDetails = () => {
        showLoader()
        platformApi.getAllOpportunity({
            OpportunityId: params?.id
        }, (message: string, resp: any) => {
            setData(resp.data.list.data[0])
            var currentActionButton = ['Close Won', 'Close Lost'].includes(resp.data.list.data[0]?.StageName) ? false : true;
            setShowPartnerActionBtn(currentActionButton)

            hideLoader()
        }, (message: string) => {
            hideLoader()
        })
    }

    const getAccountList = (id: string) => {
        showLoader();
        setLoading(true)
        platformApi.getAllAccount({ AccountId: id },
            (message: string, resp: any) => {
                setAccountData(resp.data.list.data[0])
                setLoading(false)
                hideLoader();
            }, (message: string) => {
                setLoading(false)
                hideLoader();
            })
    }

    useEffect(() => {
        if (params && params.id) {
            getOpportunityDetails()
        }
    }, [])

    useEffect(() => {
        if (data) {
            getAccountList(data?.AccountId)
        }
    }, [data])

    const closePartnerModal = () => {
        setShowPartnerModal(false)
    }
    const changeStatusCallbackFn = () => {
        getOpportunityDetails();


    }

    return (
        <React.Fragment>
            <AddPartnerTeamModal
                close={closePartnerModal}
                show={showPartnerModal}
                AccountId={params?.id}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Opportunity Details</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.OPPORTUNITY_PAGE}>Opportunity Page</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Opportunity Details</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2"> </h5>
                                        <div className='d-flex justify-content-end mb-3 gap-10'>
                                            <Link to={`/opportunity-management`} className="btn btn-primary"><AiOutlineOrderedList /> Opportunity List</Link>
                                            {
                                                (data?.StageName != 'Close Won' && data?.StageName != 'Close Lost') &&
                                                <Link to={`/add-opportunity/${(data?.AccountId != null) ? data?.AccountId : ''}?id=${data?.OpportunityId}`} className="btn btn-warning"><AiOutlineEdit /> Edit Opportunity</Link>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <OpportunityStageStep oppId={data?.OpportunityId} oppData={data} changeStatusCallbackFn={changeStatusCallbackFn} />

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Opportunity Information</h5>
                                                {/* <div className='d-flex justify-content-end mb-3 gap-10'>
                                                    <Link to={`/opportunity-management`} className="btn btn-primary"><AiOutlineOrderedList /> Opportunity List</Link>
                                                    <Link to={`/add-opportunity/${(data?.AccountId != null) ? data?.AccountId : ''}?id=${data?.OpportunityId}`} className="btn btn-warning"><AiOutlineEdit /> Edit Opportunity</Link>
                                                </div> */}
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-6">
                                                    <label htmlFor="opportunityName">Opportunity name : </label>&nbsp;&nbsp;
                                                    <strong>{data?.Name ? data?.Name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="accntName">Account Manager : </label>&nbsp;&nbsp;
                                                    <strong>{data?.accountManagerdetails ? data?.accountManagerdetails?.name : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="type">Stage Name : </label> &nbsp;&nbsp;
                                                    <strong>{data?.StageName ? data?.StageName : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="type">Region Name : </label> &nbsp;&nbsp;
                                                    <strong>{data?.regiondetails?.region_name ? data?.regiondetails?.region_name : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="type">Type : </label> &nbsp;&nbsp;
                                                    <strong>{data?.OpportunityType ? data?.OpportunityType : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="closeDate">Contract Term :</label>&nbsp;&nbsp;
                                                    <strong>{data?.contractterm + " " + data?.termtype}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="closeDate">Close Date :</label>&nbsp;&nbsp;
                                                    <strong>{data?.CloseDate ? showDate(data?.CloseDate, 'MM-DD-YYYY') : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="primaryCampSource">Primary Campaign Source : </label>&nbsp;&nbsp;
                                                    <strong>{data?.primaryCampSource ? data?.primaryCampSource : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="probability">Probability : </label>&nbsp;&nbsp;
                                                    <strong>{data?.Probability ? data?.Probability + " %" : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="amount">Amount : </label>&nbsp;&nbsp;
                                                    <strong>{data?.Amount ? "$" + formatNumber(data?.Amount) : "--"}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="lossReason">Loss Reason : &nbsp;&nbsp;</label>
                                                    <strong>{(data?.lossReason && data?.lossReason == 1) ? <AiOutlineCheckCircle /> : '--'}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="budgetConfirmed">Budget Confirmed : &nbsp;&nbsp;</label>
                                                    <strong>{(data?.budgetConfirmed && data?.budgetConfirmed == '1') ? <AiOutlineCheckCircle /> : '--'}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="discoveryCompleted">Discovery Completed : &nbsp;&nbsp; </label>
                                                    <strong>{(data?.discoveryCompleted && data?.discoveryCompleted == '1') ? <AiOutlineCheckCircle /> : '--'}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="roiAnalysis">ROI Analysis Completed : &nbsp;&nbsp;</label>
                                                    <strong>{(data?.roiAnalysis && data?.roiAnalysis == '1') ? <AiOutlineCheckCircle /> : '--'}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Additional Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label htmlFor="nextStep">Next Step : </label>&nbsp;&nbsp;
                                                    <strong>{data?.NextStep ? data?.NextStep : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="leadSource">Lead Source : </label>&nbsp;&nbsp;
                                                    <strong>{data?.LeadSource ? data?.LeadSource : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="description">Description : </label>&nbsp;&nbsp;
                                                    <strong>{data?.Description ? data?.Description : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="contracted">Contracted : &nbsp;&nbsp;</label>
                                                    <strong>{(data?.contracted && data?.contracted == '1') ? <AiOutlineCheckCircle /> : '--'}</strong>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body" >
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Manage Partner Team</h5>
                                                {
                                                    (showPartnerActionBtn) &&
                                                    <button className="btn btn-sm btn-success" onClick={() => setShowPartnerModal(true)} >
                                                        <AiOutlinePlusCircle /> Add Partner Team
                                                    </button>
                                                }
                                            </div>
                                            {
                                                // console.log('showPartnerActionBtn', showPartnerActionBtn)
                                            }
                                            {
                                                data?.StageName && <AccountPartnerListing AccountId={params?.id}
                                                    type="OpportunityId" show={showPartnerModal}
                                                    actionBtn={showPartnerActionBtn} />
                                            }

                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Manage Quotes</h5>
                                                {
                                                    (showPartnerActionBtn) &&
                                                    <Link
                                                        to={`/add-quote/${params.id}?type=OpportunityId&func=ADD${(data?.AccountId != null) ? "&AccountId=" + data?.AccountId : ''}${(data?.contractterm != null) ? "&cTerm=" + data?.contractterm : ''}${(data?.upliftperfornextquote != null) ? "&uplift=" + data?.upliftperfornextquote : ''}`}
                                                        className="btn btn-success">
                                                        <AiOutlinePlusCircle /> Add Quote
                                                    </Link>
                                                }
                                            </div>
                                            <QuoteList
                                                id={params?.id}
                                                type="OpportunityId"
                                                isEdit={true}
                                                selectedRow={selectedRow}
                                                setSelectedRow={setSelectedRow}
                                                selectedQuoteId={selectedQuoteId}
                                                setSelectedQuoteId={setSelectedQuoteId}
                                                isLoad={showPartnerActionBtn}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default OpportunityDetails