import { call } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi } from './api/callApi';


export function* getPlatformObject(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_PLATFORM_OBJECT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getPlatformObjectField(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_PLATFORM_OBJECT_FIELD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* savePlatformObjectField(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.SAVE_PLATFORM_OBJECT_FIELD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllAccount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_ACCOUNT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_PRODUCT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContact(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllOpportunity(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_OPPORTUNITY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getOpportunityStagesList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_OPPORTUNITY_STAGE_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllOpportunityStages(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_OPPORTUNITY_STAGES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageOpportunityStage(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_OPPORTUNITY_STAGES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* OpportunityStagedestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.OPPORTUNITY_STAGE_DELETE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContract(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContractList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContractNew(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_NEW, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContractChurned(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_CHURNED, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContractCcontraction(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_CCONTRACTION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContractRiskLevel(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_RISK_LEVEL, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllContractEarlycontraction(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_EARLY_CONTRACTION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAllContractChurnRreason(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CONTRACT_CHURN_REASON, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getAllPriceBook(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_PRICEBOOK, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllQuote(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_QUOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getQuotePdf(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_QOUTE_PDF, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageAccount(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_ACCOUNT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_PRODUCT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageOpportunity(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_OPPORTUNITY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageQuote(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_QUOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* managePriceBook(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_PRICEBOOK, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllPriceBookEntities(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_PRICEBOOK_ENTITIES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* managePriceBookEntities(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_PRICEBOOK_ENTITIES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllQuoteLineItem(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_QUOTE_LINEITEMS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllAccountComment(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_ACCOUNT_COMMENT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageAccountComment(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_ACCOUNT_COMMENT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageQuoteLineItem(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_QUOTE_LINEITEMS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageSingleQuoteLineItem(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_SINGLE_QUOTE_LINEITEMS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllArrUsers(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_ARR_USER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageArrUser(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_ARR_USER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllAccountSaComment(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_ACCNT_SA_COMMENT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageAccountSaComment(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_ACCNT_SA_COMMENT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRenewalProcess(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_RENEWAL_PROCESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageRenewalProcess(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_RENEWAL_PROCESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageContact(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CONTACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAccountTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ACCOUNT_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageAccountTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_ACCOUNT_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCustomerTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CUSTOMER_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageCustomerTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CUSTOMER_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* arrUserDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_ARR_USER + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* accountDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_ACCOUNT + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* accountCommentDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_ACCOUNT_COMMENT + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* accountSaCommentDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_ACCOUNT_SA_COMMENT + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* accoungTeamDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_ACCOUNT_TEAM + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* customerTeamDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_CUSTOMER_TEAM + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* productDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_PRODUCT + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* contactDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_CONTACT + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addAccountPOC(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ADD_ACCOUNT_POC  , data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* opportunityDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_OPPORTUNITY + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* pricebookDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_PRICEBOOK + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* pricebookEntityDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_PRICEBOOK_ENTITY + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* quoteDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_QUOTE + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* quoteLineitemDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_QUOTE_LINEITEMS + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* cloneQuote(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.CLONE_QUOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createQuoteFromContract(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.CREATE_QUOTE_FROM_CONTRACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRevenueDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_REVENUE_DETAILS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageContract(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CONTRACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* transferContract(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.TRANSFER_CONTRACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getLateRenewals(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_LATE_RENEWALS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getlaterenewalsCP(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_LATE_RENEWALS_CP, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getUpsells(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_UPSELLS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContractProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CONTRACT_PRODUCT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRevenueMapDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_REVENUE_MAP_DETAILS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContractLevelList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CONTRACT_LEVEL_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContractTimeLevelList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CONTRACT_TIME_LEVEL_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageContractReNewvalEvent(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CONTRACT_RENEWVAL_EVENT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContractReasonCOC(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CONTRACT_REASON_COC, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageRenewalNotes(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_RENEWAL_NOTES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRenewalNotes(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_RENEWAL_NOTES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContractAccountTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CONTRACT_ACCOUNT_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* accoungContractTeamDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_CONTRACT_ACCOUNT_TEAM + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageContractAccountTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CONTRACT_ACCOUNT_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllCustomerContact(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CUSTOMER_CONTACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* contactCustomerDestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_CUSTOMER_CONTACT + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageCustomerContact(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CUSTOMER_CONTACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* addcontractmanager(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ADD_CONTRACT_MANAGER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
} 

export function* getQuotesSummery(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_QUOTES_SUMMERY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageContractCustomerTeam(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_CONTRACT_CUSTOMER_TEAM, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCrossSells(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CROSSSELLS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getContractPdf(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CONTRACT_PDF, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getForeCastSummary(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_FORECAST_SUMMARY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* uploadContractDoc(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.UPLOAD_CONTRACT_DOC, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* mergeContract(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MERGE_CONTRACT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getArrPageList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ARR_PAGE_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAccountPartner(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ACCOUNT_PARTNER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageAccountPartner(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_ACCOUNT_PARTNER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteAccountPartner(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_ACCOUNT_PARTNER + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* forecastBlukUpdate(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.FORCAST_BULK_UPDATE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllApprovalRulesForQuote(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_APPROVAL_RULES_FOR_QUOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addApprovalForQuote(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ADD_APPROVAL_FOR_QUOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addQuoteSignature(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ADD_QUOTE_SIGNATURE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateContractEntitlement(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.UPDATE_CONTRACT_ENTITLEMENT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllLead(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_LEAD_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* manageLead(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.MANAGE_LEAD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* leaddestroy(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.SALESFORCE.DELETE_LEAD + `/${data.id}`, {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        } 
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* createOpportunityFromLead(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.CREATE_OPPORTUNITY_FROM_LEAD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* editQuoteMultiyear(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.QUOTE_MULTIYEAR_EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* editQuoteLineitemMultiyear(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.QUOTE_LINEITEM_MULTIYEAR_EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getAllActivityForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.ACCOUNT_VIEW_ACTIVITY_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getAllNoteForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.ACCOUNT_VIEW_NOTE_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addEditNoteForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ACCOUNT_VIEW_NOTE_ADD_EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAllTaskForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.ACCOUNT_VIEW_TASK_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addEditTaskForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ACCOUNT_VIEW_TASK_ADD_EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getAllCallForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.ACCOUNT_VIEW_CALL_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* addEditCallForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ACCOUNT_VIEW_CALL_ADD_EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getAllMeetingForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.ACCOUNT_VIEW_MEETING_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* addEditMeetingForAccountView(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.ACCOUNT_VIEW_MEETING_ADD_EDIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getOpportunityPipeline(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.OPPORTUNITY_PIPELINE_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getallcsv(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_ALL_CSV, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* uploadCSV(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.SALESFORCE.UPLOAD_CSV, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getcsvfiledata(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_CSV_DATA, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* storecsvdata(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.STORE_CSV_DATA, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getPowerBIToken(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.SALESFORCE.GET_POWER_BI_TOKEN, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}