export function handleKeyPressDecimalNumber(event: any) {
    if (!/[.0-9]|ArrowLeft|ArrowRight|Delete|Backspace/.test(event.key)) {
        event.preventDefault();
    } else if (!/^[0-9]*\.?[0-9]{0,1}$/.test(event.target.value) && !['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(event.key)) {
        event.preventDefault()
    }
}

export const handleKeyUpDown = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault(); // prevent up/down arrow keys
    }
};

export const handleWheel = (event: any) => {
    event.preventDefault(); // prevent scrolling
};

export const handleFocus = (e: any) => {
    const input = e.target;
    input.addEventListener('wheel', disableScroll);
}

export const handleBlur = (e: any) => {
    const input = e.target;
    input.removeEventListener('wheel', disableScroll);
}

export const disableScroll = (e: any) => {
    e.preventDefault();
}

export const numberFieldValidationMsg = (error:any) => {
    console.log('error', error);
    if(error?.type == 'max'){
        return error?.message;
    } else if(error?.type == 'typeError'){
        return 'Field is required.';
    } else {
        return ''
    }
}
