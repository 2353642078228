import React, { Fragment, useState } from "react"
import "./Pagination.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";



const Pagination = (props) => {
    var perPageData = props.perPage;
    var totalDataCount = props.total_count;

    const [currentPage, setCurrentPage] = useState(props.selectedPage);
    let rows = [];

    var lastPage = (totalDataCount) && (totalDataCount > 0) ? ((totalDataCount % perPageData === 0) ? parseInt(totalDataCount / perPageData) : parseInt(totalDataCount / perPageData) + parseInt(1)) : 0;
    // console.log(lastPage, 'lastPage')
    // console.log(currentPage, 'currentPage')
    for (var i = 1; i <= lastPage; i++) {
        rows.push(i);
    }


    var newPage = 0;
    const clickPaginateHandler = (event) => {
        if (event.currentTarget.id === 'previous') {
            newPage = currentPage - 1;
        } else if (event.currentTarget.id === 'next') {
            newPage = parseInt(currentPage) + parseInt(1);
        } else {
            newPage = event.currentTarget.id;
        }
        setCurrentPage(newPage)
        props.selectPage(newPage)
    }



    let content = "";
    if ((rows) && (rows.length > 20)) {
        content = rows.map((page) =>
            <Fragment key={`li${Math.random()}`} >
                {
                    (page >= currentPage - 5 && page <= parseInt(currentPage) + 5) ?
                        <li onClick={clickPaginateHandler} id={page} key={`pageBtn1${Math.random()}`} className={(currentPage == page) ? 'active' : ''}>{page}</li>
                        :
                        ((page > lastPage - 4) ?
                            (
                                (page > lastPage - 3) ? <li onClick={clickPaginateHandler} id={page} key={`pageBtn2${Math.random()}`} className={(currentPage == page) ? 'active' : ''}>{page}</li>
                                    : <b className="paginateDot" key={`pageBtn3${Math.random()}`}>...</b>
                            )
                            :
                            ((page <= 3) ?
                                <li onClick={clickPaginateHandler} id={page} key={`pageBtn4${Math.random()}`} className={(currentPage == page) ? 'active' : ''}>{page}</li>
                                :
                                (page === 4 ? <b className="paginateDot" key={`pageBtn5${Math.random()}`}>...</b> : <></>)
                            ))
                }
            </Fragment>

        )
    } else {
        content = rows.length > 1 && rows.map((page) =>
            <li onClick={clickPaginateHandler} id={page} key={`pageSmLi${Math.random()}`} className={(currentPage == page) ? 'active' : ''}>{page}</li>
        )
    }


    return (
        <React.Fragment>
            <div className="paginationContent">
                <div className="row col-md-12">
                    <div className="col-md-6">
                        Showing data {parseInt((currentPage - 1) * perPageData) + 1} to&nbsp;
                        {((currentPage - 1) * perPageData) + parseInt(props.this_page_count)}
                        &nbsp;out of {totalDataCount}
                    </div>
                    <div className="col-md-6">
                        <ul>
                            {(currentPage > 1) && (<li onClick={clickPaginateHandler} id="previous" key={`pageLi${Math.random()}`}> <AiOutlineArrowLeft /> </li>)}
                            {content}
                            {(lastPage > currentPage) && (<li onClick={clickPaginateHandler} id="next" key={`pageLi${Math.random()}`}><AiOutlineArrowRight /></li>)}
                        </ul>

                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default Pagination