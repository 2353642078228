import React, { useEffect, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { toast } from 'react-hot-toast';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import CloseWonQuoteListModal from '../../components/modals/closeWonQuoteList.modal';

interface IncomingProps {
    oppId?: any;
    oppData?: any;
    changeStatusCallbackFn: any
}

const OpportunityStageStep = ({ oppId, oppData, changeStatusCallbackFn }: IncomingProps) => {
    const platformApi = usePlatformApi()
    const [stageStatus, setStageStatus] = useState<any>(null);
    const { showLoader, hideLoader } = useAppLoader()
    const [closeWonQuoteModal, setCloseWonQuoteModal] = useState<any>(false);
    const [selectedStatusNo, setSelectedStatusNo] = useState<any>(1);

    const getOpportunityStages = () => {
        platformApi.getOpportunityStagesList({}, (message: string, resp: any) => {
            setStageStatus(resp.data)
        }, (message: string) => {
        })
    }

    const updateOpportunityStageStatus = (status: any) => {
        console.log('status', status)

        let params: any = {
            StageName: status
        }
        if (oppData && oppData?.id) {
            params["id"] = oppData?.id
        }
        if (status == 'Close Won') {
            setCloseWonQuoteModal(true)
        } else {
            showLoader()
            platformApi.manageOpportunity(params, (message: string, resp: any) => {
                toast.success(message)
                hideLoader()
                changeStatusCallbackFn();
            }, (message: string) => {
                toast.error(message)
                hideLoader()
            })
        }
        // console.log(params)

    }

    const closeWonQuoteModalCloseHandler = () => {
        setCloseWonQuoteModal(false)
    }

    const closeWonCallBackFn = (closewon_quote_id: any) => {
        let params: any = {
            StageName: 'Close Won',
            id: oppData?.id,
            closewon_quote_id: closewon_quote_id
        }

        showLoader()
        platformApi.manageOpportunity(params, (message: string, resp: any) => {
            toast.success(message)
            hideLoader()
            setCloseWonQuoteModal(false)
            changeStatusCallbackFn();
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const setActive = () => {
        stageStatus && stageStatus.length > 0 && stageStatus.map((status: string, key: any) => {
            if (oppData?.StageName == status) {
                setSelectedStatusNo(key + 1)
            }
        })
    }

    useEffect(() => {
        getOpportunityStages()
        setActive()
    }, [oppId, oppData])

    return (
        <React.Fragment>
            {
                closeWonQuoteModal &&
                <CloseWonQuoteListModal
                    show={closeWonQuoteModal}
                    close={closeWonQuoteModalCloseHandler}
                    closeWonCallBackFn={closeWonCallBackFn}
                    oppId={oppId} />
            }
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title text-center mb-3">Opportunity Stages</h5>
                        <div className="">
                            <div className="progress-container">
                                {
                                    (stageStatus && stageStatus.length > 0) &&
                                    stageStatus.map((status: string, key: any) =>
                                        <div className='renewal-evnt renewal-evnt-text' key={Math.random()}>
                                            <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                                                <div
                                                    className={'progress-circle ' + ((key + 1 <= selectedStatusNo) ? 'active' : 'cursor-pointer')}
                                                    onClick={() => updateOpportunityStageStatus(status)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title={status}>
                                                    {key + 1}
                                                </div>
                                                <div>{status}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div >
                    </div >
                </div >
            </div >

        </React.Fragment>
    )
}

export default OpportunityStageStep