import React, { useEffect, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useNavigate } from 'react-router-dom';

interface IncomingProps {
    filterDetails: any;
    doFilter: boolean;
    setDoFilter: any;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Bar Chart',
        // },
    },
};


const ChartContainer = ({ filterDetails, doFilter, setDoFilter }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const isInitialRender = useRef(true);

    const { showLoader, hideLoader } = useAppLoader()
    const [revenueMapData, setRevenueMapData] = useState<any | []>([])
    const navigate = useNavigate();

    const data = {
        labels: revenueMapData[0],
        datasets: [
            {
                label: 'Revenue',
                data: revenueMapData[1],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const getRevenueMapDetails = () => {
        showLoader();
        platformApi.getRevenueMapDetails(filterDetails,
            (message: string, resp: any) => {
                hideLoader();
                setRevenueMapData(resp?.data?.list)
                setDoFilter(false)
            }, (message: string) => {
                hideLoader();
                setDoFilter(false)
            }
        )
    }

    const chartRef = useRef<any>();

    const onClick = (event: any) => {
        // console.log(getElementAtEvent(chartRef.current, event));
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let pos = getElementAtEvent(chartRef.current, event)[0].index
        let quarter = months[pos]
        navigate(`/contract-page?quarter=${quarter}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}&Status=${filterDetails?.Status}&type=revenuegraph`)
    }


    useEffect(() => {  
        if (isInitialRender.current) {
          isInitialRender.current = false;
        } else {
            getRevenueMapDetails();
        }
    }, [JSON.stringify(filterDetails)])

    return (
        <React.Fragment>
            <Bar
                ref={chartRef}
                options={options}
                data={data}
                onClick={onClick}
            // height={260}
            />
        </React.Fragment>
    )
}

export default ChartContainer