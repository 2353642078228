import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-hot-toast'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import { URLS } from '../../_config';
import { handleKeyPressNumber } from '../../_common/functions';
import Footer from '../../components/commonComponents/Footer';

interface AddProductFormValues {
    contract_renewal_days_early: string,
    contract_renewal: boolean,
    new_quote: boolean,
    new_account: boolean,
    new_contract: boolean
}

const validationSchema = Yup.object().shape({
    contract_renewal_days_early: Yup.string().required("Renewal days is required"),
});

const NotificationSettings = () => {

    const commonApi = useCommonApi()

    const { showLoader, hideLoader } = useAppLoader()
    const [notificationSettingsData, setNotificationSettingData] = useState<any>(null)

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddProductFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            contract_renewal_days_early: "",
            contract_renewal: false,
            new_quote: false,
            new_account: false,
            new_contract: false
        },
    });

    const getNotificationSettings = () => {
        showLoader();
        commonApi.getNotification({},
            (message: string, resp: any) => {
                // console.log(resp);
                setNotificationSettingData(resp.data.list);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const onSubmit = (data: any) => {
        console.log(data)
        let params: any = {};
        params["contract_renewal"] = data?.contract_renewal && data?.contract_renewal === true ? "1" : "0";
        params["new_quote"] = data?.new_quote && data?.new_quote === true ? "1" : "0";
        params["new_account"] = data?.new_account && data?.new_account === true ? "1" : "0";
        params["new_contract"] = data?.new_contract && data?.new_contract === true ? "1" : "0";
        if (data?.contract_renewal_days_early) {
            params["contract_renewal_days_early"] = data?.contract_renewal_days_early;
        }
        if (notificationSettingsData && notificationSettingsData?.id) {
            params["id"] = notificationSettingsData?.id;
        }
        showLoader()
        commonApi.manageNotification(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            reset()
            getNotificationSettings()
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    useEffect(() => {
        getNotificationSettings()
    }, [])

    useEffect(() => {
        if (notificationSettingsData) {
            setValue('contract_renewal_days_early', notificationSettingsData?.contract_renewal_days_early)
            setValue('contract_renewal', notificationSettingsData?.contract_renewal && notificationSettingsData?.contract_renewal === "1" ? true : false)
            setValue('new_quote', notificationSettingsData?.new_quote && notificationSettingsData?.new_quote === "1" ? true : false)
            setValue('new_account', notificationSettingsData?.new_account && notificationSettingsData?.new_account === "1" ? true : false)
            setValue('new_contract', notificationSettingsData?.new_contract && notificationSettingsData?.new_contract === "1" ? true : false)
        }
    }, [notificationSettingsData]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Notification Settings</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Notification Settings</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Notification Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-4">
                                                        <label htmlFor="contract_renewal_days_early">Contract Renewal Days <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"contract_renewal_days_early"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.contract_renewal_days_early ? ' border-danger' : '')}
                                                                        id="contract_renewal_days_early"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter Contract Renewal Days"
                                                                        onKeyDown={handleKeyPressNumber}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.contract_renewal_days_early &&
                                                            <span className='text-danger'>{errors?.contract_renewal_days_early?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-2 d-flex flex-column">
                                                        <label htmlFor="contract_renewal" data-on-label="on" data-off-label="off">
                                                            Contract Renewal
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name={"contract_renewal"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <div className='cstm-switch'>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={"contract_renewal"}
                                                                        className={"form-control"}
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value === true ? "1" : "0"}
                                                                        onBlur={onBlur}
                                                                        defaultChecked={notificationSettingsData?.contract_renewal === "1"}
                                                                        checked={value === true}
                                                                    />
                                                                    <label htmlFor="contract_renewal" data-on-label="on" data-off-label="off"></label>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors && errors.contract_renewal &&
                                                            <span className='text-danger'>{errors?.contract_renewal?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-2 d-flex flex-column">
                                                        <label htmlFor="new_quote" data-on-label="on" data-off-label="off">
                                                            New Quote
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name={"new_quote"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <div className='cstm-switch'>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={"new_quote"}
                                                                        className={"form-control"}
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value === true ? "1" : "0"}
                                                                        onBlur={onBlur}
                                                                        defaultChecked={notificationSettingsData?.new_quote === "1"}
                                                                        checked={value === true}
                                                                    />
                                                                    <label htmlFor="new_quote" data-on-label="on" data-off-label="off"></label>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors && errors.new_quote &&
                                                            <span className='text-danger'>{errors?.new_quote?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-2 d-flex flex-column">
                                                        <label htmlFor="new_account" data-on-label="on" data-off-label="off">
                                                            New Account
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name={"new_account"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <div className='cstm-switch'>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={"new_account"}
                                                                        className={"form-control"}
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value === true ? "1" : "0"}
                                                                        onBlur={onBlur}
                                                                        defaultChecked={notificationSettingsData?.new_account === "1"}
                                                                        checked={value === true}
                                                                    />
                                                                    <label htmlFor="new_account" data-on-label="on" data-off-label="off"></label>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors && errors.new_account &&
                                                            <span className='text-danger'>{errors?.new_account?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-2 d-flex flex-column">
                                                        <label htmlFor="new_contract" data-on-label="on" data-off-label="off">
                                                            New Contract
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <Controller
                                                            name={"new_contract"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <div className='cstm-switch'>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={"new_contract"}
                                                                        className={"form-control"}
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value === true ? "1" : "0"}
                                                                        onBlur={onBlur}
                                                                        defaultChecked={notificationSettingsData?.new_contract === "1"}
                                                                        checked={value === true}
                                                                    />
                                                                    <label htmlFor="new_contract" data-on-label="on" data-off-label="off"></label>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors && errors.new_contract &&
                                                            <span className='text-danger'>{errors?.new_contract?.message}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                    <button type='submit' className='btn btn-primary'>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default NotificationSettings