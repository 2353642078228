/* for all table loader with no-data view  */

interface props {
    data?: any[] | null
}
export default function TableLoader({ data }: props) {
    return (
        <>
            {data && data.length == 0 && (
                <div className="no-data">
                    {/* <img src="/images/no-data.jpg" alt="" /> */}
                    <p className="text-center">No data found!!!</p>
                </div>

            )}
            {data == null && (
                <p className="text-center">Loading...</p>
            )}
        </>
    )
}



