import React, { useEffect, useState } from 'react'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import Footer from '../../components/commonComponents/Footer';
import { Link, useSearchParams } from 'react-router-dom';
import { URLS } from '../../_config';
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import { formatNumber, showDate } from '../../_common/functions';
import { AiOutlineEdit, AiOutlineOrderedList } from 'react-icons/ai';
import PipelineFieldEditModal from './PipelineFieldEdit.modal';


const PipelineOpportunityList = () => {
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [searchParams, setSearchParams] = useSearchParams();
    const quarter = searchParams.get("q");
    const regSec = searchParams.get("sec");
    const regId = searchParams.get("region");
    const acc = searchParams.get("acc");
    const year = searchParams.get("year");

    const [opportunityDataList, setOpportunityDataList] = useState<any>(null)
    const [showPipelineFieldEditModal, setShowPipelineFieldEditModal] = useState<any>(null)
    const [sendDataModal, setSendDataModal] = useState<any>(null)


    const getOpportunityPipelineData = () => {
        showLoader();
        var params = {
            type: "opportunity",
            typeid: acc,
            parenttype: 'accountmanager',
            quarter: quarter
        }
        platformApi.getOpportunityPipeline(params,
            (message: string, resp: any) => {
                // console.log('resp', resp);
                setOpportunityDataList(resp.data.list);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    useEffect(() => {
        getOpportunityPipelineData()
    }, [])

    const pipelineFieldEditModalHandeler = (typeid: any, val: any, input_name: any, lable: any) => {
        setSendDataModal({ typeid: typeid, val: val, input_name: input_name, lable: lable })
        setShowPipelineFieldEditModal(true);
    }

    const closePipelineFieldEditModal = () => {
        setShowPipelineFieldEditModal(false)
    }

    const pipelineFieldEditModalCallback = () => {
        setShowPipelineFieldEditModal(false)
        getOpportunityPipelineData()
    }

    return (
        <React.Fragment>

            {showPipelineFieldEditModal && <PipelineFieldEditModal
                show={showPipelineFieldEditModal}
                close={closePipelineFieldEditModal}
                pageData={sendDataModal}
                callbackFunctions={pipelineFieldEditModalCallback}
            />}

            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Pipeline Opportunity </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`/opportunity-pipeline?q=${quarter}&year=${year}`}>Pipeline Management</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`/opportunity-pipeline/region?q=${quarter}&sec=${regSec}&year=${year}`}>Region</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Opportunity</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mb-3">Manage Pipeline Opportunity</h5>
                                                <div className='d-flex justify-content-end mb-3 gap-10'>
                                                    <Link to={`/opportunity-pipeline?q=${quarter}&year=${year}`} className="btn btn-primary"><AiOutlineOrderedList /> Back to Pipeline List</Link>
                                                    <Link to={`/opportunity-pipeline/region?q=${quarter}&sec=${regSec}&year=${year}`} className="btn btn-info"><AiOutlineOrderedList /> Back to Region List</Link>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table id="datatable" className="table table-bordered dt-responsive nowrap" style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Opportunity</th>
                                                            <th>Next Step</th>
                                                            <th>Opportunity Close Date</th>
                                                            <th>Most Likely Forecast</th>
                                                            <th>Upside Forecast</th>
                                                            <th>Deal Level Forecast</th>
                                                            <th>AI Forecast</th>
                                                            <th>Closed</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {opportunityDataList && opportunityDataList.length > 0 && opportunityDataList.map((item: any, index: number) => (
                                                            <tr key={Math.random()}>
                                                                <td >
                                                                    <Link to={`/opportunity-details/${item.OpportunityId}`}>{item.typename}</Link>
                                                                </td>
                                                                <td  >
                                                                    {(item.canedit) && <AiOutlineEdit
                                                                        onClick={() => pipelineFieldEditModalHandeler(item.typeid, item.NextStep, 'NextStep', 'Next Step')} />} &nbsp;
                                                                    {item?.NextStep ? item.NextStep : '--'}
                                                                </td>
                                                                <td className="text-center">
                                                                    {(item.canedit) && <AiOutlineEdit
                                                                        onClick={() => pipelineFieldEditModalHandeler(item.typeid, item.CloseDate, 'CloseDate', 'Close Date')} />} &nbsp;
                                                                    {showDate(item.CloseDate, 'MM-DD-yyyy')}
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="d-flex justify-content-between mb-2">
                                                                        <i className="bx bx-user font-size-18"></i>
                                                                        <p>
                                                                            {(item.canedit) && <AiOutlineEdit
                                                                                onClick={() => pipelineFieldEditModalHandeler(item.typeid, item.mostlikely, 'mostlikely', 'Most Likely Forecast')} />}
                                                                            ${formatNumber(item.mostlikely)}
                                                                        </p>
                                                                    </div>
                                                                </td>

                                                                <td className="text-right">
                                                                    <div className="d-flex justify-content-between mb-2">
                                                                        <i className="bx bx-user font-size-18"></i>
                                                                        <p>
                                                                            {(item.canedit) && <AiOutlineEdit
                                                                                onClick={() => pipelineFieldEditModalHandeler(item.typeid, item.upside, 'upside', 'Upside Forecast')} />}
                                                                            ${formatNumber(item.upside)}
                                                                        </p>
                                                                    </div>
                                                                </td>

                                                                <td className="text-right">
                                                                    {(item.canedit) && <AiOutlineEdit
                                                                        onClick={() => pipelineFieldEditModalHandeler(item.typeid, item.deallevel, 'deallevel', 'Deal Level Forecast')} />}
                                                                    ${formatNumber(item.deallevel)}
                                                                </td>
                                                                <td className="text-right">${formatNumber(item.ai)}</td>
                                                                <td className="text-right">${formatNumber(item.closed)}</td>
                                                            </tr>)
                                                        )}
                                                        {
                                                            opportunityDataList && opportunityDataList.length == 0 &&
                                                            <tr><td colSpan={8} className='text-center'>No Data Found.</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PipelineOpportunityList