import React from 'react'


const TermsAndConditionsText = () => {


	return (
		<React.Fragment>
			<div>
				<>
					<p>
						<strong>
							<span style={{ color: "#282c33", fontSize: "26pt" }}>
								ARRsuite, LLC. Terms of Service
							</span>
						</strong>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>Introduction</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>Last updated: September 14, 2023</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>
							ARRsuite has updated our Terms of Service, which are effective as of the
							date shown above.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>ARRsuite, LLC. (“</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>ARRsuite</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>” “</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>we</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>” “</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>us</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>” or “</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>our</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”), makes its CRM, CPQ, Forecasting and account management tools available
							online, including via a mobile application, as subscription services
							(each, a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Subscription</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>&nbsp;</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Service</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) as well as implementation, consulting, configuration, integration,
							training, advisory, development, and other professional services (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Professional</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>&nbsp;</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Services</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” and collectively with the Subscription Services, Support (as defined
							below) and any other services described on an Order, the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Services</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”). Our marketing websites, including&nbsp;
						</span>
						<a href="http://www.arrsuite.com">
							<u>
								<span style={{ color: "#0000ff", fontSize: "13.5pt" }}>
									www.arrsuite.com
								</span>
							</u>
						</a>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;are referred to herein as the&nbsp;
						</span>
						<span
							style={{
								color: "#4d4d4d",
								backgroundColor: "#ffffff",
								fontSize: "10.5pt"
							}}
						>
							"
						</span>
						<strong>
							<span
								style={{
									color: "#4d4d4d",
									backgroundColor: "#ffffff",
									fontSize: "10.5pt"
								}}
							>
								Websites
							</span>
						</strong>
						<span
							style={{
								color: "#4d4d4d",
								backgroundColor: "#ffffff",
								fontSize: "10.5pt"
							}}
						>
							".
						</span>
						<span style={{ fontSize: "13.5pt" }}>&nbsp;</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>
							ARRsuite may update these Terms of Service (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Terms</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) from time to time in accordance with the “Changes to the Terms” section
							below.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>
							DISPUTES ABOUT THESE TERMS OF SERVICE AND THE SERVICES ARE SUBJECT TO
							BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS SET FORTH IN
							THE “MANDATORY ARBITRATION” AND “CLASS ACTION WAIVER” SECTIONS BELOW.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								1. Your Relationship with ARRsuite
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>1.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Types of Users</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite has three different types of end users: (a) “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Site Visitors</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”, who are Users of the Websites; (b) “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Free Users</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” who are Users on a free or trial version of the Services; and (c) “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Subscribers</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”, who are Users who are part of a paid subscription plan (collectively,
							the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>User(s)</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>” or “</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>you</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” for the purposes of these Terms. While Free Users can access and use the
							Services, they have access to a more limited set of features and
							functionality than Subscribers. Additionally, the features and
							functionalities available to Subscribers are determined by the
							subscription tier.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>1.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Affiliates</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you are an entity purchasing a Multi-User Plan, your affiliates may
							use the Services purchased by you as Users on your account. Alternatively,
							your affiliate may enter into its own Order(s) as mutually agreed with
							ARRsuite, which will create a separate agreement between the affiliate and
							ARRsuite that incorporates these Terms.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>
								Regardless of what type of User you are, you agree that these Terms
								create a legally binding agreement between you and ARRsuite and govern
								your use of the Services and Websites.&nbsp;
							</span>
						</strong>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>2. Accepting the Terms</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>2.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Acknowledge these Terms</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You acknowledge and agree that you have read, understand, and agree to
							be bound by these Terms&nbsp;by (a) clicking or tapping on a button
							indicating your acceptance (usually during the registration or onboarding
							process), (b) accessing or using a Service or Websites, or (c) executing
							or making payment based on an ordering document, statement of work, or
							invoice (each, an “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Order</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) that references these Terms.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>2.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Authority</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. By accepting these Terms, you represent that you have the legal power to
							do so. If you accept these Terms on behalf of an organization, (a) “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>you</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>” and “</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>your</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” will refer to that organization and any individual accessing a Service
							through your account will be referred to as a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>User</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”; (b) you represent and warrant that you have the authority to bind the
							organization; and (c) you understand and agree that the organization is
							bound by and responsible for ensuring that each of its Users comply with
							these Terms.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>2.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Prohibited Users</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you are barred from receiving the Services under the laws of the
							United States or other countries, including the country in which you are a
							resident or from which you use the Services, you may not use the Services
							and not accept the Terms. If you represent a Multi-User Plan (defined
							below), you will ensure that: (a) your Users do not use a Services in
							violation of any export restriction or embargo by the United States; and
							(b) you do not provide access to any Service to persons or entities on any
							restricted lists.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>2.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Age Requirement for Users</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you are under 18 years old, you may not create a ARRsuite account or
							use the Services unless you have received access to the Services through
							your School (defined below).
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								3. The Services Provided by ARRsuite
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>3.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Subscription Services</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You and each User may access and use the applicable Subscription Service
							during the Subscription Term solely for your internal business operations,
							subject to payment of the applicable fees and the requirements of these
							Terms and the applicable Order. Your use of ARRsuite AI products,
							features, and functionality are subject to&nbsp;ARRsuite AI Terms You may
							purchase additional Subscription Services or add paid Users during the
							applicable Subscription Term at your then-current price; related fees will
							be prorated for the remainder of the applicable Subscription Term. The
							Subscription Services include the features and functionality applicable to
							the version selected. Available subscription plans and details of those
							plans may change over time but will not be materially degraded
							mid-Subscription Term.&nbsp;“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Subscription Term</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means the period of time beginning on the first start date and
							concluding on the last end date listed in an Order.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>3.2.&nbsp;</span>
						</strong>
						<strong>
							<u>
								<span style={{ fontSize: "13.5pt" }}>Subscription Renewal</span>
							</u>
						</strong>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>
								. If you are a Subscriber, you are enrolling in a recurring payment
								plan. After each Subscription Term, your Subscription Service will
								automatically renew at your then current number of subscriptions for the
								same period of time (e.g., 12 months if you chose an annual plan),
								unless either party cancels the auto-renewal or otherwise terminates the
								agreement at least 30 days before the end of the current Subscription
								Term as outlined in the “Ending Your Relationship with ARRsuite” section
								below. You authorize ARRsuite to invoice you or charge the designated
								payment method at the start of each new Subscription Term for the
								quantity and price then in effect for your paid account. An account
								owner or admin may review and make changes to subscription levels,
								number of Users, and Subscription Term on the Account Settings page at
								any time.
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>3.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Professional Services</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRSuite may provide Professional Services as described in, and subject
							to payment of the fees specified in, an Order. Any material changes
							requested or required to be made to the Order will require a change order
							agreed to and signed by the parties. ARRsuite hereby grants you the right
							to access and use the deliverables identified in an Order for Professional
							Services (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Deliverables</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) solely for your internal business purposes and in accordance with these
							Terms.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>3.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Free and Beta Services</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you or a User receive any Services received free of charge or
							Services released as beta, pilot, limited release, non-production or
							evaluation (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Beta Features</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”), you acknowledge and accept that such Services are provided “AS-IS”
							without any representations, warranties, support, maintenance or other
							obligation of any kind. Unless otherwise agreed in an Order, ARRsuite may
							terminate your access to, or use of, a free Service or a Beta Feature at
							any time. In addition, Beta Features are ARRsuite Confidential Information
							subject to the “Confidentiality” section below.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>3.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Trial Period</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you register for a trial&nbsp;online, your first payment will be
							charged immediately following the conclusion of the free trial period. You
							can cancel your free trial at any time before the free trial period ends
							(see “Ending Your Relationship with ARRsuite” regarding how to cancel a
							Subscription Service). ARRsuite will provide notice of the terms of the
							free trial period at the time you register, including when you need to
							cancel a free trial to avoid rolling over to a paid Subscription Service.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>3.6.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Non-ARRsuite Applications</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If&nbsp;you or a User&nbsp;chooses to utilize interoperability with a
							Non-ARRsuite Application, your purchase and use of such product is subject
							to the end user license or other agreement between you and the third-party
							provider. ARRsuite has no liability with respect to procurement,
							maintenance, use, or interoperability of any Non-ARRsuite Application. “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Non-ARRSuite Application</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means a software application, service, integration, or functionality
							that is developed or provided by a third-party, is not owned or under
							ARRsuite’s control and interoperates with a Service. Non-ARRsuite
							Applications are not part of the Services.&nbsp;
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>4. Using the Services</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>4.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Registration Information</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You and each User will be required to provide registration information
							in order to register for and access certain Services. You agree to keep
							this information, including contact information (e.g., e-mail address) and
							billing/payment details, accurate and current.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>4.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Access and Users</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You will obtain, maintain, and support all internet access, equipment,
							and ancillary services needed to access the Services and Deliverables.
							Subscriptions are for named individuals and cannot be shared or used by
							more than one individual at a time. However, with a Multi-User Plan, User
							subscriptions may be reassigned to new Users replacing individuals who no
							longer use a Subscription Service for any purpose (e.g. transferring a
							subscription from a terminated employee to a new employee). Each User must
							keep a secure password for accessing the Subscription Service and keep
							such password confidential. You will (a) if applicable, obtain from the
							Users on your account any consents necessary for ARRsuite to provide the
							Services; (b) maintain commercially reasonable security standards with
							respect to use of the ARRsuite Assets (defined below); and (c) in the
							event of any unauthorized access to or use of the Services or
							Deliverables, promptly notify ARRsuite.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>4.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Your Responsibilities</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You are responsible for: (a) access to and use of the Subscription
							Service(s) and Deliverables by the Users on your account and each User’s
							compliance with these Terms; (b) the secure transmission of your Content
							to the Subscription Service(s); (c) the legality, reliability, integrity,
							accuracy and quality of the Content, any conclusions drawn or actions
							taken therefrom, and the means by which you or the Users acquired the
							Content so that ARRsuite and its service providers may lawfully use,
							process, and transfer the Content in accordance with these Terms; (d) if
							desired, backing-up your Content outside of the Subscription Service; (e)
							using commercially available technologies to prevent the introduction of
							viruses, malware, Trojan horses, worms, spyware or other destructive code
							(“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Malware</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) into the Subscription Service(s); and (f) instructing the Users on the
							Use Restrictions and the limitations on Protected Information below, and
							ensuring that those limitations and/or Use Restrictions are not
							breached.&nbsp;“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Content</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means the data, information, images, and other content that is uploaded
							to, imported into or created in a Subscription Service by the Users, all
							of which is your Confidential Information.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>4.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Protected Information</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You acknowledge that the Subscription Service(s) were not designed or
							intended to process Protected Information and that ARRsuite does not
							classify Content or provide special protection based on the type of data
							submitted by you. If Protected Information is entered into a Subscription
							Service, ARRsuite will treat such information as your Confidential
							Information and afford it the protections in these Terms but will not
							afford it any added protections based on the type of data provided. You
							acknowledge that any Protected Information submitted is done so at your
							own risk and ARRsuite will not be liable for heightened or statutory
							damages due to the nature or type of Protected Information submitted. “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Protected Information</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means information that is subject to specific regulations or laws that
							impose increased protections, obligations and/or penalties with respect to
							handling that type of information or that is not appropriate for use in
							the Services, as intended by ARRsuite. Protected Information includes,
							without limitation, classified information, data that is subject to the
							Payment Card Industry Data Security Standards (PCI DSS), Health Insurance
							Portability and Accountability Act (HIPAA), Gramm-Leach-Bliley Act (GLBA),
							Criminal Justice Information Services (CJIS) Security Policy, IRS
							Publication 1075 or any similar legislation in an applicable jurisdiction,
							or any credit or debit card and magnetic stripe information, government
							issued identification numbers, health or biometric information, education
							records, financial account information, personally identifiable
							information of children under the age of 16, or information deemed
							“sensitive” or “special category” under applicable law (such as racial or
							ethnic origin, political opinions, or religious or philosophical beliefs).
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>4.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Use Restrictions</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You will not and will ensure that each User does not:&nbsp;(a) license,
							sublicense, sell, resell, rent, lease, transfer, distribute, provide
							access, or otherwise commercially exploit, or make the Services or
							Deliverables available to any third-party except as expressly authorized
							herein;&nbsp;(b) copy, modify, translate, adapt, merge, or create
							derivative works of the Services or Deliverables or disassemble,
							decompile, reverse engineer, or otherwise extract the source code of, or
							reduce to human-perceivable form, any part of them unless the foregoing
							restrictions are expressly prohibited by applicable law;&nbsp;(c) use or
							access the Services or Deliverables (i) to develop a product or service
							that competes with ARRSuite or (ii) other than in compliance with these
							Terms and all applicable laws and regulations (including export control
							laws and restrictions);&nbsp;(d) remove or modify any proprietary markings
							or restrictive legends in a Subscription Service or on the
							Deliverables;&nbsp;(e) infringe or misappropriate any ARRsuite
							Assets;&nbsp;(f) attempt to gain unauthorized access to the Services or
							any portion thereof;&nbsp;(g) knowingly, intentionally or negligently
							introduce Malware into, or otherwise engage in any malicious act or
							disrupt the security, integrity or operation of, a Subscription
							Service;&nbsp;(h) access or attempt to access a Subscription Service by
							any means other than ARRsuite’s publicly supported interfaces, including
							any automated means (i.e. use of scripts or web crawlers);&nbsp;(i) probe,
							scan, or test the vulnerability of any ARRsuite system or network;
							or&nbsp;(j) access, store, create, share, display, publish or transmit any
							material that a person would reasonably believe to be unlawful or related
							to illegal activity, threatening, deceptive, defamatory, discriminatory,
							obscene, libelous, an invasion of privacy, or infringes the intellectual
							property rights of a third-party through the Services (a-j collectively,
							the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Use Restrictions</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>”).</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								5. Sharing Features in the Services
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>5.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Third-Party Content</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. The Services may contain content provided by others (such as templates
							authored by third parties) that may not be owned by ARRsuite and may be
							protected by intellectual property rights of third parties. Such content
							is the sole responsibility of the person or entity from whom it
							originated.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>5.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Sharing Content</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. The Subscription Services allow you to share information and Content
							within your account, outside your account and even publicly. You are
							solely responsible for the Content that you create, transmit, display or
							share with others while using the Subscription Services, and for the
							consequences of your actions.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>5.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Collaboration</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you accept an invitation to view or collaborate on another User’s
							Content, you acknowledge that your access to that Content and any
							information you provide or changes you make will be under the sole control
							of the owner of that Content. In addition, the owner of that Content,
							other Users who have access to that Content and the owner and
							administrators of the account containing that Content will be able to view
							certain information, including personal information, about you.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								6. ARRsuite's Provision of the Services
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>6.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Updates</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite makes updates (e.g. bug fixes, enhancements) to the
							Subscription Service(s) on an ongoing basis. You consent to ARRsuite’s
							delivery of updates automatically through the Subscription Service(s).
							Except for urgent updates, ARRsuite schedules maintenance during non-peak
							usage hours (that minimizes the impact on all Users, worldwide).
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								7. Data Security, Privacy and Your Personal Information
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>7.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Your Personal Information</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuites privacy policy governs how ARRsuite treats your personal
							information and protects your privacy when you use the Websites and
							Services. You acknowledge that you have read, and agree to the use of your
							data, including personal information, as outlined in ARRsuites privacy
							policy .&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>7.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Security</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;ARRsuite will maintain administrative, physical, technical, and
							organizational measures to protect the security, confidentiality, and
							integrity of your Content in accordance with our Information Security
							Program. Any revisions to our Information Security Program will not
							diminish our current data security obligations.&nbsp;“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Information Security Program</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means ARRsuite’s then-current data security and data management policies
							and procedures that apply to the operation and use of the Services&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>7.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Service Providers</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite may utilize subcontractors or SaaS tools in connection with
							ARRsuite’s provision of the Services, including processing Content,
							provided that such third parties are subject to appropriate
							confidentiality and data security obligations. ARRsuite is responsible for
							such third-parties’ acts and omissions in relation to ARRsuite’s
							obligations hereunder.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>7.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Account Information</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite will use data provided in connection with the creation or
							administration of entity and User accounts to set up and maintain such
							accounts, provide and maintain the Services, detect and prevent fraud or
							violations of these Terms, inform you and Users about new products and
							features, and as necessary to comply with applicable law, regulation,
							legal process or enforceable governmental requests.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>8. Confidentiality</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>8.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Definition</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>. “</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Confidential Information</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means non-public, proprietary, business, technical, security, legal, or
							financial information that is marked or identified as Confidential
							Information or would reasonably be understood to be confidential, such as
							information about products, processes, services, trade secrets, marketing
							and business plans, client lists, pricing, financial information, system
							architecture, security programs, or intellectual property. For
							clarification, pricing for the Services is ARRSuite’s Confidential
							Information. Notwithstanding the foregoing, Confidential Information does
							not include information that: (a) the receiving party possesses without a
							duty to keep confidential prior to acquiring it from the disclosing party;
							(b) is or becomes publicly available through no violation of this
							agreement by the receiving party; (c) is given to the receiving party by a
							third-party not under a confidentiality obligation to the disclosing
							party; or (d) is developed by the receiving party independently of, and
							without reliance on, confidential or proprietary information provided by
							the disclosing party.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>8.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Use and Disclosure</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Each party may be given access to Confidential Information of the other
							party in connection with these Terms. The receiving party may only use
							this Confidential Information as provided for in these Terms or to
							exercise its rights hereunder and may only share this Confidential
							Information with its employees, agents, advisors, procurement agents and
							service providers who need to know it, provided they are subject to
							similar confidentiality obligations. The receiving party will use the same
							degree of care, but no less than a reasonable degree of care, as such
							party uses with respect to its own Confidential Information to protect the
							disclosing party’s Confidential Information and to prevent any
							unauthorized use or disclosure thereof. Neither party is responsible for
							any loss, destruction, alteration or disclosure of Confidential
							Information caused by any third-party not under the receiving party’s
							control. If the receiving party is compelled by law to disclose the other
							party’s Confidential Information, it will provide the disclosing party
							prior written notice of such compelled disclosure (to the extent legally
							permitted) and reasonable assistance, at the disclosing party’s cost, if
							the disclosing party wishes to contest the disclosure. These
							confidentiality obligations will remain in effect for the Term (defined
							below) and for an additional one (1) year following termination.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>9. Intellectual Property</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>9.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Your Content</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. As between the parties, you own all rights, title, and interest in and
							to the Content in the accounts for which you are the account owner and
							your Confidential Information, including all intellectual property and
							proprietary rights therein. Except as expressly set forth herein, ARRsuite
							acquires no right, title, or interest from you in or to your Content or
							Confidential Information.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>9.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>ARRsuite’s Use of Your Content</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;You hereby grant ARRsuite a limited, worldwide, non-exclusive,
							non-transferable, right to access, use and process Content until removed
							from a Subscription Service: (a) as requested by you or a User; (b) as
							necessary to provide and improve the Services, including to identify,
							investigate, or resolve technical problems with the Services; (c) to
							detect and prevent fraud or violations of these Terms; and (d) as required
							by applicable law, regulation, legal process or enforceable governmental
							request.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>9.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Trademark Violations.</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;If you believe that ARRsuite, or any User, has violated a copyright,
							trademark, or other intellectual property right you claim in your work,
							please contact us. ARRsuite responds to notices of alleged copyright
							infringement in accordance with the U.S. Digital Millennium Copyright Act
							(“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>DMCA”</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>).</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>9.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>ARRsuite.</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;As between the parties, ARRsuite owns all rights, title, and
							interest in and to the ARRsuite Assets and ARRsuite Confidential
							Information, including all intellectual property and proprietary rights
							therein. Except as expressly set forth herein, ARRsuite does not convey
							any rights to the Customer or any User. “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>ARRsuite Assets</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means (a) the Subscription Service(s), work product, Documentation and
							Deliverables (but not any Content or your Confidential Information
							contained therein); (b) all ARRsuite copyrights, patents, trademarks,
							trade names, trade secrets, specifications, technology, software, data,
							methodologies, machine learning models, changes, improvements, components
							and documentation used to provide the Services or made available in
							connection herewith, and all intellectual property, proprietary rights and
							underlying source code, object code, and know-how in and to the foregoing.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>9.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Feedback and Analyses.</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;You or your Users may voluntarily provide feedback or suggestions (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Feedback</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) to ARRsuite and ARRsuite may freely use and exploit such Feedback
							(without any obligations or restrictions).&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>9.6.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>
								Statistical Data and Analyses.&nbsp;
							</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							ARRsuite owns all rights to the Statistical Data and may perform analyses
							on Statistical Data your Content (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Analyses</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”). Content utilized as part of Analyses will be anonymized and
							aggregated. ARRsuite may use Statistical Data and Analyses for its own
							business purposes (such as improving, testing, and maintaining a
							Subscription Service, training machine learning algorithms, identifying
							trends, and developing additional products and services). Provided that
							ARRsuite does not reveal any of your Confidential Information or the
							identity, directly or indirectly, of any User or entity, ARRsuite may
							publish Feedback and anonymized aggregated Statistical Data and Analyses.
							“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Statistical Data</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means data generated or related to the provision, operation or use of a
							Subscription Service, including measurement and usage statistics,
							configurations, survey responses, and performance results.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>10. Multi-User Accounts</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>10.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>
								Account Owners and Administrators
							</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You retain administrative control over who is granted access to your
							account. Each account is controlled by an account owner tied to a specific
							email address and may also have one or more billing admins and team admins
							to help manage the account. ARRsuite is entitled to rely on communications
							from the account owner and admins when servicing your account. If a person
							within your organization requests a change to the account owner, we will
							attempt to contact the account owner for consent, but to the extent that
							the account owner does not respond to our communications, we will transfer
							the account owner based on our internal verification methods.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>10.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>
								Content in a Multi-User Account
							</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. When you create Content under an individual plan, that Content is
							available only to you and others with whom you share the Content. However,
							if you are or become an individual User managed under a multi-user
							subscription plan maintained by your employer or other third-party (a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Multi-User Plan</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”), such as a Team or Enterprise account, any Content you have created or
							will create will be subject to control by the applicable account owner and
							admins and will then be considered Content owned by your employer or such
							other third-party. The account owner and admins under a Multi-User Plan
							can designate other Users under that Multi-User Plan as owners of the
							Content you originally created, in which case you may no longer be able to
							access or use the Content. In addition, the account owner or admins of
							such Multi-User Plan may downgrade your access, remove you from the
							account or permanently delete you from the account without your prior
							consent. If you have questions about Multi-User Plans, please
							contact&nbsp;us.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>10.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Account Control</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You acknowledge and agree that if you are a registered User, and the
							domain of the email address associated with your ARRsuite account is owned
							by an organization, and an authorized representative of that organization
							wishes to establish a Multi-User Plan and add you to it, then information
							concerning your existing account will become accessible to that
							organization, including your name, email address, and Content, and your
							existing account may be added to a Multi-User Plan without your prior
							consent. In these situations, any credit remaining on your account may be
							applied to the Multi-User Plan account. In addition, you acknowledge and
							agree that if you are or become a registered User on a Multi-User Plan
							your account information and data will be shared with the owner and admins
							of the account and your information and Content may also be visible to
							other Users in the account.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>11. Education Accounts</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>11.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Applicability</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you are a school, school district, or related person, entity or
							organization (such as an administrator or educator who accesses the
							Services on their behalf) (each a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>School</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”), then this section applies to you and “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>you</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							” means the School purchasing the account, as well as its Users. If you
							are not a School, then this section will not apply.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>11.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Agreements</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You agree to (a) only provide access to the Service to those individuals
							employed by or enrolled as students in your School or classroom and (b) be
							responsible for any Content, communications, and activity that occur under
							such accounts. Regardless of the account level being utilized, to the
							extent a School offers or requires access to the Services to minors, the
							School will be responsible for those User accounts under this section.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>11.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Responsibilities</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Both parties agree to uphold their responsibilities under the FERPA, the
							Protection of Pupil Rights Amendment, and the Children’s Online Privacy
							and Protection Act (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>COPPA</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”). We provide the Services under the “school official” exception of FERPA
							34 CFR Part 99.31(a)(1). COPPA requires that online service providers
							obtain clear and verifiable parental consent before collecting personal
							information from children under the age of 13. You represent and warrant
							that you have the authority to provide consent on behalf of parents in
							order for us to collect information from students before allowing children
							under the age of 13 to access our Services. We require all Schools to
							provide appropriate disclosures to students and parents regarding their
							use of the Services. If you are located outside of the United States, you
							will obtain any required consent or approval from the parent or guardian
							of any student covered by similar laws and, as a condition to your and
							your students' use of the Services, you agree that you will be responsible
							for complying with such laws.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>11.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Deletion of Student Accounts</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. For individuals that select a student persona in our product and are on
							an email domain that we have classified as a K-12 domain, we will
							permanently delete the individual accounts after a certain number of years
							of inactivity within our product. We will attempt to notify the individual
							prior to deletion.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								12. Representations and Warranties
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>12.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>ARRsuite</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite warrants that: (a) during the applicable Subscription Term, the
							Subscription Service(s) will operate substantially as described in the
							Documentation; (b) Support and Professional Services will be performed in
							a competent and workmanlike manner; (c) the Deliverables will conform to
							the specifications in the applicable Order; (d) it has the necessary
							authority to enter into these Terms; and (e) it will comply with all laws
							and regulations (including export control laws and restrictions)
							applicable to its provision of the Services to its users generally (i.e.
							without regard for Customer’s particular use of the Services or laws and
							regulations specific to Customer and its industry). If you believe a
							Service or Deliverable does not comply with the warranties in (a)-(c), you
							must notify ARRsuite in writing within 30 days of discovery of the
							nonconformity. As your exclusive remedy and ARRsuite’s sole liability for
							breach of these warranties, ARRsuite will use commercially reasonable
							efforts to correct the non-conforming Service or Deliverable at no
							additional charge within a reasonable time period. These warranties will
							not apply to any failure caused by you, any User, or a person acting at
							your direction.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>12.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>You</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you are the representative of an entity with Multi-User Plan and you
							(a) convert any existing accounts registered using email addresses from
							Your entity domain into accounts under your control, or (b) enable
							administrative controls over access to a Subscription Service based on
							your e-mail domains (i.e. ARRsuite’s lock-down and consolidation
							features), you represent and warrant that the entity owns such e-mail
							domain(s) and the Content that has been created by individuals with such
							e-mail domains. You will appoint an administrator to manage your account
							and will be solely responsible for the administrator’s acts and omissions
							with respect to the Services.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>12.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Disclaimer</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. EXCEPT AS EXPRESSLY PROVIDED HEREIN, AND TO THE MAXIMUM EXTENT PERMITTED
							BY LAW, NEITHER PARTY MAKES ANY REPRESENTATION OR WARRANTY OF ANY KIND,
							WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE
							IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND
							FITNESS FOR A PARTICULAR PURPOSE. ARRsuite MAKES NO REPRESENTATION OR
							WARRANTY OF ANY KIND REGARDING ANY NON-ARRsuite APPLICATION WITH WHICH THE
							SERVICE MAY INTEROPERATE. ARsuite DOES NOT WARRANT THAT THE SERVICES WILL
							BE ERROR-FREE OR OPERATE WITHOUT INTERRUPTIONS OR DOWNTIME OR THAT DEFECTS
							IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART
							OF THE SERVICES WILL BE CORRECTED.&nbsp;
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								13. Billing, Payment, and Renewal
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Subscription Fees</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;If you select a paid tier of the Subscription Service (a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>paid account</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) ARRsuite will bill you in advance for use of the Services on the
							frequency that you select. The term of your Subscription Service will be
							available in your account settings and/or set forth in the associated
							Order. Unless otherwise communicated to you, credit card, debit card, or
							other non-invoice forms of payment are due at the beginning of the
							relevant Subscription Term. You authorize ARRsuite to charge you for all
							fees for all Subscription Terms when due. ARRsuite may enable other forms
							of payment in the Account Settings page, which may be subject to
							additional terms. Payments for invoices are due thirty (30) days after the
							invoice date, unless otherwise specified, and are considered delinquent
							thereafter.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Professional Services Fees</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Unless otherwise agreed to in an Order, Professional Services will be
							performed remotely and are provided on a time and materials basis (“
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>T&amp;M</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) at the rates set forth in the Order. T&amp;M estimates are not a
							guarantee that the project will be completed in the estimated number of
							hours. Actual T&amp;M hours may be more or less than the estimate
							provided. In addition, you will reimburse ARRsuite for reasonable,
							documented, out-of-pocket expenses (including all travel costs and
							expenses) incurred by ARRsuite while providing Professional Services that
							are authorized or pre-approved by you in writing.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Non-refundable</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Except as expressly set forth in these Terms or when required by
							applicable law (a) all fees are non-cancellable and once paid are
							non-refundable; and (b) quantities purchased cannot be decreased during a
							Subscription Term.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Pricing</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Unless specified in an Order, the pricing applicable to a Service is
							specified on the Websites. ARRsuite reserves the right to modify pricing
							at any time for renewal periods; provided, that ARRsuite will notify you
							or an account admin prior to any price increase affecting your account,
							and such modified pricing will not take effect during your then-current
							Subscription Term. If you do not accept the pricing change, you may elect
							to not renew your paid account subject to the “Ending Your Relationship
							with ARRsuite” section below.&nbsp;If you upgrade to a higher tier of paid
							account, ARRsuite will credit any remaining balance from your previous
							subscription payment to your new tier.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Downgrade or Cancellation</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You may cancel or downgrade your subscription at any time before the end
							of the current Subscription Term (or free trial period). The cancellation
							or downgrade will take effect at the end of the current Subscription Term.
							You will continue to have access to all the features of your paid or trial
							account until the end of the current Subscription Term. ARRsuite does not
							provide any refunds or credits for partial Subscription Terms. To cancel,
							the account owner or an admin must log into the account and select the
							appropriate options on the Account Settings page. Alternatively, the
							account owner or admin can contact our customer support team.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.6.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Billing disputes</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Billing disputes must be notified to ARRsuite in writing within 30 days
							from discovery of an error. Except as prohibited by law, ARRsuite may
							charge a late fee of 1.5% (or the highest rate permitted by law, whichever
							is less) per month on undisputed past due amounts. You will be responsible
							for all reasonable expenses (including attorneys' fees) incurred by
							ARRsuite in collecting delinquent amounts. If you require a purchase
							order, vendor registration form, or other documentation, such requirement
							will in no way relieve, affect or delay your obligation to pay any amounts
							due hereunder.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.7.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Currency and Taxes</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. All amounts payable to ARRsuite will be paid in the currency set forth
							on the pricing page, in the Order, or USD and are exclusive of any
							applicable sales or use taxes (such as GST or VAT).&nbsp;If ARRsuite is
							obligated to collect or pay these&nbsp;taxes, they will be stated
							separately on each invoice, unless you provide ARRsuite (in advance) a
							valid tax exemption certificate authorized by the applicable taxing
							authority.&nbsp;If you are required by law to withhold any taxes from your
							payments to ARRsuite, you must provide ARRsuite with an official tax
							receipt or other appropriate documentation to support such withholding and
							reimburse ARRsuite for such withholding tax.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>13.8.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Procurement Agents</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you elect to purchase Services through a procurement agent, you will
							remain liable for the fees payable to ARRsuite for such Services and the
							other obligations in this agreement.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>14. Indemnification</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>14.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>By&nbsp;</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							ARRsuite. ARRsuite will indemnify you, your officers, directors and
							employees (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Customer Indemnified Parties</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) against any claim, action, demand, suit or proceeding (each, a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Claim</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) brought against the Customer Indemnified Parties by a third-party
							alleging that a Subscription Service or Deliverable infringes such
							third-party’s intellectual property rights, including any finally awarded
							damages or settlement amount and reasonable expenses (including attorneys’
							fees) to the extent arising from such Claim. Notwithstanding the
							foregoing, ARRsuite will not be obligated to indemnify the Customer
							Indemnified Parties if an infringement claim arises from: (a) the Content;
							(b) a User’s misuse of a Subscription Service; (c) a User’s use of the
							Subscription Service in combination with any products, services, or
							technology not provided by ARRsuite or a modification of a Subscription
							Service or Deliverable by you or a User, if the Subscription Service or
							Deliverable or use thereof would not infringe without such combination or
							modification; or (d) continued use of a Subscription Service or
							Deliverable after written notice by ARRsuite to discontinue use. If an
							infringement Claim is made or threatened, ARRsuite may, in its sole
							discretion: (i) replace or modify the infringing Subscription Service or
							Deliverable so that it is non-infringing (but functionally equivalent);
							(ii) procure the right for you to continue using the Subscription Service
							or Deliverable; or (iii) notwithstanding ARRsuite’s obligation to
							indemnify, terminate use of the infringing Subscription Service or
							Deliverable and refund any unused prepaid fees covering the terminated
							portion of the Subscription Service or Deliverable.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>14.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>By You</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. You will indemnify ARRsuite and ARRsuite’s affiliates, and their
							respective officers, directors and employees (the “ARRsuite
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>&nbsp;Indemnified Parties</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) against any Claim made or brought against the ARRsuite Indemnified
							Parties by a third-party or User (a) alleging your Content infringes or
							misappropriates any intellectual property rights; (b) related to ownership
							or use of your Content; or (c) related to an administrator’s actions with
							respect to your account, including any finally awarded damages or
							settlement amount and reasonable expenses (including attorneys’ fees) to
							the extent arising from such Claim.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>14.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Process</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. The party seeking indemnification (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Indemnified Party</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) will provide the other party (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Indemnifying Party</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”) prompt written notice of any Claim and reasonable cooperation to the
							Indemnifying Party in the defense, investigation or settlement of any
							Claim at the Indemnifying Party’s expense. The Indemnifying Party will
							have sole control of such defense, provided that the Indemnified Party may
							participate in its own defense at its sole expense. The Indemnifying Party
							may not settle a Claim without the Indemnified Party’s consent if such
							settlement imposes a payment or other obligation on the Indemnified Party.
							This section sets forth the Indemnifying Party’s sole liability to, and
							the Indemnified Party’s exclusive remedy for, any type of Claim described
							in this section.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>15. Limitation of Liability</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>15.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Limitation of Liability</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. TO THE EXTENT PERMITTED BY LAW:
						</span>
					</p>
					<ol>
						<li style={{ listStyleType: "lower-alpha", fontSize: "13.5pt" }}>
							<p>
								<span style={{ fontSize: "13.5pt" }}>
									NEITHER PARTY WILL BE LIABLE TO THE OTHER PARTY FOR ANY LOST PROFITS,
									REVENUES OR DATA, BUSINESS INTERRUPTION, DEPLETION OF GOODWILL, OR
									INDIRECT, SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
									DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS, REGARDLESS OF THE
									BASIS OR TYPE OF CLAIM AND EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
									DAMAGES; AND
								</span>
							</p>
						</li>
						<li style={{ listStyleType: "lower-alpha", fontSize: "13.5pt" }}>
							<p>
								<span style={{ fontSize: "13.5pt" }}>
									EACH PARTY’S AGGREGATE LIABILITY FOR DAMAGES ARISING OUT OF OR RELATED
									TO THIS AGREEMENT (WHETHER IN CONTRACT, TORT OR OTHERWISE) WILL NOT
									EXCEED THE GREATER OF (i) THE AMOUNT PAID OR PAYABLE BY YOU HEREUNDER
									WITHIN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE
									TO LIABILITY OR (ii) $200.00.
								</span>
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "13.5pt" }}>15.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>General</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. The parties acknowledge and agree that the limitations of liability,
							disclaimer of warranties, and any exclusion of damages included herein
							represent an allocation of risk between the parties (including the risk
							that a remedy may fail of its essential purpose) which is reflected by the
							fees paid. Notwithstanding the foregoing, nothing in these Terms excludes
							or limits ARRsuite’s warranty obligations or liability for losses which
							may not be lawfully excluded or limited by applicable law. Only the
							limitations which are lawful in your jurisdiction will apply to you and
							ARRsuite’s liability will be limited to the maximum extent permitted by
							law.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>
								16. Ending Your Relationship with ARRsuite
							</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Term</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;These Terms will apply until all accounts under your control are
							permanently closed unless superseded by a written agreement between you
							and ARRsuite (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Term</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>”).</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Termination By You</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If you want to cancel your auto-renewal or terminate your subscription
							and close your account, you must do so by: (a) notifying ARRsuite. or (b)
							canceling your subscription in the Accounts Settings page and then closing
							your account, if this option is available to you, no less than 30 days
							before the end of your then-current Subscription Term. In order to delete
							your data from our servers after ending your relationship with ARRsuite
							you must permanently delete your account (see Retention of Information and
							Content). For help permanently deleting an account, please review our
							Documentation or contact&nbsp;
						</span>
						<u>
							<span style={{ color: "#0000ff", fontSize: "13.5pt" }}>us</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>.</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Termination by Either Party</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Either party may terminate the Services if: (a) if the other party
							commits a material breach of these Terms (or has acted in a manner which
							clearly shows that the party does not intend to, or is unable to comply
							with, the provisions of the Terms) and such breach either (i) is not
							capable of being cured, or (ii) has not been cured within 10 days of
							receiving written notice of the breach; (b) immediately upon the other
							party ceasing to operate in the ordinary course, making an assignment for
							benefit of creditors, or becoming the subject of any insolvency,
							bankruptcy, liquidation, dissolution, or similar proceeding; or (c) it is
							required to do so by law.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Termination by&nbsp;</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							ARRsuite. In addition, ARRsuite may terminate your access to any or all of
							the Services and/or close your account, for any or no reason, by giving
							you 30 days’ written notice to your email address on file and providing a
							pro rata refund for any prepaid, unused subscription fees for the
							Services.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>
								Suspension or Termination for Cause
							</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite reserves the right to suspend access to the Service(s) if (a)
							you have undisputed amounts past due; (b) ARRsuite reasonably determines
							that you or any Users on your account are in breach of these Terms; or (c)
							ARRsuite reasonably determines that you or any Users on your account are
							using a Service in a way that creates a security vulnerability, may
							disrupt others’ use of a Service, or have misappropriated or infringed
							ARRsuite’s or another third-party’s intellectual property or proprietary
							rights. ARRsuite will only suspend access to the extent, and for the
							duration, necessary to address the violation and will promptly restore
							access once the issue has been resolved. ARRsuite will not suspend access
							if you are (reasonably and in good faith) disputing a charge and
							cooperating in resolving the dispute. You acknowledge and agree that if
							ARRsuite suspends access to your account, you may be prevented from
							accessing the Services, your account details, or any files or other
							Content contained in your account. If the reason for suspension cannot be
							resolved, ARRsuite will automatically downgrade your account to a free
							account or terminate your use of the Service(s). Downgrading your account
							may cause the loss of Content, features, functionality or capacity.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.6.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Effect of Termination</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. On termination of the Services, (a) ARRsuite will disable your account
							and each User’s access to the paid Services; (b) you will immediately pay
							any accrued but unpaid and undisputed fees; (c) each party will return and
							make no further use of, or destroy (subject to each party’s automated
							deletion schedule and back-up policy), any Confidential Information
							belonging to the other party, subject to (d); and (d) provided you have
							permanently closed your account(s), ARRsuite will delete all Content in
							accordance with its automated deletion schedule and back-up policy. You
							may export or download your Content at any time from the Subscription
							Service in accordance with the Documentation. Any requested
							post-termination transition assistance is subject to the mutual agreement
							of the parties (and may require payment of Professional Services
							fees).&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.7.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>
								Retention of Information and Content
							</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. If your paid account is downgraded or canceled, your information and
							Content may be deactivated but not deleted. In addition, upon cancellation
							of a Multi-User Plan, if the Users are downgraded to and maintain free
							accounts after termination of paid Services, the ownership of the free
							account(s) and Content therein will transfer to the applicable User(s).
							Information and Content in those free accounts will not be deleted until
							such accounts are permanently closed by the User. ARRsuite may retain
							copies of Content as part of records, documents, or broader data sets in
							accordance with ARRsuite’s legal and financial compliance obligations, on
							the condition that ARRsuite continues to comply with the requirements of
							these Terms in relation to any retained Content.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.8.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Survival</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Any provision of these Terms that by its nature is reasonably intended
							to survive beyond termination of these Terms will survive.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>16.9.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Site Visitors</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. A Site Visitor may terminate its use of the Websites at any time by
							ceasing further use of the Website. ARRsuite may terminate your use of the
							Websites and deny you access to the Websites in our sole discretion for
							any reason or no reason, including for violation of these Terms.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>17. Changes to the Terms</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>17.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Amendments and Effective Date</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. ARRsuite may make changes to the Terms from time to time. When these
							changes are made, ARRsuite will make a new copy of the Terms available, as
							applicable. You understand and agree that if you are on a free account and
							you use the Services after the date on which the Terms have changed,
							ARRsuite will treat your continued use of the Services as acceptance of
							the updated Terms. If you have a paid account, the new Terms will apply
							upon your renewal.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>17.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Waivers</span>
						</u>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>.&nbsp;</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							Any waiver of these Terms must be in writing and no written waiver will
							operate or be construed as a waiver of any subsequent breach. The failure
							of either party to exercise or enforce any right or provision of these
							Terms will not constitute a waiver of such right or provision or of any
							other right or provision.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>18. Mandatory Arbitration</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>18.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Cooperative Resolution Process</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. The parties agree that most disputes can be resolved without resort to
							litigation. If you have any dispute with us, you agree that before taking
							any formal action you will contact us, and provide a brief, written
							description of the dispute and your contact information (including your
							username, if your dispute relates to an account). Except for the Excluded
							Disputes (defined below), the parties agree to use their best efforts to
							settle any dispute directly through consultation with each other, and good
							faith negotiations will be a condition to either party initiating an
							arbitration (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Cooperative Resolution Process</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”). If the parties do not reach an agreed-upon solution within a period of
							thirty (30) days from the time the Cooperative Resolution Process is
							initiated, then either party may initiate binding arbitration as the sole
							means to resolve any dispute (except for Excluded Disputes) subject to the
							terms set forth below.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>18.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Mandatory Arbitration</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Any dispute, controversy, or claim relating to, connected with, or
							arising out of the subject matter of these Terms, or the breach,
							termination, enforcement, interpretation, or validity thereof, including
							the determination of the scope or applicability of this agreement to
							arbitrate (a “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Dispute</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”), will be determined by arbitration in Salt Lake City, Utah before one
							arbitrator. The arbitration will be administered by JAMS pursuant to JAMS’
							Streamlined Arbitration Rules and Procedures. Judgment on an award may be
							entered in any court having jurisdiction. This clause does not preclude
							parties from seeking provisional remedies in aid of arbitration from a
							court of appropriate jurisdiction. Any demand for arbitration under these
							Terms must be made before the statute of limitations applicable to such
							claim has expired. In any arbitration arising out of or related to these
							Terms, the arbitrator is not empowered to award punitive or exemplary
							damages, except where permitted by statute, and the parties waive any
							right to recovery any such damages.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>18.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Excluded Disputes</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Notwithstanding the parties’ decision to resolve all Disputes through
							arbitration, the following Disputes may be brought in any court of
							competent jurisdiction: (a) disputes relating to or arising from
							misappropriation, infringement, validity and/or enforceability of a
							party’s intellectual property rights (including patents, copyrights, moral
							rights, trademarks, and trade secrets, but not privacy or publicity
							rights), (b) any action by a party seeking entry of a temporary
							restraining order, preliminary injunctive relief, or permanent injunctive
							relief, or (c) any claims that, as a matter of law, the parties cannot
							agree to arbitrate (collectively, the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Excluded Disputes</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>”).</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>19. Class Action Waiver</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>19.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Class Action Waiver</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. The parties agree that an arbitrator will not have authority to conduct
							class arbitration of any Dispute. You and ARRsuite each agree that any
							arbitration or court action to resolve any Dispute will take place on an
							individual basis without resort to any form of class, consolidated, or
							representative action (the “
						</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>Class Action Waiver</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							”). YOU UNDERSTAND AND AGREE THAT THE CLASS ACTION WAIVER PRECLUDES ANY
							PARTY FROM PARTICIPATING IN OR BEING REPRESENTED IN ANY CLASS OR
							REPRESENTATIVE ACTION FOR ANY DISPUTE, INCLUDING CONSUMER DISPUTES AND
							BUSINESS DISPUTES. If any court or arbitrator determines that the Class
							Action Waiver is void or unenforceable for any reason or that an
							arbitration can proceed on a class basis, then the arbitration provisions
							set forth above will be null and void in their entirety and the parties
							will be deemed to have not agreed to arbitrate Disputes.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>20. General Legal Terms</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Publicity</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Unless otherwise set forth in an Order, ARRsuite may use your name,
							corresponding trademark or logo, and non-competitive use details in both
							text and pictures to identify you as a customer and highlight such use
							details, subject to any usage guidelines you provide to ARRsuite, in
							publicly available oral and written statements, including on ARRsuite’s
							website, in marketing materials, and in press releases. To request removal
							of this identification, please notify us in writing. In addition, ARRsuite
							may disclose the relationship between you and ARRsuite if legally required
							or in connection with a merger, acquisition, corporate reorganization, or
							sale of all or substantially all of its assets.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.2.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Relationship of the Parties.</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;You and ARRsuite are independent contractors. These Terms do not
							create or imply any agency, partnership, joint venture, fiduciary,
							employment or franchise relationship. No right or cause of action for any
							third-party is created by these Terms or any transaction under it.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.3.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Force Majeure.</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;Neither party is liable for delay or default hereunder if caused by
							conditions beyond its reasonable control, including natural disasters,
							acts of God, hacker attacks, acts of terror or war, riots, actions or
							decrees of governmental bodies, changes in applicable laws, or
							communication or power failures.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.4.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Governing Law</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;These Terms and your relationship with ARRsuite under the Terms,
							are governed by the laws of the State of Utah without regard to its
							conflict or choice of laws rules. To the extent the arbitration provisions
							herein are excluded by written agreement, held void or unenforceable, and
							for all Excluded Disputes, the parties consent to exclusive jurisdiction
							and venue in the state or federal courts seated in, respectively, either
							Salt Lake County, Utah or the District of Utah to resolve any Dispute. Any
							legal or arbitration proceeding will be in the English language.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.5.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Injunctive Relief</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;Each party acknowledges that any breach, threatened or actual, of
							the confidentiality and intellectual property obligations hereunder may
							cause irreparable injury to the other party for which there may not be an
							adequate remedy at law. Therefore, upon any such breach or threat thereof,
							the party alleging breach will be entitled to seek injunctive and other
							appropriate equitable relief in addition to any other remedies available
							to it, without the requirement of posting a bond.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.6.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Notices.</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							&nbsp;You agree that ARRsuite may provide you with notices by email,
							regular mail, or postings on the Services. You may provide notice to
							ARRsuite by emailing us.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.7.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Assignment</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							.&nbsp;Neither this agreement, nor the rights or obligations hereunder may
							be assigned or transferred by either party without the other party’s prior
							written consent, which will not be unreasonably withheld or delayed.
							Notwithstanding the foregoing, either party may assign this agreement
							(including all Orders) without the consent of the other party to an
							affiliate or a successor in connection with a merger, acquisition,
							corporate reorganization, or sale of all or substantially all of its
							assets not involving a direct competitor of the non-assigning party,
							provided that all fees owed and due by you have been paid.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.8.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Entire Agreement</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. These Terms, any exhibits, schedules, or documents referred to herein,
							constitute the entire agreement between you and ARRsuite related to the
							use of the Services and supersede all prior or contemporaneous
							representations, agreements or understandings (written or verbal) relating
							to the subject matter hereof, including any non-disclosure agreements
							previously entered into by you and ARRsuite. If you require purchase
							orders, vendor registration forms, payment or vendor portals, or the like,
							they will be for convenience only, and all associated terms and conditions
							(pre-printed or otherwise and regardless of how or when referenced or
							acknowledged) will be void and of no effect.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.9.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Translation</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. Where ARRsuite has provided you with a translation of the English
							language version of the Terms, you agree that the translation is provided
							for your convenience only and that the English language version of the
							Terms will govern your relationship with ARRsuite. If there is any
							contradiction between the English language version of the Terms and a
							translation, the English language version will take precedence.
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.10.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Interpretation</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							. References to “include(s)”, “including” or similar terms will not be
							read as terms of limitation, but rather as followed by the words “without
							limitation.”
						</span>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>20.11.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>Severability</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>.</span>
						<strong>
							<span style={{ fontSize: "13.5pt" }}>&nbsp;</span>
						</strong>
						<span style={{ fontSize: "13.5pt" }}>
							If any provision of these Terms is found to be invalid or unenforceable,
							the remaining provisions will remain in full force and effect and the
							invalid or unenforceable provision will be deemed modified so that it is
							valid and enforceable to the maximum extent permitted by law.
						</span>
					</p>
					<p>
						<strong>
							<span style={{ fontSize: "18pt" }}>21. U.S. Government End Users</span>
						</strong>
					</p>
					<p>
						<span style={{ fontSize: "13.5pt" }}>21.1.&nbsp;</span>
						<u>
							<span style={{ fontSize: "13.5pt" }}>
								The following terms apply to federal government (“
							</span>
						</u>
						<strong>
							<u>
								<span style={{ fontSize: "13.5pt" }}>Government</span>
							</u>
						</strong>
						<u>
							<span style={{ fontSize: "13.5pt" }}>”) Users</span>
						</u>
						<span style={{ fontSize: "13.5pt" }}>
							: The Services include computer software, which is a “commercial product”
							as defined at 48 C.F.R. § 2.101, and also include “commercial services” as
							defined in 48 C.F.R. § 2.101. &nbsp;The Services also consist of
							“commercial computer software” and “commercial computer software
							documentation,” as those terms are used in 48 C.F.R. § 2.101 and 48 C.F.R.
							§ 12.212. Consistent with 48 C.F.R. § 12.212 and 48 C.F.R. § 227.7202-1
							through § 227.7202-4, You acquire the Services with only those rights, and
							subject to the obligations, set forth herein to the extent not
							inconsistent with federal procurement law in accordance with 48 C.F.R. §
							552.212-4(w)). In the event of a dispute with the Government in connection
							with this Agreement, the rights and duties of the parties shall be
							governed in accordance with federal procurement law, and such disputes
							shall be resolved pursuant to the Contract Disputes Act of 1978, as
							amended (41 U.S.C. §§ 7101-7109), as implemented by 48 C.F.R. §
							52.233-1.&nbsp;
						</span>
					</p>

					<br />

				</>



			</div>



		</React.Fragment>
	)
}

export default TermsAndConditionsText