import React, { useEffect, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useNavigate } from 'react-router-dom';

interface IncomingProps {
    filterDetails: any;
    doFilter: boolean;
    setDoFilter: any;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Bar Chart',
        // },
    },
};


const ReasonChartContainer = ({ filterDetails, doFilter, setDoFilter }: IncomingProps) => {

    const platformApi = usePlatformApi();

    const { showLoader, hideLoader } = useAppLoader()
    const [graphLabel, setGraphLabel] = useState<any>([])
    const [graphLabelVal, setGraphLabelVal] = useState<any>([])
    const [graphLabelVal2, setGraphLabelVal2] = useState<any>([])
    const navigate = useNavigate();

    const data = {
        labels: graphLabel,
        datasets: [
            {
                label: 'Count',
                data: graphLabelVal,
                backgroundColor: 'rgba(6, 79, 96, 1)',
            },
            {
                label: 'Actual',
                data: graphLabelVal2,
                backgroundColor: 'rgba(0, 162, 207, 1)',
            },
        ],
    };

    const getRevenueMapDetails = () => {

        let tempArr1: any = [];
        let tempArr2: any = [];
        let tempArr3: any = [];
        showLoader();
        platformApi.getAllContractChurnRreason(filterDetails,
            (message: string, resp: any) => {
                hideLoader();
                setDoFilter(false)
                var tempData = resp?.data?.list;
                tempData.map((item: any) => {
                    tempArr1.push(item?.risklevel)
                    tempArr2.push(item?.count)
                    tempArr3.push(item?.actual)
                })
                setGraphLabel(tempArr1)
                setGraphLabelVal(tempArr2)
                setGraphLabelVal2(tempArr3)

            }, (message: string) => {
                hideLoader();
                setDoFilter(false)
            }
        )

    }

    const chartRef = useRef<any>();

    const onClick = (event: any) => {
        // console.log(getElementAtEvent(chartRef.current, event));
        // console.log('event--------', event);
        let pos = getElementAtEvent(chartRef.current, event)[0]?.index

        let churnreasonArr = ["Relationship", "Financial", "Timing", "Product", "Competition", "Usage", "Other", "N/A"]
        let churnreasonVal = churnreasonArr[pos]

        let quarterVal = filterDetails?.quarter != null ? filterDetails?.quarter : 'FY';
        let yearVal = filterDetails?.year;

        let returnLink = `/contract-page?year=${yearVal}&quarter=${quarterVal}&type=churngraph&churnreason=${churnreasonVal}`
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            if (filterDetails?.rep) {
                returnLink += `&rep=${filterDetails?.rep}`
            }
            if (filterDetails?.region) {
                returnLink += `&region=${filterDetails?.region}`
            }
        }
        // console.log('month', yearVal, quarterVal, pos, churnreasonVal, returnLink)
        if (pos && churnreasonVal) {
            navigate(returnLink)
        }
    }


    useEffect(() => {
        getRevenueMapDetails()
    }, [])

    useEffect(() => {
        if (doFilter)
            getRevenueMapDetails()
    }, [doFilter])

    return (
        <React.Fragment>
            {/* {
                doFilter && */}
            <Bar
                ref={chartRef}
                options={options}
                data={data}
                onClick={onClick}
            // height={260}
            />
            {/* } */}
        </React.Fragment>
    )
}

export default ReasonChartContainer