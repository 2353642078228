import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import AddUserModal from '../components/modals/addUser.modal'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { AiOutlineEdit } from 'react-icons/ai'
import { TiTickOutline } from 'react-icons/ti'
import { RiLockPasswordLine } from 'react-icons/ri'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import EditArrUserPassword from '../components/modals/editArrUserPassword.modal'
import UserDetailsModal from '../components/modals/UserDetails.modal'


const ManageUsers = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [arrUserData, setArrUserData] = useState<any>(null)
    const [arrPageData, setArrPageData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [showEditArrUserPasswordModal, setShowEditArrUserPasswordModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [arrUserId, setArrUserId] = useState<any>(null)
    const [showUserDetailsModal, setShowUserDetailsModal] = useState<boolean>(false)
    // const [selectedLead, setSelectedLead] = useState<any>()

    const getArrUserData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllArrUsers({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setArrUserData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const getArrPageList = () => {
        platformApi.getArrPageList({},
            (message: string, resp: any) => {
                console.log(resp);
                setArrPageData(resp.data);
            }, (message: string) => {
            })
    }

    const closeAddUserModal = () => {
        setShowAddUserModal(false)
        setIsEdit(false)
        setArrUserId(null)
    }

    const closeEditArrUserPasswordModal = () => {
        setShowEditArrUserPasswordModal(false)
        setArrUserId(null)
    }

    const EditPasswordBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowEditArrUserPasswordModal(true)
            setArrUserId(value)
        }
        return (
            // <Link className="btn btn-link" to={`#`} onClick={handleClick} > Edit</Link >
            <RiLockPasswordLine
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit Password"
            />
        )
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            console.log(value)
            setShowAddUserModal(true)
            setArrUserId(value)
            setIsEdit(true)
        }
        return (
            // <Link className="btn btn-link" to={`#`} onClick={handleClick} > Edit</Link >
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteArrUser = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.arrUserDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const callbackFnAfterDelete = () => {
        getArrUserData();
    }

    const columns = useMemo(
        () => {
            const filteredData: any[] = arrPageData ? Object.keys(arrPageData)
                .filter(key => arrPageData[key].status === 1)
                .map(key => ({
                    Header: arrPageData[key].name,
                    accessor: arrPageData[key].key,
                    Cell: ({ value }: any) => (
                        <div className={value == "1" ? "d-flex justify-content-center" : ""}>
                            {value == '1' ? <TiTickOutline color='green' size={24} /> : ""}
                        </div>
                    ),
                }))
                : [];
            return [
                {
                    Header: 'Actions',
                    accessor: "id",
                    Cell: ({ column, value, row }: any) =>
                        <>
                            {
                                (row.original.iscompanyadmin != 1) &&
                                <DeleteButtonCell
                                    column={column}
                                    value={value}
                                    deleteFunc={deleteArrUser}
                                    callbackFunc={callbackFnAfterDelete}
                                    type={"User"}
                                />
                            }

                            <EditBtn column={column} value={value} />
                            <EditPasswordBtn column={column} value={value} />
                        </>,
                },
                // {
                //     Header: "User Name",
                //     accessor: "username",
                // },
                {
                    Header: "Full Name",
                    accessor: "name",
                    Cell: ({ column, value, row }: any) =>
                        <>
                            <span className='text-primary c-pointer' onClick={() => {
                                setShowUserDetailsModal(true)
                                setArrUserId(row.original.id)
                            }}>{value}</span>
                        </>,
                },
                {
                    Header: "Designation",
                    accessor: "designation.name",
                },
                {
                    Header: "Region",
                    accessor: "arrregion.region_name",
                },
                {
                    Header: "Email",
                    accessor: "email",
                },
                {
                    Header: "Time Zone",
                    accessor: "timezone",
                },
                ...filteredData,
            ]
        },
        [data, arrPageData]
    )

    useEffect(() => {
        getArrUserData()
        getArrPageList()
    }, [])

    const closeUserDetailsModal = () => {
        setShowUserDetailsModal(false)
        setArrUserId(null)
    }

    return (
        <React.Fragment>
            <AddUserModal
                show={showAddUserModal}
                close={closeAddUserModal}
                arrUserId={arrUserId}
                isEdit={isEdit}
                arrPageData={arrPageData}
                callbackFuncs={[getArrUserData]}
                showPermission={true}
            />
            <EditArrUserPassword
                show={showEditArrUserPasswordModal}
                close={closeEditArrUserPasswordModal}
                id={arrUserId}
                userListCallbackFunc={getArrUserData}
            />
            {
                showUserDetailsModal && <UserDetailsModal
                    show={showUserDetailsModal}
                    close={closeUserDetailsModal}
                    arrUserId={arrUserId}
                />
            }
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Users</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Users</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row d-none">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">ARR / Forecasted</p>
                                                            <h5 className="mb-2">0</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-book-bookmark" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="dash-para-fix">
                                                        <li className="mb-1">
                                                            NRR: <strong>0</strong> ORR: <strong>0</strong>
                                                        </li>
                                                        <li>
                                                            Best: <strong>0</strong> Most Likely: <strong>0</strong>
                                                            <br />
                                                            Worst: <strong>0</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Expansion / Forecast</p>
                                                            <h5 className="mb-0">0</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-note" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Renewed</p>
                                                            <h5 className="mb-0">0</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-message-square-dots" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Churned / Forecasted</p>
                                                            <h5 className="mb-0">0</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-message-square-dots" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end row */}
                                </div>
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Users</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowAddUserModal(true)}
                                        >
                                            Add User
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getArrUserData}
                                                    loading={loading}
                                                    rawData={arrUserData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default ManageUsers