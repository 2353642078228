import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import { configTypeData, subscriptionPricingData, subscriptionTypeData } from '../_common/staticData'
import { AiOutlinePlusSquare } from 'react-icons/ai'
import AddProductFamilyModal from '../components/modals/addProductFamily.modal'

interface AddProductFormValues {
    productName: string;
    productCode: string;
    productDesc: string;
    familyid: any;
    // configCodePtrn: string;
    // configType: any;
    // subscriptionType: any;
    // subscriptionPricing: any;
    // subscriptionTerm: string;
    // active: string;
}

const validationSchema = Yup.object().shape({
    productName: Yup.string().required("Product Name is required")
});


const AddProduct = () => {

    const platformApi = usePlatformApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const id: any = searchParams.get("id")

    const { showLoader, hideLoader } = useAppLoader()
    const [productData, setProductData] = useState<any>(null)
    const [productFamilyOptions, setProductFamilyOptions] = useState<any>([])
    const [showAddProductFamilyModal, setShowAddProductFamily] = useState<boolean>(false);

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddProductFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            productName: "",
            productCode: "",
            productDesc: "",
            familyid: "",
            // configCodePtrn: "",
            // configType: "",
            // subscriptionType: "",
            // subscriptionPricing: "",
            // subscriptionTerm: "",
            // active: ""
        },
    });

    const getProductData = () => {
        showLoader();
        platformApi.getAllProduct({ ProductId: id },
            (message: string, resp: any) => {
                // console.log(resp);
                setProductData(resp.data.list.data[0]);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getProductFamilyData = (page = 1) => {
        showLoader();
        commonApi.getAllProductFamily({},
            (message: string, resp: any) => {
                const tempData = resp.data.list.data;
                if (tempData.length > 0) {
                    setProductFamilyOptions(tempData?.map((item: any) => {
                        item.label = item.name
                        item.value = item.id
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const onSubmit = (data: any) => {
        // console.log(data);
        let params: any = {
            "Description": data?.productDesc,
            "Name": data?.productName,
            "familyid": data?.familyid?.value,
            "ProductCode": data?.productCode,
            // "Status": data?.active,
            // "Type": data?.configType?.value,
            // "SubscriptionPricing": data?.subscriptionPricing?.value,
            // "SubscriptionTerm": data?.subscriptionTerm,
            // "SubscriptionType": data?.subscriptionType?.value
        }
        if (productData && productData?.id) {
            params["id"] = productData?.id
        }
        // console.log(params)
        showLoader()
        platformApi.manageProduct(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            reset()
            navigate(URLS.PRODUCT_LIST)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const closeAddProductFamilyModal = () => {
        setShowAddProductFamily(false)
    }

    useEffect(() => {
        if (id) {
            getProductData()
        }
    }, [id])

    useEffect(() => {
        getProductFamilyData()
    }, [])

    useEffect(() => {
        if (productData) {
            setValue('productName', productData?.Name)
            setValue('productCode', productData?.ProductCode)
            setValue('productDesc', productData?.Description)
            setValue('familyid', productFamilyOptions?.find((item: any) => item.value == productData?.familyid) || '')
            // setValue('configCodePtrn', '')
            // setValue('configType', configTypeData?.find((item: any) => item.value === productData?.Type) || '')
            // setValue('subscriptionType', subscriptionTypeData?.find((item: any) => item.value === productData?.SubscriptionType) || '')
            // setValue('subscriptionPricing', subscriptionPricingData?.find((item: any) => item.value === productData?.SubscriptionPricing) || '')
            // setValue('subscriptionTerm', productData?.SubscriptionTerm)
            // setValue('active', '')
        }
    }, [productData, productFamilyOptions])

    return (
        <React.Fragment>
            <AddProductFamilyModal
                show={showAddProductFamilyModal}
                close={closeAddProductFamilyModal}
                isEdit={false}
                callbackFunc={getProductFamilyData}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Product</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.PRODUCT_LIST}>Product List</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Add Product</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Product Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="productName">Product name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"productName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.productName ? ' border-danger' : '')}
                                                                        id="productName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter product name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.productName &&
                                                            <span className='text-danger'>{errors?.productName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="familyid">Product Family</label>
                                                        <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                            <Controller
                                                                name={"familyid"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <Select
                                                                            className={"select-box " + (errors?.familyid ? ' border-danger' : '')}
                                                                            options={productFamilyOptions ? productFamilyOptions : []}
                                                                            placeholder={"Select product family"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            <span
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={"Add Product Family"}
                                                                onClick={() => setShowAddProductFamily(true)}
                                                            >
                                                                <AiOutlinePlusSquare className='cursor-pointer' />
                                                            </span>
                                                        </div>
                                                        {errors && errors.familyid &&
                                                            <span className='text-danger'>Product family is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="productCode">Product Code</label>
                                                        <Controller
                                                            name={"productCode"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.productCode ? ' border-danger' : '')}
                                                                        id="productCode"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter product code"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.productCode &&
                                                            <span className='text-danger'>{errors?.productCode?.message}</span>
                                                        }
                                                    </div>
                                                    {/* <div className="align-items-center col-6 d-flex form-group gap-10">
                                                        <label htmlFor="active" className='mb-0'>Active</label>
                                                        <Controller
                                                            name={"active"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        className={(errors?.active ? ' border-danger' : '')}
                                                                        id="active"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter active"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.active &&
                                                            <span className='text-danger'>{errors?.active?.message}</span>
                                                        }
                                                    </div> */}
                                                    {/* <div className="form-group col-6">
                                                        <label htmlFor="configCodePtrn">Configure Code Pattern</label>
                                                        <Controller
                                                            name={"configCodePtrn"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.configCodePtrn ? ' border-danger' : '')}
                                                                        id="configCodePtrn"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter configured code pattern"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.configCodePtrn &&
                                                            <span className='text-danger'>{errors?.configCodePtrn?.message}</span>
                                                        }
                                                    </div> */}
                                                    {/* <div className="form-group col-6">
                                                        <label htmlFor="configType">Configuration Type</label>
                                                        <Controller
                                                            name={"configType"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.configType ? ' border-danger' : '')}
                                                                        options={configTypeOptions ? configTypeOptions : []}
                                                                        placeholder={"Select configuration type"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.configType &&
                                                            <span className='text-danger'>Configuration type is required</span>
                                                        }
                                                    </div> */}
                                                    {/* <div className="form-group col-6">
                                                        <label htmlFor="subscriptionType">Subscription Type</label>
                                                        <Controller
                                                            name={"subscriptionType"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.subscriptionType ? ' border-danger' : '')}
                                                                        options={subscriptionTypeOptions ? subscriptionTypeOptions : []}
                                                                        placeholder={"Select product family"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.subscriptionType &&
                                                            <span className='text-danger'>Subscription type is required</span>
                                                        }
                                                    </div> */}
                                                    {/* <div className="form-group col-6">
                                                        <label htmlFor="subscriptionPricing">Subscription Pricing</label>
                                                        <Controller
                                                            name={"subscriptionPricing"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.subscriptionPricing ? ' border-danger' : '')}
                                                                        options={subscriptionPricingOptions ? subscriptionPricingOptions : []}
                                                                        placeholder={"Select subscription pricing"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.subscriptionPricing &&
                                                            <span className='text-danger'>Subscription pricing is required</span>
                                                        }
                                                    </div> */}
                                                    {/* <div className="form-group col-6">
                                                        <label htmlFor="subscriptionTerm">Subscription Term</label>
                                                        <Controller
                                                            name={"subscriptionTerm"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.subscriptionTerm ? ' border-danger' : '')}
                                                                        id="subscriptionTerm"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter subscription term"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.subscriptionTerm &&
                                                            <span className='text-danger'>{errors?.subscriptionTerm?.message}</span>
                                                        }
                                                    </div> */}
                                                    <div className="form-group col-6">
                                                        <label htmlFor="productDesc">Product Description</label>
                                                        <Controller
                                                            name={"productDesc"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <textarea
                                                                        // type="text"
                                                                        className={"form-control " + (errors?.productDesc ? ' border-danger' : '')}
                                                                        id="productDesc"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter product description"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.productDesc &&
                                                            <span className='text-danger'>{errors?.productDesc?.message}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                    <button type='submit' className='btn btn-primary'>Save</button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-danger'
                                                        onClick={() => navigate(URLS.PRODUCT_LIST)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddProduct