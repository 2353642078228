import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { STORAGE } from '../../_config';
const PowerBI = () => {
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [data, setData] = useState<any>(null)


    const getPowerBIToken = () => {
        showLoader()
        platformApi.getPowerBIToken({
        }, (message: string, resp: any) => {

    let reportid=resp.data.details.report_id;
    let token_str = localStorage.getItem(STORAGE);
    let user_id=null;
    if (token_str) {
        user_id=JSON.parse(token_str).adminuser.id;
    }
    const accessToken = resp.data.details.token ;
               const embedUrl = 'https://app.powerbi.com/reportEmbed?reportId='+reportid+'&filter=users/id eq '+user_id;
     
                const embedReportId = reportid;
                const tokenType = '1';
                const permissions = (window as any)['powerbi-client'].models.Permissions.All;
                const config = {
                    type: 'report',
                    tokenType:  (window as any)['powerbi-client'].models.TokenType.Embed,
                    accessToken: accessToken,
                    embedUrl: embedUrl,
                    id: embedReportId,
                    permissions: permissions,
                  //  viewMode:  window['powerbi-client'].models.ViewMode.Edit,
                    settings: {
                        panes: {
                            filters: {
                                visible: false
                            },
                            pageNavigation: {
                                visible: false
                            }
                        }
                    }
                };
    
      var embedContainer = document.getElementById('embedContainer');
     
     var report = (window as any).powerbi.embed(embedContainer, config);

         

            hideLoader()
        }, (message: string) => {
            hideLoader()
        })
    }

    useEffect(() => {
        getPowerBIToken();
     
    }, [])

    return (
        <React.Fragment>
            <div className="iframe-container">
            <div id="embedContainer" ></div>
                 </div>


        </React.Fragment>
    )
}

export default PowerBI