import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';

interface IncomingProps {
	show: boolean;
	close: () => void;
	arrUserId?: any;
}


const UserDetailsModal = ({ show, close, arrUserId }: IncomingProps) => {
	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [userData, setArrUserData] = useState<any>(null)

	const getArrUserData = () => {
		showLoader();
		platformApi.getAllArrUsers({ id: arrUserId },
			(message: string, resp: any) => {
				setArrUserData(resp.data.list);
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	useEffect(() => {
		getArrUserData()
	}, [arrUserId])

	return (
		<React.Fragment>
			<Modal className='salesforce-modal modal-lndscp' show={show}  >
				<div className="modal-header custom-modal-header">
					<h5>User Details</h5>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<div className="row">
										<table className='table table-responsive'>
											<tbody> 
												<tr>
													<th>Name:</th>
													<td>{userData?.name}</td>
													
													<th className='border-left'>User Name:</th>
													<td>{userData?.username}</td>
												</tr>
												<tr>
													<th>Licence Type:</th>
													<td>{userData?.licencetype}</td>
													
													<th className='border-left'>Email:</th>
													<td>{userData?.email}</td>
												</tr>
												<tr>
													<th>Time Zone:</th>
													<td>{userData?.timezone}</td>
													
													<th className='border-left'>Region:</th>
													<td>{userData?.arrregion?.region_name}</td>
												</tr>
												<tr>
													<th>Designation:</th>
													<td colSpan={3}>{userData?.designation?.name}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className='align-items-center d-flex gap-10 justify-content-center'>
								<button type='button' className='btn btn-outline-danger' onClick={() => { close(); }} >
									Close
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default UserDetailsModal