import React, { useEffect, useRef, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserDetailsSelector } from '../../_common/hooks/selectors/userSelector';
import Select from 'react-select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { contractFilterStatusData, monthQuarterOptions, openCloseFilterData, quoteStatusData, yearOptionsStatic } from '../../_common/staticData';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getSeletedValue } from '../../_common/functions';

interface IncomingProps {
    type: string;
    filterDetails: any;
    setFilterDetails: any;
    setDoFilter?: any;
    callbackFunctions?: Function;
    defaultYear?: boolean;
}

const FilterRow = ({ type, setDoFilter, filterDetails, setFilterDetails, callbackFunctions, defaultYear = false }: IncomingProps) => {
    // console.log('filterDetails---', filterDetails)
    const platformApi = usePlatformApi();
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const userDetails: any = useUserDetailsSelector();
    const location = useLocation();
    const isInitialRender = useRef(true);

    const [arrUserOption, setArruserOption] = useState<any>(null)
    const [regionOption, setRegionOption] = useState<any>(null)
    const [yearOptions, setYearOptions] = useState<any>(yearOptionsStatic)
    const [openCloseOptions, setOpenCloseOptions] = useState<any>([])
    const [selectedRepValue, setSelectedRepValue] = useState<any | []>([])
    const [selectedRegionValue, setSelectedRegionValue] = useState<any | []>([])
    const [searchParams] = useSearchParams();
    const urlYear: any = searchParams.get("year")
    const urlQuarter: any = searchParams.get("quarter")
    const urlStatus: any = searchParams.get("Status")
    const urlRegion: any = searchParams.get("region")
    const [urlRegionArr, setUrlRegionArr] = useState<any>((urlRegion != 'undefined' && urlRegion != null) ? urlRegion.split(',') : [])
    const [urlRegionArr2, setUrlRegionArr2] = useState<any>([])
    const urlAccUser: any = searchParams.get("rep")
    const [urlAccUserArr, setUrlAccUserArr] = useState<any>((urlAccUser != 'undefined' && urlAccUser != null) ? urlAccUser.split(',') : [])
    const [urlAccUserArr2, setUrlAccUserArr2] = useState<any>([])
    const [status, setStatus] = useState<any>(null)
    const [selectedYear, setSelectedYear] = useState<any>((urlYear > 0) ? urlYear : new Date().getFullYear())

    const getArrUserData = () => {
        showLoader();
        platformApi.getAllArrUsers({},
            (message: string, resp: any) => {
                const tempList = resp.data.list.data;
                if (tempList.length > 0) {
                    setArruserOption(tempList.map((item: any) => {
                        item.label = item.name
                        item.value = item.id
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                // setLoading(false);
                hideLoader();
            })
    }

    const getRegionsData = () => {
        showLoader();
        commonApi.getAllRegions({},
            (message: string, resp: any) => {
                const tempList = resp.data.list.data;
                if (tempList.length > 0) {
                    setRegionOption(tempList.map((item: any) => {
                        item.label = item.region_name
                        item.value = item.Region
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const handleFilterChange = (key: any, data: any, changeType?: string) => {
        console.log('handleFilterChange====')
        let params: any = filterDetails;
        if (key == 'rep' || key == 'region') {
            let tempData = "";
            if (typeof data == 'string' || data instanceof String) {
                params[key] = data
            } else {
                data?.forEach((item: any, idx: number) => tempData += (key == 'rep' ? item.id : item.Region) + (data?.length - 1 != idx ? "," : ""))
                params[key] = tempData
            }
        } else {
            params[key] = data;
        }
        setFilterDetails(params);
        setDoFilter(true)
        if (callbackFunctions) {
            callbackFunctions()
        }
    }

    const handleSelectedValue = (key: any, data: any) => {
        if (key == 'rep') {
            setSelectedRepValue(data)
        } else {
            setSelectedRegionValue(data)
        }
    }

    const onRepMenuClose = () => {
        handleFilterChange('rep', selectedRepValue, 'on_change')
    }

    const onRegionMenuClose = () => {
        handleFilterChange('region', selectedRegionValue, 'on_change')
    }

    const renderOption = ({ innerProps, label, isSelected }: any) => {
        return (
            <div>
                <div {...innerProps}
                    className=''
                >
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => { }}
                        className='ml-2 mr-2'
                    />
                    <label>{label}</label>
                </div>
            </div>
        );
    }

    useEffect(() => {
        getArrUserData()
        getRegionsData()
    }, [])

    useEffect(() => {
        if (openCloseOptions.length > 0) {
            setStatus(getSeletedValue(urlStatus, 'value', openCloseOptions))
        }
    }, [openCloseOptions])

    useEffect(() => {
        if (type && type == 'quoting-management') {
            setOpenCloseOptions(quoteStatusData)
        } else if (type && ['account-list', 'contract-page', 'revenue', 'upside', 'forecast'].includes(type)) {
            setOpenCloseOptions(contractFilterStatusData)
        } else {
            setOpenCloseOptions(openCloseFilterData)
        }
    }, [type])

    useEffect(() => {
        let params: any = filterDetails;

        if (yearOptions && yearOptions.length > 0) {
            params['year'] = selectedYear
        }
        if (urlQuarter != null) {
            params['quarter'] = urlQuarter
        }
        if (urlStatus != null) {
            params['Status'] = urlStatus
            setStatus(getSeletedValue(urlStatus, 'value', openCloseOptions))
        }
        setFilterDetails(params);

        console.log('yearOptions && quarter')

    }, [yearOptions, urlQuarter, urlStatus])

    useEffect(() => {
        let params: any = filterDetails;

        if (urlAccUserArr?.length && arrUserOption?.length) {
            console.log('urlAccUser-----------------')
            params['rep'] = urlAccUser

            let arr: any = []
            urlAccUserArr.map((id: any) => {
                arr.push(getSeletedValue(id, 'value', arrUserOption))
            })
            setUrlAccUserArr2(arr)
        }

        if (urlRegionArr?.length && regionOption?.length) {
            console.log('urlRegionArr-----------------')
            params['region'] = urlRegion

            let arr: any = []
            urlRegionArr.map((id: any) => {
                arr.push(getSeletedValue(id, 'value', regionOption))
            })
            setUrlRegionArr2(arr)
        }

        setFilterDetails(params);

    }, [urlAccUser, arrUserOption, urlRegionArr, regionOption])

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else if (location.search == '') {
            setFilterDetails({year: new Date().getFullYear()});
            setDoFilter(true)
            if (callbackFunctions) {
                callbackFunctions()
            }
        } else {
            handleFilterChange('', null)
        }
        // console.log('location', location)
    }, [location.search])


    return (
        <div className="row">
            {
                userDetails && userDetails?.userType == "primary" ?
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="formrow-inputOwner">
                                {type && ['revenue', 'upside', 'churn-forecast', 'forecast', 'contract-page'].includes(type) ? 'Contract Manager' : 'Account Owner'}
                            </label>
                            <Select
                                className="select-box"
                                options={arrUserOption ? arrUserOption : []}
                                placeholder={"Select..."}
                                onChange={(e: any) => { handleSelectedValue("rep", e); setUrlAccUserArr2(e) }}
                                isClearable={true}
                                isMulti={true}
                                components={{ Option: renderOption }}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onMenuClose={onRepMenuClose}
                                value={urlAccUserArr2}
                            />
                        </div>
                    </div>
                    : null
            }
            <div className="col">
                <div className="form-group">
                    <label htmlFor="formrow-inputRegion">Region</label>
                    <Select
                        className="select-box"
                        options={regionOption ? regionOption : []}
                        placeholder={"Region"}
                        isClearable={true}
                        isMulti={true}
                        components={{ Option: renderOption }}
                        onChange={(e: any) => { handleSelectedValue("region", e); setUrlRegionArr2(e) }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onMenuClose={onRegionMenuClose}
                        value={urlRegionArr2}
                    />
                </div>
            </div>


            <div className="col-lg-5 col-12">
                <div className="form-group">
                    <label htmlFor="formrow-inputDate">
                        {type && type == 'forecast' ? 'Fiscal period' : 'Available To Renew Date'}
                    </label>
                    <div className="row">
                        <Select
                            className="select-box col-6"
                            options={yearOptions ? yearOptions : []}
                            placeholder={"Year"}
                            onChange={(e: any) => handleFilterChange("year", e?.value)}
                            isClearable={false}
                            // defaultValue={{ label: selectedYear, value: selectedYear }}
                            defaultValue={getSeletedValue(selectedYear, 'value', yearOptions)}
                        />
                        <Select
                            className="select-box col-6"
                            options={monthQuarterOptions ? monthQuarterOptions : []}
                            placeholder={"Quarter / Month "}
                            onChange={(e: any) => handleFilterChange("quarter", e?.value)}
                            isClearable={true}
                            defaultValue={getSeletedValue(urlQuarter, 'value', monthQuarterOptions)}
                        />
                    </div>
                </div>
            </div>

            {
                (type != 'churn-forecast') &&

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="formrow-inputRegion">Status</label>
                        <Select
                            className="select-box"
                            options={openCloseOptions}
                            placeholder={'Select...'}
                            onChange={(e: any) => { handleFilterChange("Status", e?.value); setStatus(e) }}
                            isClearable={true}
                            value={status}
                        // value={(openCloseOptions && openCloseOptions.length > 0) && getSeletedValue(urlStatus, 'value', openCloseOptions)}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default FilterRow