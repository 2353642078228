import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form'; 
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'; 
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';

interface FormValues {
	field: any
}

const validationSchema = Yup.object().shape({
	field: Yup.string().required("Tax is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	fieldDetails: any;
	callEditQuoteMultiyearApi: Function;
	callEditQuoteLineitemMultiyearApi: Function;
}


const EditFieldModal = ({ show, close, fieldDetails, callEditQuoteMultiyearApi, callEditQuoteLineitemMultiyearApi }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			field: ""
		},
	});

	const onSubmit = (data: any) => {
		console.log({ data })
		if (fieldDetails?.type == 'quote') {
			callEditQuoteMultiyearApi(data)
		} else if (fieldDetails?.type == 'lineitem') {
			callEditQuoteLineitemMultiyearApi(data)
		}
	}

	useEffect(() => {
		if (fieldDetails?.value) {
			console.log({ fieldDetails })
			setValue('field', fieldDetails?.value)
		}
	}, [fieldDetails])

	var placeHolderText = fieldDetails?.field;
	if (fieldDetails?.field == 'ShippingCharge') {
		placeHolderText = 'Shipping and Handling Charge'
	}

	
	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}  >
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="tax">Edit {fieldDetails?.label}
													{(fieldDetails?.field == "ShippingCharge" || fieldDetails?.label == "Sales Price") ? " ($)" : (fieldDetails?.field == "Quantity" ? "" : " (%)")}
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"field"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.field ? ' border-danger' : '')}
																id="field"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={(e) => { onBlur(); handleBlur(e) }}
																onFocus={(e) => handleFocus(e)}
																onKeyDown={(event: any) => { fieldDetails?.field == "Quantity" ? handleKeyPressNumber(event) : handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																step={"0.01"}
																ref={ref}
																placeholder={"Enter " + placeHolderText}
															/>
														</>
													)}
												/>
												{errors && errors.field &&
													<span className='text-danger'>Please enter the {fieldDetails?.label} value</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default EditFieldModal