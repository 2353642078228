import React from 'react'
import Modal from 'react-bootstrap/Modal'
import LateRenewalsList from '../dataListing/LateRenewalsList';
import PossibleLateRenewalsList from '../dataListing/PossibleLateRenewalsList';

interface IncomingProps {
    show: boolean;
    close: () => void;
    filterDetails: any;
    doFilter: boolean;
    setDoFilter: any;
    connectPage?: any,
}

const GeneralTableListingModal = ({ show, close, filterDetails, doFilter, setDoFilter, connectPage }: IncomingProps) => {
    return (
        <React.Fragment>
            <Modal
                className='salesforce-modal modal-lndscp'
                show={show}
            // onHide={close}
            >
                <div className="modal-header custom-modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    <h4>{connectPage}</h4>
                    {show &&
                        (connectPage == 'Possible Late Renewals List') ?
                        <PossibleLateRenewalsList
                            filterDetails={filterDetails}
                            doFilter={doFilter}
                            setDoFilter={setDoFilter}
                        />
                        :
                        <LateRenewalsList
                            filterDetails={filterDetails}
                            doFilter={doFilter}
                            setDoFilter={setDoFilter}
                        />
                         
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default GeneralTableListingModal