import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';

interface FormValues {
	arruserid: any
}

const validationSchema = Yup.object().shape({
	arruserid: Yup.object().required("User is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
	type?: any;
}


const AddAccountTeamModal = ({ show, close, AccountId, type }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [arrUserOptions, setArrUserOptions] = useState<any>([])

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			arruserid: ""
		},
	});

	const getArrUserData = () => {
		showLoader();
		platformApi.getAllArrUsers({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setArrUserOptions(tempArr.map((item: any) => {
						item.label = item.name;
						item.value = item.arr_user_id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const customOptionUI = (props: any) => {
		const { innerProps, innerRef, data } = props;
		return (
			<div ref={innerRef} {...innerProps} className="cstm-option m-2 cursor-pointer">
				{data?.name}
				<span className='ml-2'>({data?.licencetype})</span>
			</div>
		);
	}

	const onSubmit = (data: any) => {
		// console.log({ data })

		// console.log(params)
		showLoader()
		console.log('types', type)
		if (type && type == 'contract') {
			let params: any = {
				"ContractId": AccountId,
				"arruserid": data.arruserid?.value
			}
			platformApi.manageContractAccountTeam(params, (message: string, resp: any) => {
				toast.success(message)
				reset()
				close()
				hideLoader()
			}, (message: string) => {
				toast.error(message)
				hideLoader()
			})
		}
		else if (type && type == 'contract_customer') {
			let params: any = {
				"ContractId": AccountId,
				"arruserid": data.arruserid?.value,
				"ContactId": "",
			}
			platformApi.manageCustomerContact(params, (message: string, resp: any) => {
				// console.log(message, resp)
				toast.success(message)
				reset()
				close()
				hideLoader()
			}, (message: string) => {
				// console.log(message)
				toast.error(message)
				hideLoader()
			})
		}
		else {
			let params: any = {
				// "id": "",
				"AccountId": AccountId,
				"arruserid": data.arruserid?.value
			}
			platformApi.manageAccountTeam(params, (message: string, resp: any) => {
				// console.log(message, resp)
				toast.success(message)
				reset()
				close()
				hideLoader()
			}, (message: string) => {
				// console.log(message)
				toast.error(message)
				hideLoader()
			})
		}
	}

	useEffect(() => {
		if (show) {
			getArrUserData()
		}
	}, [show])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="arruserid">Select Account User <span className='text-danger'>*</span></label>
												<Controller
													name={"arruserid"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<Select
																className={"select-box" + (errors?.arruserid ? ' border-danger' : '')}
																id="arruserid"
																components={{ Option: customOptionUI }}
																options={arrUserOptions ? arrUserOptions : []}
																placeholder={""}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
												{errors && errors.arruserid &&
													<span className='text-danger'>Please select user</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddAccountTeamModal