import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserLeftPannelAccessSelector } from '../../_common/hooks/selectors/userSelector';

const LeftPanel = () => {

    const userLeftPannelAccess: any = useUserLeftPannelAccessSelector();

    const path: string = (window as any).location.pathname;
    const [showDropdown, setShowDropdown] = useState<any>("")

    const makeDropdownList = (pannelList: any) => {
        let dropdownPannelObj: any = {};
        if (pannelList && Object.entries(pannelList).length > 0) {
            Object.entries(pannelList)?.forEach(([key, value]: any) => {
                let tempObj: any = [];
                if (value && value?.children && Object.entries(value?.children).length > 0) {
                    Object.entries(value?.children)?.forEach(([subKey, subValue]: any) => {
                        tempObj.push(subValue?.url)
                    })
                }
                if (tempObj.length > 0) {
                    dropdownPannelObj[key] = tempObj;
                }
            })
        }
        return dropdownPannelObj
    }

    const dropDownList = useMemo(
        () => makeDropdownList(userLeftPannelAccess)
        , [userLeftPannelAccess])


    const handleClickDropdown = (dropDownname: string) => {
        let tempObject = showDropdown;
        if (tempObject != "") {
            if (tempObject == dropDownname) {
                tempObject = ""
            } else {
                tempObject = dropDownname
            }
        }
        else {
            tempObject = dropDownname
        }
        setShowDropdown(tempObject)
    }

    useEffect(() => {
        if (Object.entries(dropDownList) && Object.entries(dropDownList).length > 0) {
            Object.entries(dropDownList)?.forEach(([key, value]: any) => {
                if (value.includes(path)) {
                    handleClickDropdown(key);
                }
            })
        }
    }, [path])


    return (
        <div className="vertical-menu">
            <div data-simplebar="" className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {userLeftPannelAccess && Object.entries(userLeftPannelAccess).length > 0 &&
                            Object.entries(userLeftPannelAccess)?.map(([key, value]: any, index: number) => (
                                <React.Fragment key={Math.random()}>
                                    {value && !value.children ?
                                        <li>
                                            <Link to={value?.url} className={`waves-effect mm-active ${(path == value?.url) ? 'active-menu' : ''}`}>
                                                <i className={value?.icon ? value?.icon : ""} />
                                                <span key="t-layouts">{value?.name}</span>
                                            </Link>
                                        </li>
                                        :
                                        <li
                                            className={(showDropdown === key ? "mm-active" : "")}>
                                            <Link
                                                to="javascript:void(0)"
                                                className="has-arrow waves-effect"
                                                onClick={() => handleClickDropdown(key)}
                                                id={key}
                                            >
                                                <i className={value?.icon ? value?.icon : ""} />
                                                <span key="t-layouts">{value?.name}</span>
                                            </Link>
                                            <ul
                                                className={"sub-menu " + (showDropdown === key ? "mm-collapse mm-show" : "mm-collapse")}
                                            >
                                                {Object.entries(value?.children).length > 0 &&
                                                    Object.entries(value?.children)?.map(([subKey, subValue]: any, index: number) => (
                                                        <li key={Math.random()} >
                                                            <Link
                                                                to={subValue?.url}
                                                                key="t-user-grid"
                                                                className={`waves-effect mm-active ${(path == subValue?.url) ? 'active-menu' : ''}`}
                                                            >
                                                                {/* <i className={"bx bx-circle"} /> */}
                                                                {subValue?.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </li>
                                    }
                                </React.Fragment>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default LeftPanel