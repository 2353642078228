import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { formatNumber, showDate } from '../_common/functions'
import ViewButtonCell from '../components/actionsBtns/ViewButtonCell'
import EditButtonCell from '../components/actionsBtns/EditButtonCell'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'

const OpportunityPage = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const [searchParams, setSearchParams] = useSearchParams();
    const [opportunityData, setOpportunityData] = useState<any>(null)


    const getOpportunityData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = { page: page }
        platformApi.getAllOpportunity(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setOpportunityData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }


    const opportunityDestroy = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.opportunityDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }
    
    const callbackFnAfterDelete = () => {
        getOpportunityData();
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "OpportunityId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {/* {console.log({ column, value, row })} */}
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={opportunityDestroy}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Opportunity"}
                        />
                        <ViewButtonCell column={column} value={value} link={'opportunity-details'} />
                        {
                            (row?.original?.StageName != 'Close Won' && row?.original?.StageName != 'Close Lost') &&
                            <EditButtonCell column={column} value={value} link={`add-opportunity/${(row?.original?.AccountId != null) ? row?.original?.AccountId : ''}`} />

                        }
                    </>,
            },
            {
                Header: "Code",
                accessor: "id",
                Cell: ({ column, value, row }: any) =>
                    <>{row?.values?.OpportunityId}</>,
            },
            {
                Header: "Name",
                accessor: "Name",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {/* {console.log({ column, value, row })} */}
                        <Link to={`/opportunity-details/${row?.values?.OpportunityId}`} className='text-primary'>{value}</Link>
                    </>,
            },
            {
                Header: "Amount($)",
                accessor: "Amount",
                Cell: ({ column, value, row }: any) => (
                    <div className='text-right'>
                        {value ? formatNumber(value) : ''}&nbsp;&nbsp;
                    </div>
                )
            },
            {
                Header: "Stage name",
                accessor: "StageName",
            },
            {
                Header: "Region",
                accessor: (d: any) => {
                    return d?.regiondetails?.region_name ? d?.regiondetails?.region_name : '--'
                },
            },
            {
                Header: "Lead Source",
                accessor: "LeadSource",
                className: "nowrap",
            },
            {
                Header: "Contract Term",
                accessor: "termtype",
                Cell: ({ column, value, row }: any) => (
                    <div className=' '>
                        {row?.original?.contractterm + ' ' + value}
                    </div>
                )
            },
            {
                Header: "Close Date", accessor: (d: any) => {
                    return showDate(d.CloseDate, "MM-DD-YYYY")
                },
            },
        ],
        []
    )


    useEffect(() => {
        getOpportunityData()
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Manage Opportunity </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Manage Opportunity</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                    />
                                </div>
                            </div> */}
                           
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Opportunities</h5>
                                                <Link to={`/add-opportunity`} className="btn btn-success"><AiOutlinePlusCircle /> Add Opportunity</Link>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getOpportunityData}
                                                    loading={loading}
                                                    rawData={opportunityData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default OpportunityPage