import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';


interface FormValues {
	meeting_with: any,
	date: string,
	time: string,
	meeting_title: string,
	description: string
}
const validationSchema = Yup.object().shape({
	meeting_with: Yup.object().required("User is required"),
	date: Yup.string().nullable().required("Meeting date is required"),
	time: Yup.string().nullable().required("Meeting time is required"),
	meeting_title: Yup.string().nullable().required("Agenda is required"),
	description: Yup.string().nullable().required("Description is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
	type?: any;
}

const today = new Date();
var todayString = moment(today).local().format("YYYY-MM-DD");


const MeetingFormOnAccActivity = ({ show, close, AccountId, type }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();
	const [userListData, setUserListData] = useState<any>([]);
	const datePickerRef = useRef<any>()

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			meeting_with: "",
			date: "",
			time: "",
			meeting_title: "",
			description: "",
		},
	});

	const getUserListData = (page = 1) => {
		platformApi.getAllContact({ page: page, AccountId: AccountId, limit: 500 },
			(message: string, resp: any) => {
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setUserListData(tempArr.map((item: any) => {
						item.label = item.FirstName + " " + item.LastName;
						item.value = item.id;
						return item
					}));
				}
			}, (message: string) => {
				hideLoader();
			})
	}

	const customOptionUI = (props: any) => {
		const { innerProps, innerRef, data } = props;
		return (
			<div ref={innerRef} {...innerProps} className="cstm-option m-2 cursor-pointer">
				{data.FirstName + " " + data.LastName}
				{/* <span className='ml-2'>{data?.licencetype}</span> */}
			</div>
		);
	}

	useEffect(() => {
		getUserListData();
	}, []);

	const onSubmit = (data: any) => {
		showLoader()
		let params: any = {
			"AccountId": AccountId,
			"meeting_with": data.meeting_with?.value,
			"meeting_date": data?.date ? moment(data.date).format("MM/DD/YY") : "",
			"meeting_time": data?.time,
			"agenda": data?.meeting_title,
			"description": data?.description,
		}
		platformApi.addEditAccountViewMeeting(params, (message: string, resp: any) => {
			toast.success(message)
			reset()
			close()
			hideLoader()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}


	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}>
				<div className="modal-header custom-modal-header">
					Add Meeting Schedule
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { close(); reset() }} >
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="meeting_with">Meeting with <span className='text-danger'>*</span></label>
												<Controller
													name={"meeting_with"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<Select
															className={"select-box" + (errors?.meeting_with ? ' border-danger' : '')}
															id="meeting_with"
															components={{ Option: customOptionUI }}
															options={userListData ? userListData : []}
															placeholder={""}
															onChange={onChange}
															value={value}
															defaultValue={value}
														/>
													)}
												/>

												{errors && errors.meeting_with &&
													<span className='text-danger'>Please select user</span>
												}
											</div>


											<div className="form-group col-6">
												<label htmlFor="arruserid">Meeting Date <span className='text-danger'>*</span></label>
												<Controller
													name={"date"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<DatePicker
																selected={value ? new Date(value) : null}
																onChange={onChange}
																dateFormat="MM/dd/yyyy"
																className={"form-control " + (errors?.date ? ' border-danger' : '')}
																id="date"
																name={name}
																onBlur={onBlur}
																ref={datePickerRef}
																value={value}
																minDate={new Date()}
																placeholderText="Enter date"
																autoComplete='off'
															/>
														</>
													)}
												/>
												{errors && errors.date &&
													<span className='text-danger'>{errors?.date?.message}</span>
												}
											</div>

											<div className="form-group col-6">
												<label htmlFor="arruserid">Meeting Time <span className='text-danger'>*</span></label>
												<Controller
													name={"time"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="time"
																className={"form-control " + (errors?.time ? ' border-danger' : '')}
																id="time"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter time"
															/>
														</>
													)}
												/>
												{errors && errors.time &&
													<span className='text-danger'>{errors?.time?.message}</span>
												}
											</div>

											<div className="form-group col-12">
												<label htmlFor="arruserid">Meeting Agenda <span className='text-danger'>*</span></label>
												<Controller
													name={"meeting_title"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.meeting_title ? ' border-danger' : '')}
																id="meeting_title"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter meeting agenda"
															/>
														</>
													)}
												/>
												{errors && errors.meeting_title &&
													<span className='text-danger'>{errors?.meeting_title?.message}</span>
												}
											</div>

											<div className="form-group col-12">
												<label htmlFor="arruserid">  Description <span className='text-danger'>*</span></label>
												<Controller
													name={"description"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.description ? ' border-danger' : '')}
																id="description"
																name={name}
																onChange={onChange}
																onBlur={onBlur}
																placeholder="Enter description"
															/>
														</>
													)}
												/>

												{errors && errors.description &&
													<span className='text-danger'>{errors?.description?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button type='button' className='btn btn-outline-danger' onClick={() => { close(); reset() }} >
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default MeetingFormOnAccActivity