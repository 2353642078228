import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import TableContainer from '../../components/commonComponents/TableContainer';
import { URLS } from '../../_config';
import { AiOutlineEdit } from 'react-icons/ai'
import Footer from '../../components/commonComponents/Footer';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import AddTaxModal from '../../components/modals/addTax.modal';


const ManageTax = () => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [taxData, setTaxData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddTaxModal, setShowAddTaxModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [taxId, setTaxId] = useState<any>(null)

    const getTaxData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllTax({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setTaxData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddTaxModal = () => {
        setShowAddTaxModal(false)
        setIsEdit(false)
        setTaxId(null)
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowAddTaxModal(true)
            setTaxId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteTax = (id: any) => {
        // console.log(id)
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteTax({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const callbackFnAfterDelete = () => {
        getTaxData();
    }
    
    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteTax}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Tax"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Tax Name",
                accessor: "taxname",
            },
            {
                Header: "Tax (%)",
                accessor: "taxpercent",
            },
            {
                Header: "Tax for",
                accessor: "taxfor",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value && value == '1' ? "Active" : 'Inactive'}
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        getTaxData()
    }, [])

    return (
        <React.Fragment>
            <AddTaxModal
                show={showAddTaxModal}
                close={closeAddTaxModal}
                id={taxId}
                isEdit={isEdit}
                callbackFunc={getTaxData}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Tax</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Tax</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Tax</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowAddTaxModal(true)}
                                        >
                                            Add Tax
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getTaxData}
                                                    loading={loading}
                                                    rawData={taxData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default ManageTax