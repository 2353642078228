import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import Select from 'react-select';
import { operatorOptions, targetFieldOptions } from '../../_common/staticData';

interface FormValues {
	conditionname: string;
	conditionindex: string;
	fieldvalue: string;
	rules: any;
	targetfield: any;
	operator: any;
	fieldtype: any;
}

const validationSchema = Yup.object().shape({
	conditionname: Yup.string().required("Condition name is required"),
	conditionindex: Yup.string().required("Condition index is required"),
	operator: Yup.object().required("Operator is required"),
	fieldtype: Yup.object().required("Field type is required"),
	rules: Yup.object().required("Rules is required"),
	targetfield: Yup.object().required("Target field is required"),
	fieldvalue: Yup.number().required("Field value is required").when('targetfield',
		{
			is: (item: any) => item && item.value != 'Amount',
			then: Yup.number().max(100, 'Percentage can not be greater than 100'),
			otherwise: Yup.number(),
		}),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	approverRuleId: any;
	id?: any
	isEdit: boolean;
}

const AddApproverRuleConditionModal = ({ show, close, id, approverRuleId, isEdit = false }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [approverRuleConditionData, setApproverRuleConditionData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [approverRuleOptions, setApproverRuleOptions] = useState<any | []>([])
	const [fieldTypeOptions, setFieldTypeOptions] = useState<any | []>([{ label: "value", value: "value" }])

	const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			conditionname: "",
			conditionindex: "1",
			fieldvalue: "",
			rules: "",
			targetfield: "",
			operator: "",
			fieldtype: "",
		},
	});

	const selectedTargetField = watch('targetfield');

	const getApproverRuleConditionData = () => {
		showLoader();
		setLoading(true)
		commonApi.getAllApproverRuleCondition({ id: id },
			(message: string, resp: any) => {
				// console.log(resp);
				setApproverRuleConditionData(resp.data.list);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const getApproverRuleList = () => {
		showLoader();
		commonApi.getAllApproverRule({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setApproverRuleOptions(tempArr.map((item: any) => {
						item.label = item.rulename;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			conditionname: data?.conditionname,
			conditionindex: data?.conditionindex,
			fieldvalue: data?.fieldvalue,
			rules: data?.rules?.value,
			targetfield: data?.targetfield?.value,
			operator: data?.operator?.value,
			fieldtype: data?.fieldtype?.value,
		}
		if (isEdit) {
			if (approverRuleConditionData && approverRuleConditionData.id) {
				params["id"] = approverRuleConditionData.id
			}
		}
		// console.log(params)
		showLoader()
		commonApi.manageApproverRuleCondition(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			handleClose()
			hideLoader()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}
	const handleClose = () => {
		setApproverRuleConditionData(null)
		reset()
		close()
	}

	useEffect(() => {
		if (id) {
			getApproverRuleConditionData()
		}
	}, [id])

	useEffect(() => {
		if (show) {
			getApproverRuleList()
		}
	}, [show])

	useEffect(() => {
		// console.log(approverRuleConditionData?.fieldvalue)
		if (approverRuleConditionData) {
			setValue('conditionname', approverRuleConditionData?.conditionname)
			setValue('conditionindex', approverRuleConditionData?.conditionindex)
			setValue('fieldvalue', approverRuleConditionData?.fieldvalue ? approverRuleConditionData?.fieldvalue : '')
			setValue('rules', approverRuleOptions?.find((item: any) => item.value == approverRuleConditionData?.rules))
			setValue('targetfield', targetFieldOptions?.find((item: any) => item.value == approverRuleConditionData?.targetfield))
			setValue('operator', operatorOptions?.find((item: any) => item.value == approverRuleConditionData?.operator))
			setValue('fieldtype', fieldTypeOptions?.find((item: any) => item.value == approverRuleConditionData?.fieldtype))
		}
	}, [approverRuleConditionData, approverRuleOptions, targetFieldOptions, fieldTypeOptions])

	useEffect(() => {
		setValue('rules', approverRuleOptions?.find((item: any) => item.value == approverRuleId))
	}, [approverRuleOptions])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Condition Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="conditionname">Name <span className='text-danger'>*</span></label>
													<Controller
														name={"conditionname"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.conditionname ? ' border-danger' : '')}
																	id="conditionname"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter condition name"
																/>
															</>
														)}
													/>
													{errors && errors.conditionname &&
														<span className='text-danger'>{errors?.conditionname?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="targetfield">Target Field <span className='text-danger'>*</span></label>
													<Controller
														name={"targetfield"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.targetfield ? ' border-danger' : '')}
																	id="targetfield"
																	options={targetFieldOptions ? targetFieldOptions : []}
																	placeholder={"Select target field"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.targetfield &&
														<span className='text-danger'>target field is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="conditionindex">Index <span className='text-danger'>*</span></label>
													<Controller
														name={"conditionindex"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.conditionindex ? ' border-danger' : '')}
																	id="conditionindex"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																	onWheel={handleWheel}
																	step={"1"}
																	min={0}
																	ref={ref}
																	disabled={true}
																	placeholder="Enter condition index"
																/>
															</>
														)}
													/>
													{errors && errors.conditionindex ?
														<span className='text-danger'>{errors?.conditionindex?.message}</span>
														:
														null
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="operator">Operator <span className='text-danger'>*</span></label>
													<Controller
														name={"operator"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.operator ? ' border-danger' : '')}
																	id="operator"
																	options={operatorOptions ? operatorOptions : []}
																	placeholder={"Select operator"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.operator &&
														<span className='text-danger'>operator is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="rules">Rule <span className='text-danger'>*</span></label>
													<Controller
														name={"rules"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.rules ? ' border-danger' : '')}
																	id="rules"
																	options={approverRuleOptions ? approverRuleOptions : []}
																	placeholder={"select rule"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	isDisabled={true}
																/>
															</>
														)}
													/>
													{errors && errors.rules &&
														<span className='text-danger'>Rule is required</span>
													}
												</div><div className="form-group col-6">
													<label htmlFor="fieldtype">Field type <span className='text-danger'>*</span></label>
													<Controller
														name={"fieldtype"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.fieldtype ? ' border-danger' : '')}
																	id="fieldtype"
																	options={fieldTypeOptions ? fieldTypeOptions : []}
																	placeholder={"select field type"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.fieldtype &&
														<span className='text-danger'>field type is required</span>
													}
												</div>

											</div>
											<div className="row">
												<div className="form-group col-6"></div>
												<div className="form-group col-6">
													<label htmlFor="fieldvalue">Field value {selectedTargetField && selectedTargetField.value != 'Amount' ? '(%)' : '($)'} <span className='text-danger'>*</span></label>
													<Controller
														name={"fieldvalue"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.fieldvalue ? ' border-danger' : '')}
																	id="fieldvalue"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => {
																		handleKeyPressDecimalNumber(event);
																		handleKeyUpDown(event)
																	}}
																	onWheel={handleWheel}
																	step={"0.01"}
																	min={0}
																	ref={ref}
																	placeholder="Enter field value"
																/>
															</>
														)}
													/>
													{errors && errors.fieldvalue &&
														<span className='text-danger'>{errors?.fieldvalue?.message}</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={handleClose}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddApproverRuleConditionModal