import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import AddRegionModal from './addRegion.modal';
import AddDesignationModal from './addDesignation.modal';
import { licenceOptions } from '../../_common/staticData';

interface FormValues {
	name: string;
	email: string;
	licencetype: any;
	region: any;
	timezone: string;
	designationid: any;
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
	email: Yup.string().email('Invalid email').required("Email is required"),
	licencetype: Yup.object().required("Licence is required"),
	region: Yup.object().required("Region is required"),
	designationid: Yup.object().required("Designation is required"),
	timezone: Yup.string().required("Time Zone is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
	callbackFunc: any;
}

const AddContractManagerModal = ({ show, close, AccountId, callbackFunc }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [regionOptions, setRegionOption] = useState<any>(null)
	const [designationOptions, setDesignationOptions] = useState<any>(null)
	const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false)
	const [showAddDesignationModal, setShowDesignationModal] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: "",
			email: "",
			licencetype: "",
			region: "",
			timezone: "",
			designationid: "",
		},
	});

	const getRegionList = () => {
		showLoader();
		commonApi.getAllRegions({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setRegionOption(tempArr.map((item: any) => {
						item.label = item.region_name;
						item.value = item.Region;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const getDesignationData = (page = 1) => {
		commonApi.getAllDesignation({ page: page },
			(message: string, resp: any) => {
				const tempList: any = resp.data.list.data;
				if (tempList && tempList.length > 0) {
					setDesignationOptions(tempList.map((item: any) => {
						item.label = item.name
						item.value = item.id
						return item
					}));
				}
			}, (message: string) => {
			})
	}

	const onSubmit = (data: any) => {
		let params: any = {
			"AccountId": AccountId,
			"email": data?.email,
			"name": data?.name,
			"licencetype": data?.licencetype?.value,
			"region": data?.region?.value,
			"timezone": data?.timezone,
			"status": data?.status,
			"designationid": data?.designationid?.value,
		}
		showLoader()
		platformApi.manageArrUser(params, (message: string, resp: any) => {
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFunc()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}

	const closeAddRegionModal = () => {
		setShowAddRegionModal(false)
	}

	const closeAddDesignationModal = () => {
		setShowDesignationModal(false)
	}

	useEffect(() => {
		getRegionList()
		getDesignationData()
	}, [])

	useEffect(() => {
		setValue('licencetype', licenceOptions?.find((item: any) => item.value === 'User') || '')
	}, [show])

	return (
		<React.Fragment>
			<AddRegionModal
				show={showAddRegionModal}
				close={closeAddRegionModal}
				isEdit={false}
				regionListCallbackFunc={getRegionList}
			/>
			<AddDesignationModal
				show={showAddDesignationModal}
				close={closeAddDesignationModal}
				isEdit={false}
				designationListCallbackFunc={getDesignationData}
			/>
			<Modal
				className={'salesforce-modal modal-lndscp ' + (showAddRegionModal || showAddDesignationModal ? 'opec-5' : '')}
				show={show}
			>
				<div className="modal-header custom-modal-header">
					<div className="d-flex flex-wrap">
						<h5 className="card-title mr-2">User Information Modal</h5>
					</div>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-6">
												<label htmlFor="name">Full Name <span className='text-danger'>*</span></label>
												<Controller
													name={"name"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.name ? ' border-danger' : '')}
																id="name"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter full name"
															/>
														</>
													)}
												/>
												{errors && errors.name &&
													<span className='text-danger'>{errors?.name?.message}</span>
												}
											</div>

											<div className="form-group col-6">
												<label htmlFor="licencetype">Licence Type <span className='text-danger'>*</span></label>
												<Controller
													name={"licencetype"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<Select
																className={"select-box " + (errors?.licencetype ? ' border-danger' : '')}
																options={licenceOptions ? licenceOptions : []}
																placeholder={"Select licence"}
																onChange={onChange}
																value={value}
																defaultValue={value}
																ref={ref}
																isDisabled={true}
															/>
														</>
													)}
												/>
												{errors && errors.licencetype &&
													<span className='text-danger'>Licence is required</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="email">Email <span className='text-danger'>*</span></label>
												<Controller
													name={"email"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="email"
																className={"form-control " + (errors?.email ? ' border-danger' : '')}
																id="email"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter email"
															/>
														</>
													)}
												/>
												{errors && errors.email &&
													<span className='text-danger'>{errors?.email?.message}</span>
												}
											</div>
											{/* <div className="form-group col-6">
												<label htmlFor="password">Password <span className='text-danger'>*</span></label>
												<Controller
													name={"password"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="password"
																className={"form-control " + (errors?.password ? ' border-danger' : '')}
																id="password"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter password"
																autoComplete="new-password"
															/>
														</>
													)}
												/>
												{errors && errors.password &&
													<span className='text-danger'>{errors?.password?.message}</span>
												}
											</div> */}
											<div className="form-group col-6">
												<label htmlFor="timezone">Time Zone <span className='text-danger'>*</span></label>
												<Controller
													name={"timezone"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.timezone ? ' border-danger' : '')}
																id="timezone"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter timezone"
															/>
														</>
													)}
												/>
												{errors && errors.timezone &&
													<span className='text-danger'>{errors?.timezone?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<div className='d-flex justify-content-between'>
													<label htmlFor="region">Region <span className='text-danger'>*</span></label>
												</div>
												<div className='d-flex align-items-center gap-10 add-new-fld-row'>
													<Controller
														name={"region"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.region ? ' border-danger' : '')}
																	id="region"
																	options={regionOptions ? regionOptions : []}
																	placeholder={"Select region"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	isClearable={true}
																/>
															</>
														)}
													/>
													<span
														data-toggle="tooltip"
														data-placement="top"
														title={"Add Region"}
														onClick={() => setShowAddRegionModal(true)}
													>
														<AiOutlinePlusSquare className='cursor-pointer' />
													</span>
												</div>

												{errors && errors.region &&
													<span className='text-danger'>Region is required</span>
												}
											</div>
											<div className="form-group col-6">
												<div className='d-flex justify-content-between'>
													<label htmlFor="region">Designation <span className='text-danger'>*</span></label>
												</div>
												<div className='d-flex align-items-center gap-10 add-new-fld-row'>
													<Controller
														name={"designationid"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.designationid ? ' border-danger' : '')}
																	id="designationid"
																	options={designationOptions ? designationOptions : []}
																	placeholder={"Select designation"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	isClearable={true}
																/>
															</>
														)}
													/>
													<span
														data-toggle="tooltip"
														data-placement="top"
														title={"Add Designation"}
														onClick={() => setShowDesignationModal(true)}
													>
														<AiOutlinePlusSquare className='cursor-pointer' />
													</span>
												</div>

												{errors && errors.designationid &&
													<span className='text-danger'>Designation is required</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddContractManagerModal