import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';

interface FormValues {
	partnerid: any
}

const validationSchema = Yup.object().shape({
	partnerid: Yup.object().required("User is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
}


const AddPartnerTeamModal = ({ show, close, AccountId }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [partnerOptions, setPartnerOptions] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			partnerid: ""
		},
	});

	const getPartnerData = () => {
		showLoader();
		commonApi.getAllPartner({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setPartnerOptions(tempArr.map((item: any) => {
						item.label = item.name;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const customOptionUI = (props: any) => {
		const { innerProps, innerRef, data } = props;
		return (
			<div ref={innerRef} {...innerProps} className="cstm-option m-2 cursor-pointer">
				{data?.name}
			</div>
		);
	}

	const onSubmit = (data: any) => {
		showLoader()
		let params: any = {
			"partnerid": data?.partnerid?.value
		} 
		if (AccountId.includes('A-')) {
			params['AccountId'] = AccountId;
		}
		if (AccountId.includes('OP-')) {
			params['OpportunityId'] = AccountId;
		}
		platformApi.manageAccountPartner(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		getPartnerData()
	}, [])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="partnerid">Select Partner <span className='text-danger'>*</span></label>
												<Controller
													name={"partnerid"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<Select
																className={"select-box" + (errors?.partnerid ? ' border-danger' : '')}
																id="partnerid"
																components={{ Option: customOptionUI }}
																options={partnerOptions ? partnerOptions : []}
																placeholder={""}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
												{errors && errors.partnerid &&
													<span className='text-danger'>Please select partner</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddPartnerTeamModal