import React, { useMemo } from 'react';
import TableContainer from '../commonComponents/TableContainer';
import moment from 'moment';
import { formatNumber } from '../../_common/functions';

interface IncomingProps {
    loading: boolean;
    crossSellsData: any;
    data: any;
    getCrossSellsData: Function
}

const CrossSellsList = ({ loading, crossSellsData, data, getCrossSellsData }: IncomingProps) => {

    const columns = useMemo(
        () => [
            {
                Header: "Account Cross Sells",
                accessor: "AccountId",
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Contract Term (months)",
                accessor: "ContractTerm",
            },
            {
                Header: "Cross Sell ($)",
                accessor: "Crosssellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Due Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Status",
                accessor: "newstatus",
            },
        ],
        []
    )


    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getCrossSellsData}
                    loading={loading}
                    rawData={crossSellsData}
                    clickFunc={true}
                />
            </div>
        </React.Fragment>
    )
}

export default CrossSellsList