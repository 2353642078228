import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { toast } from 'react-hot-toast'
import { changeString, formatNumber } from '../_common/functions'
import moment from 'moment'
import ForecastEndDate from '../components/modals/forecastEndDate.modal'
import { AiOutlineEdit } from 'react-icons/ai'
import ForecastUpdateModal from '../components/modals/forecastUpdate.modal'
import FilterRow from '../components/commonComponents/FilterRow'
import ForecastBulkUpdateModal from '../components/modals/forecastBulkUpdate.modal'

const RiskLevelForecast = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Risk Level Forecast</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript: void(0);">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Risk Level Forecast</li>
                                        </ol>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                    <div className="col-lg-3">
                                        <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="renewal-block">
                                            <h3>Q1 Renewals</h3>
                                            <div className="renewal-block-row-wrapper">
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">Count</div>
                                                <div className="renewal-block-cont">23</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR</div>
                                                <div className="renewal-block-cont">$2,000,00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Completed</div>
                                                <div className="renewal-block-cont">$0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Renewals Completed
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Remaining</div>
                                                <div className="renewal-block-cont">$20,000</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR At Risk</div>
                                                <div className="renewal-block-cont">$425,124</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    ARR Churned &amp; Contracted + Reactivated
                                                </div>
                                                <div className="renewal-block-cont">$0.00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Customers Churned
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">GRR</div>
                                                <div className="renewal-block-cont">0.00%</div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="renewal-block">
                                            <h3>Q2 Renewals</h3>
                                            <div className="renewal-block-row-wrapper">
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">Count</div>
                                                <div className="renewal-block-cont">23</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR</div>
                                                <div className="renewal-block-cont">$2,000,00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Completed</div>
                                                <div className="renewal-block-cont">$0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Renewals Completed
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Remaining</div>
                                                <div className="renewal-block-cont">$20,000</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR At Risk</div>
                                                <div className="renewal-block-cont">$425,124</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    ARR Churned &amp; Contracted + Reactivated
                                                </div>
                                                <div className="renewal-block-cont">$0.00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Customers Churned
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">GRR</div>
                                                <div className="renewal-block-cont">0.00%</div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="renewal-block">
                                            <h3>Q3 Renewals</h3>
                                            <div className="renewal-block-row-wrapper">
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">Count</div>
                                                <div className="renewal-block-cont">23</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR</div>
                                                <div className="renewal-block-cont">$2,000,00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Completed</div>
                                                <div className="renewal-block-cont">$0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Renewals Completed
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Remaining</div>
                                                <div className="renewal-block-cont">$20,000</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR At Risk</div>
                                                <div className="renewal-block-cont">$425,124</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    ARR Churned &amp; Contracted + Reactivated
                                                </div>
                                                <div className="renewal-block-cont">$0.00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Customers Churned
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">GRR</div>
                                                <div className="renewal-block-cont">0.00%</div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="card mini-stats-wid">
                                        <div className="card-body">
                                            <div className="renewal-block">
                                            <h3>Q4 Renewals</h3>
                                            <div className="renewal-block-row-wrapper">
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">Count</div>
                                                <div className="renewal-block-cont">23</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR</div>
                                                <div className="renewal-block-cont">$2,000,00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Completed</div>
                                                <div className="renewal-block-cont">$0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Renewals Completed
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR Remaining</div>
                                                <div className="renewal-block-cont">$20,000</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">ARR At Risk</div>
                                                <div className="renewal-block-cont">$425,124</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    ARR Churned &amp; Contracted + Reactivated
                                                </div>
                                                <div className="renewal-block-cont">$0.00</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">
                                                    Customers Churned
                                                </div>
                                                <div className="renewal-block-cont">0</div>
                                                </div>
                                                <div className="d-flex justify-content-between renewal-block-row">
                                                <div className="renewal-block-head">GRR</div>
                                                <div className="renewal-block-cont">0.00%</div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/* end row */}
                                </div>
                            {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">Churn</h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Region</th>
                                                <th>Account Owner</th>
                                                <th>ATR Data</th>
                                                <th>Renewal Risk Level</th>
                                                <th>ARR</th>
                                                <th>Renewal Forecast</th>
                                                <th>ARR At Risk</th>
                                                <th>Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                <strong>TOTAL</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">Contraction</h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Region</th>
                                                <th>Account Owner</th>
                                                <th>ATR Data</th>
                                                <th>Renewal Risk Level</th>
                                                <th>ARR</th>
                                                <th>Renewal Forecast</th>
                                                <th>ARR At Risk</th>
                                                <th>Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                <strong>TOTAL</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">Reactivation</h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Region</th>
                                                <th>Account Owner</th>
                                                <th>ATR Data</th>
                                                <th>Renewal Risk Level</th>
                                                <th>ARR</th>
                                                <th>Renewal Forecast</th>
                                                <th>ARR At Risk</th>
                                                <th>Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                <strong>TOTAL</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">Early Renewal Contraction</h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Region</th>
                                                <th>Account Owner</th>
                                                <th>ATR Data</th>
                                                <th>Renewal Risk Level</th>
                                                <th>ARR</th>
                                                <th>Renewal Forecast</th>
                                                <th>ARR At Risk</th>
                                                <th>Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                <strong>TOTAL</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">Possible Late</h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Region</th>
                                                <th>Account Owner</th>
                                                <th>ATR Data</th>
                                                <th>Renewal Risk Level</th>
                                                <th>ARR</th>
                                                <th>Renewal Forecast</th>
                                                <th>ARR At Risk</th>
                                                <th>Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                <strong>TOTAL</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">Possible Addition C/C</h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>Account Name</th>
                                                <th>Region</th>
                                                <th>Account Owner</th>
                                                <th>ATR Data</th>
                                                <th>Renewal Risk Level</th>
                                                <th>ARR</th>
                                                <th>Renewal Forecast</th>
                                                <th>ARR At Risk</th>
                                                <th>Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td>Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                                <td className="text-right">Example</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                <strong>TOTAL</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0.00</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">
                                            Risk level by open ATR by Forecasted C/C by actual C/C
                                        </h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th />
                                                <th>Open ATR</th>
                                                <th>Forecasted C/C</th>
                                                <th>Actual C/C</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Churn</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>Contraction</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>Reactivation</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>Severe</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>High</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>Med</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>Low</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>Late/Early Contraction</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>No Risk</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                                <td className="text-right">$54,8547</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <strong>Total</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$5600</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$4500</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$6500</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-4">
                                        <h4 className="card-title">
                                            Churn and contraction by region by month
                                        </h4>
                                        </div>
                                        <div className="table-responsive">
                                        <table
                                            className="table table-bordered dt-responsive nowrap"
                                            style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: 0,
                                            width: "100%"
                                            }}
                                        >
                                            <thead>
                                            <tr>
                                                <th>GEO</th>
                                                <th>Total</th>
                                                <th>Oct</th>
                                                <th>Nov</th>
                                                <th>Dec</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>AMER Strategic</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                            </tr>
                                            <tr>
                                                <td>AMER Commercial</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                            </tr>
                                            <tr>
                                                <td>AMER</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                            </tr>
                                            <tr>
                                                <td>EMEA</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                            </tr>
                                            <tr>
                                                <td>APAC</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                                <td className="text-right">$0</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <strong>Total</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0</strong>
                                                </td>
                                                <td className="text-right">
                                                <strong>$0</strong>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                        </div>{" "}
                        {/* container-fluid */}
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default RiskLevelForecast