import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import TableContainer from '../../components/commonComponents/TableContainer'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import AddNoteFormOnAccActivity from '../../components/modals/AccountView/AddNoteFormOnAccActivity.modal'
import { showDate } from '../../_common/functions'

const TabNotes = () => {
    const urlParams: any = useParams();
    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    var accountIdString = urlParams?.id  

    const [showModal, setShowModal] = useState<boolean>(false);

    const [returnData, setReturnData] = useState<any>([])
    const [listData, setListData] = useState<any>([])

    const closeModalHandler = () => {
        setShowModal(false);
        getListData();
    }


    const columnData = useMemo(() => [
        { Header: "Note Title", accessor: "note_title" },
        { Header: "Note Description", accessor: "note_description" },
        {
            Header: "Created Date & Time", accessor:( d:any )=> {
                return showDate(d.created_at, "MM-DD-YYYY hh:mm A")

             },
         }
    ],
        [listData]
    )

    const getListData = (page = 1) => {
        showLoader();
        let params: any = { page: page, AccountId: accountIdString  };
        platformApi.getAccountViewNoteList(params,
            (message: string, resp: any) => {
                setReturnData(resp.data.list);
                setListData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    useEffect(() => {
        getListData();
    }, [])

    return (
        <React.Fragment>
            <AddNoteFormOnAccActivity close={closeModalHandler} show={showModal} AccountId={urlParams?.id} />

            <div className="card">
                <div className="card-body">
                    <h5 className="font-size-18">Manage Notes
                        <button className='btn btn-sm btn-success' onClick={() => setShowModal(true)} style={{ float: 'right' }}>
                            <AiOutlinePlusCircle /> Add Note
                        </button>
                    </h5>

                    <div className="table-responsive">
                        <TableContainer
                            columns={columnData}
                            fetchData={getListData}
                            rawData={returnData}
                            data={listData}
                            loading={false}
                            clickFunc={true}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default TabNotes