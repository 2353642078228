import React from 'react'
import Modal from 'react-bootstrap/Modal'

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any;
	actionRow: any;
	type?: any;
}

const CompletedNoteBoxView = ({ show, close, AccountId, actionRow, type }: IncomingProps) => {
	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}>
				<div className="modal-header custom-modal-header"> Note Box
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { close(); }} >
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<input type="hidden" name='AccountId' value={AccountId} />
						<input type="hidden" name='type' value={type} />

						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<div className="row">

										<div className="form-group col-12">
											<label htmlFor="arruserid">
												Agenda: {(type == 'task') ? actionRow.task_title : actionRow.agenda}
											</label>
										</div>

										<div className="form-group col-12">
											<label htmlFor="arruserid">Completed Note: </label>
											<div>{(actionRow.completed_note == null) ? '--' : actionRow.completed_note}</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className='align-items-center d-flex gap-10 justify-content-center'>
								<button type='button' className='btn btn-outline-danger' onClick={() => { close() }} >
									Close
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default CompletedNoteBoxView