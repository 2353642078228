import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import { URLS } from '../../_config';
import ApolloMaping1Modal from './ApolloMaping1.modal'; 
import ApolloMaping2Modal from './ApolloMaping2.modal';
import ApolloMaping3Modal from './ApolloMaping3.modal';

const ExternalApolloMaping = () => {
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);


    const closeModalHandler = () => {
        setShowModal1(false);
        setShowModal2(false);
        setShowModal3(false);
    }
    return (
        <React.Fragment>
            {showModal1 && <ApolloMaping1Modal
                show={showModal1}
                close={closeModalHandler}
            />}
            {showModal2 && <ApolloMaping2Modal
                show={showModal2}
                close={closeModalHandler}
            />}
            {showModal3 && <ApolloMaping3Modal
                show={showModal3}
                close={closeModalHandler}
            />}

            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between title-mob-fix">
                                        <h4 className="mb-0 font-size-18">Manage Apollo Maping </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.CONNECTS_EXTERNAL_APPLICATION}>External Application</Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    Manage Apollo Maping
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="fl-mod-select">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-6">
                                                        <div className="fl-logo-top">
                                                            <div className="arr-log mb-2">
                                                                <img src="assets/images/logo-light.png" />
                                                            </div>
                                                            <strong>ARR Modules</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-6">
                                                        <div className="fl-logo-top">
                                                            <div className="fl-sale-force mb-2">
                                                                <img src="assets/images/Apollo.png" />
                                                            </div>
                                                            <strong>CRM Modules</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mod-login mt-3">
                                                <div className="mod-arr-top-part">

                                                    <React.Fragment  >
                                                        <button type="button" className="btn" onClick={() => setShowModal1(true)} > Lead </button>
                                                        <span className="d-block" />
                                                        <span className="d-block" />
                                                        <span className="d-block" />

                                                        <button type="button" className="btn" onClick={() => setShowModal2(true)} > Account </button>
                                                        <span className="d-block" />
                                                        <span className="d-block" />
                                                        <span className="d-block" />



                                                        <button type="button" className="btn" onClick={() => setShowModal3(true)}>  Contact </button>

                                                    </React.Fragment>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExternalApolloMaping