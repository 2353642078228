import React, { useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/numberFieldRestriction';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { showDate } from '../../_common/functions';

interface FormValues {
	field_name: string
}

const validationSchema = Yup.object().shape({
	field_name: Yup.string().required("Field is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	pageData: any;
	type?: any;
	callbackFunctions?: any
}

const PipelineFieldEditModal = ({ show, close, pageData, callbackFunctions }: IncomingProps) => {
	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();
	const datePickerRef = useRef<any>()

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			field_name: ''
		},
	});

	useEffect(() => {
		setValue('field_name', pageData?.val);
	}, [show])

	const onSubmit = (data: any) => {
		showLoader()

		let params: any = {
			"id": pageData.typeid,
		}
		if (pageData?.input_name == 'mostlikely') {
			params['mostlikely_forecast'] = data.field_name;
		} else if (pageData?.input_name == 'upside') {
			params['upside_forecast'] = data.field_name;
		} else if (pageData?.input_name == 'deallevel') {
			params['deallevel_forecast'] = data.field_name;
		} else if (pageData?.input_name == 'CloseDate') { 
			params['CloseDate'] = data?.field_name ? moment(data.field_name).format("MM/DD/YY") : "";
		} else if (pageData?.input_name == 'NextStep') {
			params['NextStep'] = data.field_name;
		}

		platformApi.manageOpportunity(params, (message: string, resp: any) => {
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFunctions()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}

	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show} >
				<div className="modal-header custom-modal-header">
					<button type="button" className="close"
						data-dismiss="modal" aria-label="Close"
						onClick={() => { close(); reset() }} >
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="arruserid">{pageData?.lable}
													{
														(!['Close Date', 'Next Step'].includes(pageData?.lable)) && ' ($)'
													}
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"field_name"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															{
																(pageData?.lable == 'Close Date') &&
																<DatePicker
																	selected={value ? new Date(value) : null}
																	onChange={onChange}
																	dateFormat="MM/dd/yyyy"
																	className={"form-control " + (errors?.field_name ? ' border-danger' : '')}
																	id="field_name"
																	name={name}
																	onBlur={onBlur}
																	ref={datePickerRef}
																	value={showDate(value, 'MM/DD/yyyy')}
																	placeholderText={`Enter ${pageData?.lable}`}
																	autoComplete='off'
																/>
															}
															{
																(pageData?.lable == 'Next Step') &&
																<input
																	type="text"
																	className={"form-control " + (errors?.field_name ? ' border-danger' : '')}
																	id="field_name"
																	name={name}
																	onChange={onChange}
																	value={value ? value : ''}
																	placeholder={`Enter ${pageData?.lable}`}
																	autoComplete='off'
																/>
															}
															{
																(['Most Likely Forecast', 'Upside Forecast', 'Deal Level Forecast'].includes(pageData?.lable)) &&
																<input
																	type="text"
																	className={"form-control " + (errors?.field_name ? ' border-danger' : '')}
																	id="field_name"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																	onWheel={handleWheel}
																	step={"0.01"}
																	min={0}
																	placeholder={`Enter ${pageData?.lable}`}
																	autoComplete='off'
																/>
															}

														</>
													)}
												/>
												{errors && errors.field_name &&
													<span className='text-danger'>{errors?.field_name?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button type='button' className='btn btn-outline-danger' onClick={() => { close(); reset() }}>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default PipelineFieldEditModal