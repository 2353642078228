import React, { useEffect, useMemo, useState } from 'react'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import TableContainer from '../commonComponents/TableContainer'
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai'
import { formatNumber } from '../../_common/functions'

interface IncomingProps {
    ruleId?: any;
    setRuleId?: any;
    setShowConditionModal?: any;
    showConditionModal?: boolean;
    setIsEdit?: any;
    isEdit?: boolean;
    callFunction: boolean;
    setCallFunction: any
}

const ConditionList = ({ ruleId, setRuleId, setShowConditionModal, showConditionModal = false, setIsEdit, isEdit = false, callFunction, setCallFunction }: IncomingProps) => {

    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader();

    const [approverRuleConditionData, setApproverRuleConditionData] = useState<any>([])
    const [rawData, setRawData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const getAllApproverRuleCondition = (page = 1) => {
        showLoader();
        setLoading(true);
        const params: any = {
            page: page
        }
        if (ruleId) {
            params["rules"] = ruleId
        }
        commonApi.getAllApproverRuleCondition(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setRawData(resp.data.list);
                setApproverRuleConditionData(resp.data.list.data);
                hideLoader();
                setCallFunction(false)
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteApproverRuleCondition = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteApproverRuleCondition({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const EditBtn = ({ column, value }: any) => {
        const handleEdit = (value: any) => {
            setShowConditionModal(true)
            setRuleId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={() => handleEdit(value)}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const callbackFnAfterDelete = () => {
        getAllApproverRuleCondition();
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value, row }: any) =>
                    <>
                    {/* {row.original.rules} */}
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deleteApproverRuleCondition}
                            callbackFunc={callbackFnAfterDelete}
                            type={"Condition"}
                        />
                        <EditBtn column={column} value={row.original.rules} />
                    </>,
            },
            {
                Header: "Approver Name",
                accessor: "ruleobject.rulename",
            },
            {
                Header: "Condition Name",
                accessor: "conditionname",
            },
            {
                Header: "Target Field",
                accessor: "targetfield",
            },
            {
                Header: "Operator",
                accessor: "operator",
            },
            {
                Header: "Field Value",
                accessor: "fieldvalue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.targetfield && row.original.targetfield == 'Amount' ?
                            <span>${value ? formatNumber(value) : '0.00'}</span>
                            :
                            <span>{value ? formatNumber(value) : '0.00'}%</span>
                        }
                    </>
            },
        ],
        []
    )

    useEffect(() => {
        if (callFunction) {
            getAllApproverRuleCondition()
        }
    }, [callFunction])

    useEffect(() => {
        // if (showConditionModal) {
        getAllApproverRuleCondition()
        // }
    }, [])

    return (
        <React.Fragment>
            <div className="d-flex flex-wrap justify-content-between">
                <h5 className="card-title mr-2">Condition</h5>
                <button onClick={() => setShowConditionModal(true)} className="btn btn-success" >
                    <AiOutlinePlusCircle /> Add Condition
                </button>
            </div>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={approverRuleConditionData}
                    fetchData={getAllApproverRuleCondition}
                    loading={loading}
                    rawData={rawData}
                />
            </div>
        </React.Fragment>
    )
}

export default ConditionList