import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';

interface FormValues {
	note_title: string,
	note_description: string
}

const validationSchema = Yup.object().shape({
	note_title: Yup.string().nullable().required("Note title is required"),
	note_description: Yup.string().nullable().required("Note description is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: any
}


const AddNoteFormOnAccActivity = ({ show, close, AccountId }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();
 

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			note_title: "",
			note_description: "",
		},
	});


	const onSubmit = (data: any) => {
		showLoader()
		let params: any = { 
			"AccountId": AccountId, 
			"note_title": data?.note_title,
			"note_description": data?.note_description,
		}
		platformApi.addEditAccountViewNote(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
		}, (message: string) => {
			toast.error(message)
			hideLoader()
		})
	}


	return (
		<React.Fragment>
			<Modal className='salesforce-modal' show={show}>
				<div className="modal-header custom-modal-header">
					Add Note
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { close(); reset() }} >
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="arruserid">Note Title <span className='text-danger'>*</span></label>
												<Controller
													name={"note_title"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.note_title ? ' border-danger' : '')}
																id="note_title"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter note title"
															/>
														</>
													)}
												/>
												{errors && errors.note_title &&
													<span className='text-danger'>{errors?.note_title?.message}</span>
												}
											</div>

											<div className="form-group col-12">
												<label htmlFor="arruserid">Note Description <span className='text-danger'>*</span></label>
												<Controller
													name={"note_description"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<textarea
																className={"form-control " + (errors?.note_description ? ' border-danger' : '')}
																id="note_description"
																name={name}
																onChange={onChange}
																onBlur={onBlur}
																placeholder="Enter note description"
															/>
														</>
													)}
												/>

												{errors && errors.note_description &&
													<span className='text-danger'>{errors?.note_description?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddNoteFormOnAccActivity