import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import { URLS } from '../../_config';
import Footer from '../../components/commonComponents/Footer';
import { toast } from 'react-hot-toast';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/numberFieldRestriction';
import { AiOutlineDollar } from 'react-icons/ai';
import { yearOptionsStatic } from '../../_common/staticData';


const RegionBudget = () => {

    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [regionsData, setRegionsData] = useState<any>(null)
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState<any>(currentYear)
    const [yearOption, setYearOption] = useState<any>(yearOptionsStatic)

    const [formValue, setFormValue] = useState<any>(null)

    const getAllRegionBudget = (page = 1) => {
        showLoader();
        commonApi.getAllRegionBudget({ year: year },
            (message: string, resp: any) => {
                setRegionsData(resp.data.list);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const yearHandler = (e: any) => {
        setYear(e.target.value);
    }

    const onSubmit = (event: any) => {
        event.preventDefault()

        var totalData = regionsData.length
        var formArr: any = [];
        var i = 0;
        for (i; i < totalData; i++) {
            let x: any = {}
            for (let j = 0; j < 5; j++) {
                var fieldNameArr = event?.target[i * 5 + j].name.split('-');
                var keyId = fieldNameArr[0];
                var keyLabel = fieldNameArr[1];
                var fieldValue = event?.target[i * 5 + j].value;
                x[keyLabel] = fieldValue;
            }
            formArr.push(x)
        }
        console.log('formArr', formArr);

        var formData = {
            'year': year,
            'regionbudget': formArr
        }

        showLoader()
        commonApi.manageRegionBudget(formData, (message: string, resp: any) => {
            toast.success(message)
            hideLoader()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }


    useEffect(() => {
        getAllRegionBudget()
    }, [year])

    return (
        <React.Fragment>

            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Region Budget</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Region Budget</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Region Budget</h5>

                                        <div style={{ display: 'inline-flex' }}>
                                            <label style={{ width: '180px' }} >Budget Year  </label>
                                            <select className='form-control' value={year} onChange={yearHandler}>
                                                {
                                                    yearOption && yearOption.length > 0 &&
                                                    yearOption.map((item: any) =>
                                                        <option value={item.value} key={Math.random()}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <form onSubmit={(onSubmit)} className='w-100'>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>Region</th>
                                                                <th>Quarter 1</th>
                                                                <th>Quarter 2</th>
                                                                <th>Quarter 3</th>
                                                                <th>Quarter 4</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (regionsData && regionsData.length > 0) ?
                                                                    <>
                                                                        {regionsData.map((item: any) =>
                                                                            <tr key={Math.random()}>
                                                                                <td>
                                                                                    <input type="hidden" value={item?.regionid}
                                                                                        name={`${item?.regionid}-regionid`} />
                                                                                    {item?.region?.region_name}
                                                                                </td>
                                                                                <td>
                                                                                    <div className='cstm-password'>
                                                                                        <input type="number" defaultValue={(item?.quarterone > 0) ? item?.quarterone : 0} step="0.01"
                                                                                            onFocus={(e) => handleFocus(e)}
                                                                                            onWheel={handleWheel}
                                                                                            name={`${item?.regionid}-quarterone`} className='form-control' />
                                                                                        <AiOutlineDollar />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='cstm-password'>
                                                                                        <input type="number" defaultValue={(item?.quartertwo > 0) ? item?.quartertwo : 0} step="0.01"
                                                                                            onFocus={(e) => handleFocus(e)}
                                                                                            onWheel={handleWheel}
                                                                                            name={`${item?.regionid}-quartertwo`} className='form-control' />
                                                                                        <AiOutlineDollar />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='cstm-password'>
                                                                                        <input type="number" defaultValue={(item?.quarterthree > 0) ? item?.quarterthree : 0} step="0.01"
                                                                                            onFocus={(e) => handleFocus(e)}
                                                                                            onWheel={handleWheel}
                                                                                            name={`${item?.regionid}-quarterthree`} className='form-control' />
                                                                                        <AiOutlineDollar />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='cstm-password'>
                                                                                        <input type="number" defaultValue={(item?.quarterfour > 0) ? item?.quarterfour : 0} step="0.01"
                                                                                            onFocus={(e) => handleFocus(e)}
                                                                                            onWheel={handleWheel}
                                                                                            name={`${item?.regionid}-quarterfour`} className='form-control' />
                                                                                        <AiOutlineDollar />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        <tr>
                                                                            <td colSpan={4}></td>
                                                                            <td>
                                                                                <button type='submit' className='btn btn-info'>Submit Budget</button>
                                                                            </td>
                                                                        </tr>

                                                                    </>
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={5}>No data found!</td>
                                                                    </tr>
                                                            }


                                                        </tbody>
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default RegionBudget