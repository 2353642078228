import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { formatNumber } from '../../_common/functions';

interface IncomingProps {
	show: boolean;
	close: () => void;
	leadData?: any;
}


const LeadDetailsModal = ({ show, close, leadData }: IncomingProps) => {

	return (
		<React.Fragment>
			<Modal className='salesforce-modal modal-lndscp' show={show}  >
				<div className="modal-header custom-modal-header">
					<h5>Lead Details</h5>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<div className="row">
										<table className='table table-responsive'>
											<tr>
												<th colSpan={4}><h5> <u>Lead Information</u> </h5></th>
											</tr>
											<tr>
												<th>Title:</th>
												<td>{leadData?.Title}</td>

												<th className='border-left'>Lead Source:</th>
												<td>{leadData?.LeadSource}</td>
											</tr>
											<tr>
												<th>Industry:</th>
												<td>{leadData?.Industry}</td>

												<th className='border-left'>Account Manager:</th>
												<td>{leadData?.accountManagerdetails?.name}</td>
											</tr>
											<tr>
												<th>Number Of Employees:</th>
												<td>{leadData?.NumberOfEmployees}</td>


												<th className='border-left'>Annual Revenue:</th>
												<td>${formatNumber(leadData?.AnnualRevenue)}</td>
											</tr>
											<tr>
												<th>Company:</th>
												<td>{leadData?.Company}</td>

												<th className='border-left'>Status:</th>
												<td>{leadData?.Status}</td>
											</tr>
											<tr>

												<th>Email:</th>
												<td>{leadData?.Email}</td>

												<th className='border-left'>Phone:</th>
												<td>{leadData?.Phone}</td>
											</tr>
											<tr>
												<th >Website:</th>
												<td colSpan={3}><a href={leadData?.Website} target='_blank'>{leadData?.Website}</a></td>
											</tr>
											<tr>
												<th>Description:</th>
												<td colSpan={3}>{leadData?.Description}</td>
											</tr>
											<tr>
												<th colSpan={4}><h5> <u>Address Information</u> </h5></th>
											</tr>
											<tr>
												<th>Full Name</th>
												<td>{leadData?.Salutation} {leadData?.FirstName} {leadData?.LastName}</td>


												<th className='border-left'>Street</th>
												<td>{leadData?.Street}</td>
											</tr>
											<tr>
												<th>City</th>
												<td>{leadData?.City}</td>


												<th className='border-left'>State</th>
												<td>{leadData?.State}</td>
											</tr>
											<tr>
												<th>Zip/Postal Code</th>
												<td>{leadData?.PostalCode}</td>


												<th className='border-left'>Country</th>
												<td>{leadData?.Country}</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className='align-items-center d-flex gap-10 justify-content-center'>
								<button type='button' className='btn btn-outline-danger' onClick={() => { close(); }} >
									Close
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default LeadDetailsModal