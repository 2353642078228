import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import TableContainer from '../../components/commonComponents/TableContainer'
import { AiOutlineInfoCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import AddNoteFormOnAccActivity from '../../components/modals/AccountView/AddNoteFormOnAccActivity.modal'
import { formatNumber, showDate } from '../../_common/functions'

interface IncomingProps {
    quoteId?: any;
    quoteData?: any
}

const LineItems = ({ quoteId, quoteData }: IncomingProps) => {
    const platformApi = usePlatformApi()
    const { showLoader, hideLoader } = useAppLoader()
    const [loading, setLoading] = useState<boolean>(false)

    const [currentProductId, setCurrentProductId] = useState<any>()

    const [lineItemData, setLineItemData] = useState<any>([])
    const [rawData, setRawData] = useState<any>(null)

    const expandCollapseProduct = (id: any) => {
        setCurrentProductId(id)
    }

    const getAllQuoteLineItem = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllQuoteLineItem({
            page: page,
            QuoteId: quoteId
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setRawData(resp.data.list);
                setLineItemData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    useEffect(() => {
        getAllQuoteLineItem()
    }, [])

    return (
        <React.Fragment>

            <div className="table-responsive">
                <table className="table table-bordered dt-responsive nowrap"
                    style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }} >
                    <thead>
                        <tr>
                            <th className="w-15">Product Code</th>
                            <th className="w-15">Product Name</th>
                            <th>List Price ($)</th>
                            {lineItemData[0]?.multiyearpricing?.length > 0 &&
                                lineItemData[0]?.multiyearpricing?.map((year: any, i: any) =>
                                    <th className="text-right w-15" key={Math.random()}>Year {i + 1}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {lineItemData?.length ?
                            lineItemData.map((item: any, index: number) =>
                                <Fragment key={Math.random()}>
                                    <tr>
                                        <td  >
                                            {item?.product?.ProductCode}
                                            <span className="collapse-link">
                                                {
                                                    currentProductId != item?.id ?
                                                        <i className="bx bxs-right-arrow" onClick={() => expandCollapseProduct(item?.id)} />
                                                        :
                                                        <i className="bx bxs-down-arrow" onClick={() => expandCollapseProduct(null)} />
                                                }
                                            </span>
                                        </td>
                                        <td>{item?.product?.Name}</td>
                                        <td className="text-right w-15">${formatNumber(item?.ListPrice)}</td>

                                        {item?.multiyearpricing?.length > 0 &&
                                            item?.multiyearpricing?.map((itemTd: any) =>
                                                <td className="text-right w-15" key={Math.random()}> ${formatNumber(itemTd.TotalPrice)}</td>
                                            )
                                        }
                                    </tr>

                                    <tr style={{ display: (currentProductId == item?.id ? "show" : "none") }}>
                                        <td colSpan={3}>
                                            <p className="text-right">Quantity</p>
                                            <p className="text-right">Sales Price</p>
                                            <p className="text-right">Additional Disc</p>
                                            <p className="text-right">Partner Discount</p>
                                            <p className="text-right">Tax</p>
                                            <p className="text-right">Sub Total</p>
                                        </td>


                                        {item?.multiyearpricing?.length > 0 &&
                                            item?.multiyearpricing.map((yearData: any) =>
                                                <td key={Math.random()}>
                                                    <p className="text-right">{yearData?.Quantity}</p>
                                                    <p className="text-right">${formatNumber(yearData?.UnitPrice)}</p>
                                                    <p className="text-right"><AiOutlineInfoCircle title={`${yearData?.Discount}%`} className='font-size-14' data-toggle="tooltip" data-placement="top" /> ${formatNumber(yearData?.Discountval)}</p>
                                                    <p className="text-right"><AiOutlineInfoCircle title={`${yearData?.partner_discount}%`} className='font-size-14' data-toggle="tooltip" data-placement="top" /> ${formatNumber(yearData?.partner_discount_value)}</p>
                                                    <p className="text-right"><AiOutlineInfoCircle title={`${yearData?.taxper}%`} className='font-size-14' data-toggle="tooltip" data-placement="top" /> ${formatNumber(yearData?.Taxval)}</p>
                                                    <p className="text-right">${formatNumber(yearData?.TotalPrice)}</p>
                                                </td>
                                            )}
                                    </tr>

                                </Fragment>
                            )
                            :
                            <tr>
                                <td colSpan={30} className="text-center">No Data Found</td>
                            </tr>
                        }

                        {/* //=== This section for total values =============// */}
                        {lineItemData?.length &&
                            <Fragment>
                                <tr style={{ borderTop: '3px solid #c1c1c1' }}>
                                    <th colSpan={3} className="text-right">Sub Total</th>
                                    {quoteData?.multiyearpricing?.length > 0 &&
                                        quoteData?.multiyearpricing?.map((yearData: any) =>
                                            <th className="text-right" key={Math.random()}>
                                                ${formatNumber(yearData?.Subtotal)}
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-right">Discount</th>
                                    {quoteData?.multiyearpricing?.length > 0 &&
                                        quoteData?.multiyearpricing?.map((yearData: any) =>
                                            <th className="text-right" key={Math.random()}>
                                                <AiOutlineInfoCircle title={`${(yearData?.Discount == null ? '0.00' : yearData?.Discount)}%`} className='font-size-14' data-toggle="tooltip" data-placement="top" /> &nbsp;
                                                ${yearData?.Discount ? formatNumber(+yearData?.Subtotal * +yearData?.Discount / 100) : "0.00"}
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-right">Total Price</th>
                                    {quoteData?.multiyearpricing?.length > 0 &&
                                        quoteData?.multiyearpricing?.map((yearData: any) =>
                                            <th className="text-right" key={Math.random()}>
                                                ${yearData?.TotalPrice ? formatNumber(yearData?.TotalPrice) : "0.00"}
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-right">Tax</th>
                                    {quoteData?.multiyearpricing?.length > 0 &&
                                        quoteData?.multiyearpricing?.map((yearData: any) =>
                                            <th className="text-right" key={Math.random()}>
                                                <AiOutlineInfoCircle title={`${(yearData?.Tax == null ? '0.00' : yearData?.Tax)}%`} className='font-size-14' data-toggle="tooltip" data-placement="top" /> &nbsp;
                                                ${yearData?.Tax ? formatNumber(yearData?.TotalPrice * +yearData?.Tax / 100) : "0.00"}
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-right">Shipping and Handling</th>
                                    {quoteData?.multiyearpricing?.length > 0 &&
                                        quoteData?.multiyearpricing?.map((yearData: any) =>
                                            <th className="text-right" key={Math.random()}>
                                                ${yearData?.ShippingCharge ? formatNumber(yearData?.ShippingCharge) : "0.00"}
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-right">Grand Total</th>
                                    {quoteData?.multiyearpricing?.length > 0 &&
                                        quoteData?.multiyearpricing?.map((yearData: any) =>
                                            <th className="text-right" key={Math.random()}>
                                                ${yearData?.GrandTotal ? formatNumber(yearData?.GrandTotal) : "0.00"}
                                            </th>
                                        )
                                    }
                                </tr>
                            </Fragment>
                        }

                    </tbody>
                </table>
            </div>

        </React.Fragment >
    )
}

export default LineItems