import React, { useEffect, useRef, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useNavigate } from 'react-router-dom';

interface IncomingProps {
  filterDetails: any;
  doFilter: boolean;
  setDoFilter: any;
  toUpside?: boolean;
  revenueDetails?: any;
  setRevenueDetails?: any
  initialLoadFromPage?: string
}

const CommonBoxDetails = ({ filterDetails, doFilter, setDoFilter, revenueDetails, setRevenueDetails, toUpside = false, initialLoadFromPage }: IncomingProps) => {

  const platformApi = usePlatformApi();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);

  const { showLoader, hideLoader } = useAppLoader()

  const [debouncedFilter, setDebouncedFilter] = useState('default');
  const [pervFilter, setPrevFilter] = useState('');

  const debounce = (fn: any, delay: number) => {
    let timerId: any;
    return function (...args: any) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  };

  const getRevenueDetails = () => {
    // console.log('getRevenueDetails', JSON.stringify(filterDetails), pervFilter)
    if (JSON.stringify(filterDetails) != pervFilter) {
      showLoader();
      platformApi.getRevenueDetails(filterDetails,
        (message: string, resp: any) => {
          if (resp?.data?.list) {
            setRevenueDetails(resp?.data?.list)
          }
          hideLoader();
          setDoFilter(false)
        }, (message: string) => {
          hideLoader();
          setDoFilter(false)
        })
    }
    setPrevFilter(JSON.stringify(filterDetails));
  }


  const gotToContractPage = (quarter: any, type: any) => {
    if (filterDetails && Object.keys(filterDetails).length > 0) {
      navigate(`/contract-page?quarter=${filterDetails?.quarter ? filterDetails?.quarter : quarter}&type=${type}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}&Status=${filterDetails?.Status}`)
    } else {
      navigate(`/contract-page?quarter=${filterDetails?.quarter ? filterDetails?.quarter : quarter}&type=${type}`)
    }
  }

  // console.log('comobx', filterDetails)
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      // setDebouncedFilter(filterDetails)
      getRevenueDetails();
    }
  }, [JSON.stringify(filterDetails)])
  // }, [doFilter])

  // useEffect(() => {
  //   const debouncedFetchData = debounce(getRevenueDetails, 1000);
  //   debouncedFetchData();

  //   return () => {};
  // }, [debouncedFilter]);


  useEffect(() => {
    console.log(typeof (filterDetails?.year))
    // if (filterDetails?.year > 0) {
    if (initialLoadFromPage == 'churn-forecast') {
      getRevenueDetails();
    }
  }, [initialLoadFromPage, filterDetails]);

  return (
    <div className="row dashboard-top-panel">
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card mini-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => gotToContractPage('FY', 'arr_forcasted')} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">ARR / Forecasted</p>
                <h5 className="mb-2">${revenueDetails?.currentfydata?.forcasted?.arr ? revenueDetails?.currentfydata?.forcasted?.arr : 0}</h5>
                <p className="text-muted mb-2">ARR No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.arrno ? revenueDetails?.currentfydata?.forcasted?.arrno : 0}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-file-document-outline" />
                </div>
              </div>
            </div>
            <ul className="dash-para-fix">
              <li className="mb-1">MRR: <strong>${revenueDetails?.currentfydata?.forcasted?.mrr ? revenueDetails?.currentfydata?.forcasted?.mrr : 0}</strong>{" "}</li>
              <li className="mb-1">NRR: <strong>{revenueDetails?.currentfydata?.forcasted?.nrr ? revenueDetails?.currentfydata?.forcasted?.nrr : 0}%</strong>{" "}</li>
              <li className="mb-1">GRR: <strong>{revenueDetails?.currentfydata?.forcasted?.orr ? revenueDetails?.currentfydata?.forcasted?.orr : 0}%</strong>{" "}</li>
              <li>Best: <strong>${revenueDetails?.currentfydata?.forcasted?.best ? revenueDetails?.currentfydata?.forcasted?.best : 0}</strong></li>
              <li className="mb-1">Most Likely:{" "}
                <strong>${revenueDetails?.currentfydata?.forcasted?.mostlikely ? revenueDetails?.currentfydata?.forcasted?.mostlikely : 0}</strong>
              </li>
              <li className="mb-1">Base ARR:{" "}
                <strong>${revenueDetails?.currentfydata?.forcasted?.baseARR ? revenueDetails?.currentfydata?.forcasted?.baseARR : 0}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card blog-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => { gotToContractPage('FY', 'expansion_forcasted') }} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">Expansion / Forecast</p>
                <h5 className="mb-0">${revenueDetails?.currentfydata?.forcasted?.expansion ? revenueDetails?.currentfydata?.forcasted?.expansion : 0}
                  / ${revenueDetails?.currentfydata?.forcasted?.expansion_forecasted ? revenueDetails?.currentfydata?.forcasted?.expansion_forecasted : 0}</h5>
                <p className="text-muted mt-2">Expansion No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.expansionno ? revenueDetails?.currentfydata?.forcasted?.expansionno : 0}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-chart-bell-curve-cumulative" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card blog-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => gotToContractPage('FY', 'renewed')} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">Renewed</p>
                <h5 className="mb-0">${revenueDetails?.currentfydata?.forcasted?.renewed ? revenueDetails?.currentfydata?.forcasted?.renewed : 0}</h5>
                <p className="text-muted mt-2">Renewed No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.renewedno ? revenueDetails?.currentfydata?.forcasted?.renewedno : 0}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-autorenew" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card blog-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">Churned / Forecasted</p>
                <h5 className="mb-0">${revenueDetails?.currentfydata?.forcasted?.churned ? revenueDetails?.currentfydata?.forcasted?.churned : 0}
                  /
                  ${revenueDetails?.currentfydata?.forcasted?.churned_forecasted ? revenueDetails?.currentfydata?.forcasted?.churned_forecasted : 0}</h5>
                <p className="text-muted mt-2">Churned No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.churnedno ? revenueDetails?.currentfydata?.forcasted?.churnedno : 0}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-file-multiple-outline" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonBoxDetails