import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { Navigate, useNavigate } from 'react-router-dom';

interface IncomingProps {
	show: boolean;
	close: () => void;
	id: any;
}

const CreateOpportunityModal = ({ show, close, id }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();
	const [loading, setLoading] = useState<boolean>(false)
	const navigate = useNavigate()

	const createOpportunity = () => {
		showLoader()
		setLoading(true)
		platformApi.createOpportunityFromLead(
			{id},
			(message: any, resp: any)=>{
				console.log({resp})
				navigate(`/add-opportunity/${resp.data.details.AccountId}?id=${resp.data.details.OpportunityId}`)
				hideLoader()
				setLoading(false)
			},
			(message: any)=>{
				hideLoader()
				setLoading(false)
			})
	}

	useEffect(() => {
		console.log(id)
	}, [id])

	useEffect(() => {
	}, [])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal '
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<h5 className="card-title mr-2">Create Opportunity</h5>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<div className="col-12">
								<span>
									Do you want to create an opportunity for this lead?
								</span>
							</div>
							<div className="col-12 modal-footer">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button
										type='button'
										className='btn btn-primary'
										onClick={createOpportunity}
									>
										Yes
									</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={close}
									>
										No
									</button>
								</div>
							</div>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default CreateOpportunityModal