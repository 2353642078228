import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber, showDate } from "../../_common/functions";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { usePlatformApi } from "../../_common/hooks/api/PlatformApiHook";
import GeneralTableListingModal from "../../components/modals/generalTableListing.modal";
import TableLoader from "../../components/commonComponents/TableLoader";


interface IncomingProps {
    filterDetails: any;
    doFilter: boolean;
    setDoFilter: any;
}

const RevenuePossibleLateRenewals = ({ filterDetails, doFilter, setDoFilter }: IncomingProps) => {

    const navigate = useNavigate(); 
    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")
    const regionValue = searchParams.get("region")
    const repValue = searchParams.get("rep")
    const statusValue = searchParams.get("Status")


    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null) 
    const [loading, setLoading] = useState<boolean>(false)

    const [showListingModal, setShowListingModal] = useState<boolean>(false)



    const getlaterenewalsCP = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page }
        if (repValue && repValue != 'undefined') { params['rep'] = repValue };
        if (regionValue && regionValue != 'undefined') { params['region'] = regionValue };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getlaterenewalsCP(params,
            (message: string, resp: any) => {
                setLoading(false);
                setContractData(resp.data.list.data); 
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }


    useEffect(() => {
        getlaterenewalsCP();
    }, [])

    useEffect(() => {
        if (doFilter) {
            getlaterenewalsCP();
        }
    }, [doFilter])

    const handleCloseListingModal = () => {
        setShowListingModal(false)
    }


    return (
        <div>
            <GeneralTableListingModal
                show={showListingModal}
                close={handleCloseListingModal}
                filterDetails={filterDetails}
                doFilter={doFilter}
                setDoFilter={setDoFilter}
                connectPage="Possible Late Renewals List"
            />



            <div className="table-responsive">
                <table id="datatable2" className="table table-striped dt-responsive nowrap"
                    style={{ borderCollapse: "collapse", borderSpacing: "0", width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>Contract</th>
                            <th>Due Date</th> 
                            <th>Amount ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractData && contractData.length > 0 ?
                            contractData.map((data: any, index: number) => {
                                if (index <= 4) {
                                    return (
                                        <tr key={Math.random()}>
                                            <td
                                                className='cursor-pointer text-primary'
                                                onClick={() => navigate(`/account-view/${data?.account?.AccountId}`)}
                                            >
                                                {data?.account?.Name}
                                            </td>
                                            <td
                                                className='cursor-pointer text-primary'
                                                onClick={() => navigate(`/contract-details/${data?.ContractId}`)}
                                            >
                                                {data?.ContractNumber}
                                            </td>
                                            <td>{data?.EndDate ? showDate(data?.EndDate, "MM/DD/YY") : ""}</td>

                                            <td>{data?.ContractValue ? formatNumber(data?.ContractValue) : "0.00"}</td>
                                        </tr>
                                    )
                                }
                            })
                            :
                            null
                        }
                    </tbody>
                </table>
                
                <TableLoader data={contractData}/>
            </div>
            {contractData && contractData.length > 4 &&
                <div className='d-flex justify-content-center mt-3'>
                    <button
                        type='button'
                        className='btn btn-success'
                        onClick={() => setShowListingModal(true)}
                    >
                        view more
                    </button>
                </div>
            }
        </div>

    )
}

export default RevenuePossibleLateRenewals;