import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import Footer from '../../components/commonComponents/Footer'
import { URLS } from '../../_config'
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineInfoCircle } from 'react-icons/ai';
import { Controller, useForm } from 'react-hook-form'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'



interface EditProfile {
    newPassword: string;
    confirmPassword: string;
}
const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("New password is required")
        .min(6, "New password must be at least 6 characters")
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&%|]).{6,32}$/, 'Password does not match allowed format.'),

    confirmPassword: Yup.string().required("Confirm password is required").min(6, 'Confirm password must be at least 6 characters').oneOf([Yup.ref('newPassword'), ''], 'New password and confirm password must match'),
});



const MyChangePassword = () => {
    const navigate = useNavigate();
    const commonApi = useCommonApi()
    const userApi = useUserApi();

    const { showLoader, hideLoader } = useAppLoader();

    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    const { control, formState: { errors }, handleSubmit, setValue, watch, reset } = useForm<EditProfile>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
        },
    });


    const onSubmit = (data: any) => {
        let params: any = {
            password: data.newPassword,
        }
        showLoader();
        commonApi.updatepasword(params, (message: string, resp: any) => {
            toast.success(resp?.message, { duration: 5000 })

            userApi.logout({}, (message: string, resp: any) => {
            }, (message: string) => {
            })

            navigate(URLS.LOGIN)
            hideLoader();
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            {/* <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Change Password</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.SUMMARY}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Change Password</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* end page title */}



                            <div className="row">
                                <div className="col-md-3 col-sm-12"></div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Change Password  <hr /></h5>

                                            </div>

                                            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>

                                                <div className="row">

                                                    <div className="form-group col-12">
                                                        <label htmlFor="newPassword">New Password <span className='text-danger'>*</span> </label>
                                                        <div className="cstm-password">
                                                            <Controller
                                                                name="newPassword"
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <input
                                                                        type={!showNewPass ? "password" : "text"}
                                                                        className={"form-control " + (errors?.newPassword ? ' border-danger' : '')}
                                                                        id="newPassword"
                                                                        autoComplete="new-password"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter new password"
                                                                    />
                                                                )}
                                                            />
                                                            {!showNewPass ?
                                                                <AiOutlineEyeInvisible onClick={() => setShowNewPass(!showNewPass)} />
                                                                :
                                                                <AiOutlineEye onClick={() => setShowNewPass(!showNewPass)} />
                                                            }
                                                        </div>
                                                        <AiOutlineInfoCircle title='At least 1 digit, 1 lowercase, 1 uppercase, 1 special character & minimum 6 characters.' />
                                                        &nbsp;&nbsp;
                                                        {errors && errors.newPassword &&
                                                            <span className='text-danger'>{errors?.newPassword?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label htmlFor="confirmPassword">Confirm Password <span className='text-danger'>*</span> </label>
                                                        <div className="cstm-password">
                                                            <Controller
                                                                name="confirmPassword"
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <input
                                                                        type={!showConfPass ? "password" : "text"}
                                                                        className={"form-control " + (errors?.confirmPassword ? ' border-danger' : '')}
                                                                        id="confirmPassword"
                                                                        autoComplete="new-password"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter confirm password"
                                                                    />
                                                                )}
                                                            />
                                                            {!showConfPass ?
                                                                <AiOutlineEyeInvisible onClick={() => setShowConfPass(!showConfPass)} />
                                                                :
                                                                <AiOutlineEye onClick={() => setShowConfPass(!showConfPass)} />
                                                            }
                                                        </div>
                                                        {errors && errors.confirmPassword &&
                                                            <span className='text-danger'>{errors?.confirmPassword?.message}</span>
                                                        }
                                                    </div>


                                                    <div className="col-12">
                                                        <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                            <button type='submit' className='btn btn-primary'>Update</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default MyChangePassword